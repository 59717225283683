import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './Topup.scss';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../../../../../../../_helpers';
import CurrencySelectionModal from './CurrencySelectionModal';

interface Props {
  show: boolean;
  onClose: any;
  onSave: any;
  onconfirm: any;
}

const ProductionSelectionModal = (props: Props, setModalVisibility: any) => {
  const [showModal, setShow] = useState(props.show);
  const [selectedMethod, setSelectedMethod] = useState('');
  const [showCurrency, setShowCurrency] = useState(false);
  const user = getUser()?.data ?? null;

  const handleNiobiApi = () => {
    setShow(false);
    setShowCurrency(true);
    // props.onconfirm(true);
  };
  const handleNiobiApp = () => {
    setShow(false);
    setShowCurrency(true);
    // props.onconfirm(true);
  };

  
  const onClose = () => {
    setShow(false);
    props.onClose(false);
  };
  return (
    <>
      <>
       
          <Modal.Header closeButton>
            <Modal.Title>Account Setup</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="label">I am interested in</div>
            <div className="type-card-list">
              <div
                id="main_fast_buttun_data"
                className={`type-card-twoo ${
                  selectedMethod === 'Niobi App' ? 'active' : ''
                }`}
                onClick={() => setSelectedMethod('Niobi App')}
              >
                <span id="sdFsfsfsfsfff" className="type-card__name">
                  Niobi App
                </span>
              </div>
              <div
                className={`type-card-twoo ${
                  selectedMethod === 'Niobi API' ? 'active' : ''
                }`}
                onClick={() => setSelectedMethod('Niobi API')}
              >
                <span id="sdFsfsfsfsfff" className="type-card__name">
                  Niobi API
                </span>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button className="white-btn" onClick={onClose}>
              Cancel
            </Button>

            <Button
              className="green-btn"
              disabled={selectedMethod === ''}
              onClick={() => {
                if (selectedMethod === 'Niobi App') {
               
                  handleNiobiApp();
                } else {
                  handleNiobiApi();
                }
              }}
            >
              Next
            </Button>
          </Modal.Footer>
       
      </>
      {showCurrency && (
        <CurrencySelectionModal
          show={showCurrency}
          onClose={() => {
            setShowCurrency(false);
            props.onClose();
            // setShowBalanceGrid(true);
          }}
          onSave={(el: boolean) => {
            setShowCurrency(false);
            props.onClose();
            // setCereat_Teabal(true)
          }}
          onconfirm={(el: boolean) => {
            setShowCurrency(false);
            props.onClose();
            // setIsOpen(true)
          }}
        />
      )}
    </>
  );
};

export default ProductionSelectionModal;

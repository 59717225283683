import { Box } from "@mui/material"
import { useState } from "react"
import Step1 from "./Components/Step1/Step1"
import Step2 from "./Components/Step2/Step2"
import Step3 from "./Components/Step3/Step3"
import { payablesFlowactions } from "../../../../../../../../_actions"
import { useDispatch } from "react-redux"
import { Setp4 } from "./Components/Step4/step4"
import moment from "moment"

const SchedulePayment = ({selectedFlag,users, setFormik, setIsSchedulePayment, formik, selectedPayee, time, setTime, setEnableButton, enableButton, Submitsetp3or4, ExpressPayment, setUseLayout, setIsStepsNavHidden }: any) => {
  const [scheduleStep, setScheduleStep] = useState(0);
  const [statusLoader, setStatusLoader] = useState('')
  const [loaderpayment, setLoaderPayment] = useState(false)
  const dispatch = useDispatch();
console.log(statusLoader,"statusLoader");

  const [loader, setLoader] = useState(false)
  const formatTime = (time: Date) => {
    const hours = String(time.getHours()).padStart(2, '0');
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  } 



  const ScheduleOtpres = (data: any) => {
    setEnableButton(false)
    setUseLayout("popuplayout")
    setTime(120);
    setScheduleStep(2)
    setTimeout(() => {
      setEnableButton(true)
    }, 120000)
  }
  const SchedulePaymentRes = (data: any, status: any) => {
    if (status == "success") {
      setScheduleStep(3)

      setStatusLoader("success")
    }else{
      setScheduleStep(3)
      setStatusLoader("failed")

    }
  }
  console.log(moment(formik.time, 'HH:mm:ss').format('HH:mm:ss'), formik.time, formatTime(new Date(formik.time)), "moment(formik.time).format('HH:mm:ss')");

  const schedulePaymentPayload = (otp: any) => {
    var body = {
      "otp": otp,
      "payment_type": "express", // "express", "bill", "bulk"
      "currency": formik.currency,
      "country_id": formik.country_id,
      "total_amount": Number(formik.amount),
      "date": moment(formik.date).format("YYYY-MM-DD HH:mm:ss"),
      "time": formatTime(new Date(formik.time)), // 1,2,3 ... 24
      "data": [{
        "monite_counterpart_id": formik?.counterpart?.monite_counterpart_id,
        // "account_number": 80000003, // required if payment_type is express or bill
        "amount": Number(formik.amount),
        "fees": Number(formik.Fees),
        "vat": formik.vat,
        "invoice_record": formik.invoice_record, // required if payment_type is express
        "description": formik.description, // required if payment_type is express
        // "payables": [1234, 1234, 1234], // required if payment_type is bulk.
        // "payment_details": {}, // required if payent_type is bill
        "tags": formik.tags?.map((item: any) => {
          return item.id;
        }),
        "team": formik.teams?.map((item: any) => {
          return item.id;
        }), // optional
        "payment_method_type": formik.payment_channel
      }]
    }
    setStatusLoader("pending")
    dispatch(payablesFlowactions.PostPaymentfull("/v4/schedule-payments", body, SchedulePaymentRes, setLoaderPayment,false) as any)
  }

  const submitScheduleForOtp = () => {
    setLoader(true)
    dispatch(payablesFlowactions.getApipayment(`/get-otp`, ScheduleOtpres) as any);
  }
  return (
    <>
      {
        scheduleStep === 0 && <Step1 setFormik={setFormik} setIsStepsNavHidden={setIsStepsNavHidden} selectedPayee={selectedPayee} formik={formik} setIsSchedulePayment={setIsSchedulePayment} setScheduleStep={setScheduleStep} />
      }
      {
        scheduleStep === 1 && <Step2 selectedFlag={selectedFlag} users={users} loader={loader} setUseLayout={setUseLayout} formik={formik} setScheduleStep={setScheduleStep} submitScheduleForOtp={submitScheduleForOtp} />
      }
      {
        scheduleStep === 2 && <Step3 schedulePaymentPayload={schedulePaymentPayload} setUseLayout={setUseLayout} ExpressPayment={ExpressPayment} formik={formik} time={time} setTime={setTime} enableButton={enableButton} setEnableButton={setEnableButton} setScheduleStep={setScheduleStep} />
      }
      {
        scheduleStep === 3 && <Setp4 formik={formik} payment_method={formik.payment_method} statusLoader={statusLoader} amount={formik.amount} currancy={formik.currency} payee={formik.counterpart.name} onClick={() => { }} />
      }
    </>
  )
}

export default SchedulePayment
import { Box } from '@mui/material'
import "./model.scss"




const Model = ({children , isModelOpen , setIsModelOpen} : any) => {

   if(!isModelOpen) return null
  return (
     <Box className="model_container d-flex flex-column justify-content-center">
        {children}
     </Box>
  )
}

export default Model

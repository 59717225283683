import { payablesConstants } from '../_constants/payables.constants'

export function payablesCreate(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.PAYABLES_REQUEST:
            return {
                registering: true,
                loading: true

            }
        case payablesConstants.PAYABLES_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            }
        case payablesConstants.PAYABLES_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            }
        default:
            return state
    }
}
export function payablesCreateOTP(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.OTP_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case payablesConstants.OTP_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.otp.data,
                loading: false,
                success: true,
            };
        case payablesConstants.OTP_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state;
    }
}
export function transactionExistReducer(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.GET_TRANSACTIONEXIST_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case payablesConstants.GET_TRANSACTIONEXIST_SUCCESS:
            return {
                data:action.otp,
                loading: false,
                success: true,
            };
        case payablesConstants.GET_TRANSACTIONEXIST_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state;
    }
}

export function initailPaymentReducer(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.INITIATE_PAYMENT_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case payablesConstants.INITIATE_PAYMENT_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case payablesConstants.INITIATE_PAYMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function initailPayment_BULKReducer(state = {}, action: any) {

    switch (action.type) {
        case payablesConstants.INITIATE_BULK_PAYMENT_REQUEST:
            return {
                loading: true
            };
        case payablesConstants.INITIATE_BULK_PAYMENT_SUCCESS:
            return {

                data: action.payables,
                loading: false,
            };
        case payablesConstants.INITIATE_BULK_PAYMENT_FAILURE:
            return {
                error: action.error,
                loading: false,
            };
        default:
            return state
    }
}
export function getPayablesListReducer(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.GETPAYABLES_FROM_STORE:
            return {
                loading: true
            };
        case payablesConstants.GETPAYABLES_SUCCESS:
            return {
                data: action.data
            };
        case payablesConstants.GETPAYABLES_NEW_SUCCESS:
            return {
                data: action.data
            };
        case payablesConstants.GETPAYABLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function bulkpayables(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.BULKPAYABLES_REQUEST:
            return {
                loading: true,
            };
        case payablesConstants.BULKPAYABLES_SUCCESS:
            return {
                data: !!action.data ? action.data : action.payables.data,
            };
        case payablesConstants.BULKPAYABLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function bulkbills(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.BULKBILLS_REQUEST:
            return {
                loading: true
            };
        case payablesConstants.BULKBILLS_SUCCESS:
            return {
                data: action.data
            };
        case payablesConstants.BULKBILLS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function setSelectedItem(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.GETSELECTEDPAYABLES_FROM_STORE:
            return {
                loading: true
            };
        case payablesConstants.GETSELECTEDPAYABLES_SUCCESS:
            return {
                data: action.data
            };
        case payablesConstants.GETSELECTEDPAYABLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function create_recurring_bill_Reducer(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.PAYABLES_RECURRING_BILL_REQUEST:
            return {
                loading: true
            };
        case payablesConstants.PAYABLES_RECURRING_BILL_SUCCESS:
            return {
                data: action.data
            };
        case payablesConstants.PAYABLES_RECURRING_BILL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function submit_recurring_bill_Reducer(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.PAYABLES_RECURRING_SUBMIT_BILL_REQUEST:
            return {
                loading: true
            };
        case payablesConstants.PAYABLES_RECURRING_SUBMIT_BILL_SUCCESS:
            return {
                data: action.data
            };
        case payablesConstants.PAYABLES_RECURRING_SUBMIT_BILL_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getTransactionsReducer(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.TRANSACTION_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case payablesConstants.TRANSACTION_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case payablesConstants.TRANSACTION_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export function getpayable_id_reducer(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.GETPAYABLES_ID_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case payablesConstants.GETPAYABLES_ID_SUCCESS:
            return {
                data: action.data,
                loading: false,
                success: true,
            };
        case payablesConstants.GETPAYABLES_ID_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function getTransactionsReducer_mass_data(state = {}, action: any) {
 
    switch (action.type) {

        case payablesConstants.TRANSACTION_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case payablesConstants.TRANSACTION_SUCCESS:


            return {
                data: !!(action.data) ? action.data : action.payables,
                loading: false,
                success: true,
            };
        case payablesConstants.TRANSACTION_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}
export function getOtpvirifay(state = {}, action: any) {
    switch (action.type) {
        case payablesConstants.OTPVIREFAT_REQUEST:
            return {
                registering: true,
                loading: true
            }
        case payablesConstants.OTPVIREFAT_SUCCESS:
            return {
                data: !!(action.data) ? action.data : action.payables.data,
                loading: false,
                success: true,
            };
        case payablesConstants.OTPVIREFAT_FAILURE:
            return {
                error: action.error,
                loading: false,
                success: false,
            };
        default:
            return state
    }
}

export const selectAllPayablesListData = (state: any) => {
    return {
        'GETPAYABLES_SUCCESS': state.getPayablesListReducer.data,
        "GETPAYABLES_FAILURE": state.getPayablesListReducer.error,
        "GETPAYABLES_NEW_SUCCESS": state.getPayablesListReducer.data,
        'GETSELECTEDPAYABLES_SUCCESS': state.setSelectedItem.data,
        'OTP_SUCCESS': state.payablesCreateOTP.success,
        'OTP_FAILURE': state.payablesCreateOTP.error,
        'OTP_REQUEST': state.payablesCreateOTP.loading,
        'GET_TRANSACTIONEXIST_SUCCESS': state.transactionExistReducer.data,
        'GET_TRANSACTIONEXIST_FAILURE': state.transactionExistReducer.error,
        'GET_TRANSACTIONEXIST_REQUEST': state.transactionExistReducer.loading,
        'INITIATE_PAYMENT_SUCCESS': state.initailPaymentReducer.data,
        'INITIATE_PAYMENT_REQUEST': state.initailPaymentReducer.loading,
        'INITIATE_PAYMENT_FAILURE': state.initailPaymentReducer.error,

        'INITIATE_BULK_PAYMENT_SUCCESS': state.initailPayment_BULKReducer.data,
        'INITIATE_BULK_PAYMENT_REQUEST': state.initailPayment_BULKReducer.loading,
        'INITIATE_BULK_PAYMENT_FAILURE': state.initailPayment_BULKReducer.error,
        'TRANSACTION_SUCCESS': state.getTransactionsReducer.data,
        'TRANSACTION_FAILURE': state.getTransactionsReducer.error,

        'TRANSACTION_REQUEST': state.getTransactionsReducer.loading,

        'GETPAYABLES_ID_SUCCESS': state.getpayable_id_reducer.data,
        'GETPAYABLES_ID_FAILURE': state.getpayable_id_reducer.error,

        'GETPAYABLES_ID_REQUEST': state.getpayable_id_reducer.loading,


        "TRANSACTION_SUCCESS_DATA": state.getTransactionsReducer_mass_data.data,
        'OTPVIREFAT_SUCCESS': state.getOtpvirifay.data,
        'OTPVIREFAT_FAILURE': state.getOtpvirifay.error,
        'OTPVIREFAT_REQUEST': state.getOtpvirifay.loading,
        'PAYABLES_REQUEST': state.payablesCreate.loading,
        'PAYABLES_SUCCESS': state.payablesCreate.data,
        'PAYABLES_FAILURE': state.payablesCreate.error,

        'PAYABLES_RECURRING_BILL_REQUEST': state.create_recurring_bill_Reducer.loading,
        'PAYABLES_RECURRING_BILL_SUCCESS': state.create_recurring_bill_Reducer.data,
        'PAYABLES_RECURRING_BILL_FAILURE': state.create_recurring_bill_Reducer.error,

        'PAYABLES_RECURRING_SUBMIT_BILL_REQUEST': state.submit_recurring_bill_Reducer.loading,
        'PAYABLES_RECURRING_SUBMIT_BILL_SUCCESS': state.submit_recurring_bill_Reducer.data,
        'PAYABLES_RECURRING_SUBMIT_BILL_FAILURE': state.submit_recurring_bill_Reducer.error,

        'BULKPAYABLES_REQUEST': state.bulkpayables.loading,
        'BULKPAYABLES_SUCCESS': state.bulkpayables.data,
        'BULKPAYABLES_FAILURE': state.bulkpayables.error,


        'BULKBILLS_REQUEST': state.bulkbills.loading,
        'BULKBILLS_SUCCESS': state.bulkbills.success,
        'BULKBILLS_FAILURE': state.bulkbills.error,
    };
}
import { useState } from 'react';
import style from './Accounting_System.module.scss';
import { getUser, isUserAuthenticated } from "../../../../../../_helpers";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import React from 'react';
import { useDispatch, useSelector } from "react-redux";

interface Props {
    onclick: any;

}
const Accounting_System = (props: Props) => {
    const [render, setRender] = useState(true);
    const [render_two, setRender_two] = useState(true);
    const dispatch = useDispatch();
    const dataList = useSelector((state) => selectPaymentJourneyData(state))

    const [data_add, setData_add] = useState([])

    React.useEffect(() => {
        if (render_two && dataList.GET_ACCOUNTING_TYPE_SUCCESS !== undefined) {
            setData_add(dataList.GET_ACCOUNTING_TYPE_SUCCESS)
            setRender_two(false)
        }
    }, [render_two, dataList]);
    // create_connection
    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            dispatch(paymentJourneyActions.get_accounting_types_active() as any)
            setRender(false)
        }
    }, [render, isUserAuthenticated]);
    // get_accounting_types_active
    const onSave = (data: any) => {
        var body = {
            platform: data.name
        }
        dispatch(paymentJourneyActions.create_connection(body) as any)
        setTimeout(() => {
            props.onclick(data)
        }, 4000)

    }
    return (
        <>
            <div className={style.main_dev_contean}>
                <p className={style.heding_tage_listtt}>
                    Choose an Accounting System
                </p>
                <div className={style.div_main_calssname_this}>
                    {data_add.map((element: any, index: number) => {


                        return (

                            <div className={style.contenar_data} onClick={() => { onSave(element) }}>
                                <img src={element.image} />
                                <p>
                                    {element.name}
                                </p>

                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}
export default Accounting_System;
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getUser, isUserAuthenticated, authHeader } from '../../../../../../_helpers';
import axios from 'axios';
import moment from 'moment';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import toast from "react-hot-toast";



const Popup = (props: any) => {
  const [show, setShowlist] = useState(props.show == true ? 0 : '');
  const [selectedMethod_two, setSelectedMethod_two] = useState('');
  const [CsvUrl, setCsvUrl] = useState<any>([]);
  const [select_pdf, setSelect_pdf] = useState('')



  const onClose_topup = () => {
    props.onClose();
    setShowlist('300');
    // setBankResponseshow(false);
  };

  const getCsvurl = async (typemethod: any, search: any, startdate: any, enddate: any, category: any, payee: any, type: any, currancy: any, team: any) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    };
    const stateDateApi = !!startdate
      ? moment(startdate).format('YYYY-MM-DD')
      : '';
    const endDateApi = !!enddate
      ? moment(enddate).format('YYYY-MM-DD')
      : '';
    props.setLoadercsv(true)
    const response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/export-transaction-csv?search=${search}&type=${typemethod == "Niobi" ? '' : typemethod}&start-date=${stateDateApi}&end-date=${endDateApi}&category=${category}&payee=${payee}&payment_type=${type}&currency=${currancy}&team=${team}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success === true) {
          setCsvUrl(response.data);

          if (response?.data?.csv) {
            window.location.href = response.data.csv;
          }
          props.setLoadercsv(false)
          props.setShowSuccessPopup(true);
          if (!!response && response?.data?.csv)
            window.location.href = response?.data?.csv
          onClose_topup();
          // setPdfUrl(csvUrl.pdf);
        } else {
          props.setLoadercsv(false)
          props.setShowSuccessPopup(false);
        }
      }).catch((error) => {
        props.setLoadercsv(false)
        props.setShowSuccessPopup(false);
        onClose_topup();

        console.log(error.response.data.message, "responseresponse");
        toast.error(error.response.data.message);
      });
  };
  return (
    <>
      <Modal
        show={show == 0 ? true : false}
        onHide={() => {
          setShowlist('');
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="ModalHeader"
        className="topup-modal"
        centered
        size="lg"
      >
        <Box className="CloseContenar">
          <IconButton onClick={() => {
            onClose_topup();
          }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="heding_contean">
          <Typography>
            How do you want to export statement?
          </Typography>
        </Box>
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}

        <Modal.Body>
          <div className="label">Download Options</div>
          <div className="type-card-list">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'Niobi' ? 'active' : ''
                }`}
              onClick={() => {
                setSelectedMethod_two('Niobi')
                setShowlist(1);
                getCsvurl('Niobi', props.search, props.startdate, props.enddate, props.category, props.payee, props.type, props.currancy, props.team)
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "70px", height: "45px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} height={'100%'} src='/Niobi-Logo (3).png' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Standard</p>
                {/* <p className="Kenyan_heding_text">Kenyan Shilling</p> */}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'quickbooks' ? 'active' : ''
                }`}
              onClick={() => {
                setSelectedMethod_two('quickbooks')
                setShowlist(1);
                getCsvurl('quickbooks', props.search, props.startdate, props.enddate, props.category, props.payee, props.type, props.currancy, props.team)
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "35px", height: "35px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} src='/images.jpeg' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Quickbooks</p>
                {/* <p className="Kenyan_heding_text">United States Dollar</p> */}
              </div>
            </div>
          </div>
          <div className="type-card-list">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'sage' ? 'active' : ''
                }`}
              onClick={() => {

                setSelectedMethod_two('sage')
                setShowlist(1);
                getCsvurl('sage', props.search, props.startdate, props.enddate, props.category, props.payee, props.type, props.currancy, props.team)
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "35px", height: "35px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} src='/Sage-logo_svg.svg.png' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Sage</p>
                {/* <p className="Kenyan_heding_text">Kenyan Shilling</p> */}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'zoho' ? 'active' : ''
                }`}
              onClick={() => {
                setSelectedMethod_two('zoho')
                setShowlist(1);
                getCsvurl('zoho', props.search, props.startdate, props.enddate, props.category, props.payee, props.type, props.currancy, props.team)
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "35px", height: "35px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} src='/356-3560958_zoho-books-logo-hd-png-download.png' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Zoho Books</p>
                {/* <p className="Kenyan_heding_text">United States Dollar</p> */}
              </div>
            </div>
          </div>
          <div className="type-card-list">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={`type-card ${selectedMethod_two === 'xero' ? 'active' : ''
                }`}
              onClick={() => {

                setSelectedMethod_two('xero')
                setShowlist(1);
                getCsvurl('xero', props.search, props.startdate, props.enddate, props.category, props.payee, props.type, props.currancy, props.team)
                props.OnSave('')
              }}
            >
              <div className="heding_centewr_data">
                <div style={{ width: "35px", height: "35px", margin: "auto", marginBottom: "4px" }}>
                  <img width={'100%'} src='/download.png' />
                </div>
                <p className="kes_text_stylee" style={{ fontWeight: "unset" }}>Xero</p>
              </div>
            </div>
          </div>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button
            className="white-btn"
            onClick={() => {
              onClose_topup();
            }}
          >
            Cancel
          </Button>
          <Button
            id="next_button_dataaa"
            className="green-btn"
            disabled={selectedMethod_two === ''}
            onClick={() => {
              setShowlist(1);
              getCsvurl('')
              props.OnSave('')
            }}
          >
            Next
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  )
}
export default Popup;
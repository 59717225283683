import { Box } from '@mui/material'
import SuccessComponent from '../../../../../../../ui/ReuseableComponents/Notifications/Success/Success'
import ProgressComponent from '../../../../../../../ui/ReuseableComponents/Notifications/Progress/Progress'
import PaymentFailed from '../../../../../../../ui/ReuseableComponents/Notifications/FailedPayment/PaymentFailed'
import { MakePaymentTransactionDetails } from '../../../../../../../ui/TransactionDetails/MakePaymentTransactionDetails'
import Waiting from '../../../../../../../ui/ReuseableComponents/Notifications/Waiting/Waiting'
import Payment_success from '../../../../../../../ui/USD_Payment_success/Payment_success'
import Bulk_payment_summary from '../../../../../../../ui/ReuseableComponents/BulkPaymentSummary/BulkPaymentSummary'
import Step5 from '../../../ExpressPayment/Components/Step5/Step5'


function Step6({selectedRetryIds , setSelectedRetryIds , retryPaymentOtp , paymentApiResdata , loaderStatus, setLoaderStatus, stepupdatefun, transactionDetailsReload ,setUseLayout , formik , setFormik}: any) {
    return (
        // <Step5 loaderStatus={loaderStatus} transactionDetailsReload={transaction_detailReload} setLoaderStatus={setLoaderStatus}  stepupdatefun={stepupdatefun} />
        <Box style={{maxWidth:"100%"}}>  
            { 
                transactionDetailsReload ? ( 
                    <Bulk_payment_summary formik={formik} setFormik={setFormik} setUseLayout={setUseLayout} stepupdatefun={stepupdatefun} selectedRetryIds={selectedRetryIds} setSelectedRetryIds={setSelectedRetryIds} retryPaymentOtp={retryPaymentOtp} paymentApiResdata ={paymentApiResdata} />
                ) : (
                <>
                    {loaderStatus == "success_USD" ?
                        (   
                            <Payment_success />
                        ) : 
                        loaderStatus === "pending" ? (<ProgressComponent
                            text="Your transaction is being processed. Please do not navigate away from this screen" />)
 
                            : loaderStatus === "success" ? (<SuccessComponent
                                heading="Transaction successful" />)

                                : loaderStatus === "failed" ? (<PaymentFailed
                                    heading="Transaction failed"
                                    text="We apologise as we were not able to process your payment request. Please try again later."
                                    buttonText="Close"
                                    onClick={() => { window.location.href = '/balance' }} />)

                                    : loaderStatus === "waiting" ? (<Waiting
                                        heading="Transaction in progress"
                                        text1="The payment is still being processed. We request for your patience as we work to complete the process."
                                        text2="In the meantime, please do not attempt making the transaction again. You can review the transaction status on the balance screen under pending transactions."
                                        onClick={() => { window.location.href = '/balance' }}
                                        buttonText="Close"
                                    />)

                                        : ""
                    }
                </>
                )
            }
        </Box>
    )
}

export default Step6
import { Box, createTheme, MenuItem, Select, TextField, ThemeProvider, FormControl, SelectChangeEvent, OutlinedInput, InputLabel, Checkbox, ListItemText, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";


export const CurrencySelectBox = ({ value, onChange, countryList, style, sx, ...props }: any) => {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        sx={sx}
        // IconComponent={(props)=><ArrowForwardIcon {...props}/>}
        style={{
          padding: "0px",
          width: "95px",
          borderRadius: "30px",
          height: "28px",
          ...style
        }}
        value={value}
        onChange={onChange}
        {...props}

      >
        {Array.isArray(countryList) && countryList.map((item: any, index) => {
          return (
            <MenuItem
              disabled={item.total_balance == 0 ? true : false}
              value={item.currency}
              key={index}>
              <Box className="MenuItemBox" >
                <img
                  loading="lazy"
                  width="25"
                  src={item.flag}
                  alt=""
                />
                <p>
                  {item.currency}
                </p>
              </Box>
            </MenuItem>

          )
        })}
      </Select>
    </ThemeProvider>
  )
}

export const InputSelectBox = ({
  value,
  onChange,
  currencyList,
  style,
  type,
  readOnly = false,
  ...props
}: any) => {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <TextField
        value={value}
        type={type}
        onChange={onChange}
        style={{
          width: "100%",
          minHeight: "48px",
          maxHeight: "48px",
          borderRadius: "10px",
          ...style
        }}

        sx={{
          '& .MuiOutlinedInput-root': {
            minHeight: "48px",
            maxHeight: "48px",
            borderRadius: "5px",
            fontSize: "12px",
            paddingLeft: "9px",
            fontFamily: "Poppins",
            '&.Mui-focused': {
              borderColor: "#015241",
              boxShadow: "0px 0px 0px 4px rgba(1, 82, 65, 0.25)",
            }
          },
          '& .MuiInputBase-input': {
            // height: "35px",
            // padding: "0 10px !important",
            // margin: "0 !important",
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              display: 'none',
            },
            '-moz-appearance': 'textfield',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            // border: '1px solid rgba(0,0,0,0.23) !important',
            // borderRadius: "10px",
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            // border: '1px solid rgba(0,0,0,0.87) !important',
          },
        }}

        InputProps={{
          readOnly: readOnly,
          endAdornment: (
            <Box>
              {currencyList}
            </Box>
          )
        }}
        {...props}
      />
    </ThemeProvider>
  );
};



/////////////

////Use Options for Dynamic Select Component 
/// use it where it use


const options = [
  {
    label: 'Select bank',
    value: 'default',
    component: null,
  },
  {
    label: 'Stanbic bank',
    value: 'option1',
    component: <div style={{
      width: "100%",
      height: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F2F2F2"
    }}><h1>I am Tab 1</h1></div>,
  },
  {
    label: 'Option 2',
    value: 'option2',
    component: <div style={{
      width: "100%",
      height: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F2F2F2"
    }}><h1>I am Tab 2</h1></div>,
  },
  {
    label: 'Option 3',
    value: 'option3',
    component: <div style={{
      width: "100%",
      height: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#F2F2F2"
    }}><h1>I am Tab 3</h1></div>,
  },
];

interface Option {
  label: string;
  value: string;
  component: React.ReactNode | null;
}

export const DynamicSelectComponent = ({
  options,
  value,
  onChange,
  inputValue,
  onInputChange,
  style
}: any) => {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <TextField
        value={inputValue}
        onChange={onInputChange}
        variant="outlined"
        style={{
          width: "100%",
          minHeight: "48px",
          maxHeight: "48px",
          borderRadius: "5px",
          ...style
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            minHeight: "48px",
            maxHeight: "48px",
            borderRadius: "5px",
            fontSize: "12px",
            paddingLeft: "9px",

          },
        }}
        InputProps={{
          endAdornment: (
            <Select
              value={value}
              onChange={onChange}
              displayEmpty
              disableUnderline
              style={{
                width: "140px",
                minHeight: "48px",
                maxHeight: "48px",
                marginLeft: "10px",
                border: "none"
              }}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none !important',
                },
                '& .MuiSelect-select': {
                  padding: "0 10px",
                },
                '& .MuiSelect-icon': {
                  color: 'inherit',
                },
                '& .MuiSelect-select:focus': {
                  backgroundColor: 'transparent !important',
                },
              }}
              input={<OutlinedInput label="Select Option" />}
            >
              {Array.isArray(options) &&
                options.map((option: any, index: number) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Box display="flex" alignItems="center">
                      {/* {option.component && <div>{option.component}</div>} */}
                      <p>{option.label}</p>
                    </Box>
                  </MenuItem>
                ))}

            </Select>
          ),
        }}
      />
      <Box mt={2} style={{ width: '100%' }}>
        {value !== 'default' &&
          options.find((option: any) => option.value === value)?.component}
      </Box>
    </ThemeProvider>
  );
}


////* TheSelectBox *////
export const TheSelectBox = ({
  value,
  onChange,
  options,
  style,
  defaultOption,
  ...props
}: any) => {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const selectedValue = value || defaultOption;

  return (
    <ThemeProvider theme={theme}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        onChange={onChange}
        fullWidth
        style={{
          padding: "0px",
          borderRadius: "5px",
          minHeight: "48px",
          maxHeight: "48px",
          border: "none",
          // paddingLeft: "12px",
          ...style,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            minHeight: "48px",
            maxHeight: "48px",
            borderRadius: "5px",
            fontSize: "12px",
            fontFamily: "Poppins",


          },
          '& .MuiTypography-root': {
            fontFamily: "Poppins",
            fontSize: "12px",
            '&.Mui-focused': {
              borderColor: "#015241",
              boxShadow: "0px 0px 0px 4px rgba(1, 82, 65, 0.25)",
            }

          },
        }}
        {...props}
      >
        <MenuItem disabled={false} value={defaultOption}>
          <Typography style={{ color: "#515151" }}>{defaultOption}</Typography>
        </MenuItem>

        {Array.isArray(options) &&
          options.map((item: any, index: number) => (
            <MenuItem
              disabled={item.total_balance === 0}
              value={item.value}
              key={index}
            >

              <Box className="MenuItemBox">
                <p>{item.name}</p>
              </Box>
            </MenuItem>
          ))}
      </Select>
    </ThemeProvider>
  );
};

export const BankSelectBox = ({
  value,
  onChange,
  options,
  style,
  defaultOption,
  ...props
}: any) => {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const selectedValue = value || defaultOption;

  return (
    <ThemeProvider theme={theme}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        onChange={onChange}
        fullWidth
        style={{
          padding: "0px",
          borderRadius: "5px",
          minHeight: "48px",
          maxHeight: "48px",
          border: "none",
          // paddingLeft: "12px",
          ...style,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            minHeight: "48px",
            maxHeight: "48px",
            borderRadius: "5px",
            fontSize: "12px",

          },
        }}
        {...props}
      >
        <MenuItem disabled={false} value={defaultOption}>
          <Typography style={{ color: "#6D6B6B" }}>{defaultOption}</Typography>

        </MenuItem>

        {Array.isArray(options) &&
          options.map((item: any, index: number) => (
            <MenuItem
              disabled={item.total_balance === 0}
              value={item.id}
              key={index}
            >
              <Box className="MenuItemBox">
                <p>{item.bank_name}</p>
              </Box>

            </MenuItem>
          ))}
      </Select>
    </ThemeProvider>
  );
};




// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 500,
//     },
//   },
// };


const MultipleSelectCheckmarks = ({ options, label, value, onChange }: any) => {
  return (
    <Select
      labelId="multiple-checkbox-label"
      id="multiple-checkbox"
      multiple
      value={value}
      onChange={onChange}
      input={<OutlinedInput label={label} />}
      renderValue={(selected) => selected.join(', ')}
      // MenuProps={MenuProps}
      style={{ width: "100%", border: "none" }}
    >
      {options.map((option: any) => (
        <MenuItem key={option} value={option}>
          <Checkbox checked={value.includes(option)} />
          <ListItemText primary={option} />
        </MenuItem>
      ))}
    </Select>

  );
};

export const BankSelectBoxName = ({
  value,
  onChange,
  options,
  style,
  defaultOption,
  ...props
}: any) => {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const selectedValue = value || defaultOption;

  return (
    <ThemeProvider theme={theme}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        onChange={onChange}
        fullWidth
        style={{
          padding: "0px",
          borderRadius: "5px",
          minHeight: "48px",
          maxHeight: "48px",
          border: "none",
          // paddingLeft: "12px",
          ...style,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            minHeight: "48px",
            maxHeight: "48px",
            borderRadius: "5px",
            fontSize: "12px",
            fontFamily: "Poppins"

          },
          '& .MuiTypography-root': {
            fontFamily: "Poppins"

          }
        }}
        {...props}
      >
        <MenuItem disabled={false} value={defaultOption}>
          <Typography style={{ color: "#515151", fontSize: "12px", paddingLeft: "10px" }}>{defaultOption}</Typography>

        </MenuItem>

        {Array.isArray(options) &&
          options.map((item: any, index: number) => (
            <MenuItem
              disabled={item.total_balance === 0}
              value={item.bank_name}
              key={index}
            >

              <Box className="MenuItemBox">
                <p style={{ fontSize: "12px", color: "#515151", paddingLeft: "10px" }}>{item.bank_name}</p>
              </Box>
            </MenuItem>
          ))}
      </Select>
    </ThemeProvider>
  );
};




export const CustomSelectBox = ({ options, value, onChange, style, ...props }: any) => {
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={onChange}
          style={{height:"48px",
                  fontSize:"12px",
                  fontFamily:"Poppins",
                  ...style
                  }}
          {...props}        
        >
          {options.map((option: any , index : number) => (
            <MenuItem key={index} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  )
}




















export default MultipleSelectCheckmarks;













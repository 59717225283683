export const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
 
    };
}

export const getKESFormate = (amount) => {
  
    const converted =  !!amount ? Number(parseFloat(amount).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}):amount ?? "0.00";
    
    return converted;
}

export const getKESFormateCurrency = (amount) => {
   
    const converted =  !!amount ? Number(parseFloat(amount).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}):amount ?? "0.00";
 
    return "KES " + converted;
}
import React, { useCallback, useState, useEffect } from "react"
import Transaction_Summary from "../../../../../../../ui/ReuseableComponents/Transaction_Summary/Transaction_Summary";
import { payablesFlowactions } from "../../../../../../../../_actions"; 
import LoaderOverlay from "../../../../../../../ui/ReuseableComponents/Loaderoverlay/LoaderOverlay"; 
import { useDispatch } from "react-redux";
import "./loader.css";
import { Box } from "@mui/material";
 

const Step3 = ({ dataSave,setDataSave,Submitsetp3or4, stepupdatefun, setUseLayout, loader,formik }: any) => {
  const [render, setRender] = useState(true)
  const [loaderPage, setLoader] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    if (render) {
      var newArray=[]
      for (let index = 0; index < formik?.apiUpdatedBills?.length; index++) {
        const element = formik?.apiUpdatedBills?.[index];
         var object={
          id:element.payable_id.toString(),
          counterpart_name:element.counterpart_info.name,
          supplier_id:element.counterpart_info.monite_counterpart_id,
          payment_method: 
            element.payment_channel === "send money" ? "M-Pesa Send Money" :
            element.payment_channel === "pay bill" ? "M-Pesa PayBill" :
            element.payment_channel === "till number" ? "M-Pesa Till Number" :
            ["pesalink","rtgs","eft"].includes(element.payment_channel) ? "Bank Transfers" : "",
          amount:element.amount,
          categories:element?.bulk_payment?.categories?.map((category: any) => ({ 
            id: category.monite_tag_id,
            name: category.name  
          })) || [],
          ...(element.payment_channel === "send money" && {
            phone_number: element.method_no,
          }),
          ...(element.payment_channel === "pay bill" && {
            paybill_number: element.method_no,
            account_number: element.paybill_account_number,
          }),
          ...(element.payment_channel === "till number" && {
            account_number: element.method_no,
          }),
          ...(element.payment_channel === "pesalink" && {
            bank_name: element.bank_name,
            bank_code: element.bank_code,
            account_number: element.bank_account_number,
          }),
        }
        newArray.push(object)
      }
      const body = {
        "payables": newArray
      }
      setLoader(true)
      dispatch(payablesFlowactions.PostPaymentfull("/v4/update-bulk-payment-payables", body, UpdatePayment, setLoader,true) as any);
    }
  }, [render])
  const UpdatePayment = (data: any) => {
    setLoader(false)
    setDataSave(data)
  }
  return (
    <>
      {loaderPage && (
        <LoaderOverlay/>
      )}
      
      <Transaction_Summary
        onSave={(fototer_detels: any) => {
          Submitsetp3or4()
          // stepupdatefun(3)
          // setUseLayout("wideboxlayout")
        }}
        margin="0px"
        loader={loader} 
        data={dataSave}
        text="Proceed to pay"
        display="none"
        onCancel={() => {
          stepupdatefun(1) 
          setUseLayout("defaultlayout")
        }} />
    </>
  )
}

export default Step3
import React, { useEffect } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { counterpartsActions } from "../../../../../../_actions/counterparts.actions";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";

interface Props {
  utilsData: any;
  singleProductData: any;
}

const CreateProductsForm = (props: any & Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data_submit, setDataSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fild_data, setFild_data] = useState({
    name: "",
    description: "",
    type: "",
    measure_unit_id: "",
    price: "",
    currency: "",
    tax: "",
  });
  const [dataSubmitfrom, setDataSubmitfrom] = useState(false);
  const [render, setRender] = useState(true);
  const [CurrenciesList, setCurrenciesList] = useState([])
  const [startgetapi, setStartgetapi] = useState(true)

  const moniteProductId = props.singleProductData
    ? props.singleProductData.monite_product_id
    : "";
  const dataList = useSelector((state) => selectPaymentJourneyData(state))
  

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(paymentJourneyActions.getCurrencies() as any)
    }
  }, [render])
  // GETCOUNTRIES_SUCCESS


  useEffect(() => {
    if (startgetapi && dataList?.GETCURRENCIES_SUCCESS !== undefined) {
      setCurrenciesList(dataList?.GETCURRENCIES_SUCCESS)
      setStartgetapi(false)
    }
  }, [startgetapi, dataList])
  useEffect(() => {
    if (!props.singleProductData) {
      return;
    }
    if (!props.singleProductData.monite_product_id) {
      return;
    }

    setFild_data((prevData) => ({
      ...prevData,
      name: props.singleProductData.name,
      description: props.singleProductData.description,
      type: props.singleProductData.type,
      measure_unit_id: props.singleProductData.measure_unit_id,
      price: props.singleProductData.price,
      currency: props.singleProductData.currency,
      tax: props.singleProductData.tax,
    }));
  }, [props.singleProductData]);

  useEffect(() => {
    setLoading(true);
    if (props?.utilsData) {
      setLoading(false);
    }
  }, [props?.utilsData]);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  // handle-submit
  const handleSubmit = () => {
    if (
      !!fild_data.name &&
      !!fild_data?.type &&
      !!fild_data?.price &&
      !!fild_data?.currency &&
      // !!fild_data.measure_unit_id &&
      !!fild_data.tax
    ) {
      setDataSubmit(true);

      if (moniteProductId) {
        var updateProduct = {
          name: fild_data?.name,
          description: fild_data?.description,
          type: fild_data?.type,
          price: fild_data?.price,
          currency: fild_data?.currency,
          measure_unit_id: fild_data.measure_unit_id,
          tax: fild_data.tax,
        };
        dispatch(
          counterpartsActions.EditProductById(
            updateProduct,
            moniteProductId
          ) as any
        );
        setTimeout(() => {
          setDataSubmit(false);
        }, 3000);
      } else {
        var createProduct = {
          name: fild_data?.name,
          description: fild_data?.description,
          type: fild_data?.type,
          price: fild_data?.price,
          currency: fild_data?.currency,
          tax: fild_data.tax,
          measure_unit_id: fild_data?.measure_unit_id,
        };
        dispatch(counterpartsActions.ProductCreate(createProduct) as any);
        setTimeout(() => {
          setDataSubmit(false);
        }, 3000);
      }
    } else {
      setDataSubmitfrom(true);
    }
  };

  // handle-cancel
  const handleCancel = () => {
    navigate("/products");
  };

  return (
    <>
      {loading === true ? (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            top: 150,
            bottom: 0,
            right: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <CircularProgress color="success" />
        </Box>
      ) : (
        <div style={{ paddingTop: "30px" }} className="main_contenar_listts">
          <Button
            id="button_back"
            style={{ color: "#025141" }}
            onClick={() => {
              navigate("/products")
            }}
            className="payment-journey-container__detailbox__white-btn"
          >
            <ThemeProvider theme={theme}>
              <ArrowBackIcon
                style={{ fontSize: "19px", marginRight: "10px" }}
              />
            </ThemeProvider>
            Go Back
          </Button>
          <div className="box_contean_data_contenar">
            <p className="p_tage_cerete_data">{moniteProductId?"Update product":"Create product"}</p>

            <div className="calss_accordion_contenar">
              <ThemeProvider theme={theme}>
                <div className="accordion_details_contean">
                  <div className="input-form-wrapper dot">
                    <label>Name</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={"doc-form-input test valid"}
                        placeholder="Enter name"
                        value={fild_data.name}
                        name="name"
                        type="text"
                        onChange={(e) => {
                          setFild_data({
                            ...fild_data,
                            name: e.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                    {!fild_data.name && dataSubmitfrom == true ? (
                      <div className="error-message">Please enter the Name</div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-form-wrapper">
                    <label>Description</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={"doc-form-input test valid"}
                        placeholder="Enter description"
                        value={fild_data.description}
                        name="description"
                        type="text"
                        onChange={(e) => {
                          setFild_data({
                            ...fild_data,
                            description: e.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                  </div>

                  <div className="input-form-wrapper">
                    <label>Unit of measures</label>
                    <Form.Select
                      style={{ width: "100%" }}
                      name="measure_unit"
                      value={fild_data.measure_unit_id}
                      onChange={(e) => {
                        setFild_data({
                          ...fild_data,
                          measure_unit_id: e.target.value,
                        });
                      }}
                    >
                      <option selected value="">
                        Select measure unit
                      </option>
                      {props?.utilsData?.units?.map(
                        (arrayElement: any, index: number) => {
                          return (
                            <option
                              value={arrayElement.monite_measure_unit_id}
                              key={index + 1}
                            >
                              {arrayElement.name}
                            </option>
                          );
                        }
                      )}
                    </Form.Select>
                    {!fild_data.measure_unit_id && dataSubmitfrom == true ? (
                      <div className="error-message">Please select the Unit of measures</div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-form-wrapper dot">
                    <label>Type</label>
                    <Form.Select
                      style={{ width: "100%" }}
                      name="type"
                      value={fild_data.type}
                      onChange={(e) => {
                        setFild_data({
                          ...fild_data,
                          type: e.target.value,
                        });
                      }}
                    >
                      <option selected value="">
                        Select type
                      </option>
                      {props?.utilsData?.types?.map(
                        (arrayElement: any, index: number) => {
                          return (
                            <option value={arrayElement.type} key={index + 1}>
                              {arrayElement.name}
                            </option>
                          );
                        }
                      )}
                    </Form.Select>
                    {!fild_data.type && dataSubmitfrom == true ? (
                      <div className="error-message">Please select the type</div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-form-wrapper dot">
                    <label>Price</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={"doc-form-input test valid"}
                        placeholder="Enter price"
                        value={fild_data.price}
                        name="price"
                        type="number"
                        onChange={(e) => {
                          setFild_data({
                            ...fild_data,
                            price: e.target.value,
                          });
                        }}
                      />
                    </InputGroup>
                    {!fild_data.price && dataSubmitfrom == true ? (
                      <div className="error-message">Please enter the Price</div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-form-wrapper dot">
                    <label>Currency</label>
                    <Form.Select
                      style={{ width: "100%" }}
                      name="currency"
                      value={fild_data.currency}
                      onChange={(e) => {
                        setFild_data({
                          ...fild_data,
                          currency: e.target.value,
                        });
                      }}
                    >
                      <option selected value="">
                        Select currency
                      </option>
                      {CurrenciesList?.map(
                        (arrayElement: any, index: number) => {
                          return (
                            <>
                              {arrayElement.currency == "GHS" ? "" :
                                <option
                                  value={arrayElement.currency}
                                  key={index + 1}
                                >
                                  {arrayElement.currency}
                                </option>}
                            </>
                          );
                        }
                      )}
                    </Form.Select>
                    {!fild_data.currency && dataSubmitfrom == true ? (
                      <div className="error-message">Please select the Currency</div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="input-form-wrapper dot">
                    <label>Tax %</label>
                    <Form.Select
                      style={{ width: "100%" }}
                      name="tax"
                      value={fild_data.tax}
                      onChange={(e) => {
                        setFild_data({
                          ...fild_data,
                          tax: e.target.value,
                        });
                      }}
                    >
                      <option selected value="">
                        Select tax
                      </option>
                      <option value={"Exempt"} key={1}>
                        Exempt
                      </option>
                      <option value={"0"} key={2}>
                        0
                      </option>
                      <option value={"8"} key={3}>
                        8
                      </option>
                      <option value={"16"} key={4}>
                        16
                      </option>
                    </Form.Select>
                    {!fild_data.tax && dataSubmitfrom == true ? (
                      <div className="error-message">Please select the tax</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </ThemeProvider>
            </div>
            <div
              className="main_buuton_line_logg"
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  onClick={() => {
                    handleCancel();
                  }}
                  className="mt-3 text-capitalize"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    margin: "20px 0px",
                    width: "100%",
                  }}
                >
                  {"Cancel"}
                </Button>
              </div>

              <div style={{ flex: 1 }}>
                <Button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="mt-3 complete-profile__btn2 text-capitalize"
                  style={{
                    backgroundColor: "#025041",
                    color: "white",
                    margin: "20px 0px",
                    width: "100%",
                  }}
                >

                  {data_submit === true ? (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  ) : (
                    ""
                  )}
                  {!!moniteProductId ? "Save " : "Create "}

                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CreateProductsForm;

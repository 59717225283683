import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from 'react-bootstrap';
import "./InviteToNiobiBalance.scss";
import { useDispatch } from "react-redux";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import PhoneInput from 'react-phone-input-2';
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";

interface Props {
    onSave: any;
    counterpart: any;
    data: any;
    paymentDeatils: any;
    type: string;
}

export const InviteToNiobiBalance = (props: Props) => {
    let navigate = useNavigate();
    const [phonedata, setPhonedata] = useState('');
    const [dataemail, setEmaildata] = useState('');
    const [moneetID, setMoneetID] = useState('');
    const [padid, setPadid] = useState('');
    const [option, setOption] = useState<any>({ id: '' });
    const [data_chack, setDATAchack] = useState(false);
    const [transloader, setTransactionLoader] = useState(false);
    const [detailsPayment, setDetailsPayment] = useState<any>();
    const [desplay, setDesplay] = useState(true);
    const [validData, setVailddata] = useState(false)
    const [emailvalidation, setEmailvalidation] = useState(false)

    const dispatch = useDispatch();

    useEffect(() => {
        setOption(props.data);
        setDetailsPayment(props.paymentDeatils);
        if (!!props.counterpart && desplay) {
            const monite_counterpart_id = !!props.counterpart.monite_id
                ? props.counterpart.monite_id
                : props.counterpart.monite_counterpart_id;
            if (props.type === 'send money' && !!props.counterpart.notify_email) {
                setEmaildata(props.counterpart.notify_email);
            }

            setPadid(props.counterpart.id);
            setMoneetID(monite_counterpart_id);
            setDesplay(false);
        }
    }, [props, desplay]);
    const onSubmitted = () => {
        if (!!isValidEmail(dataemail) || !!phonedata) {
            const body = {
                amount: option.amount,
                transaction_date: moment(option.updated_at).format('DD-MMM-YYYY'),
                mobile: phonedata,
                email: dataemail,
                transaction_id: option.ref,
                payee_id: moneetID,
                payment_method: option.payment_channel,
                transaction_description: detailsPayment.description,
                payable_id: option.payable_id,
            };
            setTransactionLoader(true);
            dispatch(paymentJourneyActions.getInveact(body) as any);

            if (data_chack) {
                setTimeout(() => {
                    saveata();
                }, 5000);
            } else {
                setTimeout(() => {
                    setTransactionLoader(false);
                    window.location.href = '/balance?currency=KES';
                }, 7000);
            }
        } else {
            setVailddata(true)
            setEmailvalidation(true)
        }
    };

    const saveata = () => {

        const body = {
            id: moneetID,
            notify_phone_number: phonedata,
            notify_email: dataemail,
        };

        setTransactionLoader(true);
        dispatch(paymentJourneyActions.getSave_data(body) as any);
        setTimeout(() => {
            setTransactionLoader(false);
            window.location.href = '/balance';
        }, 8500);

    };

    // email validation
    function isValidEmail(email:any) {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
      }

    const isButtonDisabled = transloader || (!dataemail && !phonedata);
    
    return (
        <div className='invite-niobi-balance'>
            <div className='invite-niobi-balance__heading'>
                Notify Payee
            </div>
            {props.type === 'send money' ? "" :
                <div className="addphoneno">
                    <label>Phone Number</label>
                    <PhoneInput
                        inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                        }}
                        country={'ke'}
                        prefix='+'

                        value={phonedata}
                        onChange={(value, country, e, formattedValue) => {
                     

                            setPhonedata(value)
                            setVailddata(true)
                        }}
                        inputClass={'inputBx-mobile readonly'}
                        autoFormat={false}
                    />
                    {
                        validData == true ?
                            !!phonedata ? 
                                (phonedata.length != 12 ? 
                                          <div className="red invalid-feedback d-block">Please enter 9 digit phone number</div>
                                : '')
                            : " "
                        : ""
                    }

                </div>}

            <div style={{ paddingBottom: "32px" }}>
                <label>Email</label>
                <InputGroup className="doc-input-wrapper input">
                    <Form.Control
                        className='doc-form-input'
                        placeholder="johndoe@gmail.com"
                        value={dataemail}
                        onChange={(e: any) => {
                            setEmaildata(e.target.value)
                            isValidEmail(e.target.value)

                        }}
                    />

                </InputGroup>
                {props.type === 'send money' && !isValidEmail(dataemail) &&validData == true ? <p className="error-message">Please enter a valid email address.</p> : ""}
                {props.type == 'send money'?"":
                <>
                    {props.type !== 'send money' && (!dataemail && !phonedata) && (
                        <p className="error-message">Please fill in at least one of the following: valid email or phone number.</p>
                    )}      

                    {props.type !== 'send money' && validData === true && !isValidEmail(dataemail) && dataemail && (
    <p className="error-message">Please enter a valid email address.</p>
)}

{/* {props.type !== 'send money' && validData === true && phonedata && phonedata.length !== 12 && (
    <p className="error-message">Please enter a valid phone number.</p>
)} */}

                </>}

            </div>
            {transloader && (
                <div className='d-flex dflex align-center alignitem-center justify-center h-100'>
                    <span className='spinner-border spinner-border-md mr-1'></span>
                </div>
            )}

            <div className='invite-niobi-balance__btn-row d-flex justify-content-around'>
                <Button
                    className='auth-trans-container__btn-row__white'
                    onClick={() => navigate(`/balance?currency=KES`)}
                >Go Back
                </Button>
                <Button
                    className='invite-niobi-balance__btn-row__green'
                    disabled={isButtonDisabled}
                    onClick={() => onSubmitted()}
                >
                    Notify Payee
                </Button>
            </div>
        </div>
    );
};

import { Grid, Typography, TableCell, Menu, MenuItem, TableContainer, Table, TableHead, TableRow, Button, Box } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from './user_management.module.css';
import { orange } from '@mui/material/colors';
import React, { useState } from "react";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import { useDispatch, useSelector } from "react-redux";
import Api_data from './apidata';
import { selectPaymentJourneyData } from "../../../../_reducers/paymentJourney.reducer";
import { useSearchParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const User_Management = (props: any) => {
    const dispatch = useDispatch();
    const [data_from_diplay, setData_from_display] = useState(false)
    const [userData, setUserData] = React.useState<any>([]);
    const [addexpressSuccrender, setaddexpressSuccrender] = useState(false);
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const [render, setRender] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const [trans, settrans] = useState<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [id_respons_sss, setId_respons_Sss] = React.useState('')
    const [data_aagagahaaj, setData_aahhahahga] = useState(false)
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    React.useEffect(() => {
        const popup = searchParams.get('id');
        if (!!popup && data_aagagahaaj == false) {
            dispatch(paymentJourneyActions.getIntegration_apikey_active(popup,setaddexpressSuccrender) as any)
            // setaddexpressSuccrender(true)
            setData_aahhahahga(true)
        } else {
        }
    }, [searchParams, data_aagagahaaj]);
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    })
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (render && dataList.DELETE_CREDENTIALS_SUCCESS !== undefined) {
            setRender(false);
            setData_aahhahahga(false)
        }
    }, [render, dataList])


    React.useEffect(() => {
        if (addexpressSuccrender && dataList?.GETINTEGRATION_APIKEY_SUCCESS !== undefined) {
            if (dataList.GETINTEGRATION_APIKEY_SUCCESS != trans) {
                setaddexpressSuccrender(false);
                settrans(dataList.GETINTEGRATION_APIKEY_SUCCESS);
            }
           

        }
    }, [dataList.GETINTEGRATION_APIKEY_SUCCESS, addexpressSuccrender]);
    function MaskCharacter(str: any, mask: any, n = 1) {
        return ('' + str).slice(0, -n)
            .replace(/./g, mask)
            + ('' + str).slice(-n);
    }
    return (
        <ThemeProvider theme={theme}>
            <div className='accoun-management'>
                <div className='accoun-management__header' >
                    <div className="DGDGGDGGDBJXJXBX">
                        <div>API credentials</div>
                        <Button
                            onClick={() => {
                                let requestBody = {
                                    entity_integration_id: trans.id,
                                }
                                dispatch(paymentJourneyActions.Create_credentials_active(requestBody,setaddexpressSuccrender) as any);
                                setData_from_display(true)
                                setData_aahhahahga(true)
                            }}
                            className="sasjjshshhshhs" >
                            Add credentials
                        </Button>
                    </div>
                </div>
                <div className={styles.shsggsggsggsggsg}>
                    {!!trans && trans.api_keys.length == 0 ?
                        <div className="calss_tage_ajajahhahha" style={{ display: 'flex', height: '94vh', padding: '10px 43px' }}>

                            <div className={styles.create_intigretion}>
                                <p className={styles.heading_create}>
                                    Create your  API credential
                                </p>
                                <p className={styles.description_create}>
                                    Let’s create your first API credential for current project to give your developers access to it.                            </p>
                                <Button

                                    onClick={() => {
                                        let requestBody = {
                                            entity_integration_id: trans.id,
                                        }
                                        dispatch(paymentJourneyActions.Create_credentials_active(requestBody,setaddexpressSuccrender) as any);
                                        setData_aahhahahga(false)
                                        setData_from_display(true)
                                    }}

                                    className={styles.ssjssggsggshshhsh} >
                                    Create Credential
                                </Button>
                            </div></div> :
                        <>
                            {data_from_diplay == false ?

                                <Grid container className={styles.erwrwr_contenar_datatat}>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >


                                        <MenuItem onClick={() => {
                                            dispatch(paymentJourneyActions.credentials_delete_active(id_respons_sss) as any);
                                            setRender(true)
                                            setData_aahhahahga(false)
                                            handleClose()

                                        }} className={styles.menuitem} style={{ color: "#F87161" }}>Delete
                                        </MenuItem>


                                    </Menu>
                                    <Grid item md={0.5} xs={0} sm={0}>
                                    </Grid>
                                    <Grid item md={11} sm={12} xs={0}>
                                        <ThemeProvider theme={theme}>
                                            <Button className={styles.GOback_button} onClick={() => {
                                                navigate("/settings/apikey")
                                            }}>
                                                <KeyboardBackspaceIcon /> Go Back
                                            </Button>
                                            <TableContainer className={styles.addtebalcontenar}>
                                                <Table className={styles.tebalheder}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Client ID</TableCell>
                                                            <TableCell>Secret</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Last Used</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <tbody>
                                                        {!!trans && trans.api_keys.map((element: any, index: number) => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell><p className={'tebaldateformette'}>{element.client_id}</p></TableCell>
                                                                    <TableCell><p className={'tebaldateformette'}>
                                                                        {MaskCharacter(element.secret_key.toString(), '*', 4)}

                                                                    </p></TableCell>
                                                                    <TableCell><p className={element.status == 'active' ? styles.Active : element.status == 'invited' ? styles.Invited : styles.Inactive}>{!!element.status ? element.status : "inactive"}</p></TableCell>
                                                                    <TableCell>{element.last_used}</TableCell>


                                                                    <TableCell style={{ textAlign: 'end' }}>
                                                                        {element.status == 'active' ?
                                                                            <>
                                                                                <Button
                                                                                    onClick={(event) => {
                                                                                        handleClick(event)
                                                                                        setData_aahhahahga(true)
                                                                                        setId_respons_Sss(element.id)                                                                                        // setData_aahhahahga(true)
                                                                                    }}>
                                                                                    <img src="/Vector (5).svg" /></Button>
                                                                            </> : ""}
                                                                    </TableCell>


                                                                </TableRow>
                                                            );
                                                        })}
                                                    </tbody>
                                                </Table>

                                            </TableContainer>
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item md={0.5} xs={0} sm={0}>
                                    </Grid>
                                </Grid> :
                                <Api_data onSAVE={() => {
                                    setData_from_display(false)
                                    setData_aahhahahga(false)
                                }} />}
                        </>}
                </div>
            </div>
        </ThemeProvider>
    );
}

export default User_Management;
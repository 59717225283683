import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import styles from "./pickers.module.scss"




export const MuiCustomDatePicker = ({ label, value, onChange }: any) => {

  return (
    <Box>
      <Typography className={styles.label}>{label}</Typography>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']} sx={{ width: '100%', overflow: 'visible' }}>
            <DatePicker
              value={value}
              onChange={onChange}
              minDate={dayjs()}
              sx={{
                width: '100%',
                outline: "none",
                borderColor: 'rgb(221, 221, 221)',
                overflow: 'visible',
                '& .MuiInputBase-root': {
                  height: '48px',
                  padding: '0 20px',
                  width: '100%',
                  overflow: 'visible',
                  borderRadius: '8px',
                  '&:hover': {
                    outline: "none",
                    boxShadow: '0px 0px 0px 4px #02504133',
                  },
                  '&.Mui-focused': {
                    borderColor: '#025041',
                    outline: "none",
                    boxShadow: '0px 0px 0px 4px #02504133',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '10px 0',
                  fontSize: "12px",
                  "&::placeholder": {
                    color: "#515151",
                  }
                },
                '& .MuiSvgIcon-root': {
                  color: '#757575',
                },
                '& .MuiPickersDay-root.Mui-selected': {
                  backgroundColor: '#025041 !important',
                  color: '#fff !important',
                },
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  )
}



export const MuiCustomTimePicker = ({ label, value , onChange, selectedDate }: any) => {
  const now = dayjs();
  const isToday = selectedDate && selectedDate.isSame(now, 'day');
  const minTime = isToday ? now.add(1, 'minute').startOf('minute') : null;
  const handleTimeChange = (newTime: any) => {
    if (isToday && newTime.isBefore(now)) {
      onChange(minTime);
    } else {
      onChange(newTime);
    }
  };
  return (
    <Box>
      <Typography className={styles.label}>{label}</Typography>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['TimePicker']} sx={{ width: '100%', overflow: 'visible' }}>
            <TimePicker
              value={value}
              onChange={handleTimeChange}
              views={['hours', 'minutes']}
              timeSteps={{ minutes: 1 }}
              ampm={true}
              format="HH:mm:ss a"
              minTime={minTime}
              maxTime={isToday ? dayjs().endOf('day') : null}
              slotProps={{
                textField: { 
                  placeholder: "HH:MM AM",
                }
              }}
              sx={{
                width: '100%',
                outline: "none",
                borderColor: 'rgb(221, 221, 221)',
                overflow: 'visible',
                '& .MuiInputBase-root': {
                  height: '48px',
                  padding: '0 20px',
                  width: '100%',
                  overflow: 'visible',
                  borderRadius: '8px',
                  '&:hover': {
                    outline: "none",
                    boxShadow: '0px 0px 0px 4px #02504133',
                  },
                  '&.Mui-focused': {
                    borderColor: '#025041',
                    outline: "none",
                    boxShadow: '0px 0px 0px 4px #02504133',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '10px 0',
                  fontSize: "12px",
                  "&::placeholder": {
                    color: "#515151",
                  },
                },
                '& .MuiSvgIcon-root': {
                  color: '#757575',
                },
                '& .MuiPickersDay-root.Mui-selected': {
                  backgroundColor: '#025041 !important',
                  color: '#fff !important',
                }
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  )
}
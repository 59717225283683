import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import '../../../auth/Auth.css';
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import { entityActions } from "../../../../_actions/entity.actions";import { getEntityActivitiesReducer, selectAllData } from "../../../../_reducers/entity.reducer";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import EntityLayout from '../../../auth/Layout/entityLayout';
import { Modal } from 'react-bootstrap'


const Entity = ({path,api}:any) => {
    const [entity, setEntity] = useState<EntityRequest>({
        business_name: '',
        company_type: '',
        business_activity: '',
        country: '',
        street_address: '',
    })
    const navigate = useNavigate();
    const user = getUser()?.data ?? null

    useEffect(() => {
        if (!user && !isUserAuthenticated()) {
            navigate('/login');
        }
    }, [])

    const [submitted, setSubmitted] = useState(false)
    const [entityType, setEntityType] = useState<any>();
    const [entityActivity, setEntityActivity] = useState<any>();
    const [isInitialTypeRender, setIsInitialTypeRender] = useState(true);
    const [render, setRender] = useState(true);
    const [countryError, setCountryError] = useState(false);
    const [isInitialActivityRender, setIsInitialActivityRender] = useState(true);
    const data = useSelector((state) => selectAllData(state))
    const [requestRender, setrequestRender] = useState(true);
    const [responseRender, setresponseRender] = useState(false);
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState<any>();
    const [region, setRegion] = useState<any>();
   
    
    const APIData = useSelector((state) =>
        selectAllData(state)
    );


    useEffect(() => {
        if (requestRender && APIData?.ADDTOENTITY_FAILURE == false) {
            setLoading(false);
            setrequestRender(false);
        }
    }, [APIData, requestRender]);

    useEffect(() => {
        if (responseRender && APIData?.ADDTOENTITY_SUCCESS == true) {
            setLoading(false);
            setresponseRender(false);
        }
    }, [APIData, requestRender]);


    const dispatch = useDispatch()
    const onClose = () => {
        setLoading(false)
    }
    useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false)
            dispatch(entityActions.getEntityTypes() as any)
            dispatch(entityActions.getEntityActivities() as any)
        }
    }, [render, isUserAuthenticated]);

    useEffect(() => {
        if (isInitialTypeRender && data?.GETENTITYITEMS_SUCCESS !== undefined) {
            setIsInitialTypeRender(false);
            setEntityType(data.GETENTITYITEMS_SUCCESS);
        }
    }, [data, isInitialTypeRender]);

    useEffect(() => {
        if (isInitialActivityRender && data?.GET_ENTITY_ACTIVITY_SUCCESS !== undefined) {
            setIsInitialActivityRender(false);
            setEntityActivity(data.GET_ENTITY_ACTIVITY_SUCCESS);
        }
    }, [data, isInitialActivityRender]);
    const validationSchema = Yup.object().shape({
        business_name: Yup.string()
            .required('Please enter the business name.'),
        company_type: Yup.string()
            .required('Please select the business type.'),
        business_activity: Yup.string()
            .required('Please specify the Business activity.'),
        country: Yup.string()
            .required('Please select the country where the business is located.'),
        street_address: Yup.string()
            .required('Please enter the street address.'),
    })

    const handleSubmit = (e: any) => {
        setSubmitted(true);
        if (e.business_name && e.company_type && e.business_activity && !!country) {
            e.country = country
            e.state = region
            setLoading(true)
            setresponseRender(true)
            setrequestRender(true);
            const Newigesan = () => {
                navigate(path);
              };
            dispatch(entityActions.createEntity(e,path,api,Newigesan) as any)
        }
    }
    return (
        <>
            {
                (loading == true) && (
                    <Modal
                        show={loading}
                        onHide={onClose}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby='ModalHeader'
                        className="topup_modal"
                        centered
                        size="lg"
                    // id={styles.maiana_contwean_abh}
                    >
                        <Modal.Body>
                            <>

                                <div className="div_pading_data">
                                    <img src="/logo 3.svg" />
                                    <p className="des_data">
                                    Setting up your account. This may take a few moments. Do not refresh or close this window  until the process is complete.
                                    </p>
                                    {/* <Loader_icon /> */}
                                    <img
                                        width={75}
                                        height={75}
                                        src="/Niobi Loading Animation-Cross.gif"
                                    />
                                </div>
                            </>
                        </Modal.Body>
                    </Modal>
                )
            }
            <EntityLayout>
                <div className="formBx_tow" style={{justifyContent:"center",height:"100%"}}>
                    <div className='register-business'>
                        <span><h2>Register your business</h2></span>
                    </div>
                    <Formik initialValues={entity} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ errors, touched, isSubmitting, handleChange, values }) => (
                            <Form className='form-input'>
                                <div className='inputBx'>
                                    <span>Business Name</span>
                                    <Field
                                        name="business_name"
                                        type="text"

                                        placeholder="Business Name"
                                        className={'form-text form-control' + (errors.business_name && touched.business_name ? ' is-invalid' : '')} />
                                    <ErrorMessage name="business_name" component="div" className="invalid-feedback red" />
                                </div>
                               <div className='inputBx'>
                                    <span>Business Type</span>
                                    <Field name="company_type"  id="CountryDropdown_class" as="select" className={'form-text form-control select-box' + (errors.company_type && touched.company_type ? ' is-invalid' : '')} >
                                        <option value="">--Select--</option>
                                        {Array.isArray(entityType)
                                            ? entityType?.map((element: any) => {
                                                return <option key={element.id} value={element.id}>{element.name}</option>
                                            })
                                            : "data not found"}
                                    </Field>
                                    <ErrorMessage name="company_type" component="div" className="invalid-feedback red" />
                                </div>
                                <div className='inputBx'>
                                    <span>Business Activity</span>
                                    <Field name="business_activity"  id="CountryDropdown_class" as="select" className={'form-text form-control select-box' + (errors.business_activity && touched.business_activity ? ' is-invalid' : '')} >
                                        <option value="">--Select--</option>
                                        {Array.isArray(entityActivity)
                                            ? entityActivity?.map((element: any) => {
                                                return <option key={element.id} value={element.id}>{element.name}</option>
                                            })
                                            : "data not found"}
                                    </Field>
                                    <ErrorMessage name="business_activity" component="div" className="invalid-feedback red" />
                                </div>
                                <div className='inputBx'>
                                    <span>Street Address</span>
                                    <Field name="street_address" type="text" placeholder="Street Address" className={'form-text form-control' + (errors.street_address && touched.street_address ? ' is-invalid' : '')} />
                                    <ErrorMessage name="street_address" component="div" className="invalid-feedback red" />
                                </div>
                                <div className='inputBx'>
                                    <span>Country</span>
                                    <CountryDropdown
                                    id="CountryDropdown_class"
                                        value={country}
                                        onChange={
                                            (e, event) => {
                                                setCountryError(!!e ? false : true)
                                                setCountry(e);
                                                handleChange(event);
                                            }}
                                        name="country"
                                        classes={'form-text form-control' + (errors.country && touched.country ? ' is-invalid' : '')}
                                    />
                                    <ErrorMessage name="country" component="div" className="invalid-feedback red" />
                                </div>
                                <div className='inputBx'>
                                    <button type="submit"
                                        className="form-button">
                                        {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        <span>Register Business</span>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </EntityLayout>
        </>
    )
}
export default Entity;
interface EntityRequest {
    business_name: string;
    company_type: string;
    business_activity: string;
    country: string;
    street_address: string;
}
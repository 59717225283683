import React, {useState, useEffect } from 'react';
import NotificationsTable from './NotificationsTable';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { Dropdown } from '@team-monite/ui-kit-react';
import { CounterpartType } from '@team-monite/sdk-api';
import '../Counterparts/Counterparts.scss';
import amplitude from 'amplitude-js';
import { Button } from 'react-bootstrap';
import '../../../i18n/index';
import {
  getProperties,
  isUserAuthenticated, authHeader
} from '../../../../_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { entityActions } from '../../../../_actions/entity.actions';
import Csvdata from '../Counterparts/components/csvlist';
import EnhancedTable from '../Counterparts/components/addnewtebal';
import { ThemeProvider, createTheme } from '@mui/material';
import posthog from 'posthog-js';
import Menu_List from "../Menu";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

export const Notifications = () => {
  const [counterpartId, setId] = useState<any>(undefined);
  const [counterpartType, setType] = useState<any>(undefined);
  const [csvname, setCsvnamedata] = React.useState('');
  const dispatch = useDispatch();
  const [counterpartData, setCounterpartData] = useState<any>();
  var localUser: any = localStorage.getItem('user');
  const properties = getProperties();
  const PAYABLE_ID = 'id';
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get(PAYABLE_ID);
  const [listpopup, setPopup] = useState<any>(false);
  const [render, setRender] = useState(true);
  const [showModal, setShow] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose_popup = () => {
      setAnchorEl(null);
  };
  const navigate = useNavigate();
  const changeTime = () => {
    var dateset = new Date();
    const tokentwo: any = localStorage.getItem('tokentwo');
    if (
      new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime() >=
      1800000
    ) {
     
      setRender(false);
      dispatch(entityActions.getBilldata() as any);
    } else {
     
    }
    setTimeout(() => changeTime(), 1830000);
  };
  useEffect(() => {
    changeTime();
  }, [render, isUserAuthenticated]);

  useEffect(() => {
    const popup = searchParams.get('popup');
    const BILLSBULK: any = searchParams.get('BILLSBULK');
    setCsvnamedata(BILLSBULK);
    if (popup == '0') {
      setType(CounterpartType.ORGANIZATION);
    } else if (popup == '1') {
      setType(CounterpartType.INDIVIDUAL);
    }
  }, [searchParams]);


  const [state, setState] = React.useState<any>({
    left: false,
  });
 
  const submitData = () => {
    const event = 'Payee Created';
    const eventProperties = {
      userId: !!properties && !!properties.user_id ? properties.user_id : null,
      userName: !!properties && !!properties.name ? properties.name : null,
      email: !!properties && !!properties.email ? properties.email : null,
      pageUrl: window.location.href,
    };
    amplitude.getInstance().logEvent(event, eventProperties);
    posthog.capture(event, eventProperties);
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: { primary: { main: '#000' } },
  });
  const [loading, setLoading] = React.useState(false);
  const markAllAsRead = async () => {
    const localUser = localStorage.getItem('user');
    var userId = '';
    if (localUser != null) {
      userId = JSON.parse(localUser).data.user_id;
    } 
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: userId
    }
    var pagelimit = 10;
    setLoading(true)
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/read-all`, requestOptions)
        .then((response) => {
          if(response.data.success == true){
                setLoading(false)
          } else {
      
                setLoading(false)
          }
            
            setLoading(false)
        });
    }
    const [unreadCount, setUnreadCount] = useState();
    const getUnreadCount = async () => {
        const localUser = localStorage.getItem('user');
        var userId = '';
        if (localUser != null) {
          userId = JSON.parse(localUser).data.user_id;
        } 
        const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', ...authHeader() },
              body: userId
        }
        var pagelimit = 10;
        setLoading(true)
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/unread-count`, requestOptions)
              .then((response) => {
                if(response.data.success == true){
                      setUnreadCount(response.data.data);
                      setLoading(false);
                } else {
                 
                      setLoading(false)
                }
                 
                  setLoading(false)
              });
            }
      useEffect(() => {
          getUnreadCount();
      },[]);


  return (
    <>
      {!!localUser && (
        <div className="counterparty">
        
            <PageHeader
            title="Notifications"
          />
          <ThemeProvider theme={theme}>         
            <NotificationsTable />
                 
                </ThemeProvider>
               
        </div>
      )}
    </>
  );
};

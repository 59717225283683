import { Box, Typography } from '@mui/material'
import React from 'react'
import { DefaultBtn, LightBtn } from '../../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { Button } from '@mui/material'
import { CustomInput } from '../../../../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import "./mpesa.scss"
import PhoneInput from 'react-phone-input-2'
const MpesaComponent = () => {
    return (
        <Box>
            <Box className="d-flex justify-content-center align-items-center gap-2 mb-5">
                    <Button className="mpesa_btn">Send money</Button>
                    <Button className="mpesa_btn">Pay bill</Button>
                    <Button className="mpesa_btn">Till number</Button>
            </Box>
            <Box>
                <Typography className='mb-2 label'>Phone Number</Typography>
                <PhoneInput/>
            </Box>
            <Box>
                <DefaultBtn text="Save" style={{ width: "100%", marginTop: "30px" }} />
            </Box>
        </Box>
    )
}

export default MpesaComponent
import React, { useState } from "react"; 
import { PaymentInitiated } from "../../../../../Bulk_Payments/components/PaymentInitiated/PaymentInitiated";

const Step6 = ({ stepupdatefun, formik, selectedCurrency, selectedFlag, setUseLayout }: any) => {
  const [transactin_summary, setTransaction_summry] = useState<any>();
  const [data_aapro_data, setData_aapro_data] = useState<any>();
  const [Displaydata, setDisplaydata] = useState(false);

  return (
    <PaymentInitiated
      counterpart={transactin_summary}
      id={data_aapro_data}
      setDisplaydata={setDisplaydata}
      type="Send money"
      onSave={() => {
        stepupdatefun(6);
        setUseLayout("excludenavlayout");
      }}
      onCancel={() => {stepupdatefun(4) }} />

    
  );
};

export default Step6;

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CustomSelectBox } from './ReuseableComponents/SelectBoxes/SelectBoxs';
import styles from './ui.module.scss';
import { Form } from 'react-bootstrap';

export const Pagination = ({ remove, pageneshn, setPagelist, setRemovedata, totalCount }: any) => {

    return (
        <>
            <div className="addnewbtnclass" style={{ paddingRight: "0px" }}>
                <div className="inpagenum" style={{ paddingRight: "4px" }}>
                    <p>
                        {remove - 9}-{remove}
                    </p>
                </div>
                <div className="btnmaindivin">
                    {pageneshn == 1 ? (
                        <button disabled className="nextntmistish">
                            <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                        </button>
                    ) : (
                        <button
                            className="nextntmistish"
                            onClick={() => {
                                setPagelist(pageneshn - 1);
                                setRemovedata(remove - 10);
                            }}
                        >
                            <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                        </button>
                    )}
                    {remove > totalCount ? (
                        <button disabled className="nextntmistish">
                            <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                        </button>
                    ) : (
                        <button
                            className="nextntmistish"
                            onClick={() => {
                                setPagelist(pageneshn + 1);
                                setRemovedata(remove + 10);
                            }}
                        >
                            <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                        </button>
                    )}
                </div>
            </div>
        </>
    )
}

export const SelectBoxPagination = ({ options, selectedLimit, handleLimitChange, remove, pageneshn, setPagelist, setRemovedata, totalCount }: any) => {

    return (
        <>
            <div className="addnewbtnclass" style={{ paddingRight: "0px" }}>
                <div className="inpagenum" style={{ paddingRight: "10px" }}>
                    <p>
                        {remove - 9}-{remove}
                    </p>
                </div>
                <Form.Select
                    className={styles.tabalinputdata}
                    value={selectedLimit}
                    style={{ width: '68px' }}
                    onChange={handleLimitChange}
                >
                    <option selected value="">
                        Select
                    </option>
                    {options?.map((arrayElement: any, index: number) => {
                        return (
                            <option value={arrayElement.name} key={index + 1}>
                                {arrayElement.name}
                            </option>
                        );
                    })}
                </Form.Select>
                
                <div className="btnmaindivin">
                    {pageneshn == 1 ? (
                        <button disabled className="nextntmistish">
                            <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                        </button>
                    ) : (
                        <button
                            className="nextntmistish"
                            onClick={() => {
                                setPagelist(pageneshn - 1);
                                setRemovedata(remove - 10);
                            }}
                        >
                            <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                        </button>
                    )}
                    {remove > totalCount ? (
                        <button disabled className="nextntmistish">
                            <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                        </button>
                    ) : (
                        <button
                            className="nextntmistish"
                            onClick={() => {
                                setPagelist(pageneshn + 1);
                                setRemovedata(remove + 10);
                            }}
                        >
                            <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                        </button>
                    )}
                </div>
            </div>
        </>
    )
} 
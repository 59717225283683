import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './Topup.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUser } from '../../../../../../_helpers';
import { Autocomplete, Box, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import SelectBox from '../../../../../ui/select';
interface Props {
  show: boolean;
  onClose: any;
  onSave: any;
  onconfiam: any;
  onBalkpaymeant: any;
  onSaveCurrancy: any;
  values: any;
  countryList: any;
}

export const TopupModal_two = (props: Props) => {
  const navigate = useNavigate();
  const [showModal, setShow] = useState(props.show);
  const [selectedMethod, setSelectedMethod] = useState('');
  const [show, setShowlist] = useState(false);
  const [showBankSuccess, setshowBankSuccess] = useState(false);
  const [SelecData, setSelectData] = useState<any>(props.values)
  const [countryList, setCountrtList] = useState<any>([]);


  const user = getUser()?.data ?? null;

  const handleShow = () => {
    props.onSave();
    setShow(false);
    setShowlist(true);
  };
  const hadleshow_two = () => {
    props.onconfiam();
    setShow(false);
  };
  const onBalkpaymeant_LIST = () => {
    props.onBalkpaymeant();
    setShow(false);
  };
  const onClose = () => {
    setShow(false);
    setshowBankSuccess(false);
    props.onClose(false);
  };
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  useEffect(() => {
    if (props?.countryList) {


      var array: any = []
      // for (let index = 0; index < props?.countryList.length; index++) {
      //   const element = props?.countryList[index];
      //   // if (element.currency == "KES" || element.currency == "USD") {
      //   array.push(element)
      //   // }
      // }
      if (props?.countryList?.length > 0) {
        var CurrencyList = props?.countryList?.filter((element: any) => element.isBalance)
     
      
       
        setCountrtList(CurrencyList);
      } else {
        setCountrtList([])
      }
      // (array)
    }
  }, [props])


  return (
    <>
      <>
        <Modal
          show={showModal}
          onHide={onClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="ModalHeader"
          className="topup-modal"
          centered
          size="lg"
        >
          <ThemeProvider theme={theme}>
            <Box className="CloseContenar">
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className="heding_contean">
              <Typography>How do you  want to pay?</Typography>

              <SelectBox
                countryList={countryList}
                value={props.values}
                onChange={(e: any) => {
                  props.onSaveCurrancy(e.target.value)
                  localStorage.setItem("currency", e.target.value)
                  // const FindCountry: any = countryList?.find((item: any) => item.currency == e.target.value)
                  // localStorage.setItem("CountryId", FindCountry.id)
                }}
              // if (e.target.value != "KES") {
              //   navigate(`/payment-journey?currency=${e.target.value}`, {
              //     replace: true,
              //     state: { BILLSBULK: e.target.value},
              //   });
              // }
              // }}
              />
              {/* {
                  countryList?.filter((element: any) => element.isBalance).map((item: any, index: any) => {
                    return (
                      <MenuItem disabled={item.total_balance == 0 ? true : false} style={item.total_balance == 0 ? { cursor: "no-drop" } : {}} value={item.currency} key={index}>
                        <Box className="MenuItemBox" {...props}>
                          <img
                            loading="lazy"
                            width="25"
                            src={item.flag}
                            alt=""
                          />
                          {item.currency}
                        </Box>
                      </MenuItem>
                    )
                  })}
              </Select> */}
            </Box>
          </ThemeProvider>
          <Modal.Body>
            {props?.values == "KES" ?
              <>
                <div className="label">Choose payment method</div>
                <div className="type-card-list">
                  {(!!user && !!user.role_name && user.role_name == 'Director') ||
                    user.role_name == 'Admin' ? (
                    <div
                      id="main_fast_buttun_data"
                      className={`type-card-twoo ${selectedMethod === 'Express Payment KES' ? 'active' : ''
                        }`}
                      onClick={() => {
                        handleShow();
                        navigate('/payment-journey?currency=' + "KES", {
                          replace: true,
                          state: { BILLSBULK: "KES" },
                        });
                      }}
                    >
                      <span id="sdFsfsfsfsfff" className="type-card__name">
                        Express Payment
                      </span>
                    </div>
                  ) : (
                    <div
                      style={{ cursor: 'no-drop' }}
                      id="main_fast_buttun_data"
                      className={`type-card-twoo ${selectedMethod === 'Express Payment KES' ? 'active' : ''
                        }`}
                    >
                      <span id="sdFsfsfsfsfff" className="type-card__name">
                        Express Payment
                      </span>
                    </div>
                  )}
                  <div
                    className={`type-card-twoo ${selectedMethod === 'From a bill' ? 'active' : ''
                      }`}
                    onClick={() => hadleshow_two()}
                  >
                    <span id="sdFsfsfsfsfff" className="type-card__name">
                      From a Bill
                    </span>
                  </div>
                </div>

                <div className="type-card-list">
                  {(!!user && !!user.role_name && user.role_name == 'Director') ||
                    user.role_name == 'Admin' ? (
                    <div
                      id="main_fast_buttun_data"
                      className={`type-card-twoo ${selectedMethod === 'Bulk_Payment' ? 'active' : ''
                        }`}

                      onClick={() => onBalkpaymeant_LIST()}
                    >
                      <span id="sdFsfsfsfsfff" className="type-card__name">
                        Bulk Payment
                      </span>
                    </div>) : (
                    <div
                      id="main_fast_buttun_data"
                      style={{ cursor: 'no-drop' }}
                      className={`type-card-twoo ${selectedMethod === 'Bulk_Payment' ? 'active' : ''
                        }`}
                    >
                      <span id="sdFsfsfsfsfff" className="type-card__name">
                        Bulk Payment
                      </span>
                    </div>
                  )}
                </div>
              </> :
              <div className="type-card-list">
                {(!!user && !!user.role_name && user.role_name == 'Director') ||
                  user.role_name == 'Admin' ? (
                  <div
                    id="main_fast_buttun_data"
                    className={`type-card-twoo ${selectedMethod === 'Express Payment KES' ? 'active' : ''
                      }`}
                    onClick={() => {
                      handleShow();
                      navigate(`/payment-journey?currency=${props?.values}`, {
                        replace: true,
                        state: { BILLSBULK: `${props?.values}` },
                      });
                      localStorage.setItem("currency", props?.values)

                    }}
                  >
                    <span id="sdFsfsfsfsfff" className="type-card__name">
                      Express Payment
                    </span>
                  </div>
                ) : (
                  <div
                    style={{ cursor: 'no-drop' }}
                    id="main_fast_buttun_data"
                    className={`type-card-twoo ${selectedMethod === 'Express Payment KES' ? 'active' : ''
                      }`}
                  >
                    <span id="sdFsfsfsfsfff" className="type-card__name">
                      Express Payment
                    </span>
                  </div>
                )}
              </div>
            }
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};
interface CountryType {
  label: string;
  img: string;
}
const BlenkData: readonly CountryType[] = [
  { label: '', img: "" },
]
const countries: readonly CountryType[] = [
  { label: 'KES', img: "/kenya-flag.png" },
  {
    label: 'USD',
    img: "/united_states_of_america_round_icon_64.png"
  },

]

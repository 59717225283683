import { Grid, Tooltip } from "@mui/material";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';



const Outflows = ({ data, bill }: any) => {


    return (
        <>
            <div className="main_dev_total" style={{ paddingTop: '16px' }}>
                <div className="d-flex">
                    <div className="left">Bills created</div>
                    <div className="right">

                        {bill?.total_bills}
                    </div>
                </div>
                <div className="d-flex">
                    <div className="left">Bills paid</div>
                    <div className="right">
                        {bill?.currency}{' '}
                        {!!bill && !!bill.current_month_bills ? (bill?.current_month_bills).toLocaleString(
                            'en',
                            { minimumFractionDigits: 2 }
                        )
                            : '0.00'}
                    </div>
                </div>
                <div className="d-flex">
                    <div className="left">Bills due</div>
                    <div className="right">
                        {bill?.currency}{' '}
                        {!!bill && !!bill.outstanding_month_bills ? (bill?.outstanding_month_bills).toLocaleString(
                            'en',
                            { minimumFractionDigits: 2 }
                        )
                            : '0.00'}
                    </div>
                </div>
            </div>
            <div style={{ paddingTop: '22px' }} className="footer">
                <div className="left"></div>
                <div className="right" id="Total_Bills">
                    <Link className="link" to="/bills">
                        View all
                        <svg
                            width="7"
                            height="10"
                            viewBox="0 0 7 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.19058 0.595215L5.51607 4.9207L1.19058 9.24618"
                                stroke="#257D6B"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </Link>
                </div>
            </div>

        </>
    )
}
export default Outflows;

import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import styles from './Bulktebal.module.scss';
import { Divider } from '@mui/material';
import { selectAllCounterpartsListData } from '../../../../../_reducers/counterparts.reducer';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Papa from 'papaparse';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {
  getProperties,
  getUser,
  isUserAuthenticated,
} from '../../../../../_helpers';
import { counterpartsActions } from '../../../../../_actions/counterparts.actions';
import {
  Formik,
  Field,
  ErrorMessage,
  useField,
  useFormikContext,
  FieldArray,
  getIn,
} from 'formik';
import * as Yup from 'yup';
import { payablesActions } from '../../../../../_actions/payables.actions';
import '../../../../auth/Auth.css';
import { Link, useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import countryList from 'react-select-country-list';
import Autocomplete from '@mui/material/Autocomplete';
import amplitude from 'amplitude-js';
import { selectAllPayablesListData } from '../../../../../_reducers/payables.reducer';
import { selectPaymentJourneyData } from '../../../../../_reducers/paymentJourney.reducer';
import { paymentJourneyActions } from '../../../../../_actions/paymentJourney.actions';
import * as XLSX from 'xlsx';
import posthog from 'posthog-js';

const AddIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z"
      fill="#025041"
    />
  </svg>
);
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'transaction_date';
const DEFAULT_ROWS_PER_PAGE = 5;

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
          className={styles.addnewtext}
        >
          Confirm Imported Payees
        </Typography>
      </Toolbar>
      <Divider className={styles.addborderin} />
      <div className={styles.addlowlis}></div>
    </>
  );
}

export default function EnhancedTable() {
  const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [visibleRows, setVisibleRows] = React.useState<any>(null);
  const [paddingHeight, setPaddingHeight] = React.useState(0);
  const [datatebal, setDatatebal] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filedata, setFile] = React.useState('');
  const [lisrture, setTuredata] = React.useState(false);
  const [age, setAge] = React.useState('');
  const [checkbox, setChecbox] = React.useState(false);
  const [isError, setIserror] = React.useState<any>();
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const payablesList = useSelector((state) => selectAllPayablesListData(state));
  const [counterpartsList, setCounterpartsList] = React.useState<any>();
  const [counterpartsListRender, setCounterpartsListRender] = useState(false);
  const [dataselect, setDataselect] = useState('');
  const [tagsList, setTagsList] = useState<any>();
  const [tagsListRender, setTagsListRender] = useState(true);
  const [newdate, setNewdate] = useState('');
  const [selectedValue, setSelectedValue] = useState(new Date());
  const [selectedList, setSelectedList] = useState<any>([]);
  const [datalist, setSubmitdata] = useState('');
  const [render, setRender] = useState(true);
  const [users, setUsers] = useState<any>();
  const [isUsers, isSetUsers] = useState<any>(true);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [requestRender, setrequestRender] = useState(false);
  const [responseRender, setresponseRender] = useState(false);
  const [myData, setMyData] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [Countyvalue, setCountyvalue] = useState('');
  const [bankListRender, setBankListRender] = useState<any>(true);
  const [bankList, setBankList] = useState<any>([]);
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const countryoptions: any = useMemo(() => countryList().getData(), []);

  const changeHandler = (value: any) => {
    setCountyvalue(value);
  };

  const inputProps = {
    inputStyle: 'box',
    labelStyle: 'stacked',
    placeholder: 'Please select...',
  };

  const dispatch = useDispatch();

  const [patternDataArray, setPatternDataArray] = React.useState<any>([
    {
      id: '',
      Type: '',
      Name: '',
      Phone_number: '',
      Email: '',
      Address_line_1: '',
      Address_line_2: '',
      description: '',
      City: '',
      County: '',
      Postal_address: '',
      Country: '',
      Countrytwo: '',
      First_name: '',
      Last_name: '',
      is_current_price: '',
      Send_Money: '',
    },
  ]);
 
  
  const properties = getProperties();
  const eventProperties = {
    userId: !!properties && !!properties.user_id ? properties.user_id : null,
    userName: !!properties && !!properties.name ? properties.name : null,
    email: !!properties && !!properties.email ? properties.email : null,
    pageUrl: window.location.href,
  };
  const onSelect = (selectedList: any) => {
    setSelectedList(selectedList);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datatebal.length) : 0;

  useEffect(() => {
    if (isUsers && getUser) {
      isSetUsers(false);
      setUsers(getUser);
    }
  });
  const listteball = () => {
    const listtebal: any = localStorage.getItem('Bulkdatatwo');
    if (!!listtebal) {
      setDatatebal(JSON.parse(listtebal));


      var newData = [];
      for (let index = 0; index < JSON.parse(listtebal).length; index++) {
        const element = JSON.parse(listtebal)[index];
  
        const findresults: any = countryoptions.find(function (entry: any) {
          return entry.label === element['Country*'];
        });
        const find_name = bankList.find(function (entry: any) {
          return entry.bank_name === element['Bank Name'];
        });
        if (!element['Name*']) {
          break;
        }
        var object = {
          id: index,
          is_current_price: false,
          Type: element.Type,
          Name: element['Name*'],
          First_name: element.First_name,
          Email: element['Email*'],
          Phone_number: element['Phone Number'],
          Address_line_1: element['Street Address*'],
          Address_line_2: element.Address_line_2,
          City: element['City*'],
          Postal_address: element['Postal Address*'],
          County: element['County*'],
          Country: !!findresults ? findresults.value : '',
          Countrytwo: !!findresults ? findresults.label : '',
          Last_name: element.Last_name,
          Send_Money: element['Account Number - Send Money'],
          Paybill_Number: element['Pay Bill Number'],
          Paybill_account_number: element['Account Number - PayBill'],
          Till_number: element['Account Number - Till Number'],
          Bank_Name: !!find_name ? find_name.bank_name : '',
          Bank_Branch: element['Bank Branch'],
          Account_Name: element['Bank Account Name'],
          Account_Number: element['Account Number - Bank'],
        };
       

        newData.push(JSON.parse(JSON.stringify(object)));
      }
      setPatternDataArray(newData);
    }
  };
  useEffect(() => {
    if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
      let options: any = [];
      if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
        counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
          options.push({
            id: item.id,
            name: item.name,
          });
        });
      }
      setTagsListRender(false);
      setTagsList(options);
    }
  }, [counterpartsListData, tagsListRender]);
  useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(counterpartsActions.getAllCounterparts(setCounterpartsListRender) as any);
      dispatch(counterpartsActions.getAllTags() as any);
      dispatch(paymentJourneyActions.getPesaBankList() as any);
    }
  }, [render, isUserAuthenticated]);


  useEffect(() => {
    if (bankListRender && dataList?.GETPESABANKLIST_SUCCESS !== undefined) {
      setBankListRender(false);
      setBankList(dataList.GETPESABANKLIST_SUCCESS);
    }
  }, [dataList, bankListRender]);
  useEffect(() => {
    if (requestRender && payablesList?.BULKBILLS_REQUEST == true) {
      setLoading(true);
      setrequestRender(false);
      setresponseRender(true);
    }
  }, [payablesList, requestRender]);
  useEffect(() => {
    if (responseRender && payablesList?.BULKBILLS_REQUEST == false) {
      setLoading(false);
      setresponseRender(false);
    }
  }, [payablesList, responseRender]);
  const handleCSVUpload = async (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const jsonData: any = XLSX.utils.sheet_to_json(sheet);
      const listtebal: any = localStorage.getItem('Bulk_Payment');
      const bulkdata = JSON.parse(listtebal);
      xlsx_data(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };
  const xlsx_data = (file: any) => {
    var newData = [];
    for (let index = 0; index < file.length; index++) {
      const element: any = file[index];
    
      const find_name = bankList.find(function (entry: any) {
        return entry.bank_name === element['Bank Name'];
      });
      const findresults: any = countryoptions.find(function (entry: any) {
        return entry.label === element['Country*'];
      });
    
      if (!element['Name*']) {
        break;
      }
      var object = {
        id: index,
        is_current_price: false,
        Type: element.Type,
        Name: element['Name*'],
        Email: element['Email*'],
        Phone_number: element['Phone Number'],
        Address_line_1: element['Street address*'],
        Address_line_2: element.Address_line_2,
        City: element['City*'],
        Postal_address: element['Postal Address*'],
        County: element['County*'],
        Country: !!findresults ? findresults.value : '',
        Countrytwo: !!findresults ? findresults.label : '',
        First_name: element.First_name,
        Last_name: element.Last_name,
        Send_Money: element['Account Number - Send Money'],
        Paybill_Number: element['Pay Bill Number'],
        Paybill_account_number: element['Account Number - PayBill'],
        Till_number: element['Account Number - Till Number'],
        Bank_Name: !!find_name ? find_name.bank_name : '',
        Bank_Branch: element['Bank Branch'],
        Account_Name: element['Bank Account Name'],
        Account_Number: element['Account Number - Bank'],
      };
      newData.push(JSON.parse(JSON.stringify(object)));
    }
    setPatternDataArray(patternDataArray.concat(newData));
    if (!!file) {
      setTuredata(true);
    }
  };
  const handleChange = (event: any) => {
    setAge(event.target.value as string);
  };
  React.useEffect(() => {
    if (bankList.length == 0) {
    } else {
      listteball();
    }
    let rowsOnMount: any = stableSort(
      datatebal,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );
    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    setVisibleRows(rowsOnMount);
  }, [filedata, bankList]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      var new_array_two = [];

      for (let j = 0; j < patternDataArray.length; j++) {
        const element = patternDataArray[j];
        if (
          element.is_current_price === false &&
          event.target.checked == true
        ) {
       

          element.is_current_price = true;
          new_array_two.push(element);
        } else {
          new_array_two.push(JSON.parse(JSON.stringify(element)));
        }
      }
      setPatternDataArray(new_array_two);
      const newSelected = patternDataArray.map((n: any, index: any) => index);
      setSelected(newSelected);
      return;
    } else {
      var new_array_two = [];
      for (let j = 0; j < patternDataArray.length; j++) {
        const element = patternDataArray[j];
        if (
          element.is_current_price === true &&
          event.target.checked == false
        ) {
         

          element.is_current_price = false;
          new_array_two.push(element);
        } else {
          new_array_two.push(JSON.parse(JSON.stringify(element)));
        }
      }
      setPatternDataArray(new_array_two);
      setSelected([]);
    }
  };
  useEffect(() => {
    if (
      counterpartsListRender &&
      counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined
    ) {
      setCounterpartsListRender(false);
      setCounterpartsList(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
    }
  }, [counterpartsListData, counterpartsListRender]);
  const isSelected = (name: string, data: boolean) =>
    selected.indexOf(name) !== -1 && data == true;

  
  const formInitailData = {
    users: patternDataArray,
  };
  const validationSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        Type: Yup.string()
          .required("Please enter the payee's name."),
        Name: Yup.string().required('Name is required'),
      })
    ),
  });
  const handleClick = (
    event: React.MouseEvent<unknown>,
    index: any,
    Nametwo: string
  ) => {
    const selectedIndex = selected.indexOf(index);
    setIserror(index);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  function getStyles(errors: any, fieldName: any) {
    if (getIn(errors, fieldName)) {
      return {
        border: '1px solid red',
      };
    }
  }
  function deleteRow() {
    const list = patternDataArray.filter(function (entry: any, index: any) {
      return entry.is_current_price === false;
    });
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
    }
    setSelected([]);
    setPatternDataArray(list);
  }
  const enableDelete: any = patternDataArray.filter(function (entry: any) {
    return entry.is_current_price === true;
  });

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: { primary: { main: '#000' } },
  });

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <ThemeProvider theme={theme}>
          <Paper
            sx={{
              width: '100%',
              boxShadow: '0px 4px 12px rgba(89, 87, 87, 0.16)',
            }}
          >
            <EnhancedTableToolbar numSelected={selected.length} />
            <Formik
              enableReinitialize
              initialValues={formInitailData}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const ReqBody: any[] = [];
                for (let index = 0; index < patternDataArray.length; index++) {
                  const values = patternDataArray[index];

                  var obj: any = values.Name;
                  const words = obj.split(' ');
                  const findBankDetails = bankList.find(
                    (o: any) => o.bank_name == values.Bank_Name
                  );

                  const body = {
                    type: values.Type,
                    last_name: words[1],
                    first_name: words[0],
                    phone: !!values.Phone_number
                      ? values.Phone_number
                      : '254123456789',
                    email: !!values.Email ? values.Email : 'hello@niobi.co',
                    is_vendor: 0,
                    is_customer: 1,
                    country: !!values.Country ? values.Country : 'KE',
                    city: 'city',
                    postal_code: 'postal_code',
                    line1: !!values.Address_line_1
                      ? values.Address_line_1
                      : '123 Main Street',
                    payment_details: {
                      phone_number: values.Send_Money,
                      paybill_number: values.Paybill_Number,
                      account_number: values.Paybill_account_number,
                      till_number: values.Till_number,
                      bank_name:
                        !!findBankDetails && !!findBankDetails.bank_name
                          ? findBankDetails.bank_name
                          : '',
                      bank_branch: values.Bank_Branch,
                      bank_account_number: values.Account_Number,
                      account_name: values.Account_Name,
                      bank_code:
                        !!findBankDetails && !!findBankDetails.niobi_bank_code
                          ? findBankDetails.niobi_bank_code
                          : '',
                    },
                  };
                  const bodytwo = {
                    type: values.Type,
                    legal_name: values.Name,
                    phone: !!values.Phone_number
                      ? values.Phone_number
                      : '254123456789',
                    email: !!values.Email ? values.Email : 'hello@niobi.co',
                    is_vendor: 0,
                    is_customer: 1,
                    country: !!values.Country ? values.Country : 'KE',
                    city: 'city',
                    postal_code: 'postal_code',
                    line1:  !!values.Address_line_1?values.Address_line_1 : '123 Main Street',
                    payment_details: {
                      phone_number: values.Send_Money,
                      paybill_number: values.Paybill_Number,
                      account_number: values.Paybill_account_number,
                      till_number: values.Till_number,
                      bank_name:
                        !!findBankDetails && !!findBankDetails.bank_name
                          ? findBankDetails.bank_name
                          : '',
                      bank_branch: values.Bank_Branch,
                      bank_account_number: values.Account_Number,
                      account_name: values.Account_Name,
                      bank_code:
                        !!findBankDetails && !!findBankDetails.niobi_bank_code
                          ? findBankDetails.niobi_bank_code
                          : '',
                    },
                  };
                  if (values.is_current_price === true) {
                    if (values.Type == 'individual') {
                      ReqBody.push(body);
                    } else {
                      ReqBody.push(bodytwo);
                    }
                  }

                  // });
                }
                const event = 'Payees Imported';
                amplitude.getInstance().logEvent(event, eventProperties);
                posthog.capture(event, eventProperties);
                dispatch(payablesActions.bulkbills({ data: ReqBody }) as any);
                setLoading(true);
                localStorage.setItem('Bulkdatatwo', JSON.stringify([]));
                //Simulate submitting to database, shows us values submitted, resets form
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <TableContainer>
                      <Table
                        className={styles.addteballist}
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                      >
                        <TableHead className={styles.hedarliath}>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                icon={<img src="/Checkbox.svg" />}
                                checkedIcon={<img src="/Checkbox_two.svg" />}
                                color="primary"
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < patternDataArray.length
                                }
                                checked={
                                  patternDataArray.length > 0 &&
                                  selected.length === patternDataArray.length
                                }
                                onChange={handleSelectAllClick}
                                inputProps={{
                                  'aria-label': 'select all desserts',
                                }}
                              />
                            </TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Street Address</TableCell>
                            <TableCell className={styles.listactivetabal}>
                              Country
                            </TableCell>
                            <TableCell>Send Money</TableCell>
                            <TableCell>Paybill Number</TableCell>
                            <TableCell>Paybill Account Number</TableCell>
                            <TableCell>Till Number</TableCell>
                            <TableCell>Bank Name</TableCell>
                            <TableCell>Account Name</TableCell>
                            <TableCell>Account Number</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <FieldArray
                            name="users"
                            render={(arrayHelpers) => {
                              const users =
                                values.users.length < 0
                                  ? patternDataArray
                                  : patternDataArray;
                              return (
                                <>
                                  {users && users.length > 0
                                    ? users.map((user: any, index: any) => {
                                        var value = user;
                                        const isItemSelected = isSelected(
                                          index,
                                          user.is_current_price
                                        );
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                          <TableRow hover key={index}>
                                            <TableCell>
                                              <Checkbox
                                                name={`users.${index}.is_current_price`}
                                                color="primary"
                                                checked={isItemSelected}
                                                icon={
                                                  <img src="/Checkbox.svg" />
                                                }
                                                checkedIcon={
                                                  <img src="/Checkbox_two.svg" />
                                                }
                                                onClick={(event) => {
                                                  handleClick(
                                                    event,
                                                    index,
                                                    user.id
                                                  );
                                                  var newArray = [];
                                                  for (
                                                    let j = 0;
                                                    j < patternDataArray.length;
                                                    j++
                                                  ) {
                                                    const element =
                                                      patternDataArray[j];
                                                    if (
                                                      element.is_current_price ===
                                                        false &&
                                                      index == j
                                                    ) {
                                                 

                                                      element.is_current_price =
                                                        true;
                                                      newArray.push(element);
                                                    } else if (
                                                      element.is_current_price ===
                                                        true &&
                                                      index == j
                                                    ) {
                                                      element.is_current_price =
                                                        false;
                                                      newArray.push(element);
                                                    } else {
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            element
                                                          )
                                                        )
                                                      );
                                                    }
                                                  }
                                                  setPatternDataArray(newArray);
                                                }}
                                                inputProps={{
                                                  'aria-labelledby': labelId,
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                <Form.Select
                                                  name={`users.${index}.Type`}
                                                  style={getStyles(
                                                    errors,
                                                    `users.${index}.Type`
                                                  )}
                                                  id="from_select"
                                                  value={value.Type}
                                                  className={
                                                    styles.tableDropdown
                                                  }
                                                  onChange={(e: any) => {
                                                    let text = e.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Type = text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                >
                                                  <option selected value="">
                                                    Select type
                                                  </option>
                                                  <option value="organization">
                                                    organization
                                                  </option>
                                                  <option value="individual">
                                                    individual
                                                  </option>
                                                </Form.Select>{' '}
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                {' '}
                                                <>
                                                  <TextField
                                                    style={getStyles(
                                                      errors,
                                                      `users.${index}.Name`
                                                    )}
                                                    className={
                                                      styles.tabalinputdata + ''
                                                    }
                                                    placeholder="Name"
                                                    name={`users.${index}.Name`}
                                                    onChange={(value1: any) => {
                                                      let text =
                                                        value1.target.value;
                                                      var newArray = [];
                                                      for (
                                                        let j = 0;
                                                        j <
                                                        patternDataArray.length;
                                                        j++
                                                      ) {
                                                        const element =
                                                          patternDataArray[j];
                                                        if (index == j) {
                                                          value.Name = text;
                                                          newArray.push(
                                                            JSON.parse(
                                                              JSON.stringify(
                                                                value
                                                              )
                                                            )
                                                          );
                                                        } else {
                                                          newArray.push(
                                                            JSON.parse(
                                                              JSON.stringify(
                                                                element
                                                              )
                                                            )
                                                          );
                                                        }
                                                      }
                                                      setPatternDataArray(
                                                        newArray
                                                      );
                                                    }}
                                                    value={value.Name}
                                                  />{' '}
                                                </>
                                              </Box>
                                            </TableCell>

                                            <TableCell>
                                              <Box>
                                                <TextField
                                                  placeholder="Email"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                              
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Email = text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  value={value.Email}
                                                />
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                <TextField
                                                  placeholder="Phone number"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Phone_number =
                                                          text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  value={value.Phone_number}
                                                />
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                <TextField
                                                  placeholder="Street address"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Address_line_1 =
                                                          text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  value={value.Address_line_1}
                                                />
                                              </Box>
                                            </TableCell>

                                            <TableCell>
                                              <Box>
                                                <Autocomplete
                                                  options={countryoptions}
                                                  value={value.Countrytwo}
                                                  disablePortal={false}
                                                  onBlur={handleBlur}
                                                  onChange={(
                                                    event,
                                                    Countrycode: any,
                                                    reason,
                                                    details
                                                  ) => {
                                                    let text =
                                                      Countrycode.value;

                                                    let vaiye =
                                                      Countrycode.label;
                                                  

                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Country = !!text
                                                          ? text
                                                          : '';
                                                        value.Countrytwo =
                                                          !!vaiye ? vaiye : '';
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      className={
                                                        styles.tabalinputdatadattag +
                                                        ''
                                                      }
                                                      {...params}
                                                      onChange={(
                                                        countryoptions: any
                                                      ) => {
                                                      
                                                      }}
                                                      inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'country', // disable autocomplete and autofill
                                                      }}
                                                    />
                                                  )}
                                                />
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                <TextField
                                                  placeholder="Send money"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Send_Money = text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  value={value.Send_Money}
                                                />{' '}
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                <TextField
                                                  placeholder="Paybill number"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Paybill_Number =
                                                          text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  value={value.Paybill_Number}
                                                />{' '}
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                <TextField
                                                  placeholder="Paybill account number"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Paybill_account_number =
                                                          text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  value={
                                                    value.Paybill_account_number
                                                  }
                                                />{' '}
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                <TextField
                                                  placeholder="Till number"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Till_number =
                                                          text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  value={value.Till_number}
                                                />{' '}
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                <Form.Select
                                                  id="Bank_Name_Select"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                                  style={{
                                                    width: '100px',
                                                    color:
                                                      'rgba(0, 0, 0, 0.87)',
                                                    fontSize: '13px',
                                                    borderRadius: '0px',
                                                  }}
                                                  value={value.Bank_Name}
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Bank_Name = text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                >
                                                  <option selected value="">
                                                    Select Bank
                                                  </option>
                                                  {bankList?.map(
                                                    (
                                                      arrayElement: any,
                                                      index: number
                                                    ) => {
                                                      const selected =
                                                        value.Bank_name ==
                                                        arrayElement.bank_name
                                                          ? true
                                                          : false;
                                                      return (
                                                        <option
                                                          selected={selected}
                                                          value={
                                                            arrayElement.bank_name
                                                          }
                                                          key={index + 1}
                                                        >
                                                          {
                                                            arrayElement.bank_name
                                                          }
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </Form.Select>
                                              </Box>
                                            </TableCell>

                                            <TableCell>
                                              <Box>
                                                <TextField
                                                  placeholder="Account name"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Account_Name =
                                                          text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  value={value.Account_Name}
                                                />{' '}
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              <Box>
                                                <TextField
                                                  type="number"
                                                  placeholder="Account number"
                                                  className={
                                                    styles.tabalinputdata + ''
                                                  }
                                                  onChange={(value1: any) => {
                                                    let text =
                                                      value1.target.value;
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.Account_Number =
                                                          text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  value={value.Account_Number}
                                                />{' '}
                                              </Box>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })
                                    : null}
                                </>
                              );
                            }}
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div
                      className={styles.adhuadhuwqhhw}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px 0px',
                      }}
                    >
                      <label className={styles.save_btn22}>
                        Upload More <AddIcon />
                        <input
                          id="inputTag"
                          className={styles.addaffafagga}
                          type="file"
                          onChange={(file: any) => {
                            handleCSVUpload(file);
                            setFile(file.target.files[0]);
                          }}
                          accept=".xlsx"
                        />
                      </label>
                      <div className={styles.afdafafagalfff}>
                        <Button
                          color="#025041"
                          className={styles.white_btn}
                          onClick={() => {
                            navigate('/Counterparts');
                          }}
                        >
                          Cancel
                        </Button>
                        {enableDelete.length > 0 && (
                          <Button
                            color="#025041"
                            className={styles.save_btn}
                            onClick={() => {
                              deleteRow();
                            }}
                          >
                            Delete
                          </Button>
                        )}
                        {enableDelete.length > 0 ? (
                          <Button
                            disabled={loading}
                            type="submit"
                            color="#025041"
                            className={styles.save_btn}
                            onClick={() => {
                              setSubmitdata('add');
                            }}
                          >
                            {loading == true && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            Submit
                          </Button>
                        ) : (
                          <Button
                            disabled
                            type="submit"
                            color="#025041"
                            className={styles.save_btn}
                          >
                            {loading == true && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            Submit
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </Paper>
        </ThemeProvider>
      </Box>
    </>
  );
}

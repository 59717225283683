import './makepayment.scss'
import { DefaultBtn, LightBtn } from '../../../ui/ReuseableComponents/Buttons/Buttons'
import { DefaultGoBackBtn } from '../../../ui/ReuseableComponents/Buttons/Buttons'
import { Box, createTheme, Grid, ThemeProvider, Typography, TextField, SelectChangeEvent, useTheme, styled, AppBar, Tabs, Tab } from '@mui/material'
import { orange } from '@mui/material/colors'
import MakePaymentLayout from '../../../ui/ReuseableComponents/Layouts/MakePaymentLayout/MakePaymentLayout'
import { CurrencySelectBox, DynamicSelectComponent } from '../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import { InputSelectBox } from '../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import React, { useEffect, useState } from 'react'
import MakePaymentHeader from './Components/MakePaymentHeader'
import { payablesFlowactions } from '../../../../_actions'
import { useDispatch } from 'react-redux'
import { StyledTabPanel } from '../../../ui/tab'
import ExpressPayment from './Components/ExpressPayment/ExpressPayment'
import BulkPayment from './Components/BulkPayment/BulkPayment'
import BillPayment from './Components/BillPayment/BillPayment'
import BillFormikWrapper from './Components/BillPayment/BillFormikWrapper'
import { useNavigate } from 'react-router-dom';



const MakePayment = () => {
  const navigate = useNavigate();
  const [Step, setStep] = React.useState(0)
  const [isAddPayeeComponent, setIsAddPayeeComponent] = useState(false);
  const [render, setRender] = useState(true)
  const [isGoBackHidden, setIsGoBackHidden] = useState(false)
  const [currency, setCurrency] = useState([])
  const [SelectCurrency, setSelectCurrency] = useState("KES")
  const [SelectCountry_id, setSelectCountry_id] = useState(1)
  const [SelectBlence, setSelectBlence] = useState(0)
  const [useLayout, setUseLayout] = useState("defaultlayout") 
  const [isStepsNavHidden, setIsStepsNavHidden] = useState(false)
  const [Currencyedit, setCurrencyedit] = useState('KES')
  const [CountriyIdEDIT, setCountriyIdEDIT] = useState(1)
  const [tablist, setTabList] = useState(
    [{
      name: "Express payment"
    },
    {
      name: "Bulk payment"
    },
    {
      name: "Bill payment"
    }]
  )
  const [SelectFleg, setSelectFleg] = useState('https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/Kenya-Flag.png')
  const dispatch = useDispatch()
  const [value, setValue] = useState(0);
  const stepsForValue0 = [
    { label: 'Choose payee' },
    { label: 'Amount, schedule/pay now' },
    { label: 'Pay' },
    { label: 'OTP' }
  ];

  const stepsForValue1 = [
    { label: 'Upload file' },
    { label: 'Amount, submit for approval' },
    { label: 'Approve' },
    { label: 'Transaction summary' },
    { label: 'OTP' },
    { label: 'Pay' },
  ];

  const stepsForValue2 = [
    { label: 'Choose bill' },
    { label: 'Amount, schedule/pay now' },
    { label: 'Summary' },
    { label: 'Pay' },
  ];

  const steps = value === 0 && stepsForValue0 || value === 1 && stepsForValue1 || value === 2 && stepsForValue2;

  const [selectedUser, setSelectedUser] = React.useState(null);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  console.log(SelectCurrency, "SelectCurrency");

  const handleClick = () => {
    navigate('/balance')
  }
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const stepupdatefun = (setp: any) => {
    setStep(setp)
  }
  const CurrancyList = (data: any) => {
    // setCurrency(data)
    var FindFleg: any = data.find((item: any) => item.currency == SelectCurrency)
    setSelectBlence(FindFleg?.balance?.available_balance)

    var NewArray: any = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index];

      if (element.currency == "GHS") {

      }
      else {
        NewArray.push(element)
      }
    }
    setCurrency(NewArray)
  }
  useEffect(() => {
    if (render) {

      dispatch(payablesFlowactions.getApipayment("/get-all-currencies-details-by-user", CurrancyList) as any);
    }

  }, [render])

  const [activeTab, setActiveTab] = useState(0);

  const handleInitialTabValue = (tab: number) => {
    setActiveTab(tab);
    console.log("Active Tab:", tab);
  };


  console.log("paydata open",isAddPayeeComponent)


  return (
    <>
      <ThemeProvider theme={theme}>
        {/* //header */}
        <MakePaymentHeader value={value} step={Step} />
        {/* //body */}
        <Box className='makepayment_body mb-5'>
          <Box className='auto_container'>
            <MakePaymentLayout isStepsNavHidden={isStepsNavHidden} useLayout={useLayout} steps={steps} stepupdatefun={stepupdatefun} Step={Step} button={<DefaultGoBackBtn hidden={isGoBackHidden}  style={{ marginBottom: "25px" }} onClick={handleClick} />}>
              {Step == 1 || Step == 2 || Step == 3 || Step == 4 || Step == 5 || Step == 6 || Step == 7 || isAddPayeeComponent == true ? "" :
                <Box className='payee_header d-flex justify-content-between align-items-center mb-3'>
                  <Typography className='payee_header_text'>Payee</Typography>
                  <CurrencySelectBox countryList={currency}
                    value={SelectCurrency}
                    onChange={(e: any) => {
                      console.log(e.target.value, "asfsafasffasf");
                      var newObject = []
                      var FindFleg: any = currency.find((item: any) => item.currency == e.target.value)
                      setSelectFleg(FindFleg.flag)
                      console.log(FindFleg?.balance?.available_balance, "FindFleg?.balance?.available_balance");

                      setSelectBlence(FindFleg?.balance?.available_balance)
                      // setSelectCountry_id()
                      setCountriyIdEDIT(FindFleg.id)
                      setCurrencyedit(e.target.value)
                      setSelectCountry_id(FindFleg.id)
                      setSelectCurrency(e.target.value)
                    }} />
                </Box>}
              <Box>
                <Box sx={{ width: "100%" }}>
                  {/* {Step == 1 || Step == 2 || Step == 3 || isAddPayeeComponent == true ? "" :
                 
               </AppBar>
                   }  */}
                  {/* <AppBar
                   style={{ boxShadow: "none" }}
                   position="static"> */}
                  {Step == 1 || Step == 2 || Step == 3 || Step == 4 || Step == 5 || Step == 6 || Step == 7 || isAddPayeeComponent == true ? "" :
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#025041",
                        },
                        "& .MuiTab-root.Mui-selected": {
                          color: "#025041",
                        },
                        "& .MuiTab-root": {
                          textTransform: "none",
                          fontFamily: "Poppins",
                          fontSize: "13px",

                          "@media (max-width: 1440px)": {
                            padding: 0,
                          },
                          "@media (max-width: 1281px)": {
                            fontSize: 12,
                          },
                          "@media(min-width:1400px) and (max-width: 1525px)": {
                            padding: 1,
                          }
                        },
                      }}
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                      style={{
                        backgroundColor: '#ffffff',
                        color: '#000',
                        borderBottom: "1px solid #DDDDDD",
                        boxShadow: "none",
                        fontFamily: "Poppins"
                      }}
                    >
                      <Tab label='Express payment' className="tab_label" />
                      <Tab label='Bulk payment' />
                      <Tab label='Bill payment' />
                    </Tabs>}
                  <StyledTabPanel value={value} index={0} dir={theme.direction}>
                    <ExpressPayment setCountriyIdEDIT={setCountriyIdEDIT} CountriyIdEDIT={CountriyIdEDIT} Currencyedit={Currencyedit} setCurrencyedit={setCurrencyedit} setIsStepsNavHidden={setIsStepsNavHidden} setUseLayout={setUseLayout} SelectCountry_id={SelectCountry_id} SelectBlence={SelectBlence} setSelectedUser={setSelectedUser} selectedUser={selectedUser} SelectCurrency={SelectCurrency} SelectFleg={SelectFleg} setIsAddPayeeComponent={setIsAddPayeeComponent} isAddPayeeComponent={isAddPayeeComponent} stepupdatefun={stepupdatefun} Step={Step} />
                  </StyledTabPanel>
                  <StyledTabPanel value={value} index={1} dir={theme.direction}>
                    <BulkPayment  setIsGoBackHidden={setIsGoBackHidden} setUseLayout={setUseLayout} step={Step} SelectCurrency={SelectCurrency} stepupdatefun={stepupdatefun} SelectedFlag={SelectFleg} />
                  </StyledTabPanel>
                  <StyledTabPanel value={value} index={2} dir={theme.direction}>
                    <BillPayment useLayout={useLayout} setUseLayout={setUseLayout} SelectCountry_id={SelectCountry_id} SelectCurrency={SelectCurrency} setCountriyIdEDIT={setCountriyIdEDIT} CountriyIdEDIT={CountriyIdEDIT} Currencyedit={Currencyedit} setCurrencyedit={setCurrencyedit}  setSelectedUser={setSelectedUser} selectedUser={selectedUser} setIsAddPayeeComponent={setIsAddPayeeComponent} isAddPayeeComponent={isAddPayeeComponent} setIsGoBackHidden={setIsGoBackHidden} selectedCurrency={SelectCurrency} selectedFlag={SelectFleg} stepupdatefun={stepupdatefun} Step={Step} />
                  </StyledTabPanel>
                </Box> 
                {/* <FullWidthTabs SelectBlence={SelectBlence} SelectCurrency={SelectCurrency} SelectFleg={SelectFleg} isAddPayeeComponent={isAddPayeeComponent} setIsAddPayeeComponent={setIsAddPayeeComponent} stepupdatefun={stepupdatefun} Step={Step} /> */}
              </Box>
            </MakePaymentLayout>
          </Box>
        </Box>
      </ThemeProvider>
  
    </>
  )
}

export default MakePayment
import { Button, Box, Grid, Typography, FormControl, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { CustomInput } from '../../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import "./addnewpayee.scss"
import FullWidthTabs from './Components/tabs'
import { DefaultBtn, DefaultGoBackBtn } from '../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { useEffect, useMemo, useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors'
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from '@mui/icons-material/Delete';
import { LightIconAndTextButton } from '../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { AddCircleIcon, BinIcon } from '../../../../../../../../ui/ReuseableComponents/Icons/Icons'
import { CurrencySelectBox } from '../../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import { useDispatch } from 'react-redux'
import { payablesFlowactions } from '../../../../../../../../../_actions'
import * as Yup from "yup";
import { Formik } from "formik";
import { ErrorMessage } from '../../../../../../../../ui/eroor';
import { CountrySelectBox } from '../../../../../../../../ui/selectBox';
import { Form } from 'react-bootstrap';
import { InputNumber } from '../../../../../../../../ui/input'
import { RadioList } from '../../../../../../../../ui/RadioGroup'
import { ChangeEvent, FC } from 'react'
import countryList from 'react-select-country-list'
const Step1AddNewPayeeComponent = ({setIsEditPayeeApiCalled, setUpdetPayeeId, UpdateSeup, setCurrencyedit, Currencyedit, setCountriyIdEDIT, CountriyIdEDIT, UpdetPayeeId, isAddPayeeComponent, setIsAddPayeeComponent, bankData }: any) => {
  const [isContactDetailsOpen, setIsContactDetailsOpen] = useState(false)
  const [currency, setCurrency] = useState([])
  const [SelectCurrency, setSelectCurrency] = useState("KES")
  const [CountriyId, setCountriyId] = useState(1)
  const [render, setRender] = useState(true)
  const [mobilePaymentmethoad, setMobilePaymentMothoad] = useState([])
  const [bankPaymentMethoad, setBankPaymentMethoad] = useState([])
  const [countriesByCurrency, setCountriesByCurrency] = useState([])
  const [activeButton, setActiveButton] = useState('')
  const [PayOutMethodsByCountryId, setPayOutMethodsByCountryId] = useState([])
  const [EditGetapimethoad, setEditGetapimethoad] = useState([])
  const [countryNameget, setCountryNameget] = useState('Kenya');
  const [loaderReander, setLoaderReander] = useState(false)
  const [countryId, setCountryId] = useState("1")
  const [loaderaddmethoad, setLoaderaddmethoad] = useState(false)
  const [editGetdata, setEditgetdata] = useState<any>({})
  const [countrySortcode, setCountriesSortcode] = useState('ke')
  const [MethoadTitel, setMethoadTitel] = useState("")
  const [methodCliack, setMethoadCliack] = useState('')
  const dispatch = useDispatch();
  const [Rediolist, setRedioList] = useState([
    {
      name: "Individual",
      value: "individual"
    },
    {
      name: "Organization",
      value: "organization"
    }
  ])
  const [fildForm, setFildFrom] = useState({
    First_Name: "",
    Last_Name: "",
    currency: "KES",
    Organization_Name: "",
    Type: "individual",
    Email: "",
    Phone_number: "",
    Street_address: "",
    Country: "",
    Country_id: 1,
  })

  const [methodinAdd, setMethodAdd] = useState([])
  const [methodError, setMethodError] = useState('')
  const options = useMemo(() => countryList().getData(), [])

  console.log(methodError, "methodErrormethodError");

  const validationSchema = Yup.object().shape({
    First_Name: Yup.mixed().when('Type', {
      is: 'individual',
      then: Yup.mixed().required('Please enter the First Name.'),
      otherwise: Yup.mixed().notRequired(),
    }),
    Last_Name: Yup.mixed().when('Type', {
      is: 'individual',
      then: Yup.mixed().required('Please enter the Last Name.'),
      otherwise: Yup.mixed().notRequired(),
    }),
    Organization_Name: Yup.mixed().when('Type', {
      is: 'organization',
      then: Yup.mixed().required('Please enter the Organization Name.'),
      otherwise: Yup.mixed().notRequired(),
    }),
    currency: Yup.string()
      .required("Please select the Currency."),
    Country_id: Yup.string()
      .required("Please select the Country."),
    Type: Yup.string()
      .required("Please select the Type."),
  });
  const GetPayee = (data: any) => {
    console.log(data, "datadata852");
    setEditgetdata(data)
    setEditGetapimethoad(data?.payeeAccounts)
    setPayOutMethodsByCountryId(data?.payeeAccounts)
  }

  useEffect(() => {
    
    const newAr = PayOutMethodsByCountryId.filter((iit: any) =>
      EditGetapimethoad.find((item: any) => item.phone_number === iit.phone_number)
    );
   
    console.log(newAr, "newArraynewArray");

  }, [editGetdata])
  useEffect(() => {
    if (!!options && !!countryNameget) {
      const findcurrencies: any = options.find(
        (data: any) => data.label == countryNameget
      );
      var country_sortname_list = findcurrencies?.value?.toLowerCase();
      setCountriesSortcode(country_sortname_list);
      setLoaderReander(true)
      setTimeout(() => {
        setLoaderReander(false)
      }, 4000)
    }
  }, [countryNameget, options]);
  useEffect(() => {
    if (!!CountriyIdEDIT && !!Currencyedit) {
      dispatch(payablesFlowactions.getApipayment(`/v4/payee/edit-payee?currency=${Currencyedit}&country_id=${CountriyIdEDIT}&monite_counterpart_id=${UpdetPayeeId}`, GetPayee) as any);
    }

  }, [Currencyedit, CountriyIdEDIT])
  const CurrancyList = (data: any) => {
    // setCurrency(data)
    var NewArray: any = []
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      if (element.currency == "GHS") {
      }
      else {
        NewArray.push(element)
      }
    }
    setCurrency(NewArray)
  }
  useEffect
    (() => {
      if (render) {
        setRender(false)
        dispatch(payablesFlowactions.getApipayment("/get-all-currencies-details-by-user", CurrancyList) as any);
      }
    }, [render])
  useEffect(() => {
    if (countriesByCurrency.length > 0) {
      setCountryId((countriesByCurrency[0] as any).id);
    }
  }, [countriesByCurrency]);

  const getCountries = (data: []) => {
    console.log(data, "wajidcountrydata")
    setCountriesByCurrency(data);
    console.log(countriesByCurrency, "wajidcountrystate")
  }
  useEffect(() => {
    var newArray: any = []
    var errorMsg = ''
    var MethoadTitel = ''
    for (let index = 0; index < PayOutMethodsByCountryId.length; index++) {
      const element: any = PayOutMethodsByCountryId[index];
      if (element.title == "Till Number") {

        var bodytill_Number = {
          "payment_channel": element.payment_channel,
          "currency": element.currency,
          "country_id": element.country_id,
          till_number: element.till_number
        }
        if (!!element.till_number) {
          newArray.push(bodytill_Number)
        }
      }
      if (element.title == "RTGS" || element.title == "EFT" || element.title == "PESALINK") {

        if (!!element.account_name || !!element.bank_name || !!element.bank_account_number || !!element.branch_name) {
          console.log('setp 2');
          if (!!element.account_name && !!element.bank_name && !!element.bank_account_number) {
            console.log('setp 3');
            var bankBody = {
              "payment_channel": element.payment_channel,
              "currency": element.currency,
              "country_id": element.country_id,
              "bank_name": element.bank_name,
              "account_name": element.account_name,
              "account_number": element.account_number,
              "branch_name": element.branch_name
            }
            newArray.push(bankBody)
          } else {
            console.log('setp 4');
            MethoadTitel = element.title
            errorMsg = 'Please complete all related fields for the selected payment method.'
          }
        }
      }
      if (element.title == "Paybill") {
        if (!!element.paybill_account_number || !!element.paybill_number) {
          if (!!element.paybill_account_number && !!element.paybill_number) {
            if (element.paybill_account_number.length > 13) {
              MethoadTitel = element.title
              errorMsg = 'The account number must not exceed 13 digits.'
            } else {
              var paybillbody = {
                "payment_channel": element.payment_channel,
                "currency": element.currency,
                "country_id": element.country_id,
                paybill_number: element.paybill_number,
                account_number: element.account_number,
              }
              newArray.push(paybillbody)
            }
          } else {
            MethoadTitel = element.title
            errorMsg = 'Please complete all related fields for the selected payment method.'
          }
        }
      }
      if (element.title == "Digital Currency") {
        if (!!element.digital_currency || !!element.wallet_address || !!element.wallet_network) {
          if (!!element.digital_currency && !!element.wallet_address && !!element.wallet_network) {
            var digital_currencybody = {
              "payment_channel": element.payment_channel,
              "currency": element.currency,
              "country_id": element.country_id,
              "wallet_address": element.wallet_address,
              "wallet_network": element.wallet_network,
              "digital_currency": element.digital_currency
            }
            newArray.push(digital_currencybody)
          } else {
            MethoadTitel = element.title
            errorMsg = "Please complete all related fields for the selected payment method."
          }
        }
      }
      if (element.title == "SWIFT") {
        if (!!element.account_name || !!element.beneficiary_bank_name || !!element.account_number || !!element.beneficiary_bank_SWIFT_code || !!element.beneficiary_bank_address || !!element.correspondent_bank_name || !!element.correspondent_bank_SWIFT_code) {
          if (!!element.account_name && !!element.beneficiary_bank_name && !!element.account_number && !!element.beneficiary_bank_SWIFT_code && !!element.beneficiary_bank_address) {
            var bodyswift = {
              "payment_channel": element.correspondent,
              "currency": SelectCurrency,
              "country_id": element.country_id,
              "beneficiary_bank_name": element.beneficiary_bank_name,
              "account_name": element.account_name,
              "account_number": element.account_number,
              "beneficiary_bank_SWIFT_code": element.beneficiary_bank_SWIFT_code,
              "beneficiary_bank_address": element.beneficiary_bank_address,
              "correspondent_bank_name": element.correspondent_bank_name,
              "correspondent_bank_SWIFT_code": element.correspondent_bank_SWIFT_code,

            }
            newArray.push(bodyswift)
          } else {
            MethoadTitel = element.title
            errorMsg = 'Please complete all related fields for the selected payment method.'
          }
        }
      }
      if (element.title == "Digital Currency" || element.title == "SWIFT" || element.title == "RTGS" || element.title == "PESALINK" || element.title == "EFT" || element.title == "Paybill" || element.title == "Till Number") {

      } else {
        if (!!element.phone_number) {
          if (element.phone_number.length != 12) {
            MethoadTitel = element.title
            errorMsg = "Please enter 9 digit phone number"
          } else {
            var bodyphone_Number = {
              "payment_channel": element.payment_channel,
              "currency": element.currency,
              "country_id": element.country_id,
              phone_number: element.phone_number,

            }
            newArray.push(bodyphone_Number)
          }
        }
      }
    }
    console.log(errorMsg, newArray, "errorMsg");
    setMethodAdd(newArray)
    setMethoadTitel(MethoadTitel)
    setMethodError(errorMsg)
  }, [PayOutMethodsByCountryId])
  useEffect(() => {

    dispatch(payablesFlowactions.getApipayment(`/v4/get-country-details/${SelectCurrency}`, getCountries) as any);
  }, [SelectCurrency])

  const handleDeleteContactDetails = () => {
    setIsContactDetailsOpen(false)
  }
  const handleCountryButtonClick = (buttonName: any) => {
    setActiveButton(buttonName);
  };
  const addpayeesRes = (data: any) => {
    console.log(data, "datadata321");

    // window.location.reload()
    var body = {
      monite_counterpart_id: !!UpdetPayeeId ? UpdetPayeeId : data?.data?.monite_counterpart_id
    }
    console.log(body, 'datadata321');

    if (!!UpdetPayeeId) {
      UpdateSeup(body)
      setIsEditPayeeApiCalled(true)
      setIsAddPayeeComponent(false)
    } else {
      UpdateSeup(body)
      setIsAddPayeeComponent(false)
      setIsEditPayeeApiCalled(false)
    }
  }

  return (
    <Box>
      <Formik
        // innerRef={formdata}
        initialValues={fildForm}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log(values, "values");

          if (!methodError) {
            var body: any = {
              type: values.Type,

              "phone": !!values.Phone_number ? values.Phone_number : "254123456789",
              "email": !!values.Email ? values.Email : "hello@niobi.co",
              "country": !!values.Country ? values.Country : "KE",
              "city": 'city',
              // "tax_identification_number": values.tax_identification_number,
              "postal_code": 'postal_code',
              "line1": !!values.Street_address ? values.Street_address : "123 Main Street",
              // 'is_customer': values. == true ? 1 : 0,
              "payment_details": PayOutMethodsByCountryId
            }
            if (values.Type == "organization") {
              body.legal_name = values.Organization_Name
            } else {
              body.first_name = values.First_Name
              body.last_name = values.Last_Name
            }
            if (!!UpdetPayeeId) {
              dispatch(payablesFlowactions.PostPaymentfull(`/v4/payee/update-payee/${UpdetPayeeId}`, body, addpayeesRes, setLoaderaddmethoad,false) as any)

            } else {
              dispatch(payablesFlowactions.PostPaymentfull("/v4/payee/create-payee", body, addpayeesRes, setLoaderaddmethoad,false) as any)
            }
          }
        }}
      >
        {(formik) => (
          <>

            <Form onSubmit={formik.handleSubmit} >
              <DefaultGoBackBtn onClick={() => {
                setIsAddPayeeComponent(false)
                setUpdetPayeeId("")
              }} style={{ marginTop: "-60px" }} />
              <Box className="d-flex justify-content-center flex-column ">
                <Typography className="add_payee_heading">
                  Add payee details
                </Typography>
                <Grid container display={'flex'} justifyContent={'space-between'}>
                  {formik.values.Type === 'individual' && (
                    <>
                      <Grid item md={5.8}>
                        <InputNumber label={'First Name'} disabled={!!UpdetPayeeId ? true : false} mainDevstyle={{ margin: '0px' }}
                          value={formik.values.First_Name} onChangeset={(e: ChangeEvent<HTMLInputElement>) => formik.setFieldValue('First_Name', e.target.value)}
                        />
                        {formik.touched.First_Name && formik.errors.First_Name ? (
                          <ErrorMessage>{formik.errors.First_Name}</ErrorMessage>
                        ) : null}
                      </Grid>
                      <Grid item md={5.8}>
                        <InputNumber disabled={!!UpdetPayeeId ? true : false} label={'Last Name'} mainDevstyle={{ margin: '0px' }} value={formik.values.Last_Name} onChangeset={(e: any) => formik.setFieldValue('Last_Name', e.target.value)} />
                        {formik.touched.Last_Name && formik.errors.Last_Name ? (
                          <ErrorMessage>{formik.errors.Last_Name}</ErrorMessage>
                        ) : null}
                      </Grid>
                    </>
                  )}
                  {formik.values.Type === 'organization' && (
                    <Grid item md={12}>
                      <InputNumber disabled={!!UpdetPayeeId ? true : false} label={'Organization Name'} mainDevstyle={{ margin: '0px' }} placeholder="Organization name" style={{ width: "100% !important" }} value={formik.values.Organization_Name} onChangeset={(e: any) => formik.setFieldValue('Organization_Name', e.target.value)} />
                      {formik.touched.Organization_Name && formik.errors.Organization_Name ? (
                        <ErrorMessage>{formik.errors.Organization_Name}</ErrorMessage>
                      ) : null}
                    </Grid>
                  )}
                </Grid>
                <Box sx={{ padding: "10px 0px" }}>
                  <RadioList disabled={!!UpdetPayeeId ? true : false} list={Rediolist} value={formik.values.Type} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('Type', event.target.value)
                  }} />
                  {formik.touched.Type && formik.errors.Type ? (
                    <ErrorMessage>{formik.errors.Type}</ErrorMessage>
                  ) : null}
                </Box>
                <Box>
                  {
                    !isContactDetailsOpen ? (
                      <LightIconAndTextButton style={{ color: "#025041" }} icon={<AddCircleIcon style={{ color: "#025041", width: "20px" }} />} text="Add contact details" onClick={() => setIsContactDetailsOpen(!isContactDetailsOpen)} />
                    ) : (
                      <LightIconAndTextButton icon={<BinIcon style={{ color: "red", width: "20px" }} />} text="Remove contact details" onClick={handleDeleteContactDetails} />
                    )
                  }
                  <Box style={{ transition: "height 0.3s ease" }}>
                    {
                      isContactDetailsOpen && (
                        <Box className="mt-3">
                          <Box className="mb-4">
                            <Typography className='name-label'>Email</Typography>
                            <CustomInput placeholder="hello@niobi.co" value={formik.values.Email} onChange={(event: any) => formik.setFieldValue('Email', event.target.value)} />
                          </Box>
                          <Box className="mb-4">
                            <Typography className='name-label'>Phone number</Typography>
                            <CustomInput placeholder="254123456789" value={formik.values.Phone_number} onChange={(event: any) => formik.setFieldValue('Phone_number', event.target.value)} />
                          </Box>
                          {/* <Box className="mb-4">
                          <Typography className='name-label'>Tax Number</Typography>
                          <CustomInput value={fild_data.tax_identification_number} onChange={(event: any) => setFild_data({ ...fild_data, tax_identification_number: event.target.value })} />
                        </Box> */}
                          <Box className="mb-4">
                            <Typography className='name-label'>Street address</Typography>
                            <CustomInput placeholder="123 Main Street" value={formik.values.Street_address} onChange={(event: any) => formik.setFieldValue('Street_address', event.target.value)} />
                          </Box>
                          <Box className="mb-4">
                            <Typography className='name-label'>Country</Typography>
                            <CountrySelectBox value={formik.values.Country}  onChange={(val: string) => formik.setFieldValue('Country', val)}  />
                          </Box>
                        </Box>
                      )
                    }
                  </Box>
                </Box>
              </Box>
              <Box className="mt-4">
                <Box className="d-flex justify-content-between align-items-center">
                  <Typography className="add_payee_payment_details_heading">Add payment details</Typography>
                  <CurrencySelectBox countryList={currency}
                    value={formik.values.currency}
                    onChange={(e: any) => {
                      console.log(e.target.value, "asfsafasffasf");
                      formik.setFieldValue("currency", e.target.value)
                      var Currencylist: any = currency.find((item: any) => item.currency == e.target.value)
                      // const findcurrencies: any = options.find((data: any) => data.label == Currencylist.name)
                      // var country_sortname_list = findcurrencies?.value?.toLowerCase()
                      setSelectCurrency(e.target.value)
                      setCountriyId(Currencylist.id)
                      setCountryNameget(Currencylist.name)
                      dispatch(payablesFlowactions.getApipayment(`/v4/payee/edit-payee?currency=${e.target.value}&country_id=${Currencylist.id}&monite_counterpart_id=${UpdetPayeeId}`, GetPayee) as any);

                      // dispatch(payablesFlowactions.getApipayment(`/v4/get-payment-methods-payee/${e.target.value}/${Currencylist.id}/pay_out`, getPayOutMethodsByCountryId) as any);

                      // handleCurrencyChange();
                    }} />
                </Box>
                <Box style={{ display: countriesByCurrency.length == 1 ? "none" : "flex", margin: countriesByCurrency.length == 1 ? "0px" : "14px 0px" }} className="gap-2 justify-content-start align-items-center flex-wrap">
                  {
                    countriesByCurrency?.map((country: any, index) => {
                      return (
                        <Button

                          className={`country_btn ${activeButton === country.name ? "active-btn" : index === 0 && !countriesByCurrency?.find((c: any) => c.name === activeButton) ? "active-btn" : ""}`}
                          onClick={() => {
                            formik.setFieldValue('Country_id', country.id)
                            console.log(formik.values.Country_id, "wajidid");
                            handleCountryButtonClick(country.name)
                            setCountryId(country.id)
                            // setMethoadCliack(country.)
                          }}>
                          {country.currency === "TZS" ? "Tanzania" : country.currency === "CDF" ? "Congo" : country.name}

                        </Button>
                      )
                    })
                  }
                </Box>
                <Box>
                  <FullWidthTabs MethoadTitel={MethoadTitel} loaderReander={loaderReander} countrySortcode={countrySortcode} UpdetPayeeId={UpdetPayeeId} editGetdata={editGetdata} methodError={methodError} setPayOutMethodsByCountryId={setPayOutMethodsByCountryId} PayOutMethodsByCountryId={PayOutMethodsByCountryId} countryId={formik.values.Country_id} formik={formik} currency={formik.values.currency} bankPaymentMethoad={bankPaymentMethoad} mobilePaymentmethoad={mobilePaymentmethoad} bankData={bankData} />
                  <DefaultBtn loader={loaderaddmethoad} disabled={loaderaddmethoad} onClick={() => { }} text="Add recipient" style={{ width: "100%", marginTop: "40px" }} />
                </Box>
              </Box>
            </Form>
          </>
        )}
      </Formik>
    </Box >
  )
}
export default Step1AddNewPayeeComponent
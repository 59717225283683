import { Box, Typography } from "@mui/material"
import { BankSelectBox, BankSelectBoxName, TheSelectBox } from "../../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs"
import { CustomInput } from "../../../../../../../../ui/ReuseableComponents/Inputs/Inputs"
import { LightIconAndTextButton } from "../../../../../../../../ui/ReuseableComponents/Buttons/Buttons"
import { AddCircleIcon } from "../../../../../../../../ui/ReuseableComponents/Icons/Icons"
import { InputNumber } from "../../../../../../../../ui/input"
import { useState } from "react"


export const BankTabe = ({ setPayOutMethodsByCountryId, addNewPaymentdisabled, PayOutMethodsByCountryId, updatedIndex, bankData }: any) => {
console.log(PayOutMethodsByCountryId[updatedIndex]?.bank_branch,"updatedIndex");

    return (
        <Box className="d-flex flex-column gap-3">
            <Box>
                <Typography className='mb-2 label'>Bank Name</Typography>
                <BankSelectBoxName
                    value={PayOutMethodsByCountryId[updatedIndex]?.bank_name}
                    options={bankData}
                    fildName="bank_name"
                    defaultOption="--Select recipient bank--"
                    onChange={(e: any) => {
                        const dataUppdted = [...PayOutMethodsByCountryId]
                        dataUppdted[updatedIndex].bank_name = e.target.value
                        setPayOutMethodsByCountryId(dataUppdted)
                    }}
                />
            </Box>
            <Box>
                <Typography className='label'>Bank branch</Typography>
                <CustomInput
                value={!!PayOutMethodsByCountryId[updatedIndex]?.bank_branch?PayOutMethodsByCountryId[updatedIndex]?.bank_branch:""}
                onChange={(e: any) => {
                    console.log(PayOutMethodsByCountryId, updatedIndex, "123PayOutMethodsByCountryId");

                    const dataUppdted = [...PayOutMethodsByCountryId]
                    dataUppdted[updatedIndex].bank_branch = e.target.value
                    setPayOutMethodsByCountryId(dataUppdted)
                }}
                     placeholder="Enter bank branch" />
            </Box>
            <Box>
                <Typography className='label'>Account name</Typography>
                <CustomInput
                    onChange={(e: any) => {
                        const dataUppdted = [...PayOutMethodsByCountryId]
                        dataUppdted[updatedIndex].account_name = e.target.value
                        setPayOutMethodsByCountryId(dataUppdted)
                    }}

                    value={!!PayOutMethodsByCountryId[updatedIndex]?.account_name?PayOutMethodsByCountryId[updatedIndex]?.account_name:""}
                    placeholder="Enter account name" />
            </Box>
            <Box>
                <Typography className='label'>Account Number</Typography>
                <CustomInput
                    onChange={(e: any) => {
                        const dataUppdted = [...PayOutMethodsByCountryId]
                        dataUppdted[updatedIndex].bank_account_number = e.target.value
                        setPayOutMethodsByCountryId(dataUppdted)
                    }}

                    value={!!PayOutMethodsByCountryId[updatedIndex]?.bank_account_number?PayOutMethodsByCountryId[updatedIndex]?.bank_account_number:""}
                    placeholder="Enter account number" />
            </Box>
            {/* <LightIconAndTextButton disabled={addNewPaymentdisabled} icon={<AddCircleIcon style={{ width: "20px" }} />} text="Save & add another payment detail" /> */}

        </Box>
    )
}
export const Swiftteb = ({ setPayOutMethodsByCountryId, PayOutMethodsByCountryId, updatedIndex }: any) => {
    console.log(PayOutMethodsByCountryId, "setPayOutMethodsByCountryId");

    return (
        <>
            {PayOutMethodsByCountryId[updatedIndex]?.title == "SWIFT" ?
                <Box className="d-flex flex-column gap-3">
                    <InputNumber
                        onChangeset={(e: any) => {
                            const dataUppdted = [...PayOutMethodsByCountryId]
                            dataUppdted[updatedIndex].account_name = e.target.value
                            setPayOutMethodsByCountryId(dataUppdted)
                        }}
                        value={PayOutMethodsByCountryId[updatedIndex].account_name}
                        mainDevstyle={{ margin: '0px' }} label="Account name" name="account_name" />
                    <InputNumber
                        onChangeset={(e: any) => {
                            const dataUppdted = [...PayOutMethodsByCountryId]
                            dataUppdted[updatedIndex].account_number = e.target.value
                            setPayOutMethodsByCountryId(dataUppdted)
                        }}
                        value={PayOutMethodsByCountryId[updatedIndex]?.account_number}
                        mainDevstyle={{ margin: '0px' }} label="Account number / IBAN" name="account_name" />
                    <InputNumber
                        onChangeset={(e: any) => {
                            const dataUppdted = [...PayOutMethodsByCountryId]
                            dataUppdted[updatedIndex].beneficiary_bank_name = e.target.value
                            setPayOutMethodsByCountryId(dataUppdted)
                        }}
                        value={PayOutMethodsByCountryId[updatedIndex]?.beneficiary_bank_name}
                        mainDevstyle={{ margin: '0px' }} label="Beneficiary bank name" name="account_name" />
                    <InputNumber
                        onChangeset={(e: any) => {
                            const dataUppdted = [...PayOutMethodsByCountryId]
                            dataUppdted[updatedIndex].beneficiary_bank_SWIFT_code = e.target.value
                            setPayOutMethodsByCountryId(dataUppdted)
                        }}
                        value={PayOutMethodsByCountryId[updatedIndex]?.beneficiary_bank_SWIFT_code}
                        mainDevstyle={{ margin: '0px' }} label="Beneficiary bank SWIFT code" name="account_name" />
                    <InputNumber
                        onChangeset={(e: any) => {
                            const dataUppdted = [...PayOutMethodsByCountryId]
                            dataUppdted[updatedIndex].beneficiary_bank_address = e.target.value
                            setPayOutMethodsByCountryId(dataUppdted)
                        }}
                        value={PayOutMethodsByCountryId[updatedIndex]?.beneficiary_bank_address}
                        mainDevstyle={{ margin: '0px' }} label="Beneficiary bank address" name="account_name" />
                    <InputNumber
                        onChangeset={(e: any) => {
                            const dataUppdted = [...PayOutMethodsByCountryId]
                            dataUppdted[updatedIndex].correspondent_bank_name = e.target.value
                            setPayOutMethodsByCountryId(dataUppdted)
                        }}
                        value={PayOutMethodsByCountryId[updatedIndex]?.correspondent_bank_name}
                        requiredFild="NotActive" mainDevstyle={{ margin: '0px' }} label="Correspondent bank name" name="account_name" />
                    <InputNumber
                        onChangeset={(e: any) => {
                            const dataUppdted = [...PayOutMethodsByCountryId]
                            dataUppdted[updatedIndex].correspondent_bank_SWIFT_code = e.target.value
                            setPayOutMethodsByCountryId(dataUppdted)
                        }}
                        value={PayOutMethodsByCountryId[updatedIndex]?.correspondent_bank_SWIFT_code}
                        requiredFild="NotActive" mainDevstyle={{ margin: '0px' }} label="Correspondent bank SWIFT code" name="account_name" />

                </Box> : ""}
        </>
    )
}
export const DigitalCurrency = ({ setPayOutMethodsByCountryId, PayOutMethodsByCountryId, updatedIndex }: any) => {
    const [Digital_currencyList, setDigital_currencyList] = useState<any>([{
        id: "USDT",
        bank_name: "USDT"
    },
    {
        id: "USDC",
        bank_name: "USDC"
    }])
    const [Wallet_networkList, setWallet_networkList] = useState<any>([{
        id: "ERC20",
        bank_name: "ERC20"
    },
    {
        id: "TRC20",
        bank_name: "TRC20"
    }])
    return (
        <Box className="d-flex flex-column gap-3">
            <Box>
                <Typography className='mb-2 label'>Digital currency</Typography>
                <BankSelectBox
                    value={PayOutMethodsByCountryId[updatedIndex]?.digital_currency}
                    options={Digital_currencyList}
                    fildName="bank_name"
                    defaultOption="--Select Digital currency--"
                    onChange={(e: any) => {
                        const dataUppdted = [...PayOutMethodsByCountryId]
                        dataUppdted[updatedIndex].digital_currency = e.target.value
                        setPayOutMethodsByCountryId(dataUppdted)
                    }}
                />
            </Box>
            <Box>
                <Typography className='label'>Wallet address</Typography>
                <CustomInput
                    onChange={(e: any) => {
                        const dataUppdted = [...PayOutMethodsByCountryId]
                        dataUppdted[updatedIndex].wallet_address = e.target.value
                        setPayOutMethodsByCountryId(dataUppdted)
                    }}

                    value={PayOutMethodsByCountryId[updatedIndex]?.wallet_address}
                    placeholder="Wallet address" />
            </Box>
            <Box>
                <Typography className='mb-2 label'>Wallet network</Typography>
                <BankSelectBox
                    value={PayOutMethodsByCountryId[updatedIndex]?.wallet_network}
                    options={Wallet_networkList}
                    fildName="bank_name"
                    defaultOption="--Select Wallet network--"
                    onChange={(e: any) => {
                        const dataUppdted = [...PayOutMethodsByCountryId]
                        dataUppdted[updatedIndex].wallet_network = e.target.value
                        setPayOutMethodsByCountryId(dataUppdted)
                    }}
                />
            </Box>
        </Box>
    )
}
import './Topup.scss';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import { getUser } from '../../../../../../_helpers';
import { Button } from 'react-bootstrap';
import { Autocomplete, Box, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
const SelectMethod = (props: any) => {
    const [showModal, setShow] = useState(props.show);
    const [selectedMethod, setSelectedMethod] = useState('');
    
    const user = getUser()?.data ?? null;

    const onClose = () => {
        setShow(false);
        props.onClose(false);
    };
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    
    return (
        <>
            <Modal
                show={showModal}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="ModalHeader"
                className="topup-modal"
                centered
                size="lg"
            >
                <ThemeProvider theme={theme}>
                    <Box className="CloseContenar">
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box className="heding_contean">
                        <Typography>Select payment method</Typography>
                        <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props?.values}
                  className="selectBox"
                  onChange={(e: any) => {
                    props.onSaveCurrancy(e.target.value)
                    // const FindCountry: any = props?.currencyAndMethodData?.find((item: any) => item.currency == element.currency)

                    // localStorage.setItem("CountryId", FindCountry.id)

                  }}
                >
                  {/* {Array.isArray(countries) && countries.map((item, index) => {
                    return (
                      <MenuItem value={item.label} key={index}>
                        <Box className="MenuItemBox" {...props}>
                          <img
                            loading="lazy"
                            width="25"
                            src={item.img}
                            alt=""
                          />
                          {item.label}
                        </Box>
                      </MenuItem>

                    )
                  })} */}
                  {/* <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
                       
                    </Box>
                </ThemeProvider>
                <Modal.Body>
                    {/* <div className="label">Choose payment Method</div> */}
                    <div className="type-card-list">
                        {(!!user && user?.role_name && user?.role_name == 'Director') ||
                            user.role_name == 'Admin' ? (
                            <div
                                id="type_card_id"
                                className={`type-card-twoo ${selectedMethod === 'Express Payment USD' ? 'active' : ''
                                    }`}
                                onClick={() => {
                                    setSelectedMethod('Express Payment USD')
                                    props.onSave("Express Payment USD")
                                }}
                            >

                                <span className="type-card__name">

                                    Express payment
                                </span>

                            </div>
                        ) : (
                            <div
                                style={{ cursor: 'no-drop' }}
                                id="type_card_id"
                                className={`type-card-twoo ${selectedMethod === 'Express Payment USD' ? 'active' : ''
                                    }`}
                            >

                                <span className="type-card__name">

                                    Express payment
                                </span>

                            </div>
                        )}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
            <Button className="white-btn" onClick={onClose}>
              Cancel
            </Button>
            <Button
                className="green-btn"
                onClick={() => {
                  if (
                    (!!user &&
                      !!user?.role_name &&
                      user?.role_name == 'Accountant') ||
                    user?.role_name == 'Admin' ||
                    user?.role_name == 'Director'
                  ) {
                    props.onSave(selectedMethod)
                    setShow(false);
                  } else {
                  }
                }}
              >
                Next
              </Button> */}
                {/* </Modal.Footer> */}
            </Modal>
        </>
    )
}
export default SelectMethod;
interface CountryType {
    label: string;
    img: string;
}
const countries: readonly CountryType[] = [
    { label: 'KES', img: "/kenya-flag.png" },
    {
        label: 'USD',
        img: "/united_states_of_america_round_icon_64.png"
    },

]
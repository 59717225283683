import React from 'react';
import styles from './Create_your_invoice.module.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Autocomplete, Box, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { Col, Form, Button, InputGroup } from "react-bootstrap";
import { CountryDropdown } from 'react-country-region-selector';
import Preview_nodata from './components/Preview/Preview_nodata';
import Preview_fils from './components/Preview_fils/Preview_fils';
import { useDispatch, useSelector } from "react-redux";
import { selectAllBalanceLIstData } from "../../../../../_reducers/balance.reducer";
import { balanceActions } from "../../../../../_actions/balance.actions";
import Checkbox from '@mui/material/Checkbox';
import { selectAllCounterpartsListData } from "../../../../../_reducers/counterparts.reducer";
import { counterpartsActions } from "../../../../../_actions/counterparts.actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dashboardActions } from "../../../../../_actions";
import {
  getBalanceReducer,
  getBillReducer,
  selectAllData,
} from "../../../../../_reducers/dashboard.reducer";
import { getUser, isUserAuthenticated, authHeader } from '../../../../../_helpers';
import countryList from 'react-select-country-list';
import moment from 'moment';
import Select from "react-select";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


interface Product {
  quantity: string;
  tax: string;
  monite_product_id: string;
  price: string;
  name: string;
  calculatedTax?: number;
  subtotal?: number;
}

const Create_your_invoiceIn = (props: any) => {
  const user = getUser()?.data ?? '';

  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);

  const [selectedValue, setSelectedValue] = useState('');
  const [existingProduct, setExistingProduct] = useState<any>([]);
  // const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [productDetails, setProductDetails] = useState<any>([]);
  const [extractedProductData, setExtractedProductData] = useState([]);
  const DashboardApiData = useSelector((state) => selectAllData(state));
  const [currencyData, setCurrencyData] = useState("")
  const [calculatedData, setCalculatedData] = useState<Product[]>([]);
  const [totalSubtotal, setTotalSubtotal] = useState<number>(0);
  const [paymentLink, setPaymentLink] = useState(false)
  const [searchInput, setSearchInput] = useState('');




  const [payload, setPayload] = useState<any>({
    Invoice_Name: "",
    Upload_img: "",
    company_info_Name: user?.entity?.name,
    company_info_Title: user?.name,
    company_info_Email: user?.email,
    company_info_Mobile: user?.mobile,
    company_info_Address: user?.entity?.street_address,
    company_info_Country: user?.entity?.country,
    Type: "individual",
    Receiver_info_First_name: "",
    Receiver_info_Last_name: "",
    Receiver_info_Title: "",
    Receiver_info_Email: "",
    Receiver_info_Mobile: "",
    Receiver_info_Address: "",
    Receiver_info_Country: "",

  })

  const dispatch = useDispatch();
  const [textrea, setTextrea] = useState('')
  const [bankDetailsRender_two, setBankDetailsRender_two] = useState(true);
  const [currencies, setCurrencies] = useState([])
  const [render, setRender] = useState(true);
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [ErrorMessage, setErrorMessage] = useState({
    Invoice_Name: "Please enter the invoice name.",
    Upload_img: "",
    company_info_Name: "Please enter the company name.",
    company_info_Sender: "Please enter the sender name.",
    company_info_Receiver: "Please enter the receiver name.",
    company_info_Email: "Please enter the email.",
    company_info_Address: "Please enter the address.",
    company_info_Mobile: "Please enter the mobile number.",
    company_info_Country: "Please select the country.",
    Invoice: "Please enter the invoice number.",
    // Date_issued: "Please select the date issued.",
    Due_date: "Please select the due date.",
    Item: "Please enter the item.",
    Currency: "Please select the currency",
    Quantity: "Please enter the quantity",
    Price: "Please enter the price",
    description: "",
    tax: "Please select the Vat"
  })
  const [TaxIdentificationNumber, setTaxIdentificationNumber] = useState("")
  const [itemsbill, setItemsbill] = useState([{
    Item: "",
    Quantity: "",
    Price: "",
    total: 0,
    description: "",
    description_data: false,
    tax: "",
    calculatedTax: null,
    monite_product_id: "",
  }])
  const [bill_items, setBill_items] = useState([{
    name: "",
    quantity: "",
    price: "",
    total: 0,
    description: "",
    tax: "",
    calculatedTax: null,
    monite_product_id: "",
  }])

  const [validsan, setValidsan] = useState(false)
  const [Discount_value, setDisacaunt_value] = useState("")
  const [disacaunt_type, setDisacaunt_type] = useState([{
    name: "percentage",
    velue: "percentage"
  },
  {
    name: "Normal",
    velue: "amount"
  }])
  const[getapi,setGetapi]=useState({})
  const [counterpartsListRender_two_data, setCounterpartsListRender_two_data] = useState(true);
  const [footerdata, setFooterdata] = useState({
    totel: 0,
    textrea: "",
    Tax: 0,
    Discount: 0,
    pagetotel: 0,
    includes_tax: false,
    tex_totel: 0,
    Discount_value: 0,
    includes_Discount: false,
    Discount_type: 'amount',
  })
  const [vatall, setVatall] = useState([])
  const [vatType, setVatType] = useState('')
  const DashboardData = useSelector((state) => selectAllData(state));
  const options = useMemo(() => countryList().getData(), []);
 
  const navigate = useNavigate();

  const onCancel = () => {
    setValidsan(true)
  }
  const [info, setInfo] = useState({
    Invoice: "",
    Due_date: "",
    Currency: "",
    Invoice_Name: ""
  })
  const [validesanpayload, setValidesanpayload] = useState(false)
  const [validesannodata, setValidesannodata] = useState(false)
  const [idPage, setIdPage] = useState("")
  const [ReceiverHide, setReceiverHide] = useState(false)
  const [receiver_info, setReceiver_info] = useState({
    name: '',
    title: '',
    email: '',
    mobile: '',
    address: '',
    country: '',
  })
  const [ListdoupDoun, setListdoupDoun] = useState([])
  const [listRender, setListRender] = useState(false)
  const [customer_nameSender, setCustomer_nameSender] = useState("")
  const [customer_nameReceiverr, setCustomer_nameReceiver] = useState("")
  const [selectReceiver, setselectReceiver] = useState<any>()
  const data = useSelector((state) => selectAllBalanceLIstData(state));
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const [RedusarAPI, setRedusarAPI] = useState(true)
  const [redusar, setRedusar] = useState(false)
  const [loader, setLoader] = useState(false)
  const [prevItems, setPrevItems] = useState<any[]>([]);
  const [productTotalTax, setProductTotalTax] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [newInvoiceName, setNewInvoiceName] = useState("INVOICE")
  const [productSubtotal, setProductSubtotal] = useState<number>(0);
  const [select_data, setSelect_data] = useState("");
  const [optionsValue, setoptionsValue] = useState<any>([]);
  const [PriceDisebal, setPriceDisebal] = useState(false)
  const [apicallData, setApicallData] = useState('')



  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(counterpartsActions.getAllvat() as any)
      dispatch(balanceActions.getTopUpDetails_doller() as any);
    }
  }, [render]);


  useEffect(() => {
    const findfast = itemsbill.find((o: any) => o.Item == '')
    const findtwo = itemsbill.find((o: any) => o.Quantity == '')
    const findthdee = itemsbill.find((o: any) => o.Price == '')
    const vatitem = itemsbill.find((o: any) => o.tax == '')
   

    if (!!payload &&
      ReceiverHide == true &&
      payload?.Receiver_info_Address &&
      payload?.Receiver_info_Email &&
      // payload?.Receiver_info_Title &&
      payload?.Receiver_info_First_name &&
      payload?.Receiver_info_Last_name &&
      payload?.Type == "individual" &&
      newInvoiceName &&
      payload?.Receiver_info_Mobile &&
      payload?.company_info_Address &&
      payload?.company_info_Email &&
      payload?.company_info_Title &&
      payload?.company_info_Name &&
      payload?.company_info_Mobile &&
      info?.Currency &&
      info?.Due_date &&
      info?.Invoice &&
      vatitem == undefined &&
      findfast == undefined) {
      setValidesanpayload(true)
    } else if (!!payload &&
      ReceiverHide == true &&
      payload?.Receiver_info_Address &&
      payload?.Receiver_info_Email &&
      // payload?.Receiver_info_Title &&
      payload?.Receiver_info_First_name &&
      payload?.Type == "organization" &&
      payload?.Receiver_info_Mobile &&
      payload?.company_info_Address &&
      payload?.company_info_Email &&
      newInvoiceName &&
      payload?.company_info_Title &&
      payload?.company_info_Name &&
      payload?.company_info_Mobile &&
      info?.Currency &&
      info?.Due_date &&
      info?.Invoice &&
      vatitem == undefined &&
      findfast == undefined) {
      setValidesanpayload(true)
    } else if (!!payload &&
      ReceiverHide == false &&
      payload?.company_info_Address &&
      payload?.company_info_Email &&
      payload?.company_info_Title &&
      payload?.company_info_Name &&
      // payload?.company_info &&
      selectReceiver &&
      newInvoiceName &&
      info?.Currency &&
      info?.Due_date &&
      info?.Invoice &&
      vatitem == undefined &&
      findfast == undefined) {
      setValidesanpayload(true)
    } else {
      setValidesanpayload(false)
    }

    if (!!payload &&
      // payload?.Invoice_Name ||
      payload?.Receiver_info_Address ||
      payload?.Receiver_info_Country ||
      payload?.Receiver_info_Email ||
      // payload?.Receiver_info_Title ||
      payload?.Receiver_info_First_name ||
      payload?.Receiver_info_Last_name ||
      payload?.Receiver_info_Mobile ||
      payload?.Upload_img ||
      payload?.company_info_Address ||
      payload?.company_info_Country ||
      payload?.company_info_Email ||
      payload?.company_info_Title ||
      payload?.company_info_Name ||
      payload?.company_info_Mobile ||
      info?.Currency ||
      // info?.Date_issued ||
      info?.Due_date ||
      info?.Invoice ||
      vatitem == undefined ||
      findfast == undefined ||
      findtwo == undefined ||
      findthdee == undefined
    ) {
      setValidesannodata(true)
    } else {
      setValidesannodata(false)
    }

  }, [payload, itemsbill, info, customer_nameReceiverr, selectReceiver, newInvoiceName, ReceiverHide, invoiceImage]);


  const handleAddClick = () => {
    setItemsbill([...itemsbill, {
      Item: "",
      Quantity: "",
      Price: "",
      total: 0,
      description: "",
      description_data: false,
      tax: "",
      calculatedTax: null,
      monite_product_id: "",
    }])
    setBill_items([...bill_items, {
      name: "",
      quantity: "",
      price: "",
      total: 0,
      description: "",
      tax: "",
      calculatedTax: null,
      monite_product_id: "",
    }])
  }
  const handleRemoveClick = (index: any) => {
    const list = [...itemsbill];
    list.splice(index, 1);
    setItemsbill(list);
    const removedata = [...bill_items]
    removedata.splice(index, 1)
    setBill_items(removedata)
  }

  useEffect(() => {
    if (isUserAuthenticated() && RedusarAPI) {
      dispatch(counterpartsActions.getCustomerDropDoun() as any);
      setRedusarAPI(false)
      setTimeout(() => {
        setListRender(true)
      }, 4000)
    }
  }, [])
  useEffect(() => {
    const popup: any = searchParams.get('id');
    const callapi: any = searchParams.get('APIcall');
 
    setApicallData(callapi)
    setIdPage(popup)
  }, [searchParams])


  useEffect(() => {
    if (counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS !== undefined && listRender) {
      setListRender(false)
      setListdoupDoun(counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS)
    }
  }, [listRender, counterpartsListData])

  useEffect(() => {
    if (!!idPage) {
      dispatch(dashboardActions.getInvoicedetels(idPage) as any);
      setLoader(true)
      setTimeout(() => (
        setRedusar(true)
      ), 3000)
    }
  }, [idPage])

  useEffect(() => {
    if (redusar && DashboardData?.GET_INVOICE_BY_ID_SUCCESS !== undefined) {
      setRedusar(false)
      setLoader(false)
      var ListData = DashboardData?.GET_INVOICE_BY_ID_SUCCESS.data
      const findcurrencies: any = options.find(
        (data: any) => data.value == ListData.customer.contact_country
      );

     
      setGetapi(ListData)
      setInfo({
        Invoice: ListData?.invoice_name,
        Due_date: moment(ListData.due_date).format('YYYY-MM-DD'),
        Currency: ListData.currency,
        Invoice_Name: newInvoiceName
      })
      setPaymentLink(!!ListData?.payment_link ? true : false)
      setNewInvoiceName(ListData?.invoice_name)
      if (!!ListData?.customer?.id) {
        setReceiverHide(false)
        setCustomer_nameReceiver(ListData?.customer?.id)
      } else {
        setReceiverHide(true)
      }
      var ArrayObject: any = []
      var ArrayItem: any = []
      for (let index = 0; index < ListData?.items.length; index++) {
        const element = ListData?.items[index];
        var object = {
          name: element.name,
          quantity: Number(element.quantity),
          price: Number(element.price),
          total: Number(element.total),
          description: element.description,
        }
        var resObje = {
          // name: element.name,
          // Quantity: Number(element.quantity),
          // Price: Number(element.price),
          // total: Number(element.total),
          // description: element.description,
          // description_data: false
          Item: element.title,
          Quantity: element.quantity,
          Price: element.price,
          total: element.total,
          description: element.description,
          description_data: false,
          tax: element.tax,
          calculatedTax: null,
          monite_product_id: element.monite_product_id,
        }
        ArrayItem.push(resObje)
        ArrayObject.push(object)
      }
      setselectReceiver(ListData.customer)
      setItemsbill(ArrayItem)
      setBill_items(ArrayObject)
      setFooterdata({
        totel: Number(ListData?.sub_total),
        textrea: ListData?.memo,
        Tax: Number(ListData.tax_percentage),
        Discount_type: ListData?.discount_type,
        Discount: ListData?.discount,
        pagetotel: Number(ListData.total),
        Discount_value: Number(ListData?.discount_value),
        includes_tax: footerdata.includes_tax,
        tex_totel: ListData.tax,
        includes_Discount: footerdata.includes_Discount
      })
      // setinvoiceImage(ListData?.logo)
      if (ListData?.customer?.customer_type == "individual") {
        setPayload({
          Invoice_Name: ListData?.invoice_name,
          Upload_img: ListData?.logo,
          company_info_Name: user?.entity?.name,
          company_info_Title: user?.name,
          company_info_Email: user?.email,
          company_info_Mobile: user?.mobile,
          company_info_Address: user?.entity?.street_address,
          company_info_Country: user?.entity?.country,
          Type: ListData.customer.customer_type,
          Receiver_info_First_name: ListData.customer.contact_first_name,
          Receiver_info_Last_name: ListData.customer.contact_last_name,
          Receiver_info_Title: ListData.customer.name,
          Receiver_info_Email: ListData.customer.contact_email,
          Receiver_info_Mobile: ListData.customer.contact_phone,
          Receiver_info_Address: ListData.customer.contact_line1,
          Receiver_info_Country: findcurrencies?.label,
        })
      } else {
        setPayload({
          Invoice_Name: ListData?.invoice_name,
          Upload_img: ListData?.logo,
          company_info_Name: user?.entity?.name,
          company_info_Title: user?.name,
          company_info_Email: user?.email,
          company_info_Mobile: user?.mobile,
          company_info_Address: user?.entity?.street_address,
          company_info_Country: user?.entity?.country,
          Type: ListData.customer.customer_type,
          Receiver_info_First_name: ListData.customer.name,
          Receiver_info_Last_name: "",
          Receiver_info_Title: ListData.customer.name,
          Receiver_info_Email: ListData.customer.contact_email,
          Receiver_info_Mobile: ListData.customer.contact_phone,
          Receiver_info_Address: ListData.customer.contact_line1,
          Receiver_info_Country: findcurrencies?.label,
        })
      }
    }
  }, [redusar, DashboardData])

  useEffect(() => {
    if (bankDetailsRender_two && data?.GETCURRENCIES_SUCCESS !== undefined) {
      setBankDetailsRender_two(false);
      setCurrencies(data.GETCURRENCIES_SUCCESS)
    }
  }, [data, bankDetailsRender_two]);

  useEffect(() => {
    if (counterpartsListRender_two_data && counterpartsListData?.GETALLVAT_SUCCESS !== undefined) {
      setCounterpartsListRender_two_data(false);
      setVatall(counterpartsListData?.GETALLVAT_SUCCESS);
    }
  }, [counterpartsListData, counterpartsListRender_two_data]);
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
 

  useEffect(() => {
    if (!!itemsbill) {
      let datalist: any = 0;
      for (let index = 0; index < itemsbill.length; index++) {
        const element = itemsbill[index];
        datalist += +element.total
      }
     

      setFooterdata({
        totel: datalist,
        textrea: footerdata.textrea,
        Tax: footerdata.Tax,
        Discount: footerdata.Discount,
        pagetotel: ((Number(datalist) - Number(footerdata.Discount_value)) + Number(totalTax)),
        includes_tax: footerdata.includes_tax,
        tex_totel: (Number(datalist) + totalTax),
        Discount_value: footerdata.Discount_value,
        includes_Discount: footerdata.includes_Discount,
        Discount_type: footerdata.Discount_type,
      })
    }
  }, [itemsbill, totalTax])
  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {

    };
  }

  const handleImage = (e: any) => {
    setPayload({
      Invoice_Name: payload.Invoice_Name,
      Upload_img: e.target.value,
      company_info_Name: payload.company_info_Name,
      company_info_Title: payload.company_info_Title,
      company_info_Email: payload.company_info_Email,
      company_info_Mobile: payload.company_info_Mobile,
      company_info_Address: payload.company_info_Address,
      company_info_Country: payload.company_info_Country,
      Type: payload.Type,
      Receiver_info_First_name: payload.Receiver_info_First_name,
      Receiver_info_Last_name: payload.Receiver_info_Last_name,
      Receiver_info_Title: payload.Receiver_info_Title,
      Receiver_info_Email: payload.Receiver_info_Email,
      Receiver_info_Mobile: payload.Receiver_info_Mobile,
      Receiver_info_Address: payload.Receiver_info_Address,
      Receiver_info_Country: payload.Receiver_info_Country,
    })
    getBase64(e.target.files[0], (result: any) => {
      setinvoiceImage(result);
    });
  }

  const existingProductData = useMemo(() => {
    return counterpartsListData?.GET_EXISTING_PRODUCT_SUCCESS;
  }, [counterpartsListData?.GET_EXISTING_PRODUCT_SUCCESS]);

  useEffect(() => {
    if (existingProductData !== undefined) {
      const options = [];
      if (!!existingProductData) {
        for (let index = 0; index < existingProductData.length; index++) {
          const element = existingProductData[index];
          let label;
          // var body=
          if (element.counterpart_type === "individual") {
            label = `${element.contact_first_name} ${element.contact_last_name}`;
          } else {
            label = element.name;
          }
          options.push({ value: `${element.id}`, label });

        }
      }
      setoptionsValue([...options]);
      setExistingProduct(existingProductData);
    }
  }, [existingProductData]);



  // Function to calculate calculatedTax
  const calculateTax = (item: any) => {
    const priceVAT = parseFloat(item?.Price) * parseInt(item?.Quantity, 10);
    const singleLineTax = parseFloat(item.tax);

    if (isNaN(priceVAT) || isNaN(singleLineTax)) {
      return null;
    }
    // priceVAT - priceVAT / (1 + singleLineTax / 100)
    return ((priceVAT * singleLineTax) / 100).toFixed(2);
  };

  // Effect to calculate taxes whenever relevant fields change
  useEffect(() => {
    // const updatedBillItems = bill_items?.map((item: any, index: any) => {
    //   const calculatedTax = calculateTax(item);
    //   return { ...item, calculatedTax };
    // });
    const updatedBillItemsDATA = itemsbill?.map((item: any, index: any) => {
      const calculatedTax = calculateTax(item);
      return { ...item, calculatedTax };
    });
   

    setItemsbill(updatedBillItemsDATA);
    // setBill_items(updatedBillItems);
  }, [bill_items]);
  // useEffect(() => {
  //   const updatedBillItems = itemsbill?.map((item: any, index: any) => {
  //     const calculatedTax = calculateTax(item);
  //     return { ...item, calculatedTax };
  //   });

  //   setItemsbill(updatedBillItems);
  // }, [itemsbill]);

  useEffect(() => {
    const sumCalculatedTax = itemsbill?.reduce((acc: any, item: any) => {
      const tax = parseFloat(item.calculatedTax);
      return acc + (isNaN(tax) ? 0 : tax);
    }, 0);

    setTotalTax(sumCalculatedTax.toFixed(2));
  }, [bill_items, itemsbill]);

  useEffect(() => {
    const sumCalculatedTax = calculatedData?.reduce((acc: any, item: any) => {
      const tax = parseFloat(item.calculatedTax);
      return acc + (isNaN(tax) ? 0 : tax);
    }, 0);

    setProductTotalTax(sumCalculatedTax.toFixed(2));
  }, [extractedProductData]);


  useEffect(() => {
    if (productDetails && productDetails.length > 0) {
      const mappedProducts = productDetails.map((product: any) => ({
        quantity: product.quantity || 1,
        tax: product.tax || 0,
        monite_product_id: product.monite_product_id,
        price: product.price,
        name: product.name,
      }));

      setExtractedProductData(mappedProducts);
    }
  }, [productDetails]);

  useEffect(() => {
    dispatch(dashboardActions.getDashboardCurrencyData() as any)
  }, [dispatch])

  useEffect(() => {
    if (DashboardApiData && DashboardApiData.GET_DASHBOARD_CURRENCY_DATA_SUCCESS !== undefined) {
      setCurrencyData(DashboardApiData.GET_DASHBOARD_CURRENCY_DATA_SUCCESS.data);
    }
  }, [DashboardApiData.GET_DASHBOARD_CURRENCY_DATA_SUCCESS])

  // Helper function to round to 2 decimal places
  const round = (value: number, decimals: number) => {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
  };


  useEffect(() => {
    const newCalculatedData = extractedProductData.map((product: any) => {
      const quantity = parseFloat(product.quantity);
      const price = parseFloat(product.price);
      const tax = parseFloat(product.tax);

      const subtotal = price * quantity;
      const productTax = price * quantity;
      const Addformula = ((productTax * tax) / 100)
      const actualCalculatedTax = round(productTax - (productTax / (1 + (tax / 100))), 2); // Calculated Tax

      return {
        ...product,
        calculatedTax: Addformula,
        subtotal: subtotal
      };
    });

    setCalculatedData(newCalculatedData);

    // Sum of all subtotals
    const total = newCalculatedData.reduce((acc, product) => acc + product.subtotal!, 0);
    setTotalSubtotal(total);
  }, [extractedProductData]);



  useEffect(() => {
    const total = calculatedData.reduce((acc: any, product: any) => {
      return acc + (product.subtotal || 0);
    }, 0);
    setProductSubtotal(total);
  }, [calculatedData]);

  useEffect(() => {
    const initialInvoiceNumber = `INV${Math.floor(1000000 + Math.random() * 9000000)}`;
    setInfo((prevState) => ({
      ...prevState,
      Invoice: initialInvoiceNumber,
    }));
  }, []);


 

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box className={styles.ContenarInvoice}>
          <Box>
            <Typography className={styles.typo_heding}>{`${apicallData == "Duplicate" ? "Create" : !!idPage ? "Update" : "Create"}`} your invoice</Typography>
          </Box>
          <Box sx={{ height: "46px" }}>

          </Box>
        </Box>
      </ThemeProvider>
      <div className='page_wrapper' style={{ marginLeft: "25px", marginRight: "25px" }}>
        <ThemeProvider theme={theme}>
          {loader && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
            <CircularProgress color="success" />
          </Box>}

          <Grid container className={styles.container_grid}>
            <Button id="button_back" style={{ color: '#025141', paddingLeft: "0px", paddingBottom: "15px" }}
              onClick={() => {
                navigate('/invoice')

              }} className="payment-journey-container__detailbox__white-btn">
              <ThemeProvider theme={theme}>
                <ArrowBackIcon style={{ fontSize: "19px", marginRight: "10px" }} />
              </ThemeProvider>
              Go Back
            </Button>
            {/* <Grid item md={12}>
              <Typography className={styles.heding_typography}></Typography>
              <Typography className={styles.typo_heding_footer}>Fill in the fields, preview your invoice and get it emailed directly to your customers.</Typography>
            </Grid> */}
            <Grid container
              spacing={3}
            >
              <Grid item md={8}>
                <div className={styles.two_grid_inputt}>
                  <div id={styles.id_contean} className="input-form-wrapper dot">
                    <label>Invoice Title</label>
                    <InputGroup className="doc-input-wrapper">
                      <Form.Control
                        className={'doc-form-input test valid'}
                        placeholder="Invoice Title"
                        value={newInvoiceName}
                        name="name"
                        type="text"
                        onChange={(e: any) => {
                          setNewInvoiceName(e.target.value);
                        }}
                      />
                    </InputGroup>
                    {!newInvoiceName && validsan == true ? (
                      <div className="error-message">{ErrorMessage.Invoice_Name}</div>
                    ) : null}
                  </div>

                  <label id={styles.id_contean} className='complete-profile__upload mb-0' style={{ paddingTop: "41px" }}>
                    <div id='afafafsdaFfsf' className='complete-profile__upload__label'>
                      <div>
                        <UploadLogo />
                        <span className='complete-profile__upload__txt' style={{ paddingLeft: "10px" }}>
                          {payload?.Upload_img
                            ? (payload.Upload_img.length > 20
                              ? `${payload.Upload_img.slice(0, 20)}...`
                              : payload.Upload_img)
                            : "Upload logo"}
                        </span>
                      </div>
                      <Upload_svg />
                    </div>
                    <input type="file" name='invoice_record'
                      onChange={(e: any) => {
                        handleImage(e);
                      }}
                    />
                    {!payload.Upload_img && validsan === true ? (
                      <div className="error-message">{ErrorMessage.Upload_img}</div>
                    ) : null}
                  </label>

                </div>


                <div className={styles.div_buttomborder}>
                </div>

                <Typography className={styles.Typography_companyinfo} style={{ paddingTop: "15px" }}>Invoice Details</Typography>
                <div className={styles.newtwo_grid_inputt}>
                  {/* Left side: Invoice # and Currency */}
                  <div className={styles.newleft_side}>
                    <div className="input-form-wrapper dot" style={{ width: "65%" }}>
                      <label>Invoice #</label>
                      <InputGroup className="doc-input-wrapper">
                        <Form.Control
                          className={'doc-form-input test valid'}
                          placeholder="Invoice #"
                          value={info.Invoice}
                          name="name"
                          type="text"
                          onChange={(e: any) => {
                            setInfo({
                              ...info,
                              Invoice: e.target.value, // Allow user to change the invoice number
                            });
                          }}
                        />
                      </InputGroup>
                      {!info.Invoice && validsan ? (
                        <div className="error-message">{ErrorMessage.Invoice}</div>
                      ) : null}
                    </div>

                    <div style={{ width: "35%", marginTop: "20px" }}>
                      <label style={{ fontSize: "13px" }}>Currency</label>
                      <Form.Select
                        style={{ width: "100%", fontSize: "12px" }}
                        onChange={(e: any) => {
                          const selectedCurrency = e.target.value;
                          setInfo({
                            Invoice: info.Invoice,
                            Due_date: info.Due_date,
                            Currency: selectedCurrency,
                            Invoice_Name: info.Invoice_Name,
                          });
                          if (selectedCurrency) {
                            dispatch(counterpartsActions.getExistingProducts(selectedCurrency) as any);
                          }
                        }}
                        value={info.Currency}
                        name="Conversion_Currency"
                        className={styles.select_box_kes}
                      >
                        <option selected value="" style={{ fontSize: "13px" }}>
                          Currency
                        </option>
                        {Array.isArray(currencyData) &&
                          currencyData.map((arrayElement: any, index: number) => (
                            <>
                              {arrayElement.currency == "GHS" ? "" :

                                <option value={arrayElement.currency} key={index + 1}>
                                  {arrayElement.currency}
                                </option>}
                            </>
                          ))}
                      </Form.Select>
                      {!info.Currency && validsan ? (
                        <div className="error-message">{ErrorMessage.Currency}</div>
                      ) : null}
                    </div>


                  </div>

                  {/* Right side: Due Date */}
                  <div className={styles.newright_side}>
                    <div className="input-form-wrapper dot">
                      <label>Due Date</label>
                      <InputGroup className="doc-input-wrapper">
                        <Form.Control
                          className={'doc-form-input test valid'}
                          placeholder="date"
                          value={info.Due_date}
                          name="invoice_date"
                          type="date"
                          onChange={(e: any) => {
                            setInfo({
                              Invoice: info.Invoice,
                              Due_date: e.target.value,
                              Currency: info.Currency,
                              Invoice_Name: info.Invoice_Name,
                            });
                          }}
                        />
                      </InputGroup>
                      {!info.Due_date && validsan ? (
                        <div className="error-message">{ErrorMessage.Due_date}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className={styles.contenar_typo_left}>
                  <div className={styles.div_buttomborder}>
                  </div>
                  <Typography className={styles.Typography_companyinfo} style={{ paddingTop: "15px" }}>Customer Details</Typography>

                  <ThemeProvider theme={theme}>
                    <div>
                      <div className="input-form-wrapper dot">


                        <Grid container spacing={2} style={{ alignItems: 'end' }}>
                          {/* First Column */}
                          {/* <Grid item xs={12} md={4}>
                            <Typography className={styles.Typography_companyinfo}>Customer's</Typography>
                          </Grid> */}
                          <Grid item xs={12} md={8} sm={12}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <label>Customer name</label>

                            </div>
                            {ReceiverHide == true ?
                              <div onClick={() => {
                                setReceiverHide(false)
                                setselectReceiver('')
                                setCustomer_nameReceiver("")
                                setPayload({
                                  Invoice_Name: payload.Invoice_Name,
                                  Upload_img: payload.Upload_img,
                                  company_info_Name: payload.company_info_Name,
                                  company_info_Title: payload.company_info_Title,
                                  company_info_Email: payload.company_info_Email,
                                  company_info_Mobile: payload.company_info_Mobile,
                                  company_info_Address: payload.company_info_Address,
                                  company_info_Country: payload.company_info_Country,
                                  Type: payload.Type,
                                  Receiver_info_First_name: '',
                                  Receiver_info_Last_name: '',
                                  Receiver_info_Title: '',
                                  Receiver_info_Email: '',
                                  Receiver_info_Mobile: '',
                                  Receiver_info_Address: '',
                                  Receiver_info_Country: '',
                                })
                              }}>
                                <Form.Select

                                  style={{ width: "100%" }}
                                  onChange={(e: any) => {
                                    setCustomer_nameReceiver(e.target.value)
                                  }}
                                  value={customer_nameReceiverr}
                                  name="Conversion_Currency"
                                  className={styles.select_box_kes}
                                >
                                  <option selected value="">Select Customer</option>
                                  {ListdoupDoun?.map((arrayElement: any, index: number) => {
                                    return (
                                      <option value={arrayElement.id} key={index + 1}>{arrayElement.name}</option>
                                    )
                                  })}
                                </Form.Select>
                              </div> :
                              <Form.Select
                                style={{ width: "100%" }}
                                disabled={ReceiverHide}
                                onChange={(e: any) => {
                                  setCustomer_nameReceiver(e.target.value)
                                  var ListFind: any = ListdoupDoun.find((data: any) => data.id == e.target.value)
                                  const findcurrencies: any = options.find(
                                    (data: any) => data.value == ListFind?.contact_country
                                  );
                                  if (ListFind?.customer_type == "individual") {
                                    setPayload({
                                      Invoice_Name: payload.Invoice_Name,
                                      Upload_img: payload.Upload_img,
                                      company_info_Name: payload.company_info_Name,
                                      company_info_Title: payload.company_info_Title,
                                      company_info_Email: payload.company_info_Email,
                                      company_info_Mobile: payload.company_info_Mobile,
                                      company_info_Address: payload.company_info_Address,
                                      company_info_Country: payload.company_info_Country,
                                      Type: "individual",
                                      Receiver_info_First_name: ListFind.contact_first_name,
                                      Receiver_info_Last_name: ListFind.contact_last_name,
                                      Receiver_info_Title: ListFind?.name,
                                      Receiver_info_Email: ListFind?.contact_email,
                                      Receiver_info_Mobile: ListFind?.contact_phone,
                                      Receiver_info_Address: ListFind?.contact_line1,
                                      Receiver_info_Country: findcurrencies?.label,
                                    })
                                  } else {
                                    setPayload({
                                      Invoice_Name: payload.Invoice_Name,
                                      Upload_img: payload.Upload_img,
                                      company_info_Name: payload.company_info_Name,
                                      company_info_Title: payload.company_info_Title,
                                      company_info_Email: payload.company_info_Email,
                                      company_info_Mobile: payload.company_info_Mobile,
                                      company_info_Address: payload.company_info_Address,
                                      company_info_Country: payload.company_info_Country,
                                      Type: "organization",
                                      Receiver_info_First_name: ListFind?.name,
                                      Receiver_info_Last_name: '',
                                      Receiver_info_Title: ListFind?.name,
                                      Receiver_info_Email: ListFind?.contact_email,
                                      Receiver_info_Mobile: ListFind?.contact_phone,
                                      Receiver_info_Address: ListFind?.contact_line1,
                                      Receiver_info_Country: findcurrencies?.label,
                                    })
                                  }
                               

                                  setTaxIdentificationNumber(ListFind?.tax_identification_number)
                                  setselectReceiver(ListFind)
                                }}
                                value={customer_nameReceiverr}
                                name="Conversion_Currency"
                                className={styles.select_box_kes}
                              >
                                <option selected value="">Select Customer</option>
                                {ListdoupDoun?.map((arrayElement: any, index: number) => {
                                  return (
                                    <option value={arrayElement.id} key={index + 1}>{arrayElement.name}</option>
                                  )
                                })}
                              </Form.Select>}


                            {!customer_nameReceiverr && validsan == true && ReceiverHide == false ? (
                              <div className="error-message">{ErrorMessage.company_info_Name}</div>
                            ) : null}
                          </Grid>
                          <Grid item md={4} xs={12} sm={12}>
                            {ReceiverHide == true ?
                              <Button className={styles.add_button_class}
                              >
                                + Add new Customer
                              </Button> :
                              <Button className={styles.add_button_class}
                                onClick={() => {
                                  setPayload({
                                    Invoice_Name: payload.Invoice_Name,
                                    Upload_img: payload.Upload_img,
                                    company_info_Name: payload.company_info_Name,
                                    company_info_Title: payload.company_info_Title,
                                    company_info_Email: payload.company_info_Email,
                                    company_info_Mobile: payload.company_info_Mobile,
                                    company_info_Address: payload.company_info_Address,
                                    company_info_Country: payload.company_info_Country,
                                    Type: payload.Type,
                                    Receiver_info_First_name: '',
                                    Receiver_info_Last_name: '',
                                    Receiver_info_Title: '',
                                    Receiver_info_Email: '',
                                    Receiver_info_Mobile: '',
                                    Receiver_info_Address: '',
                                    Receiver_info_Country: '',
                                  })
                                  setselectReceiver('')
                                  setCustomer_nameReceiver("")
                                  setReceiverHide(true)
                                }}
                              >
                                + Add new Customer
                              </Button>}
                          </Grid>
                        </Grid>

                      </div>

                      {ReceiverHide &&
                        <>

                          <div className={styles.main_fast_and_last}>
                            <div className={styles.fast_name_inpurt_div}>
                              <div className={styles.main_fast_and_last} style={payload.Type == "individual" ? {} : { display: 'block' }}>
                                <div className={styles.fast_name_inpurt_div} style={payload.Type == "individual" ? {} : { width: "100%" }}>
                                  <div className="input-form-wrapper dot">
                                    <label>{payload.Type == "individual" ? "First name" : "Organization name"}</label>
                                    <InputGroup className="doc-input-wrapper">
                                      <Form.Control

                                        // disabled={ReceiverHide == false ? true : false}
                                        className={

                                          'doc-form-input test valid'}
                                        placeholder={payload.Type == "individual" ? "First name" : "Organization name"}
                                        value={payload.Receiver_info_First_name}
                                        name='name'
                                        type="text"
                                        onChange={(e: any) => {
                                          setPayload({
                                            Invoice_Name: payload.Invoice_Name,
                                            Upload_img: payload.Upload_img,
                                            company_info_Name: payload.company_info_Name,
                                            company_info_Title: payload.company_info_Title,
                                            company_info_Email: payload.company_info_Email,
                                            company_info_Mobile: payload.company_info_Mobile,
                                            company_info_Address: payload.company_info_Address,
                                            company_info_Country: payload.company_info_Country,
                                            Type: payload.Type,
                                            Receiver_info_First_name: e.target.value,
                                            Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                            Receiver_info_Title: payload.Receiver_info_Title,
                                            Receiver_info_Email: payload.Receiver_info_Email,
                                            Receiver_info_Mobile: payload.Receiver_info_Mobile,
                                            Receiver_info_Address: payload.Receiver_info_Address,
                                            Receiver_info_Country: payload.Receiver_info_Country,
                                          })
                                        }}
                                      />
                                    </InputGroup>
                                    {!payload.Receiver_info_First_name && validsan == true && ReceiverHide == true ? (
                                      <p className="error-message">
                                        Please enter the{' '}
                                        {payload.Type == 'individual'
                                          ? 'first name'
                                          : 'organization name'}
                                      </p>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                                <div className={styles.fast_name_inpurt_div}>
                                  {payload.Type == 'organization' ? (
                                    ''
                                  ) : (
                                    <div className="input-form-wrapper dot">
                                      <label>Last name</label>
                                      <InputGroup className="doc-input-wrapper">
                                        <Form.Control
                                          disabled={payload.Type == 'individual' && ReceiverHide == true ? false : true}
                                          className={'doc-form-input test valid'}
                                          placeholder="Last name"
                                          value={payload.Receiver_info_Last_name}
                                          name="name"
                                          type="text"
                                          onChange={(e: any) => {
                                            setPayload({
                                              Invoice_Name: payload.Invoice_Name,
                                              Upload_img: payload.Upload_img,
                                              company_info_Name: payload.company_info_Name,
                                              company_info_Title: payload.company_info_Title,
                                              company_info_Email: payload.company_info_Email,
                                              company_info_Mobile: payload.company_info_Mobile,
                                              company_info_Address: payload.company_info_Address,
                                              company_info_Country: payload.company_info_Country,
                                              Type: payload.Type,
                                              Receiver_info_First_name: payload.Receiver_info_First_name,
                                              Receiver_info_Last_name: e.target.value,
                                              Receiver_info_Title: payload.Receiver_info_Title,
                                              Receiver_info_Email: payload.Receiver_info_Email,
                                              Receiver_info_Mobile: payload.Receiver_info_Mobile,
                                              Receiver_info_Address: payload.Receiver_info_Address,
                                              Receiver_info_Country: payload.Receiver_info_Country,
                                            })
                                          }}
                                        />
                                      </InputGroup>
                                      {!payload.Receiver_info_Last_name && ReceiverHide == true &&
                                        payload.Type !== 'organization' &&
                                        validsan == true ? (
                                        <p className="error-message">Please enter the last name</p>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className={styles.fast_name_inpurt_div} style={{ paddingTop: "20px" }}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                className="maib_redio_button_contenar"
                                value={payload.Type}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  setPayload({
                                    Invoice_Name: payload.Invoice_Name,
                                    Upload_img: payload.Upload_img,
                                    company_info_Name: payload.company_info_Name,
                                    company_info_Title: payload.company_info_Title,
                                    company_info_Email: payload.company_info_Email,
                                    company_info_Mobile: payload.company_info_Mobile,
                                    company_info_Address: payload.company_info_Address,
                                    company_info_Country: payload.company_info_Country,
                                    Type: (event.target as HTMLInputElement).value,
                                    Receiver_info_First_name: payload.Receiver_info_First_name,
                                    Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                    Receiver_info_Title: payload.Receiver_info_Title,
                                    Receiver_info_Email: payload.Receiver_info_Email,
                                    Receiver_info_Mobile: payload.Receiver_info_Mobile,
                                    Receiver_info_Address: payload.Receiver_info_Address,
                                    Receiver_info_Country: payload.Receiver_info_Country,
                                  })
                                }}
                              >
                                <FormControlLabel
                                  className="main_redio_buttton"
                                  value="individual"
                                  control={
                                    <Radio
                                      // disabled={ReceiverHide == false ? true : false}
                                      // disabled={!!datadisplay ? true : false}
                                      icon={<img src="/Checkbox.svg" />}
                                      checkedIcon={<img src="/Checkbox_two.svg" />}
                                    />
                                  }
                                  label="Individual"
                                />
                                <FormControlLabel
                                  className="main_redio_buttton"
                                  value="organization"
                                  control={
                                    <Radio
                                      // disabled={ReceiverHide == false ? true : false}
                                      // disabled={!!datadisplay ? true : false}
                                      icon={<img src="/Checkbox.svg" />}
                                      checkedIcon={<img src="/Checkbox_two.svg" />}
                                    />
                                  }
                                  label="Organization"
                                />
                              </RadioGroup>
                            </div>
                          </div>

                          <div className={styles.main_fast_and_last}>
                            <div className={styles.fast_name_inpurt_div}>
                              <div className="input-form-wrapper dot">
                                <label>Email address</label>
                                <InputGroup className="doc-input-wrapper">
                                  <Form.Control
                                    // disabled={ReceiverHide == false ? true : false}
                                    className={

                                      'doc-form-input test valid'}
                                    placeholder="Email"
                                    value={payload.Receiver_info_Email}
                                    name='name'
                                    type="email"
                                    onChange={(e: any) => {
                                      setPayload({
                                        Invoice_Name: payload.Invoice_Name,
                                        Upload_img: payload.Upload_img,
                                        company_info_Name: payload.company_info_Name,
                                        company_info_Title: payload.company_info_Title,
                                        company_info_Email: payload.company_info_Email,
                                        company_info_Mobile: payload.company_info_Mobile,

                                        company_info_Address: payload.company_info_Address,
                                        company_info_Country: payload.company_info_Country,
                                        Type: payload.Type,
                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                        Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                        Receiver_info_Title: payload.Receiver_info_Title,
                                        Receiver_info_Email: e.target.value,
                                        Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                        Receiver_info_Address: payload.Receiver_info_Address,
                                        Receiver_info_Country: payload.Receiver_info_Country,
                                      })
                                    }}
                                  />
                                </InputGroup>
                                {!payload.Receiver_info_Email && ReceiverHide == true && validsan == true ? (
                                  <div className="error-message">{ErrorMessage.company_info_Email}</div>
                                ) : null}
                              </div>
                            </div>
                            <div className={styles.fast_name_inpurt_div}>
                              <div className="input-form-wrapper dot">
                                <label>Phone number</label>
                                <InputGroup className="doc-input-wrapper">
                                  <Form.Control
                                    // disabled={ReceiverHide == false ? true : false}
                                    className={

                                      'doc-form-input test valid'}
                                    placeholder="Phone number"
                                    value={payload.Receiver_info_Mobile}
                                    name='name'
                                    type="text"
                                    onChange={(e: any) => {
                                      setPayload({
                                        Invoice_Name: payload.Invoice_Name,
                                        Upload_img: payload.Upload_img,
                                        company_info_Name: payload.company_info_Name,
                                        company_info_Title: payload.company_info_Title,
                                        company_info_Email: payload.company_info_Email,
                                        company_info_Mobile: payload.company_info_Mobile,

                                        company_info_Address: payload.company_info_Address,
                                        company_info_Country: payload.company_info_Country,
                                        Type: payload.Type,
                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                        Receiver_info_Last_name: payload.Receiver_info_Last_name, Receiver_info_Title: payload.Receiver_info_Title,
                                        Receiver_info_Email: payload.Receiver_info_Email,
                                        Receiver_info_Mobile: e.target.value,

                                        Receiver_info_Address: payload.Receiver_info_Address,
                                        Receiver_info_Country: payload.Receiver_info_Country,
                                      })
                                    }}
                                  />
                                </InputGroup>
                                {!payload.Receiver_info_Mobile && ReceiverHide == true && validsan == true ? (
                                  <div className="error-message">{ErrorMessage.company_info_Mobile}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className={styles.main_fast_and_last}>
                            <div className={styles.fast_name_inpurt_div}>
                              <div className="input-form-wrapper dot">
                                <label>Address</label>
                                <InputGroup className="doc-input-wrapper">
                                  <Form.Control
                                    // disabled={ReceiverHide == false ? true : false}
                                    className={

                                      'doc-form-input test valid'}
                                    placeholder="Address"
                                    value={payload.Receiver_info_Address}
                                    name='name'
                                    type="text"
                                    onChange={(e: any) => {
                                      setPayload({
                                        Invoice_Name: payload.Invoice_Name,
                                        Upload_img: payload.Upload_img,
                                        company_info_Name: payload.company_info_Name,
                                        company_info_Title: payload.company_info_Title,
                                        company_info_Email: payload.company_info_Email,
                                        company_info_Mobile: payload.company_info_Mobile,

                                        company_info_Address: payload.company_info_Address,
                                        company_info_Country: payload.company_info_Country,
                                        Type: payload.Type,
                                        Receiver_info_First_name: payload.Receiver_info_First_name,
                                        Receiver_info_Last_name: payload.Receiver_info_Last_name, Receiver_info_Title: payload.Receiver_info_Title,
                                        Receiver_info_Email: payload.Receiver_info_Email,
                                        Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                        Receiver_info_Address: e.target.value,
                                        Receiver_info_Country: payload.Receiver_info_Country,
                                      })
                                    }}
                                  />
                                </InputGroup>
                                {!payload.Receiver_info_Address && ReceiverHide == true && validsan == true ? (
                                  <div className="error-message">{ErrorMessage.company_info_Address}</div>
                                ) : null}
                              </div>
                            </div>
                            <div className={styles.fast_name_inpurt_div}>
                              <div className="input-form-wrapper">
                                <label>Country</label>
                                <CountryDropdown
                                  // disabled={ReceiverHide == false ? true : false}
                                  value={payload.Receiver_info_Country}
                                  valueType="full"
                                  onChange={(e: any, val) => {
                                    setPayload({
                                      Invoice_Name: payload.Invoice_Name,
                                      Upload_img: payload.Upload_img,
                                      company_info_Name: payload.company_info_Name,
                                      company_info_Title: payload.company_info_Title,
                                      company_info_Email: payload.company_info_Email,
                                      company_info_Mobile: payload.company_info_Mobile,

                                      company_info_Address: payload.company_info_Address,
                                      company_info_Country: payload.company_info_Country,
                                      Type: payload.Type,
                                      Receiver_info_First_name: payload.Receiver_info_First_name,
                                      Receiver_info_Last_name: payload.Receiver_info_Last_name, Receiver_info_Title: payload.Receiver_info_Title,
                                      Receiver_info_Email: payload.Receiver_info_Email,
                                      Receiver_info_Mobile: payload.Receiver_info_Mobile,

                                      Receiver_info_Address: payload.Receiver_info_Address,
                                      Receiver_info_Country: e,
                                    })
                                  }}

                                  name="country"
                                  classes={'doc-form-input test valid'
                                  }
                                />
                                {!payload.Receiver_info_Country && ReceiverHide == true && validsan == true ? (
                                  <div className="error-message">{ErrorMessage.company_info_Country}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>

                          <div className={styles.main_fast_and_last}>
                            <div className={styles.fast_name_inpurt_div}>
                              {payload.Type == "individual" ?
                                <div className="input-form-wrapper dot">
                                  <label>Company name </label>
                                  <InputGroup className="doc-input-wrapper">
                                    <Form.Control
                                      // disabled={ReceiverHide == false ? true : false}
                                      className={

                                        'doc-form-input test valid'}
                                      placeholder="Company name"
                                      value={payload.Receiver_info_Title}
                                      name='name'
                                      type="text"
                                      onChange={(e: any) => {
                                        setPayload({
                                          Invoice_Name: payload.Invoice_Name,
                                          Upload_img: payload.Upload_img,
                                          company_info_Name: payload.company_info_Name,
                                          company_info_Title: payload.company_info_Title,
                                          company_info_Email: payload.company_info_Email,
                                          company_info_Mobile: payload.company_info_Mobile, company_info_Address: payload.company_info_Address,
                                          company_info_Country: payload.company_info_Country,
                                          Type: payload.Type,
                                          Receiver_info_First_name: payload.Receiver_info_First_name,
                                          Receiver_info_Last_name: payload.Receiver_info_Last_name,
                                          Receiver_info_Title: e.target.value,
                                          Receiver_info_Email: payload.Receiver_info_Email,
                                          Receiver_info_Mobile: payload.Receiver_info_Mobile,
                                          Receiver_info_Address: payload.Receiver_info_Address,
                                          Receiver_info_Country: payload.Receiver_info_Country,
                                        })
                                      }}
                                    />
                                  </InputGroup>
                                  {!payload.Receiver_info_Title && validsan == true && ReceiverHide == true ? (
                                    <div className="error-message">{ErrorMessage.company_info_Name}</div>
                                  ) : null}
                                </div> : ""
                              }

                            </div>
                            <div className={styles.fast_name_inpurt_div}>
                              <div className="input-form-wrapper dot">
                                <label>Tax Identification number</label>
                                <InputGroup className="doc-input-wrapper">
                                  <Form.Control
                                    // disabled={ReceiverHide == false ? true : false}
                                    className={

                                      'doc-form-input test valid'}
                                    placeholder="Tax Identification number"
                                    value={TaxIdentificationNumber}
                                    name='name'
                                    type="text"
                                    onChange={(e: any) => {
                                      setTaxIdentificationNumber(e.target.value)
                                    }}
                                  />
                                </InputGroup>
                                {!payload.Receiver_info_Address && ReceiverHide == true && validsan == true ? (
                                  <div className="error-message">{ErrorMessage.company_info_Address}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </div>

                  </ThemeProvider>


                </div>
                <div className={styles.div_buttomborder}>

                </div>


                {/* existing prduct and line items  */}

                <div>
                  <div>
                    <div className=''>

                      {itemsbill.map((x: any, i: any) => {
                        return (
                          <>
                            <div className={styles.remove_button}>
                              {i > 0 && (
                                <Button
                                  style={{ paddingBottom: "0px" }}
                                  className="complete-profile__removebtn"
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  Remove
                                </Button>
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingTop: "15px",
                              }}
                            >
                              <Typography className={styles.Typography_companyinfo} style={{ paddingBottom: "15px" }}>Line item</Typography>

                            </div>

                            <div className={styles.main_contenar_lineeee}>
                              <>
                                <div style={{ display: "flex" }} className={styles.manin_loop_collam}>
                                  {/* Item Name Field */}
                                  <div
                                    id={styles.main_input_datatatata}
                                    style={{ margin: "0px 0px", width: "32%" }}
                                    className="input-form-wrapper dot"
                                  >
                                    <label>Item name</label>

                                    <Autocomplete
                                      multiple={false}
                                      disableClearable
                                      disabled={!!idPage ? true : false}
                                      freeSolo
                                      sx={{
                                        "& .MuiInputBase-root": {
                                          display: "flex",
                                          alignItems: "center",
                                          background: "#FFFFFF",
                                          border: "1px solid #DDDDDD",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "#111111",
                                          borderRadius: "8px",
                                          height: "50px",
                                        },
                                      }}
                                      className={styles.autocomplete_Textfild}

                                      id={`autocomplete-${i}`}
                                      options={
                                        searchInput
                                          ? existingProduct.filter((option: any) =>
                                            option.name.toLowerCase().includes(searchInput.toLowerCase())
                                          )
                                          : existingProduct
                                      }
                                      value={x.Item || ''}

                                      getOptionLabel={(option: any) => option.name || option}
                                      onChange={(e: any, newValue: any) => {
                                        const inputValue = typeof newValue === 'string' ? newValue : newValue?.name || '';
                                        const selectedMoniteId = newValue?.monite_product_id || '';
                                   
                                        const updatedItems = itemsbill.map((item, index) => {
                                          const calculatedTax = calculateTax(item);

                                          if (index === i) {
                                            return {
                                              ...item,
                                              Item: inputValue,
                                              Quantity: !!newValue?.quantity ? newValue?.quantity : '1',
                                              Price: !!newValue?.price ? newValue.price : '0',
                                              monite_product_id: selectedMoniteId,
                                              tax: !!newValue?.tax ? newValue?.tax : "",
                                              description: !!newValue?.description ? newValue?.description : "",
                                              // calculatedTax:calculatedTax,
                                              total: Number(newValue?.quantity || item.Quantity || 1) * Number(newValue?.price)
                                            };
                                          }
                                          return item;
                                        });

                                        const updatedBillItems = bill_items.map((item: any, index: any) => {
                                          const calculatedTax = calculateTax(item);

                                          if (index === i) {
                                            return {
                                              ...item,
                                              name: inputValue,
                                              quantity: !!newValue?.quantity ? newValue.quantity : '1',
                                              price: !!newValue?.price ? newValue.price : '0',
                                              monite_product_id: selectedMoniteId,
                                              // calculatedTax:calculatedTax,
                                              tax: !!newValue?.tax ? newValue?.tax : "",
                                              description: !!newValue?.description ? newValue?.description : "",
                                              total: Number(newValue?.quantity || item.quantity || 1) * Number(newValue?.price)
                                            };
                                          }
                                          return item;
                                        });
                                        // setPriceDisebal(true)
                                        setItemsbill(updatedItems);
                                        setBill_items(updatedBillItems);
                                        // if(event.target.value)
                                      }}
                                      renderInput={(params: any) => (
                                        <TextField
                                          {...params}
                                          placeholder="item name"
                                          onChange={(event: any) => {
                                            const manualInputValue = event.target.value;
                                            setSearchInput(manualInputValue);
                                          
                                            // i(!!)
                                            const updatedItems = itemsbill.map((item, index) => {
                                              if (index === i) {
                                                return {
                                                  ...item,
                                                  Item: manualInputValue,
                                                  Quantity: '',
                                                  Price: '',
                                                  tax: "",
                                                  monite_product_id: '',
                                                  description: ""
                                                };
                                              }
                                              return item;
                                            });

                                            const updatedBillItems = bill_items.map((item: any, index: any) => {
                                              if (index === i) {
                                                return {
                                                  ...item,
                                                  name: manualInputValue,
                                                  quantity: '',
                                                  price: '',
                                                  tax: "",
                                                  monite_product_id: '',
                                                  description: ""
                                                };
                                              }
                                              return item;
                                            });
                                            // setPriceDisebal(false)
                                            setItemsbill(updatedItems);
                                            setBill_items(updatedBillItems);
                                          }}
                                          variant="outlined"
                                          sx={{
                                            "& .MuiOutlinedInput-root": {
                                              "& fieldset": {
                                                border: "none",
                                              },
                                              "&:hover fieldset": {
                                                border: "none",
                                              },
                                              "&.Mui-focused fieldset": {
                                                border: "none",
                                              },
                                            },
                                            "& .MuiInputBase-input": {
                                              border: "none",
                                              outline: "none",
                                              boxShadow: "none",
                                              padding: "0",
                                              color: "#212529",
                                              fontSize: "12px"
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                    {!x.Item && validsan && <div className="error-message">{ErrorMessage.Item}</div>}
                                  </div>
                                  <div
                                    id={styles.main_input_datatatata}
                                    style={{ margin: "0px 0px", width: "13%", paddingLeft: "5px" }}
                                    className="input-form-wrapper dot"
                                  >
                                    <label>Quantity </label>
                                    <InputGroup className="doc-input-wrapper">
                                      <Form.Control
                                        className="doc-form-input test valid"
                                        placeholder="Quantity"
                                        value={x.Quantity}
                                        type="text"
                                        onChange={(e: any) => {
                                          const updatedItems = itemsbill.map((item, index) => {
                                            if (index === i) {
                                              item.Quantity = e.target.value.replaceAll(",", "");
                                              item.total = +e.target.value * +item.Price;
                                              return item;
                                            }
                                            return item;
                                          });

                                          const updatedBillItems = bill_items.map((item, index) => {
                                            if (index === i) {
                                              item.quantity = e.target.value.replaceAll(",", "");
                                              item.total = +e.target.value * +item.price;
                                              return item;
                                            }
                                            return item;
                                          });

                                          setItemsbill(updatedItems);
                                          setBill_items(updatedBillItems);
                                        }}
                                      />
                                    </InputGroup>
                                    {!x.Quantity && validsan && <div className="error-message">{ErrorMessage.Quantity}</div>}
                                  </div>
                                  <div
                                    id={styles.main_input_datatatata}
                                    style={{ margin: "0px 0px", width: "15%", paddingLeft: "5px" }}
                                    className="input-form-wrapper dot"
                                  >
                                    <label>Price</label>
                                    <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>
                                      <Form.Control
                                        className="doc-form-input test valid"
                                        placeholder="Price"
                                        value={x.Price}
                                        disabled={!!x.monite_product_id || !!idPage ? true : false}
                                        type="number"
                                        onChange={(e: any) => {
                                          const updatedItems = itemsbill.map((item, index) => {
                                            if (index === i) {
                                              item.Price = e.target.value.replaceAll(",", "");
                                              item.total = +item.Quantity * +e.target.value;
                                              return item;
                                            }
                                            return item;
                                          });

                                          const updatedBillItems = bill_items.map((item, index) => {
                                            if (index === i) {
                                              item.price = e.target.value.replaceAll(",", "");
                                              item.total = +item.quantity * +e.target.value;
                                              return item;
                                            }
                                            return item;
                                          });

                                          setItemsbill(updatedItems);
                                          setBill_items(updatedBillItems);
                                        }}
                                      />
                                    </InputGroup>
                                  </div>

                                  {/* VAT Field */}
                                  <div
                                    id={styles.main_input_datatatata}
                                    style={{ width: "13%", margin: "0px 0px", paddingLeft: "5px" }}
                                    className="input-form-wrapper dot"
                                  >
                                    <label>VAT %</label>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <Form.Select
                                        value={x.tax || ""}
                                        onChange={(e) => {
                                          const selectedVat = e.target.value;
                                          //  e.target.value == "Exempt" ? '0' :
                                          const updatedItems = itemsbill.map((item, index) => {
                                            if (index === i) {
                                              return { ...item, tax: selectedVat };
                                            }
                                            return item;
                                          });

                                          const updatedBillItems = bill_items.map((item, index) => {
                                            if (index === i) {
                                              return { ...item, tax: selectedVat };
                                            }
                                            return item;
                                          });

                                          setItemsbill(updatedItems);
                                          setBill_items(updatedBillItems);
                                        }}
                                        className="doc-form-input"
                                      >
                                        <option selected value="">
                                          Select
                                        </option>
                                        {vatall?.map((arrayElement: any, index: number) => (
                                          <option value={arrayElement.vat} key={index + 1}>
                                            {arrayElement.vat}
                                          </option>
                                        ))}
                                      </Form.Select>

                                    </div>
                                    {!x.tax && validsan && <div className="error-message">{ErrorMessage.tax}</div>}

                                  </div>

                                  {/* Description Field */}
                                  <div
                                    id={styles.main_input_datatatata}
                                    style={{ margin: "0px 0px", width: "25%", paddingLeft: "5px" }}
                                    className="input-form-wrapper"
                                  >
                                    <label>Description</label>
                                    <InputGroup className="doc-input-wrapper">
                                      <Form.Control
                                        className="doc-form-input test valid"
                                        placeholder="Description"
                                        value={x.description}
                                        type="text"
                                        onChange={(e: any) => {
                                          const updatedItems = itemsbill.map((item, index) => {
                                            if (index === i) {
                                              item.description = e.target.value;
                                              return item;
                                            }
                                            return item;
                                          });

                                          const updatedBillItems = bill_items.map((item, index) => {
                                            if (index === i) {
                                              item.description = e.target.value;
                                              return item;
                                            }
                                            return item;
                                          });

                                          setItemsbill(updatedItems);
                                          setBill_items(updatedBillItems);
                                        }}
                                      />
                                    </InputGroup>
                                  </div>
                                </div>
                              </>
                            </div>
                          </>
                        );
                      })}





                      <div style={{ marginTop: "10px" }}>
                        <Button
                          className="complete-profile__ADDBTNHHA"
                          onClick={() => { handleAddClick() }}
                        >
                          + Add line item
                        </Button>
                      </div>
                    </div>

                    <div className={styles.div_buttomborder} style={{ paddingBottom: "10px" }}>
                    </div>
                    <FormControlLabel
                      className="main_redio_buttton"
                      style={{ fontSize: "13px", paddingBottom: "15px", paddingTop: "15px" }}
                      control={
                        <Checkbox
                          checked={paymentLink}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPaymentLink(event.target.checked);
                          }}
                          icon={<img src="/Checkbox.svg" />}
                          checkedIcon={<img src="/Checkbox_two.svg" />}
                        />
                      }
                      label={<span style={{ fontSize: "13px" }}>Automatically create and send payment link to the customer
                      </span>}
                    />
                    <div>
                      <Grid container>
                        <Grid item md={6}>
                          <Typography className={styles.textarea_Typography}>Payment Details</Typography>
                          <textarea onChange={(e: any) => {
                            setFooterdata({
                              totel: footerdata.totel,
                              textrea: e.target.value,
                              Tax: footerdata.Tax,
                              Discount_type: footerdata.Discount_type,
                              Discount: footerdata.Discount,
                              pagetotel: footerdata.pagetotel,
                              Discount_value: footerdata.Discount_value,
                              includes_tax: footerdata.includes_tax,
                              tex_totel: footerdata.tex_totel,
                              includes_Discount: footerdata.includes_Discount
                            })
                          }} id="w3review" className={styles.textarea}
                            name="w3review"
                            rows={6}
                            value={footerdata.textrea}
                            placeholder="Add a note or any terms & conditions (i.e. payment methods, late fees, etc) "
                          >
                          </textarea>
                          {props.data == "logout" ?
                            <>
                              <Typography className={styles.Typography_styles}>
                                You can also use Niobi to collect payment directly from your customers by adding a payment link to this invoice. To add a payment link and setup your account  <a href='https://niobi.co/request-a-demo/' target='_blank'>click here</a>
                              </Typography>
                              <div className={styles.main_addbutton}>
                                <Button className={styles.PaymentLink_button} href='https://share-eu1.hsforms.com/1lJP3cMs0RX22gCrvdliOMwfuo5v' target='_blank'>
                                  <a className={styles.a_yagesfffff} style={{ color: "#fff", textDecoration: "none" }} target='_blank' href='https://share-eu1.hsforms.com/1lJP3cMs0RX22gCrvdliOMwfuo5v'>Add Payment Link</a>
                                </Button>
                              </div>
                            </> : ""}





                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <div className={styles.contenar_boad_data}>
                            <div className={styles.div_totel_amout}>
                              <Typography className={styles.textarea_Typography_two}>Subtotal</Typography>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography className={styles.textarea_Typography_minhithe}>{info.Currency}</Typography>
                                <Typography className={styles.textarea_Typography_two}>{(Number(footerdata.totel))}</Typography>
                              </div>
                            </div>
                          </div>
                          <div className={styles.contenar_boad_data}>
                            <div className={styles.div_totel_amout}>
                              <Typography className={styles.textarea_Typography_two}>Total VAT</Typography>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography className={styles.textarea_Typography_two}>{totalTax}</Typography>
                              </div>
                            </div>
                          </div>


                          <div className={styles.conatebar_chackbox}>
                            <div style={{ width: "70%" }} id={styles.div_checkboxx} className="amont_text_p_chackbox">
                              <ThemeProvider theme={theme}>
                                <Checkbox
                                  icon={<img src="/Checkbox.svg" />}
                                  checkedIcon={<img src="/Checkbox_two.svg" />}
                                  color="primary"
                                  value={footerdata.includes_Discount}
                                  onClick={() => {
                                    if (footerdata.includes_Discount == true) {
                                      setFooterdata({
                                        totel: footerdata.totel,
                                        textrea: footerdata.textrea,
                                        Tax: footerdata.Tax,
                                        Discount: footerdata.Discount,
                                        pagetotel: footerdata.pagetotel,
                                        Discount_value: footerdata.Discount_value,
                                        Discount_type: footerdata.Discount_type,
                                        includes_tax: footerdata.includes_tax,
                                        includes_Discount: false,
                                        tex_totel: footerdata.tex_totel
                                      })
                                    } else {
                                      setFooterdata({
                                        totel: footerdata.totel,
                                        textrea: footerdata.textrea,
                                        Tax: footerdata.Tax,
                                        Discount_type: footerdata.Discount_type,
                                        Discount: footerdata.Discount,
                                        Discount_value: footerdata.Discount_value,
                                        pagetotel: footerdata.pagetotel,
                                        includes_tax: footerdata.includes_tax,
                                        includes_Discount: true,
                                        tex_totel: footerdata.tex_totel
                                      })

                                    }
                                  }}
                                  inputProps={{
                                    'aria-label': 'select all desserts',
                                  }}
                                />
                              </ThemeProvider>
                              <p>
                                This includes discount
                              </p>
                            </div>
                          </div>

                          {footerdata?.includes_Discount == true ?
                            <div className={styles.conatebar_chackbox}>
                              <div id={styles.disacaunt_input} className="input-form-wrapper">
                                <div style={{ width: "48.5%" }} id={styles.vat_input} className="input-form-wrapper">
                                  <label>Discount type</label>
                                  <Form.Select

                                    onChange={(e: any) => {
                                      if (e.target.value == "amount") {
                                        setFooterdata({
                                          totel: footerdata.totel,
                                          textrea: footerdata.textrea,
                                          Tax: footerdata.Tax,
                                          Discount_type: e.target.value,
                                          Discount: footerdata.Discount,
                                          Discount_value: footerdata.Discount_value,
                                          pagetotel: ((Number(footerdata.totel) - Number(footerdata.Discount_value)) + Number(totalTax)),
                                          includes_tax: footerdata.includes_tax,
                                          includes_Discount: footerdata.includes_Discount,
                                          tex_totel: footerdata.tex_totel
                                        })
                                      } else if (e.target.value == "percentage") {
                                        setFooterdata({
                                          totel: footerdata.totel,
                                          textrea: footerdata.textrea,
                                          Tax: footerdata.Tax,
                                          Discount_type: e.target.value,
                                          Discount_value: footerdata.Discount_value,
                                          Discount: footerdata.Discount,
                                          pagetotel: ((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(footerdata.Discount_value)) / 100)) + Number(totalTax)),
                                          includes_tax: footerdata.includes_tax,
                                          includes_Discount: footerdata.includes_Discount,
                                          tex_totel: footerdata.tex_totel
                                        })
                                      }


                                    }}
                                    value={footerdata.Discount_type}
                                    name="Conversion_Currency"
                                  >
                                    {disacaunt_type?.map((arrayElement: any, index: number) => {
                                      return (
                                        <option value={arrayElement.velue} key={index + 1}>{arrayElement.name}</option>
                                      )
                                    })}
                                  </Form.Select>
                                </div>
                                <div style={{ width: "48.5%" }} id={styles.vat_input} className="input-form-wrapper">
                                  <label>Discount value {footerdata.Discount_type == 'amount' ? "KES" : "%"}</label>
                                  <InputGroup className="doc-input-wrapper">

                                    <Form.Control
                                      className={
                                        'doc-form-input test valid'}
                                      placeholder="Discount"
                                      value={footerdata.Discount}
                                      name='name'
                                      type="number"
                                      onChange={(e) => {
                                        setFooterdata({
                                          totel: footerdata.totel,
                                          textrea: footerdata.textrea,
                                          Tax: footerdata.Tax,
                                          Discount_type: footerdata.Discount_type,
                                          Discount_value: footerdata.Discount_type == 'amount' ? Number(e.target.value) : ((Number(footerdata.totel) * Number(e.target.value)) / 100),
                                          Discount: footerdata.Discount_type == 'amount' ? Number(e.target.value) : Number(e.target.value),
                                          pagetotel: footerdata.Discount_type == 'amount' ?
                                            ((Number(footerdata.totel) - Number(e.target.value)) + Number(totalTax)) :
                                            ((Number(footerdata.totel) -
                                              ((Number(footerdata.totel) *
                                                Number(e.target.value)) / 100))
                                              + Number(totalTax)),                                                                       //  : ((((Number(footerdata.totel) - ((Number(footerdata.totel) * Number(e.target.value)) / 100))) / (1 + Number(footerdata.Tax) / 100)) + (Number(footerdata.totel) - ((Number(footerdata.totel) * Number(e.target.value)) / 100))),
                                          includes_tax: footerdata.includes_tax,
                                          tex_totel: footerdata.tex_totel,
                                          includes_Discount: footerdata.includes_Discount
                                        })
                                      }}
                                    />
                                  </InputGroup>
                                </div>
                              </div>
                            </div> : ""}
                          <div className={styles.contenar_boad_data}>
                            <div className={styles.div_totel_amout}>
                              <Typography className={styles.textarea_Typography_two}>Discount</Typography>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography className={styles.textarea_Typography_minhithe}>{info.Currency}</Typography>
                                <Typography className={styles.textarea_Typography_two}>{footerdata.Discount_type == 'percentage' ? (((Number(footerdata.totel) * Number(footerdata.Discount)) / 100)) : footerdata.Discount}</Typography>
                              </div>
                            </div>
                          </div>

                          <div className={styles.contenar_boad_data}>
                            <div className={styles.div_totel_amout}>
                              <Typography className={styles.textarea_Typography_two}>Total</Typography>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography className={styles.textarea_Typography_minhithe}>{info.Currency}</Typography>
                                <Typography className={styles.textarea_Typography_two}>{footerdata.pagetotel.toLocaleString(
                                  'en',
                                  { minimumFractionDigits: 2 }
                                )}</Typography>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item md={4} xs={12}>
                <div style={{ position: "-webkit-sticky" }}>
                  {validesannodata == false ?
                    <Preview_nodata /> : <Preview_fils getapi={getapi} TaxIdentificationNumber={TaxIdentificationNumber} apicallData={apicallData} idPage={idPage} paymentLink={paymentLink} Login={props.data} selectReceiver={selectReceiver} ReceiverHide={ReceiverHide} selectedValue={selectedValue} invoiceImage={invoiceImage} onCancel={onCancel} extractedProductData={extractedProductData} validesanpayload={validesanpayload} vatType={vatType} totalTax={totalTax} productTotalTax={productTotalTax} info={info} newInvoiceName={newInvoiceName} data={payload} bill_items={itemsbill} footerdata={footerdata} />}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    </>
  )
}
export default Create_your_invoiceIn;

const UploadLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.21875 2.79023C2.90766 2.79023 2.65547 3.04243 2.65547 3.35351V12.366C2.65547 12.5076 2.7077 12.637 2.79395 12.736L9.79993 6.0484C10.0726 5.7881 10.4994 5.78053 10.7811 6.03098L12.7945 7.82066V3.35351C12.7945 3.04243 12.5423 2.79023 12.2313 2.79023H3.21875ZM12.7945 9.74954C12.7673 9.73128 12.7409 9.71097 12.7157 9.68855L10.3175 7.55677L4.68912 12.9293H12.2313C12.5423 12.9293 12.7945 12.6771 12.7945 12.366V9.74954ZM1.20703 3.35351C1.20703 2.24247 2.10771 1.3418 3.21875 1.3418H12.2313C13.3423 1.3418 14.243 2.24247 14.243 3.35351V12.366C14.243 13.4771 13.3423 14.3777 12.2313 14.3777H3.21875C2.10771 14.3777 1.20703 13.4771 1.20703 12.366V3.35351ZM5.47188 5.36524C5.33855 5.36524 5.23047 5.47332 5.23047 5.60664C5.23047 5.73997 5.33855 5.84805 5.47188 5.84805C5.6052 5.84805 5.71328 5.73997 5.71328 5.60664C5.71328 5.47332 5.6052 5.36524 5.47188 5.36524ZM3.78203 5.60664C3.78203 4.67337 4.5386 3.9168 5.47188 3.9168C6.40514 3.9168 7.16172 4.67337 7.16172 5.60664C7.16172 6.53991 6.40514 7.29649 5.47188 7.29649C4.5386 7.29649 3.78203 6.53991 3.78203 5.60664Z" fill="#025041" />
  </svg>
)

const Upload_svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.27578 1.33691C8.46785 1.33691 8.65207 1.41322 8.78788 1.54903L12.0067 4.76778C12.2895 5.05061 12.2895 5.50916 12.0067 5.79198C11.7238 6.07481 11.2653 6.07481 10.9824 5.79198L9 3.80955V9.78613C9 10.1861 8.67575 10.5104 8.27578 10.5104C7.87581 10.5104 7.55156 10.1861 7.55156 9.78613V3.80955L5.56913 5.79198C5.2863 6.07481 4.82776 6.07481 4.54493 5.79198C4.2621 5.50916 4.2621 5.05061 4.54493 4.76778L7.76368 1.54903C7.8995 1.41322 8.08371 1.33691 8.27578 1.33691ZM2.48203 9.06191C2.882 9.06191 3.20625 9.38616 3.20625 9.78613V12.3612C3.20625 12.5105 3.2656 12.6537 3.37123 12.7594C3.47687 12.865 3.62014 12.9244 3.76953 12.9244H12.7821C12.9314 12.9244 13.0746 12.865 13.1803 12.7594C13.2859 12.6537 13.3453 12.5105 13.3453 12.3612V9.78613C13.3453 9.38616 13.6696 9.06191 14.0695 9.06191C14.4695 9.06191 14.7937 9.38616 14.7937 9.78613V12.3612C14.7937 12.8947 14.5818 13.4064 14.2045 13.7836C13.8273 14.1609 13.3156 14.3729 12.7821 14.3729H3.76953C3.23599 14.3729 2.7243 14.1609 2.34703 13.7836C1.96976 13.4064 1.75781 12.8947 1.75781 12.3612V9.78613C1.75781 9.38616 2.08206 9.06191 2.48203 9.06191Z" fill="#1F1F1F" fill-opacity="0.8" />
  </svg>

)
import { alertActions } from '.'
import { balanceService } from "../_services/balance.service";
import { balanceConstants } from "../_constants/balance.constants";
// import toast, { Toaster } from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const balanceActions = {
    getBalanceList,
    getTopUpDetails,
    getBalanceListtwo,
    getExportCsv,
    getBankDetailsById,
    savePaymentDetails,
    Stk_deposit_actice,
    getPdf,
    getPdf_Topup,
    PostKasPayment,
    getTopUpDetails_doller,
    savePaymentcurrencyDetails,
    TransStatustopup,
    getPdf_Topup_serea,
    updateTransactionTeam,
}

function getBalanceList(datalist: any) {


    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))

        balanceService.getBalanceList(datalist)
            .then(data => {

                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data));
                    dispatch(alertActions.success('Balance  successful'))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: balanceConstants.GETBALANCE_REQUEST, data } }
    function success(data: any) { return { type: balanceConstants.GETBALANCE_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.GETBALANCE_FAILURE } }
}
function getBalanceListtwo(datalist: any) {

    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(datalist))

        balanceService.getBalanceListtwo(datalist)
            .then(data => {
                dispatch(alertActions.clear());
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response.data));
                    dispatch(alertActions.success('Balance  successful'))
                } else {
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data: any) { return { type: balanceConstants.GETBALANCE_REQUESTTWO, data } }
    function success(data: any) { return { type: balanceConstants.GETBALANCE_SUCCESSTWO, data } }
    function failure(s: string) { return { type: balanceConstants.GETBALANCE_FAILURETWO } }
}
function getTopUpDetails() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.getTopUpDetails()
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.GET_TOPUP_DETAILS_REQUEST } }
    function success(data: any) { return { type: balanceConstants.GET_TOPUP_DETAILS_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.GET_TOPUP_DETAILS_FAILURE } }
}
function getTopUpDetails_doller() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.getTopUpDetails_doller()
            .then(data => {
                return data.json()
            })
            .then(response => {
                dispatch(success(response.data))
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.GETCURRENCIES_REQUEST } }
    function success(data: any) { return { type: balanceConstants.GETCURRENCIES_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.GETCURRENCIES_FAILURE } }
}
function getPdf_Topup() {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.getPdf_Topup_service()
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                }else{
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                }
            },
                error => {
                    
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.GET_TOPUP_PDF_REQUEST } }
    function success(data: any) { return { type: balanceConstants.GET_TOPUP_PDF_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.GET_TOPUP_PDF_FAILURE } }
}
function getPdf_Topup_serea(data:any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.getPdf_Topup_serea(data)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                }else{
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                }
            },
                error => {
                    
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.GET_TOPUP_PDF_STK_REQUEST } }
    function success(data: any) { return { type: balanceConstants.GET_TOPUP_PDF_STK_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.GET_TOPUP_PDF_STK_FAILURE } }
}
function getExportCsv(searcData : any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.getExportCsv(searcData)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.GET_CSV_EXPORT_REQUEST } }
    function success(data: any) { return { type: balanceConstants.GET_CSV_EXPORT_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.GET_CSV_EXPORT_FAILURE } }
}



function getBankDetailsById(req : any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.getBankDetailsById(req)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.GET_BANK_DETAILS_ID_REQUEST } }
    function success(data: any) { return { type: balanceConstants.GET_BANK_DETAILS_ID_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.GET_BANK_DETAILS_ID_FAILURE } }
}

function getPdf(req : any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.getPdf_service(req)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                }else{
                    dispatch(failure(response.message));
                    dispatch(alertActions.error(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.GET_PDF_REQUEST } }
    function success(data: any) { return { type: balanceConstants.GET_PDF_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.GET_PDF_FAILURE } }
}
function savePaymentDetails(req : any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.savePaymentDetails(req)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.SAVE_PAYMENT_DETAILS_REQUEST } }
    function success(data: any) { return { type: balanceConstants.SAVE_PAYMENT_DETAILS_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.SAVE_PAYMENT_DETAILS_FAILURE } }
}
function savePaymentcurrencyDetails(req : any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.savePaymentcurrencyDetails(req)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                    toast.success(response.message);
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.SAVE_PAYMENT_CURRENCY_DETAILS_REQUEST } }
    function success(data: any) { return { type: balanceConstants.SAVE_PAYMENT_CURRENCY_DETAILS_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.SAVE_PAYMENT_CURRENCY_DETAILS_FAILURE } }
}
function PostKasPayment(req : any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        balanceService.PostKasPayment(req)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request() { return { type: balanceConstants.POST_KES_PAYMENT_REQUEST } }
    function success(data: any) { return { type: balanceConstants.POST_KES_PAYMENT_SUCCESS, data } }
    function failure(s: string) { return { type: balanceConstants.POST_KES_PAYMENT_FAILURE } }
}
function Stk_deposit_actice(req : any) {
    return (dispatch: (arg0: { type: any; data?: any; message?: any }) => void) => {
        dispatch(request(req))


        balanceService.Stk_deposit_service(req)
            .then(data => {
                return data.json()
            })
            .then(response => {
                if (response.success) {
                    dispatch(success(response))
                }else{
                    dispatch(failure(response.message));
                }
            },
                error => {
                    dispatch(failure(error.toString()))
                    toast.error(error.message);

                    dispatch(alertActions.error(error.message))
                }
            )
    }

    function request(data:any) { return { type: balanceConstants.STK_DEPOSIT_REQUEST,data } }
    function success(data: any) { return { type: balanceConstants.STK_DEPOSIT_SUCCESS, data } }
    function failure(error: string) { return { type: balanceConstants.STK_DEPOSIT_FAILURE,error } }
}
function TransStatustopup(req: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))

        balanceService.TransStatustopup(req)
            .then(response => {
                return response.json()
            })
            .then(
                otp => {
                    if (otp.success) {
                        dispatch(success(otp));
                        dispatch(alertActions.success(' Express payment Request successful'))
                    } else {
                        dispatch(failure(otp.message));
                        dispatch(alertActions.error(otp.message));
                        toast.error(otp.message);
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(otp: any) { return { type: balanceConstants.TRANTOPUP_REQUEST, otp } }
    function success(otp: any) { return { type: balanceConstants.TRANTOPUP_SUCCESS, otp } }
    function failure(error: any) { return { type: balanceConstants.TRANTOPUP_FAILURE, error } }
}

function updateTransactionTeam(req: any, id: any) {
    return (dispatch: (arg0: { type: string; otp?: any; message?: any; error?: any }) => void) => {
        dispatch(request(req))

        balanceService.updateTransactionTeam(req, id)
            .then(response => {
                return response.json()
            })
            .then(
                res => {
                    if (res.success) {
                        dispatch(success(res));
                        dispatch(alertActions.success(' Transaction updated successfully'))
                    } else {
                        dispatch(failure(res.message));
                        dispatch(alertActions.error(res.message));
                        toast.error(res.message);
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            )
    }

    function request(res: any) { return { type: balanceConstants.TRANSACTIONTEAM_REQUEST, res } }
    function success(res: any) { return { type: balanceConstants.TRANSACTIONTEAM_SUCCESS, res } }
    function failure(res: any) { return { type: balanceConstants.TRANSACTIONTEAM_FAILURE, res } }
}
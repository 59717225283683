import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import '../Settings.scss';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { getUser } from '../../../../../../../../../_helpers';
import { Form, InputGroup } from 'react-bootstrap';
import { entityActions } from '../../../../../../../../../_actions';
import { settingSelector } from '../../../../../../../../../_reducers/setting.reducer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '../UploadIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ErrorMessage, Field } from 'formik';
import { choiceActions } from '../../../../../../../../../_actions/choice.action';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Box, Avatar } from '@mui/material';
import PhoneInput, { CountryData } from 'react-phone-input-2';

const SoleProprietory = ({
  formik,
  idFrontLabel,
  idFrontFile,
  idBackLabel,
  idBackFile,
  selfieLabel,
  selfieFile,
  passportLabel,
  passportFile,
  handleRemoveImage,
  loader,
  kraFile,
  kraLabel,
  handleImage,
  setpsubmit
}: any) => {
  const [render, setRender] = useState(true);
  const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false);
  const [kraAccordion, setKraAccordion] = useState<boolean>(false);
  const [idAccordion, setIdAccordion] = useState<boolean>(false);
  const [nextKinAccordion, setNextKinAccordion] = useState<boolean>(false);



  const [renderSubmit, setRenderSubmit] = useState(true);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));
  const genders = useSelector((state: any) => state.getGenderReducer.data);

  useEffect(() => {
    dispatch(choiceActions.getGender() as any);
  }, [dispatch]);

  const [dataaccodiyan, setDataccodiyan] = useState<boolean>(false);

  const [fileSize, setFileSize] = useState<any>('');
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [selectedIdType, setSelectedIdType] = useState<any>(null);

  const handleChangeIdType = (e: any) => {
    setSelectedIdType(e.target.value);
  };

  useEffect(() => {
    if (render) {
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render]);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  const paymentAccordionHasErrors = () => {
    const fieldsToCheck = [
      'firstName',
      'birthday',
      'mobile',
      'gender',
      'selfie',
    ];
    if (setpsubmit == true) {
      return fieldsToCheck.some((field) => formik.errors[field]);
    }
  };

  const idAccordionHasErrors = () => {
    const fieldsToCheck = [
      'IdNumber',
      'selectedIdType',
      'idFront',
      'idBack',
      'passport',
    ];
    if (setpsubmit == true) {
      return fieldsToCheck.some((field) => formik.errors[field]);
    }
  };
  const taxAccordionHasErrors = () => {
    const fieldsToCheck = ['kra', 'kraPin'];
    if (setpsubmit == true) {
      return fieldsToCheck.some((field) => formik.errors[field]);
    }
  };
  const kinAccordionHasErrors = () => {
    const fieldsToCheck = ['kinFullName', 'kinMobile', 'kinRelationship'];
    if (setpsubmit == true) {
      return fieldsToCheck.some((field) => formik.errors[field]);
    }
  };


  // const imageUrl =
  //   'https://myniobiawsbucket.s3.eu-central-1.amazonaws.com/documents/frontID-6ab96d5d-8af3-4e1f-8895-7069fb56f1f32023-08-22.png';
  return (
    <>
      <p className="complete-profile__businesstypo">Business owner's details</p>
      <div
        style={{ marginTop: '20px !important' }}
        className="calss_accordion_contenar"
      >
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (paymentacodiyan == false) {
              setPaymentacodiyan(true);
              setDataccodiyan(false);
              setKraAccordion(false);
              setNextKinAccordion(false);
              setIdAccordion(false);
            } else {
              setPaymentacodiyan(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div
              className={` ${!paymentAccordionHasErrors()
                ? 'avayar_text'
                : 'avayar_text_error'
                }`}
            >
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <Avatar className="Avatar_fast">
                {!!formik.values.fullName &&
                  !!formik.values.birthday &&
                  !!formik.values.mobile &&
                  !!formik.values.gender &&
                  selfieFile !== 'Passport photo'
                  ? (
                    <DoneIcon />
                  )
                  : (
                    "1"
                  )}
              </Avatar>
              <p className="accordion_p_data_contean">Personal Details</p>
            </div>
            {paymentAccordionHasErrors() && (
              <ReportProblemIcon style={{ color: 'rgb(255, 101, 101)' }} />
            )}
            {!paymentAccordionHasErrors() ? (
              <>
                {paymentacodiyan === false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}
              </>
            ) : (
              ''
            )}
          </ThemeProvider>
        </div>

        {paymentacodiyan === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>Owner's full name (same as on ID)</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="fullName"
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.fullName && Boolean(formik.errors.fullName)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="fullName" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Date of birth</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="birthday"
                  value={formik.values.birthday}
                  onChange={formik.handleChange}
                  type="date"
                  isInvalid={
                    formik.touched.birthday && Boolean(formik.errors.birthday)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="birthday" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Owner's mobile number</label>
              {/* <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.mobile && Boolean(formik.errors.mobile)
                  }
                />
              </InputGroup> */}
              <Field name="mobile">
                {({ field }: any) => (
                  <PhoneInput
                    {...field}
                    country={'ke'}
                    prefix=""
                    value={field.value}
                    onChange={(value, country: CountryData) => {
                      
                      const countryCode = `+${country.dialCode}`;
                      const mobile = value.replace(countryCode, '');
                 

                      formik.setFieldValue('countryCode', country.dialCode);
                      formik.setFieldValue('mobile', value);
                    }}
                    inputClass={'inputBx-mobile'}
                  />
                )}
              </Field>
              <p className="error-message">
                {' '}
                <ErrorMessage name="mobile" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Gender</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Select
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                >
                  <option>Gender</option>
                  {genders?.map((gender: any) => (
                    <option value={gender.key} key={gender.id}>
                      {gender.value}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="gender" component="div" />
              </p>
            </div>
            <div className="flax_data_list">
              <Tooltip title="Upload Documents (File-size: Max 2-5 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                <div style={{ width: '100%', display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="upload-label">
                      Upload passport photo
                    </label>
                    <div className="required_marker"></div>
                  </div>
                  <label
                    style={{ padding: '5px 0px 0px 0px' }}
                    className="complete-profile__upload mb-0"
                  >
                    <div
                      // id='afafafsdaFfsf'
                      className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                    >
                      <span className="complete-profile__upload__txt">
                        {formik.values?.businessDocs?.data != null &&
                          formik.values?.businessDocs?.data?.data?.selfie
                            ?.file_name != null
                          ? formik.values?.businessDocs?.data?.data?.selfie
                            ?.file_name
                          : selfieFile}
                      </span>
                      {/* {selfieFile === selfieLabel ? (
                      <UploadIcon />
                    ) : (
                      <CloseIcon />
                    )} */}
                      {/* <UploadIcon /> */}
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      name="selfie"
                      onChange={(e) => {
                        handleImage(e, formik);
                      }}
                    />
                    {1024 * 1024 <= fileSize ? (
                      <div className="error-message">
                        File size too large, max file size is 1 Mb
                      </div>
                    ) : null}
                    <p className="error-message">
                      {' '}
                      {formik.errors.selfie ? (
                        <ErrorMessage name="selfie" component="div" />
                      ) : null}
                    </p>
                  </label>
                </div>
              </Tooltip>
              {selfieFile == selfieLabel ? (
                <div className="upload-icon">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="upload-icon"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    // handleRemoveImage('selfie');
                    handleRemoveImage('selfie', formik);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <div
        style={{ marginTop: '20px !important' }}
        className="calss_accordion_contenar"
      >
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (idAccordion === false) {
              setIdAccordion(true);
              setDataccodiyan(false);
              setKraAccordion(false);
              setNextKinAccordion(false);
              setPaymentacodiyan(false);
            } else {
              setIdAccordion(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div
              className={` ${!idAccordionHasErrors()
                ? 'avayar_text' : 'avayar_text_error'
                }`}
            >
              <Avatar className="Avatar_fast">
                {!!formik.values.selectedIdType &&
                  !!formik.values.idNumber &&
                  formik.values.selectedIdType === 'National' &&
                  formik.values?.idFront &&
                  formik?.values?.idBack
                  ? (
                    <DoneIcon />
                  ) : !!formik.values.selectedIdType &&
                    !!formik.values.idNumber &&
                    formik.values.selectedIdType === 'passport' &&
                    !!formik.values.passport ?
                    (
                      <DoneIcon />
                    )
                    : (
                      "2"
                    )}
              </Avatar>
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">ID Details</p>
            </div>
            {idAccordionHasErrors() && (
              <ReportProblemIcon style={{ color: 'rgb(255, 101, 101)' }} />
            )}
            {!idAccordionHasErrors() ? (
              <>
                {idAccordion === false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}
              </>
            ) : (
              ''
            )}
          </ThemeProvider>
        </div>

        {idAccordion === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>ID type</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Select
                  id="selectedIdType"
                  name="selectedIdType"
                  value={formik.values.selectedIdType}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.selectedIdType &&
                    Boolean(formik.errors.selectedIdType)
                  }
                >
                  <option key="idType" value="">
                    Select ID type
                  </option>
                  <option key="nationalId" value="National">
                    National ID
                  </option>
                  <option key="passport" value="passport">
                    Passport
                  </option>
                </Form.Select>
              </InputGroup>

              <p className="error-message">
                {' '}
                {Boolean(formik.errors.selectedIdType) ? (
                  <ErrorMessage name="selectedIdType" component="div" />
                ) : (
                  ''
                )}
              </p>
            </div>
            {/* <div className="input-form-wrapper dot">
              <label>
                {`Owner's ${
                  formik.values.selectedIdType === 'passport'
                    ? 'passport'
                    : 'ID'
                } number`}{' '}
              </label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="idNumber"
                  value={formik.values.idNumber}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.idNumber && Boolean(formik.errors.idNumber)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="idNumber" component="div" />
              </p>
            </div> */}

            {formik.values.selectedIdType && (
              <div className="input-form-wrapper dot">
                <label>
                  {`Owner's ${formik.values.selectedIdType === 'passport'
                    ? 'passport'
                    : 'ID'
                    } number`}{' '}
                </label>
                <InputGroup className="doc-input-wrapper">
                  <Form.Control
                    name="idNumber"
                    value={formik.values.idNumber}
                    onChange={formik.handleChange}
                    type="text"
                    isInvalid={
                      formik.touched.idNumber && Boolean(formik.errors.idNumber)
                    }
                  />
                </InputGroup>
                <p className="error-message">
                  {' '}
                  <ErrorMessage name="idNumber" component="div" />
                </p>
              </div>
            )}

            {formik.values.selectedIdType === 'National' && (
              <>
                <div className="flax_data_list">
                  <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                    <div style={{ width: '100%', display: 'block' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label className="upload-label">
                          {' '}
                          Upload national ID - frontside
                        </label>
                        <div className="required_marker"></div>
                      </div>
                      <label
                        style={{ padding: '5px 0px 0px 0px' }}
                        className="complete-profile__upload mb-0"
                      >
                        <div
                          // id='afafafsdaFfsf'
                          className="complete-profile__upload__label"
                        //  style={{ cursor: 'not-allowed' }}
                        >
                          <span className="complete-profile__upload__txt">
                            {formik.values?.businessDocs?.data != null &&
                              formik.values?.businessDocs?.data?.data?.idFront
                                ?.file_name != null
                              ? formik.values?.businessDocs?.data?.data?.idFront
                                ?.file_name
                              : idFrontFile}
                          </span>
                        </div>
                        <input
                          type="file"
                          accept=".pdf, .jpg, .jpeg, .png"
                          name="idFront"
                          onChange={(e) => {
                            handleImage(e, formik);
                          }}
                        />
                        {1024 * 1024 <= fileSize ? (
                          <div className="error-message">
                            File size too large, max file size is 1 Mb
                          </div>
                        ) : null}
                        <p className="error-message">

                          {' '}
                          {/* {formik.errors.idBack ? (
                            <ErrorMessage name="idBack" component="div" />
                          ) : null} */}
                          {!!formik.errors.idFront && setpsubmit == true ? (
                            <div>ID - Frontside photo is required</div>
                          ) : null}
                        </p>
                      </label>
                    </div>
                  </Tooltip>
                  {idFrontFile === idFrontLabel ? (
                    <div className="upload-icon">
                      {' '}
                      <UploadIcon />
                    </div>
                  ) : (
                    <div
                      className="upload-icon"
                      onClick={() => {
                        // remove_data(
                        //   groupOneName[i].KRN_certi_new
                        // );
                        // handleRemoveImage('idFront');
                        handleRemoveImage('idFront', formik);
                      
                      }}
                    >
                      <CloseIcon />
                    </div>
                  )}
                </div>
                <div style={{ marginTop: '5px', height: '12px' }}></div>
                <div className="flax_data_list">
                  <Tooltip
                    style={{ marginTop: '20px' }}
                    title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya."
                  >
                    <div style={{ width: '100%', display: 'block' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label className="upload-label">
                          Upload national ID - backside
                        </label>
                        <div className="required_marker"></div>
                      </div>
                      <label
                        style={{ padding: '5px 0px 0px 0px' }}
                        className="complete-profile__upload mb-0"
                      >
                        <div
                          // id='afafafsdaFfsf'
                          className="complete-profile__upload__label"
                        //  style={{ cursor: 'not-allowed' }}
                        >
                          <span className="complete-profile__upload__txt">
                            {formik.values?.businessDocs?.data != null &&
                              formik.values?.businessDocs?.data?.data?.idBack
                                ?.file_name != null
                              ? formik.values?.businessDocs?.data?.data?.idBack
                                ?.file_name
                              : idBackFile}
                          </span>
                        </div>
                        <input
                          type="file"
                          accept=".pdf, .jpg, .jpeg, .png"
                          name="idBack"
                          onChange={(e) => {
                            handleImage(e, formik);
                          }}
                        />
                        {1024 * 1024 <= fileSize ? (
                          <div className="error-message">
                            File size too large, max file size is 1 Mb
                          </div>
                        ) : null}
                        <p className="error-message">

                          {' '}
                          {formik.errors.idBack ? (
                            <ErrorMessage name="idBack" component="div" />
                          ) : null}

                        </p>
                      </label>
                    </div>
                  </Tooltip>
                  {idBackFile === idBackLabel ? (
                    <div className="upload-icon">
                      {' '}
                      <UploadIcon />
                    </div>
                  ) : (
                    <div
                      className="upload-icon"
                      onClick={() => {
                        // remove_data(
                        //   groupOneName[i].KRN_certi_new
                        // );
                        // handleRemoveImage('idBack');
                        handleRemoveImage('idBack', formik);
                 
                      }}
                    >
                      <CloseIcon />
                    </div>
                  )}
                </div>
              </>
            )}
            {formik.values.selectedIdType === 'passport' && (
              <div className="flax_data_list">
                <Tooltip title="Upload Documents (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                  <div style={{ width: '100%', display: 'block' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <label className="upload-label">Upload passport</label>
                      <div className="required_marker"></div>
                    </div>
                    <label
                      style={{ padding: '5px 0px 0px 0px' }}
                      className="complete-profile__upload mb-0"
                    >
                      <div
                        // id='afafafsdaFfsf'
                        className="complete-profile__upload__label"
                      //  style={{ cursor: 'not-allowed' }}
                      >
                        <span className="complete-profile__upload__txt">
                          {formik.values?.businessDocs?.data != null &&
                            formik.values?.businessDocs?.data?.data?.passport
                              ?.file_name != null
                            ? formik.values?.businessDocs?.data?.data?.passport
                              ?.file_name
                            : passportFile}
                        </span>
                      </div>
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png"
                        name="passport"
                        onChange={(e) => {
                          handleImage(e, formik);
                        }}
                      />
                      {1024 * 1024 <= fileSize ? (
                        <div className="error-message">
                          File size too large, max file size is 1 Mb
                        </div>
                      ) : null}
                      <p className="error-message">
                        {' '}
                        {!!formik.errors.passport && setpsubmit == true ? (
                          <div>Passport is required</div>
                        ) : null}
                      </p>
                    </label>
                  </div>
                </Tooltip>
                {passportFile === passportLabel ? (
                  <div className="upload-icon">
                    {' '}
                    <UploadIcon />
                  </div>
                ) : (
                  <div
                    className="upload-icon"
                    onClick={() => {
                      // remove_data(
                      //   groupOneName[i].KRN_certi_new
                      // );
                      // handleRemoveImage('passport');
                      handleRemoveImage('passport', formik);
                   
                    }}
                  >
                    <CloseIcon />
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>

      <div
        style={{ marginTop: '20px !important' }}
        className="calss_accordion_contenar"
      >
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (kraAccordion === false) {
              setKraAccordion(true);
              setDataccodiyan(false);
              setPaymentacodiyan(false);
              setNextKinAccordion(false);
              setIdAccordion(false);
            } else {
              setKraAccordion(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div
              className={` ${!taxAccordionHasErrors() ? 'avayar_text' : 'avayar_text_error'
                }`}
            >
              <Avatar className="Avatar_fast">
                {!!formik.values.kraPin &&
                  !!formik.values.kra
                  ? (
                    <DoneIcon />
                  )
                  : (
                    "3"
                  )}
              </Avatar>
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">Tax Details</p>
            </div>
            {taxAccordionHasErrors() && (
              <ReportProblemIcon style={{ color: 'rgb(255, 101, 101)' }} />
            )}
            {!taxAccordionHasErrors() ? (
              <>
                {kraAccordion === false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}
              </>
            ) : (
              ''
            )}
          </ThemeProvider>
        </div>

        {kraAccordion === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>Tax identification number</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kraPin"
                  value={formik.values.kraPin}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kraPin && Boolean(formik.errors.kraPin)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kraPin" component="div" />
              </p>
            </div>
            <div className="flax_data_list">
              <Tooltip title="Upload document showing tax identification number (File-size: Max 1 MB. File-type: jpeg, png or pdf). Please upload notarized documents if entity is registered outside Kenya.">
                <div style={{ width: '100%', display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label className="upload-label">
                      Upload Tax certificate
                    </label>
                    <div className="required_marker"></div>
                  </div>
                  <label
                    style={{ padding: '5px 0px 0px 0px' }}
                    className="complete-profile__upload mb-0"
                  >
                    <div
                      // id='afafafsdaFfsf'
                      className="complete-profile__upload__label"
                    //  style={{ cursor: 'not-allowed' }}
                    >
                      <span className="complete-profile__upload__txt">
                        {formik.values?.businessDocs?.data != null &&
                          formik.values?.businessDocs?.data?.data?.kra
                            ?.file_name != null
                          ? formik.values?.businessDocs?.data?.data?.kra
                            ?.file_name
                          : kraFile}
                      </span>
                      {/* {kraFile === kraLabel ? <UploadIcon /> : <CloseIcon />} */}
                    </div>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      name="kra"
                      onChange={(e) => {
                        handleImage(e, formik);
                      }}
                    />
                    {1024 * 1024 <= fileSize ? (
                      <div className="error-message">
                        File size too large, max file size is 1 Mb
                      </div>
                    ) : null}
                    <p className="error-message">
                      {' '}
                      {formik.errors.kra ? (
                        <ErrorMessage name="kra" component="div" />
                      ) : null}
                    </p>
                  </label>
                </div>
              </Tooltip>
              {kraFile === kraLabel ? (
                <div className="upload-icon">
                  {' '}
                  <UploadIcon />
                </div>
              ) : (
                <div
                  className="upload-icon"
                  onClick={() => {
                    // remove_data(
                    //   groupOneName[i].KRN_certi_new
                    // );
                    // handleRemoveImage('kra');
                    handleRemoveImage('kra', formik);
         
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>

      <div
        style={{ marginTop: '20px !important' }}
        className="calss_accordion_contenar"
      >
        <div
          className="maian_adaat_listtts"
          onClick={() => {
            if (nextKinAccordion === false) {
              setNextKinAccordion(true);
              setDataccodiyan(false);
              setKraAccordion(false);
              setPaymentacodiyan(false);
              setIdAccordion(false);
            } else {
              setNextKinAccordion(false);
            }
          }}
        >
          <ThemeProvider theme={theme}>
            <div
              className={` ${!kinAccordionHasErrors() ? 'avayar_text' : 'avayar_text_error'
                }`}
            >
              <Avatar className="Avatar_fast">
                {!!formik.values.kinFullName &&
                  !!formik.values.kinMobile &&
                  !!formik.values.kinRelationship
                  ? (
                    <DoneIcon />
                  )
                  : (
                    "4"
                  )}
              </Avatar>
              {/* <Avatar className="Avatar_fast">{!!amont_data && !!vat_data && selectedList.length > 0 ? <DoneIcon /> : "2"}</Avatar> */}
              <p className="accordion_p_data_contean">Next Of Kin Details</p>
            </div>
            {kinAccordionHasErrors() && (
              <ReportProblemIcon style={{ color: 'rgb(255, 101, 101)' }} />
            )}
            {!kinAccordionHasErrors() ? (
              <>
                {nextKinAccordion === false ? (
                  <ExpandMoreIcon style={{ color: '#1D1D1D' }} />
                ) : (
                  <ExpandLessIcon style={{ color: '#1D1D1D' }} />
                )}
              </>
            ) : (
              ''
            )}
          </ThemeProvider>
        </div>

        {nextKinAccordion === true ? (
          <>
            <div className="input-form-wrapper dot">
              <label>Full names</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kinFullName"
                  value={formik.values.kinFullName}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kinFullName &&
                    Boolean(formik.errors.kinFullName)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kinFullName" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Mobile number </label>
              {/* <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kinMobile"
                  value={formik.values.kinMobile}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kinMobile && Boolean(formik.errors.kinMobile)
                  }
                />
              </InputGroup> */}
              <Field name="kinMobile">
                {({ field }: any) => (
                  <PhoneInput
                    {...field}
                    country={'ke'}
                    prefix=""
                    value={field.value}
                    onChange={(value, country: CountryData) => {
                      formik.setFieldValue('kinCountryCode', country?.dialCode);
                      formik.setFieldValue('kinMobile', value);
                    }}
                    inputClass={'inputBx-mobile'}
                  />
                )}
              </Field>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kinMobile" component="div" />
              </p>
            </div>
            <div className="input-form-wrapper dot">
              <label>Relationship</label>
              <InputGroup className="doc-input-wrapper">
                <Form.Control
                  name="kinRelationship"
                  value={formik.values.kinRelationship}
                  onChange={formik.handleChange}
                  type="text"
                  isInvalid={
                    formik.touched.kinRelationship &&
                    Boolean(formik.errors.kinRelationship)
                  }
                />
              </InputGroup>
              <p className="error-message">
                {' '}
                <ErrorMessage name="kinRelationship" component="div" />
              </p>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {loader && (
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              top: 150,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </ThemeProvider>
      )}
    </>
  );
};

export default SoleProprietory;

import { Avatar, Button, Typography } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from 'react';
import { userActions } from "../../../_actions";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { registerSelector } from "../../../_reducers/registration.reducer";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';


export const Updatepassword = () => {
    const [submitted, setSubmitted] = useState(false);
    const [showPasswordlistdata, setShowPasswordlistdata] = React.useState(false)
    const [showPasswordlistdata2, setShowPasswordlistdata2] = React.useState(false)
    const [showPasswordlistdata3, setShowPasswordlistdata3] = React.useState(false)
    const [registerRender, setregisterRender] = useState(true);
    const [regfailerRender, setregfailerRender] = useState(true);
    const [registerReqeust, setregisterReqeust] = useState(true);
  
    const dispatch = useDispatch();
    const handleClickShowPassword = () => setShowPasswordlistdata((show: any) => !show);
    const [loading, setLoading] = useState(false);
    const handleClickShowPassword2 = () => setShowPasswordlistdata2((show: any) => !show);
  
    const handleClickShowPassword3 = () => setShowPasswordlistdata3((show: any) => !show);
    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
      };
//       const registerData = useSelector((state) =>
//       registerSelector(state)
//   );
  function resetResquest(){
    setregisterRender(true);
    setregfailerRender(true);
}
const theme = createTheme({
  status: {
      danger: orange[500],
  },
});
    //   useEffect(() => {
    //     if (regfailerRender && registerData?.UPDATEPASSWARD_FAILURE !== undefined) {
    //       setregfailerRender(false);
    //       setLoading(false);
    //       setregisterReqeust(true);
    //     }
    //   }, [registerData, registerRender]);
    
    //   useEffect(() => {
    //     if (registerReqeust && registerData?.UPDATEPASSWARD_REQUEST !== undefined) {
    
    //       resetResquest();
    //       setregisterReqeust(false);
    //       setLoading(registerData.UPDATEPASSWARD_REQUEST);
    //     }
    //   }, [registerData, registerReqeust]);
    const validationSchema = Yup.object().shape({
        old_password: Yup
          .string()
          .required('Please enter old password'),
        password: Yup
          .string()
          .required('Please enter new password')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Password must have at least 8 characters, including atleast one uppercase letter, one lowercase letter, one number, and a special character."
          ),
        confirm_password: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Please enter the same password in both fields"
        ).required('Please enter a value in the confirm password field'),
    
      });

      const handleSubmit = (e: any) => {
        // var req = {
        //   old_password: e.old_password,
        //   password: e.password,
        //   confirm_password: e.confirm_password,
        // };
    
        // setSubmitted(true);
        // if (e.old_password && e.password) {
       
        //   dispatch(userActions.updatePassword({ req }) as any);
        // }
      }
      const [user, setUser] = useState({
        password: "",
        old_password: "",
        confirm_password: "",
      });
    return (
        <>
        {/* <ThemeProvider theme={theme}> */}
      <div className='complete-profile user-detail-container'>
        <p className="updatepasstext">Update password</p>

        <div className='user-detail-container__wrapper'>
           <Formik
            initialValues={user}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
        {({ errors, touched, isSubmitting, handleChange, values }) => ( 
              <Form className="font-14">
                <div className='input-form-wrapper font-14'>
                  <label>Old password</label>
                  <div className="position-relative">
                    <input
                      name="old_password"
                      type={showPasswordlistdata3 ? 'text' : 'password'}
                      onChange={handleChange}
                      value={values.old_password}
                      className={
                        "form-text form-control text-field" +
                        (errors.old_password && touched.old_password
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                        name="old_password"
                        component="div"
                        className="invalid-feedback red"
                      />
                    <Button
                      // className={styles.menolistlogo22}
                      onClick={handleClickShowPassword3}
                      onMouseDown={handleMouseDownPassword}
                      className="viewbbtn"
                    >
                      {showPasswordlistdata3 ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </Button>
                  </div>

                  


                </div>

                <div className='input-form-wrapper'>
                  <label>New password</label>
                  <div className="position-relative">
                    <input 
                    name="password"
                    type={showPasswordlistdata ? 'text' : 'password'} 
                     onChange={handleChange}
                    value={values.password}
                    className={
                      "form-text form-control text-field" +
                      (errors.password && touched.password
                        ? " is-invalid"
                        : "")
                    }
                    />
                    <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback red"
                      />

                    <Button
                      // className={styles.menolistlogo22}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      className="viewbbtn"
                    >
                      {showPasswordlistdata ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </Button>
                  </div>
                </div>
                <div className='input-form-wrapper'>
                  <label>Confirm password</label>
                  <div className="position-relative">
                    <input
                    name="confirm_password"
                    type={showPasswordlistdata2 ? 'text' : 'password'} 
                    onChange={handleChange}
                    value={values.confirm_password}
                    className={
                      "form-text form-control text-field" +
                      (errors.confirm_password && touched.confirm_password
                        ? " is-invalid"
                        : "")
                    } 
                    />
                    <ErrorMessage
                        name="confirm_password"
                        component="div"
                        className="invalid-feedback red"
                      />
                    <Button
                      // className={styles.menolistlogo22}
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword}
                      className="viewbbtn"
                    >
                      {showPasswordlistdata2 ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </Button>
                  </div>
                </div>
                <Button className='mt-3 complete-profile__btn2 text-capitalize' type="submit" >
                { (loading == true ) && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Change password</Button>
              </Form>
         )} 
           </Formik>  


        </div>


      </div>
{/* </ThemeProvider> */}
        </>
    )
}
import React, { useState, useEffect } from 'react';
import "../Settings.scss";
import { UserDetail } from '../UserDetails';
import { getUser } from "../../../../../_helpers";
import { Comingsoon } from '../components/Profile/components/Comingsoon';
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

export const Users = () => {
    const [userDetail, setUserDetail] = useState(false);
    const [comingsoon, setcomingsoon] = useState(false);
    const [users, setUsers] = useState<any>(getUser);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const id = searchParams.get('id');
        const Invite_id = searchParams.get('Invite_id');
        if (id == '1') {
            setUserDetail(true)
        } else if (Invite_id == '2') {
            setcomingsoon(true)
        }
    }, [searchParams]);
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
   
    return (
        <>
            <ThemeProvider theme={theme}>
                <div className='accoun-management'>
                    <div className='accoun-management__header'>
                        Team
                    </div>
                    {userDetail === true ? (
                        <UserDetail />
                    ) : (
                        <Comingsoon
                                    onCancel={() => {

                                        setcomingsoon(false);
                                    }}
                                />
                                    )}

                </div>
            </ThemeProvider>
        </>
    )
}


import { Box, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DefaultBtn } from '../../../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { CustomInput } from '../../../../../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import PhoneInput from 'react-phone-input-2'
import "./mpesa.scss"
import { InputNumber } from '../../../../../../../../../../../ui/input'
import { PayBilComponent, SendMoneyComponent, TillNumberComponent } from './methoadCom'
import { SkeletonSelectBox } from '../../../../../../../../../Skeleton/Skeleton'
import { ErrorMessage } from '../../../../../../../../../../../ui/eroor'

export const MpesaComponent = ({ MethoadTitel, methodError, loaderReander, countrySortcode, setPayOutMethodsByCountryId, PayOutMethodsByCountryId, mobilePaymentmethoad, currency, formik, setActiveButton, activeButton, mobileMoneyMethods }: any) => {
    const [renderPage, setRenderPage] = useState(true)
    const [index, setIndex] = useState(0)
    // const[render,setRender]=useState(true)
    const handleButtonClick = (buttonName: any, indexloop: any) => {
        setActiveButton(buttonName);
        setIndex(indexloop)
    };
    console.log(activeButton, "activeButton");

    useEffect(() => {
        if (renderPage && PayOutMethodsByCountryId.length != 0) {
            var Indexloop = []
            var Title=''
            for (let index = 0; index < PayOutMethodsByCountryId.length; index++) {
                const element = PayOutMethodsByCountryId[index];
                console.log(element, element.payment_method, "element");

                if (element.payment_method == "Mobile Money") {
                    Indexloop.push(index);
                }
                Title=element.title
            }
            setActiveButton(Title)
            console.log(Indexloop[0], Indexloop, "Indexloop");
            setIndex(Indexloop[0])
            setRenderPage(false)
        }
    }, [PayOutMethodsByCountryId, renderPage])
    // useEffect(() => {
    //     if () {

    //     }
    // }, [])
    return (
        <Box sx={{ paddingTop: "15px" }}>
            <Box className="d-flex justify-content-start align-items-center flex-wrap gap-2 mb-5">
                {PayOutMethodsByCountryId.map((item: any, index: number) => {

                    return (
                        <>
                            {item.payment_method === "Mobile Money" ?
                                <Button
                                    className={`mpesa_btn ${activeButton === item.title ? 'active-btn' : index === 0 && !mobileMoneyMethods?.find((c: any) => c.title === activeButton) ? "active-btn" : ""}`}
                                    onClick={() => handleButtonClick(item.title, index)}
                                >
                                    {item.title}
                                </Button> : ""}
                        </>

                    )
                })}

            </Box>
            <SkeletonSelectBox
                width={"100%"}
                height={'100px'}
                loader={loaderReander}
                // style={{ textAlign: "center", margin: '10px 0px' }}
                element={
                    <>
                        {activeButton == "Till Number" ?

                            <TillNumberComponent PayOutMethodsByCountryId={PayOutMethodsByCountryId} setPayOutMethodsByCountryId={setPayOutMethodsByCountryId} updatedIndex={index} />
                            : activeButton == "Paybill" ?
                                <PayBilComponent PayOutMethodsByCountryId={PayOutMethodsByCountryId} setPayOutMethodsByCountryId={setPayOutMethodsByCountryId} updatedIndex={index} /> :
                                <SendMoneyComponent countrySortcode={countrySortcode} PayOutMethodsByCountryId={PayOutMethodsByCountryId} setPayOutMethodsByCountryId={setPayOutMethodsByCountryId} updatedIndex={index} activeButton={activeButton} currency={currency} />}
                    </>
                }></SkeletonSelectBox>
            {/* <Typography style={{ marginTop: "10px",marginBottom:"10px" }}> */}
                {!!methodError && activeButton == MethoadTitel ? <ErrorMessage style={{marginTop: "10px",marginBottom:"10px"}} >{methodError}</ErrorMessage> : ""}
            {/* </Typography> */}
        </Box>
    )
}


import PaymentFailed from "../../../../../../../../../ui/ReuseableComponents/Notifications/FailedPayment/PaymentFailed"
import PaymentScheduled from "../../../../../../../../../ui/ReuseableComponents/Notifications/PaymentScheduled/PaymentScheduled"
import ProgressComponent from "../../../../../../../../../ui/ReuseableComponents/Notifications/Progress/Progress"
import { useNavigate } from "react-router-dom";


export const Setp4 = ({ amount, currancy, payee, payment_method, onClick, statusLoader , formik }: any) => {
    const navigate = useNavigate();

    const Sussaus=()=>{
        navigate(`/balance?currency=${currancy}`);
    }
    return (
        <>
            {statusLoader == "pending" ?
                <ProgressComponent
                    text="Your transaction is being processed. Please do not navigate away from this screen" /> :
                statusLoader == "success" ?
                    <PaymentScheduled
                        methodTitle={formik.method_title}
                        amount={`${currancy} ${amount}`}
                        payee={payee}
                        payment_method={payment_method}
                        formik={formik}
                        buttonText="Close"
                        onClick={() => {Sussaus() }} />
                    : statusLoader == "failed" ?
                        <PaymentFailed
                            heading="Transaction failed"
                            text="We apologise as we were not able to process your payment request. Please try again later."
                            buttonText="Close"
                            onClick={() => { window.location.href = '/balance' }} /> : ""}

        </>
    )
}
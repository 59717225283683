import './Request_Payment_tebal.scss';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, Grid, IconButton, Typography, Select, Checkbox } from '@mui/material';
// import styles from './setp4.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from 'react-tooltip';
import { WhatsappShareButton, EmailShareButton } from 'react-share';
import { TebalNoData } from '../../../Skeleton/TebalNoData';
import { authHeader, getUser, isUserAuthenticated } from '../../../../../../_helpers';
import axios from 'axios';
import DatePickerll from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { counterpartsActions } from '../../../../../../_actions/counterparts.actions';
import { selectAllCounterpartsListData } from '../../../../../../_reducers/counterparts.reducer';
import {
    selectAllData,
} from '../../../../../../_reducers/dashboard.reducer';
import { dashboardActions, payablesFlowactions } from '../../../../../../_actions';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import EmailShareButton from './EmailButton';
const headCells = [
    {
        id: 'transaction_date',
        numeric: false,
        disablePadding: true,
        label: 'Date',
    },
    {
        id: 'reference',
        numeric: false,
        disablePadding: true,
        label: 'Payment reference',
    },
    {
        id: 'Type',
        numeric: false,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'beneficiary',
        numeric: true,
        disablePadding: false,
        label: 'Payee',
    },
    {
        id: 'category',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Description ',
    },
    {
        id: 'transaction_amount',
        numeric: false,
        disablePadding: false,
        label: 'Transaction amount',
    },
    {
        id: 'transaction_status',
        numeric: false,
        disablePadding: false,
        label: 'Transaction Status',
    },
    {
        id: 'balance',
        numeric: false,
        disablePadding: false,
        label: 'Closing balance',
    },
    {
        id: 'more_info',
        numeric: false,
        disablePadding: false,
        label: 'More Info',
    },
];

const Request_PaymentTebal = () => {
    // const rows = data ? data : [];
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElWhatsApp, setAnchorElWhatsApp] = React.useState(null);
    const [ideditdata, setIdeditdata] = React.useState('')
    const [short_code, setShort_code] = React.useState("")
    const [shareLink, setSreeaLink] = useState('')
    const [categ, setCateg] = React.useState('');
    const [loderinapi, setLoderapi] = React.useState(false);
    const [datatabal, setDatatebal] = React.useState([
    ]);
    const [search, setSearch] = React.useState('');
    const [totalCount, setTotalCount] = React.useState(10);
    const [tagsList, setTagsList] = React.useState([]);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [pageneshn, setPagelist] = React.useState(1);
    const [remove, setRemovedata] = React.useState(10);
    const [dense, setDense] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [delete_data, setDelete_data] = React.useState('')
    const [Redusar, setRedusar] = useState(true)
    const [loading, setLoading] = useState(false)
    const [ListdoupDoun, setListdoupDoun] = useState([])
    const [listRender, setListRender] = useState(false)
    const [customer_name, setCustomer_name] = useState("")
    const [Detels, setDetels] = React.useState();
    const [selectCurrencyPopup, setSelectCurrencyPopup] = useState('KES')
    const [currencyAndMethodData, setCurrencyAndMethodData] = useState([]);
    const [status, setStatus] = useState('')
    const [email, setEmail] = useState("")
    const [name, setName] = useState('')
    const [path, setPath] = useState("")
    const [bulkDeleteData, setBulkDeleteData] = React.useState([]);
    const [showModal, setShowModal] = useState(false);
    const handleClosepopup = () => setShowModal(false);
    const [rows, setrows] = useState([])
    const [selectData, setSelecetData] = useState([])

    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const openWhatsApp = Boolean(anchorElWhatsApp)
    const navigate = useNavigate();
    const user = getUser()?.data ?? null;

    const counterpartsListData = useSelector((state) =>
        selectAllCounterpartsListData(state)
    );
    const data = useSelector((state) => selectAllData(state));

    const handleClick_datat = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose_twoo = () => {
        setAnchorElWhatsApp(null);
    };
    const handleClickWhatsApp = (event) => {
        setAnchorElWhatsApp(event.currentTarget);
    };
    function doneTyping() {
        var serdata = {
            search: search,
            pagedata: 1,
        };
        setPagelist(1)
        setRemovedata(10)
        Request_PaymentList(search, customer_name, endDate, 1, selectCurrencyPopup);
    }
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            doneTyping();
  
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [search]);
    React.useEffect(() => {
        const stateDateApi = !!endDate ? moment(endDate).format('YYYY-MM-DD') : ""

        Request_PaymentList(search, customer_name, stateDateApi, pageneshn, selectCurrencyPopup);
    }, [pageneshn]);
    const Request_PaymentList = async (search, customer_name, date, pageneshn, selectCurrencyPopup) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...authHeader() },
        };
        const stateDateApi = !!date
            ? moment(date).format('YYYY-MM-DD')
            : '';
        // const endDateApi = !!endDate
        //     ? moment(endDate).format('YYYY-MM-DD')
        //     : '';
        const category = !!categ ? categ : '';

        var pagelimit = 10;
        setLoderapi(true);
        const response = await axios
            .get(
                `${process.env.REACT_APP_API_URL}/${'payment-request/get-list'}?search=${search
                }&customer_name=${customer_name}&currency=${selectCurrencyPopup}&date=${stateDateApi}&page=${pageneshn
                }&limit=${pagelimit}`,
                requestOptions
            )
            .then((response) => {
                if (response.data.success == true) {
                    setrows(response.data.data.data);
                    setTotalCount(response.data.data.total);
                    const newSelected = []

                    for (let index = 0; index < response.data.data.data.length; index++) {
                        const element = response.data.data.data[index];
                        if (element.status == "paid") {

                        } else {
                            newSelected.push(element.id)
                        }
                    }
                    setSelecetData(newSelected)
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 2000);
                } else {
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 6000);
                }
            });
    };

    useEffect(() => {
        if (isUserAuthenticated() && Redusar) {
            const stateDateApi = !!endDate ? moment(endDate).format('YYYY-MM-DD') : ""
            const endDateApi = !!endDate
                ? moment(endDate).format('YYYY-MM-DD')
                : '';
            Request_PaymentList(search, customer_name, stateDateApi, pageneshn, selectCurrencyPopup)
            dispatch(counterpartsActions.getCustomerDropDoun());
            setTimeout(() => {
                setListRender(true)
            }, 4000)
        }
    }, [Redusar, isUserAuthenticated])
    useEffect(() => {
        dispatch(dashboardActions.getDashboardCurrencyData())
    }, [dispatch])
    const handleSelectAllClick = (event) => {
      

        if (event.target.checked) {
            const newSelected = []
            for (let index = 0; index < rows.length; index++) {
                const element = rows[index];
                if (element.status == "paid" || element.status == "canceled") {

                } else {
                    newSelected.push(element.id)
                }
            }
        

            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }

    };
    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (moniteId, name) => {

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        let newBulkDelete = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        const bulkDeleteIndex = bulkDeleteData.indexOf(moniteId);


        setSelected(newSelected);

    };
    useEffect(() => {
        if (data && data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS !== undefined) {
            setCurrencyAndMethodData(data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS.data);
        }
    }, [data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS])
    useEffect(() => {
        if (counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS !== undefined && listRender) {
            setListRender(false)
            setListdoupDoun(counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS)
        }
    }, [listRender, counterpartsListData])
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const deleteData = () => {
        const Recallapi = () => {
            const stateDateApi = !!endDate ? moment(endDate).format('YYYY-MM-DD') : "";
            Request_PaymentList(search, customer_name, stateDateApi, pageneshn, selectCurrencyPopup)
            setSelected([])
        }
        const body = {
            ids: selected
        }
        dispatch(payablesFlowactions.PostPayment('/payment-request/bulk-deactivate-payment-request', body, Recallapi));
    }
    const deleteselect = () => {
        const Recallapi = () => {
            const stateDateApi = !!endDate ? moment(endDate).format('YYYY-MM-DD') : "";
            Request_PaymentList(search, customer_name, stateDateApi, pageneshn, selectCurrencyPopup)
            setSelected([])
        }
        dispatch(payablesFlowactions.getPaymentdelete(`/payment-request/deactivate-payment-request/${ideditdata}`, Recallapi));

        // dispatch(payablesFlowactions.PostPayment('/payment-request/deactivate-payment-request',body,Recallapi));

    }
    return (
        <>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/* <MenuItem
                    className='menuitem'
                    onClick={() => {
                        navigate("/settings/CeretePaymentLink?short_code=" + short_code, { replace: true, state: { short_code: short_code } });
                    }}
                >Deactivate</MenuItem> */}
                <MenuItem onClick={() => {
                    deleteselect()
                    handleClose()
                }} className='menuitem' style={{ color: "#F87161" }}>Deactivate
                </MenuItem>
            </Menu>
            <Menu
                id="basic-menu"
                anchorEl={anchorElWhatsApp}
                open={openWhatsApp}
                onClose={handleClose_twoo}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{ "& .MuiMenu-list": { padding: "0px" } }}
            >
                <div style={{ padding: "5px" }}>
                    <div style={{ height: "27px" }}>
                        <WhatsappShareButton
                            url={shareLink}
                            onClick={handleClose_twoo}
                        >
                            Send via WhatsApp
                        </WhatsappShareButton>
                    </div>
                    <div style={{ height: "27px" }}>
                        <EmailShareButton
                            url={shareLink}
                            body={`${process.env.REACT_APP_API_URL}/get-transaction-pdf-link?hash=${shareLink}${'\n'}`}
                        >
                            Send via Email

                        </EmailShareButton>

                        {/* <a
                            href={`mailto:${email}?subject=${encodeURIComponent(shareLink)}&body=${encodeURIComponent(body)}`}
                        > Send via Email
                        </a>
                        <EmailShareButton shareLink={shareLink} /> */}

                    </div>
                </div>
            </Menu>
            <Grid container columnGap={1.9} className='Request_PaymentTebalGrid'>

                <Grid item md={2.5} sm={4} xs={12}>
                    <div
                        className="conteanaha_data_ketete"
                    >
                        <InputGroup className="balance__filter-wrapper input">
                            <InputGroup.Text id="basic-addon1" className="icon">
                                <SearchIcon />
                            </InputGroup.Text>
                            <Form.Control
                                className="balance-search-input"
                                placeholder="Search by name or Invoice no."
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                disabled={loderinapi}
                                value={search}
                                onChange={(event) => {
                               
                                    if (event.target.value == '') {
                                        setSearch(event.target.value);
                                    } else {
                                        setSearch(event.target.value);
                                    }
                                }}
                            />
                        </InputGroup>
                    </div>
                </Grid>
                <Grid item md={2.5} sm={4} xs={12}>
                    <div className=" balance_cat">
                        <Form.Select
                            onChange={(e) => {
                                setCustomer_name(e.target.value);
                                Request_PaymentList(search, e.target.value, endDate, pageneshn, selectCurrencyPopup);
                            }}
                            value={customer_name}
                        >
                            <option selected value="">
                                All customers
                            </option>
                            {ListdoupDoun?.map(
                                (arrayElement, index) => {
                                    return (
                                        <option
                                            value={arrayElement.name}
                                            key={index + 1}
                                        >
                                            {arrayElement.name}
                                        </option>
                                    );
                                }
                            )}
                        </Form.Select>
                    </div>
                </Grid>

                <Grid item md={2.5} sm={4} xs={12}>
                    <div
                        id="select_up_div"
                        className=" balance_cat"
                    >
                        <DatePickerll
                            placeholderText="Due date"
                            dateFormat="dd MMMM yyyy"
                            className="dashboard-container__datepicker"
                            selected={endDate}
                            onChange={(date) => {
                                //@ts-ignore
                                setEndDate(date);
                                const endDateApi = !!date
                                    ? moment(date).format('YYYY-MM-DD')
                                    : '';
                                const stateDateApi = !!date ? moment(date).format('YYYY-MM-DD') : ""
                                Request_PaymentList(search, customer_name, stateDateApi, pageneshn, selectCurrencyPopup)
                               
                            }}
                            selectsEnd
                        />
                    </div>

                </Grid>
                <Grid item md={2.9} sm={4} xs={12}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="selectBox"
                        value={selectCurrencyPopup}
                        // sx={{
                        //   p: {
                        //     display: "none"
                        //   }
                        // }}
                        onChange={(e) => {
                            setSelectCurrencyPopup(e.target.value)
                            Request_PaymentList(search, customer_name, endDate, pageneshn, e.target.value);

                        }}
                    >
                        {Array.isArray(currencyAndMethodData) && currencyAndMethodData?.map((item, index) => {
                            return (
                                <MenuItem
                                    value={item.currency}
                                    key={index}>
                                    <Box className="MenuItemBox" >
                                        <img
                                            loading="lazy"
                                            width="25"
                                            src={item.flag}
                                            alt=""
                                        />
                                        <p>
                                            {item.currency}
                                        </p>
                                    </Box>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Grid>
                <Grid item md={12} sm={12} xs={12} paddingTop={"50px"}>
                <TableContainer className='TableContainerdata'>
                    <Table
                        className='tebal_class_contean'
                    >
                        <thead>
                            <tr>
                                <TableCell style={{ borderBottom: "0px solid" }} padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        icon={<img src="/Checkbox.svg" />}
                                        checkedIcon={<img src="/Checkbox_two.svg" />}
                                        indeterminate={selected.length > 0 && selected.length < selectData.length}
                                        checked={selectData.length > 0 && selected.length === selectData.length}
                                        onChange={handleSelectAllClick}
                                        inputProps={{
                                            'aria-label': 'select all desserts',
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ width: "30%" }} className='LinksTableCell tabs_payment_link_cell'>
                                    Payment Link
                                </TableCell>
                                <TableCell className='LinksTableCell tabs_table_cell'>
                                    Status
                                </TableCell>
                                <TableCell className='LinksTableCell tabs_table_cell'>

                                    Create Date
                                </TableCell>
                                <TableCell className='LinksTableCell tabs_table_cell'>

                                    Last Updated
                                </TableCell>
                                <TableCell className='LinksTableCell tabs_md_cell' >
                                    Invoice number
                                </TableCell>
                                <TableCell className='LinksTableCell tabs_table_cell'>

                                    Link Amount
                                </TableCell>
                                {/* <TableCell className={styles.LinksTableCell}>
                                Payments Collected
                            </TableCell> */}

                                <TableCell className='LinksTableCell tabs_table_cell'>
                                </TableCell>
                            </tr>
                        </thead>
                        <tbody>

                            {rows
                                .map((element, index) => {
                                    const isItemSelected = isSelected(element.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow key={element.id} >
                                            <TableCell style={{ borderBottom: "0px solid" }} padding="checkbox">
                                                <Checkbox
                                                    icon={<img src="/Checkbox.svg" />}
                                                    disabled={element.status == "paid" ? true : false}
                                                    checkedIcon={<img src="/Checkbox_two.svg" />}
                                                    onClick={(event) => handleClick(element.id, element.id)}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell className='TableCell_roww'>
                                                <Box className={'Typography_Box'}>
                                                    <Typography style={{ cursor: "pointer" }} onClick={() => {
                                                        // navigate("/settings/Details_payment_Link?id=" +
                                                        //     element.id, { replace: true, state: { id: element.id } });
                                                    }}
                                                    >
                                                        {element.link}
                                                    </Typography>
                                                  
                                                    <div>
                                                        <Button onClick={(e) => {
                                                            setSreeaLink(element.link)
                                                            handleClickWhatsApp(e)
                                                        }} style={{ marginRight: "10px" }} className={'button_ContentCopyIcon'}>
                                                            <ShareIcon />
                                                        </Button>
                                                        <CopyToClipboard text={element.link}>
                                                            <Button data-tooltip-id="my-tooltip-click" className={'button_ContentCopyIcon'}>
                                                                <ContentCopyIcon />
                                                            </Button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </Box>
                                            </TableCell>
                                            <TableCell className={'TableCell_roww'}>
                                                <Typography className={element.status == "inactive" ? 'inactiveTypography' : 'TypographyStatus'}>
                                                    {element.status == "active" ? "Active" : element.status == "inactive" ? "Inactive" : element.status == "paid" ? "Paid" : element.status == "canceled" ? "Canceled" : ""}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={'TableCell_roww'}>
                                                <Typography className={'TypographyUpdated_at'}>
                                                    {moment(element.created_at).format("DD-MMM-YYYY")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={'TableCell_roww'}>
                                                <Typography className={'TypographyUpdated_at'}>
                                                    {moment(element.updated_at).format("DD-MMM-YYYY")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={'TableCell_roww'}>
                                                {element.invoices.map((item, index) => {
                                                    return (
                                                        <Typography  className={'TypographyUpdated_at'}>
                                                            {item.invoice_no}
                                                        </Typography>
                                                    )
                                                })}

                                            </TableCell>

                                            <TableCell className={'TableCell_roww'}>
                                                {element.amount == 0 ?
                                                    <Typography className={'TypographyUpdated_at'}>
                                                        -
                                                    </Typography>
                                                    : <Typography className={'TypographyUpdated_at'}>

                                                        {element.currency}{" "}{element.amount}
                                                    </Typography>}
                                            </TableCell>
                                            {/* <TableCell className={styles.TableCell_roww}>
                                            <Typography className={styles.TypographyUpdated_at}>
                                                {element.total_transactions}
                                            </Typography>
                                        </TableCell> */}
                                            <TableCell sx={{ width: "5%" }} className={'TableCell_roww'}>
                                                <IconButton disabled={element.status == "inactive" ? true : false} onClick={(e) => {
                                                    if (element.status == "paid" || element.status == "canceled") {

                                                    } else {
                                                        handleClick_datat(e)
                                                        setIdeditdata(element.id)
                                                    }
                                                }}>
                                                    <MoreVertIcon />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {rows?.length === 0 && (
                                <TableCell colSpan={7}>
                                    <TebalNoData text={'No Payment Request found'} />
                                </TableCell>
                            )}


                        </tbody>
                    </Table>
                </TableContainer>
                </Grid>
                <div style={{ paddingTop: '10px', justifyContent: "space-between", display: "flex", width: "100%" }} className="addnewbtnclass">
                    {user.role_name != "Auditor" || user.role_name != 'Associate' ?
                        <Button
                            style={{ padding: "12px 16px" }}
                            disabled={selected.length > 0 ? false : true}
                            onClick={() => deleteData()}
                            color="#F87168"
                            className='white_btn_two'
                        >
                            Delete All
                        </Button> : <div></div>}
                    <div className="addnewbtnclass">
                        <div className="inpagenum">
                            <p>
                                {remove - 9}-{remove}
                            </p>
                        </div>
                        <div className="btnmaindivin">
                            {pageneshn == 1 ? (
                                <button disabled className="nextntmistish">
                                    <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                </button>
                            ) : (
                                <button
                                    className="nextntmistish"
                                    onClick={() => {
                                        setPagelist(pageneshn - 1);
                                        setRemovedata(remove - 10);
                                    }}
                                >
                                    <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                </button>
                            )}
                            {remove > totalCount ? (
                                <button disabled className="nextntmistish">
                                    <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                </button>
                            ) : (
                                <button
                                    className="nextntmistish"
                                    onClick={() => {
                                        setPagelist(pageneshn + 1);
                                        setRemovedata(remove + 10);
                                    }}
                                >
                                    <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </Grid>
            <Tooltip
                id="my-tooltip-click"
                content="link has been copied"
                events={['click']}
            />
        </>

    )
}
export default Request_PaymentTebal;
const SearchIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
            stroke="#111111"
            stroke-width="1.1875"
            stroke-miterlimit="1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
// import { Box, Typography } from "@mui/material"
import "./step1.scss"
import { DefaultBtn } from "../../../../../../../ui/ReuseableComponents/Buttons/Buttons"
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import styles from './csvlist.module.scss'
import { Button } from "react-bootstrap";
import { selectAllCounterpartsListData } from "../../../../../../../../_reducers/counterparts.reducer";
import { counterpartsActions } from "../../../../../../../../_actions/counterparts.actions";
import { getUser, isUserAuthenticated } from "../../../../../../../../_helpers";
import { paymentJourneyActions } from "../../../../../../../../_actions/paymentJourney.actions";
import { selectPaymentJourneyData } from "../../../../../../../../_reducers/paymentJourney.reducer";
import { selectAllPayablesListData } from "../../../../../../../../_reducers/payables.reducer";
import { selectAllData } from "../../../../../../../../_reducers/dashboard.reducer";


const VisaCardIcon = () => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 14V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H15C15.5304 18 16.0391 17.7893 16.4142 17.4142C16.7893 17.0391 17 16.5304 17 16V14M4 6L9 1L14 6M9 1V13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);
const DownloadIcon = () => (
    <svg width="20" height="21" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 11.4412V12.9706C1.5 13.3762 1.65804 13.7652 1.93934 14.052C2.22064 14.3389 2.60218 14.5 3 14.5H12C12.3978 14.5 12.7794 14.3389 13.0607 14.052C13.342 13.7652 13.5 13.3762 13.5 12.9706V11.4412M3.75 6.85294L7.5 10.6765M7.5 10.6765L11.25 6.85294M7.5 10.6765V1.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

);
export class HttpClient {
    static get(): Promise<string> {
        return Promise.resolve(
            `Invoice Number*,Payee Name*,Invoice Date*,Due Date*,Amount*,Category*,Description\nNIO-001,Please make sure the Payee name entered here exactly matches the name of the Beneficiary in the Niobi App,2005-04-13,2005-04-13,Enter amount in KES,Rent,Enter description`
        );
    }
}

type MyType = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    amount: number;
    description: any;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    account_number: string;
};
type MyTypesecendobject = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    account_number: any;
    amount: number;
    description: any;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    status: boolean;
}
type MyTypeThreeobj = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    account_number: any;
    amount: number;
    description: any;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    status: boolean;
}
type MyTypeForeobj = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    account_number: any;
    paybill_number: any;
    amount: number;
    description: any;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    status: boolean;

}
type MyTypeFiweobj = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    account_number: any;
    description: any;
    bank_name: any;
    account_name: any;
    amount: number;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    status: boolean;
};
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'transaction_date';
const DEFAULT_ROWS_PER_PAGE = 5;
interface EnhancedTableToolbarProps {
    numSelected: number;
}

const Step1 = ({ payables, formik, setFormik, stepupdatefun, }: any) => {

    const [datatebal, setDatatebal] = React.useState([])
    const [filllist, setFile] = useState<{ name: string } | string>('');
    const counterpartsListData = useSelector((state) => selectAllCounterpartsListData(state))
    const [counterpartsList, setCounterpartsList] = React.useState<any>([]);
    const [counterpartsListRender, setCounterpartsListRender] = useState(false);
    const [tagsList, setTagsList] = useState<any>([]);
    const [tagsListRender, setTagsListRender] = useState(true);
    const [bankListRender, setBankListRender] = useState<any>(true);
    const [render, setRender] = useState(true);
    const [isUsers, isSetUsers] = useState<any>(true);
    const [payablesListRender, setPayablesListRender] = useState(false);
    const [payablesListStastsRender, setPayablesListStastRender] = useState(false);
    const [bankList, setBankList] = useState<any>([]);
    const payablesListData = useSelector((state) => selectAllPayablesListData(state));
    const [selectedMethod, setSelectedMethod] = useState('');
    const dispatch = useDispatch()
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const data = useSelector((state) => selectAllData(state));



    const [patternDataArray_validesan, setPatternDataArray_validesan] = React.useState<any>([
        {
            id: '',
            // ISCHECK: false,
            counterpart_name: "",
            supplier_id: '',
            payment_method: "",
            account_number: '',
            paybill_number: '',
            bank_name: "",
            bank_branch: "",
            amount: '',
            account_name: '',
            categories: [],
            is_current_price: false,
            description: '',
            status: true,
            methoadSelect: [],
            tags: [],
            teams: [],
            vat: "",


        }
    ])
    const [balanceRender, setBalanceRender] = useState(false);


    useEffect(() => {
        if (isUsers) {
            isSetUsers(false);
        }
    });


    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(paymentJourneyActions.getPesaBankList() as any)
            // dispatch(dashboardActions.getBalance("/wallet/get-balance", setBalanceRender, setBalanceloader) as any);
        }
    }, [render, isUserAuthenticated]);


    useEffect(() => {
        if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
            setBalanceRender(false)
        }
    }, [data, balanceRender]);
    useEffect(() => {
        if (bankListRender && dataList?.GETPESABANKLIST_SUCCESS !== undefined) {
            setBankListRender(false);
            setBankList(dataList.GETPESABANKLIST_SUCCESS);
        }
    }, [dataList, bankListRender]);
    useEffect(() => {
        if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
            let options: any = [];
            if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
                counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
                    options.push({
                        id: item.id,
                        name: item.name
                    })
                })
            }
            setTagsListRender(false);
            setTagsList(options);
        }
    }, [counterpartsListData, tagsListRender]);
    useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(counterpartsActions.getAllCounterparts(setCounterpartsListRender) as any)
            dispatch(counterpartsActions.getAllTags() as any)
        }
    }, [render, isUserAuthenticated])


    useEffect(() => {
        if (counterpartsListRender && counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined) {


            setCounterpartsListRender(false);
            setCounterpartsList(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
        }
    }, [counterpartsListRender]);


    const handleCSVUpload = async (event: any) => {
        if (event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e: any) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData: any = XLSX.utils.sheet_to_json(sheet);
                const listtebal: any = localStorage.getItem("Bulk_Payment")
                const bulkdata = JSON.parse(listtebal)
                xlsx_data(jsonData)
            };

            reader.readAsArrayBuffer(file);
        } else {
            toast.error("Please upload file in .xlsx format only, CSV files are not supported. Thank you!")
        }
    }
    const xlsx_data = (data: any) => {
        if (!!data) {
            setDatatebal(data)
            var newData = [];
            var data_listt = [];
            let totel_amount_data = 0;
            for (let index = 0; index < data.length; index++) {
                var element = data[index];
                var payeename = '';
                const find_name = bankList.find(function (entry: any) { return entry.bank_name === element['Bank Name']; });
                const findresults: any = counterpartsList.find((item: any) => {
                    return item.counterpart_name == element['Payee Name*'];
                });
                const findresultsCat: any = tagsList.find(function (item: any) {
                    const catName = item.name
                    const catSearc = element['Category*']
                    return catName.search(catSearc) != -1 ? true : false;
                });
                const findresults_fastname: any = counterpartsList.find(function (arrayElement: any) {
                    payeename = arrayElement.counterpart_type == 'individual' ? arrayElement.contact_first_name + ' ' + arrayElement.contact_last_name : arrayElement.name

                    const payeeSearc = !!element['Payee Name*'] ? element['Payee Name*'].split(" ") : [];
                    var SearchResult = false;
                    for (let index = 0; index < payeeSearc.length; index++) {
                        const element1 = payeeSearc[index];
                        SearchResult = payeename.toLowerCase().search(element1.toLowerCase()) != -1 ? true : false;
                        if (SearchResult == true) {
                            break;
                        }

                    }
                    return SearchResult;
                });


                totel_amount_data += Number((element['Amount*'])?.toFixed(0))

                var object = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    account_number: "122000",
                }
                // M-Pesa Send Money
                var object_validesan = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - Send Money'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    status: true
                }
                //M-Pesa Till Number
                var object_validesan_two = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - Till Number'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    status: true
                }
                //M-Pesa PayBill
                var object_validesan_three = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - PayBill'],
                    paybill_number: element['Pay Bill Number'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    status: true
                }
                // Bank Transfers
                var object_validesan_fore = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - Bank'],
                    bank_name: !!find_name ? find_name.id : "",
                    account_name: element['Bank Account Name'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    status: true
                }
                if (element["Payment Method*"] == "M-Pesa Send Money") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan)))
                } else if (element["Payment Method*"] == "M-Pesa Till Number") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan_two)))
                } else if (element["Payment Method*"] == "M-Pesa PayBill") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan_three)))

                } else if (element["Payment Method*"] == "Bank Transfers") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan_fore)))
                }
                newData.push(JSON.parse(JSON.stringify(object)));
            }
            var concetdata = patternDataArray_validesan.concat(data_listt)
            let totalamount = 0

            var newData_two = []
            var concetdata_add = []
            for (let index = 0; index < concetdata.length; index++) {
                var element = concetdata[index];
                const find_name = bankList.find(function (entry: any) { return entry.id === element.bank_name; });
                var payeename = '';

                const findresults: any = counterpartsList.find((item: any) => {
                    return item.counterpart_name == element.counterpart_name;
                });
                const findresultsCat: any = tagsList.find(function (item: any) {
                    const catName = item.name
                    const catSearc = element.categories
                    return catName.search(catSearc) != -1 ? true : false;
                });
                totalamount += element.amount;
                var amount_number = Number(element.amount)
                var object: MyType = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    amount: amount_number,
                    categories: !!element?.categories ? element?.categories : [],
                    is_current_price: false,
                    description: element.description,
                    account_number: element.account_number
                }
                // M-Pesa Send Money
                var object_validesan: MyTypesecendobject = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    // Send many
                    account_number: element.account_number,
                    amount: amount_number,
                    description: element.description,
                    categories: element.categories,
                    is_current_price: false,
                    status: true
                }
                // M-Pesa Till Number
                var object_validesan_two: MyTypeThreeobj = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    account_number: element.account_number,
                    amount: amount_number,
                    categories: element.categories,
                    is_current_price: false,
                    description: element.description,
                    status: true
                }
                //M-Pesa PayBill
                var object_validesan_three: MyTypeForeobj = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    account_number: element.account_number,
                    paybill_number: element.paybill_number,
                    amount: amount_number,
                    categories: element.categories,
                    is_current_price: false,
                    description: element.description,
                    status: true,
                }
                // Bank Transfers
                var object_validesan_fore: MyTypeFiweobj = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    account_number: element.account_number,
                    bank_name: !!find_name ? find_name.id : "",
                    account_name: element.account_name,
                    amount: amount_number,
                    categories: element.categories,
                    is_current_price: false,
                    description: element.description,
                    status: true
                }
                if (element.payment_method == "M-Pesa Send Money") {
                    concetdata_add.push(JSON.parse(JSON.stringify(object_validesan)))
                } else if (element.payment_method == "M-Pesa Till Number") {
                    concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_two)))
                } else if (element.payment_method == "M-Pesa PayBill") {
                    concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_three)))

                } else if (element.payment_method == "Bank Transfers") {
                    concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_fore)))
                }
                newData_two.push(JSON.parse(JSON.stringify(object)));
            }
            // setPatternDataArray_validesan(...concetdata_add,ISCHECK: false,)
            setPatternDataArray_validesan(concetdata_add.map((item: any) => ({
                ...item,

                ISCHECK: false
            })));

        }
    }


    React.useEffect(() => {

        let rowsOnMount: any = stableSort(
            datatebal,
            getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
        );
        rowsOnMount = rowsOnMount.slice(
            0 * DEFAULT_ROWS_PER_PAGE,
            0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
        );

    }, [filllist, counterpartsList, tagsList]);

    useEffect(() => {
        let totel_amount_data = 0;
        for (let index = 0; index < patternDataArray_validesan.length; index++) {
            const element = patternDataArray_validesan[index];
            totel_amount_data += Number(element.amount);
        }
    }, [patternDataArray_validesan])

    useEffect(() => {
        if (payablesListStastsRender && dataList?.GET_BULKPAYMENT_SUCCESS !== undefined) {
            setPayablesListStastRender(false)
            if (!!dataList?.GET_BULKPAYMENT_SUCCESS?.data) {
                var concetdata_add = []
                var newData_two = []
                for (let index = 0; index < dataList?.GET_BULKPAYMENT_SUCCESS?.data.length; index++) {
                    const element = dataList?.GET_BULKPAYMENT_SUCCESS?.data[index];
                    var object: MyType = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        amount: element.amount,
                        categories: !!element?.categories ? element?.categories : [],
                        is_current_price: false,
                        description: element.description,
                        account_number: element.account_number,
                    }
                    // M-Pesa Send Money
                    var object_validesan: MyTypesecendobject = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        // Send many
                        account_number: element.account_number?.toString(),
                        amount: element.amount,
                        description: element.description,
                        categories: element.categories,
                        is_current_price: false,
                        status: element.status
                    }
                    //M-Pesa Till Number
                    var object_validesan_two: MyTypeThreeobj = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        // Send many
                        account_number: element.account_number?.toString(),
                        amount: element.amount,
                        categories: element.categories,
                        is_current_price: false,
                        description: element.description,
                        status: element.status
                    }
                    // M-Pesa PayBill
                    var object_validesan_three: MyTypeForeobj = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        // Send many
                        account_number: element.account_number?.toString(),
                        paybill_number: element.paybill_number,
                        amount: element.amount,
                        categories: element.categories,
                        is_current_price: false,
                        description: element.description,
                        status: element.status
                    }
                    // Bank Transfers
                    var object_validesan_fore: MyTypeFiweobj = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        // Send many
                        account_number: element.account_number?.toString(),
                        bank_name: element.bank_name,
                        account_name: element.account_name,
                        amount: element.amount,
                        categories: element.categories,
                        is_current_price: false,
                        description: element.description,
                        status: element.status
                    }
                    if (element.payment_method == "M-Pesa Send Money") {
                        concetdata_add.push(JSON.parse(JSON.stringify(object_validesan)))
                    } else if (element.payment_method == "M-Pesa Till Number") {
                        concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_two)))
                    } else if (element.payment_method == "M-Pesa PayBill") {
                        concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_three)))

                    } else if (element.payment_method == "Bank Transfers") {
                        concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_fore)))
                    }
                    newData_two.push(JSON.parse(JSON.stringify(object)));
                }
                // setPatternDataArray_validesan(concetdata_add)
                setPatternDataArray_validesan(concetdata_add.map((item: any) => ({
                    ...item,
                    ...patternDataArray_validesan,
                    ISCHECK: false,
                })));

            }


        }
    }, [dataList, payablesListStastsRender]);
    useEffect(() => {
        if (payablesListRender && payablesListData?.BULKPAYABLES_SUCCESS !== undefined) {
            setPayablesListRender(false);
            const paybleD = payablesListData?.BULKPAYABLES_SUCCESS.map((el: any) => {
                return el.id
            })
            const bodyData = {
                payables: paybleD
            }
            dispatch(counterpartsActions.submitPaybles(bodyData) as any)
        }
    }, [payablesListData, payablesListRender]);
    useEffect(() => {
        var totalAmount = [];
        for (let index = 0; index < patternDataArray_validesan.length; index++) {
            const element = patternDataArray_validesan[index];
            if (element.is_current_price == true) {
                totalAmount.push(element)
            }
        }
        let amountsubtotal = 0
        for (let index = 0; index < totalAmount.length; index++) {
            const element = totalAmount[index];
            amountsubtotal += +element.amount
        }

    }, [patternDataArray_validesan]);



    const handleContinue = () => {
        if (!filllist) {
            toast.error("No file selected. Please upload a file before proceeding.");
            return;
        }

        setFormik((prevFormik: any) => ({
            ...prevFormik,
            apiUpdatedBulk: patternDataArray_validesan,
        }));

        stepupdatefun(1);
    };


    return (
        <Box className="step1_container d-flex flex-column justify-content-center gap-4">
            <Box>
                <Typography className="heading">Step 1</Typography>
                <Typography className="sub_heading">Download template file</Typography>
                <div>
                    <a href='/Niobi Bulk Payment Template.xlsx' style={{ textDecoration: 'none' }}>
                        <Button className="uploadBtn">
                            Download
                            <div className="iconWrapper"><DownloadIcon /></div>
                        </Button>
                    </a>
                </div>
            </Box>
            <Box className="mb-1">
                <Typography className="heading">Step 2</Typography>
                <Typography className="sub_heading">Upload</Typography>

                <div>
                    <label htmlFor="inputTag" style={{ width: '100%' }}
                        id={filllist == null ? '' : ''}
                        className={`type-card${selectedMethod === 'mobile' ? 'active' : ''}`}>
                        <div className={styles.addonebtn}>
                            <Button onClick={() => document.getElementById("inputTag")?.click()} className="uploadBtn">

                                {typeof filllist === "object" && filllist?.name ? filllist.name : "Upload"}
                                <div className="iconWrapper"><VisaCardIcon /></div>
                            </Button>
                        </div>
                        <input id="inputTag" className={styles.addaffafagga} type="file"
                            onChange={(file: any) => {
                                handleCSVUpload(file)
                                setFile(file.target.files[0]);
                            }}
                            accept=".xlsx" />
                    </label>
                </div>
            </Box>
            <Box className="mt-5 mb-3">
                <DefaultBtn text="Continue" onClick={handleContinue} style={{ width: "100%", height: "50px" }} />
            </Box>
        </Box>
    )
}

export default Step1
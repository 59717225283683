import "./success.scss"
import { Box, Typography } from "@mui/material"

const SuccessComponent = ({ heading, text }: any) => {
  return (
    <Box className="transaction_success_box">
      <Box style={{ textAlign: "center", padding: "0px 0px 10px 0px"}}>
        <img
          width={75}
          height={75}
          src="/Niobi Loading Animation (1).gif"
          alt="Loading animation"
        />
      </Box>
      <Typography className="transaction_success_heading text-center">{heading}</Typography>
    </Box>
  )
}

export default SuccessComponent
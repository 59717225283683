import { counterpartsConstants } from "../_constants/counterparts.constants";

export function getUserCounterpartsReducer(state = {}, action: any) {
    switch (action.type) {
        case counterpartsConstants.GETUSERCOUNTERPART_FROM_STORE:
            return {
                ...state, // Spread the current state to maintain immutability
                loading: true
            };
        case counterpartsConstants.GETUSERCOUNTERPART_SUCCESS:
            return {
                ...state, // Spread the current state
                data: action.data // Replace `data` with a new object
            };
        case counterpartsConstants.GETUSERCOUNTERPART_FAILURE:
            return {
                ...state, // Spread the current state
                error: action.error // Replace `error` with a new object
            };
        default:
            return state;
    }
}

// export function getUserCounterpartsReducer(state={},action: any) {
//     switch (action.type) {
//         case counterpartsConstants.GETUSERCOUNTERPART_FROM_STORE:
//             return {
//                 loading: true
//             };
//         case counterpartsConstants.GETUSERCOUNTERPART_SUCCESS:
//             return {
//                 data: action.data
//             };
//         case counterpartsConstants.GETUSERCOUNTERPART_FAILURE:
//             return {
//                 error: action.error
//             };
//         default:
//             return state
//     }
// }
// export function getUserCounterpartsReducer(state = { data: null, loading: false, error: null }, action: any) {
//     switch(action.type) {
//         case counterpartsConstants.GETUSERCOUNTERPART_FROM_STORE:
//             return {
//                 ...state,
//                 loading: true,
//                 error: null  // Clear error on request
//             };
//         case counterpartsConstants.GETUSERCOUNTERPART_SUCCESS:
//          
//             return {
//                 ...state,
//                 data: action.data,
//                 loading: false,
//                 error: null  // Clear error on success
//             };
//         case counterpartsConstants.GETUSERCOUNTERPART_FAILURE:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.error
//             };
//         default:
//             return state;
//     }
// }
export function getCustomerByIdReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GET_CUSTOMER_BY_ID_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GET_CUSTOMER_BY_ID_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GET_CUSTOMER_BY_ID_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCustomerDetailsIdReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETCUSTOMER_DETAILS_ID_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GETCUSTOMER_DETAILS_ID_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GETCUSTOMER_DETAILS_ID_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCustomerReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GET_CUSTOMER_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GET_CUSTOMER_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GET_CUSTOMER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCustomerDropDounReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETCUSTOMER_DROPDOUN_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GETCUSTOMER_DROPDOUN_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GETCUSTOMER_DROPDOUN_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getbulk_payment_payables_Reducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETBULK_PAYMENT_PAYABLES_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GETBULK_PAYMENT_PAYABLES_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GETBULK_PAYMENT_PAYABLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCounterpartsListReducer(state = { data: null, loading: false, error: null }, action: any) {
    switch(action.type) {
        case counterpartsConstants.GETCOUNTERPARTS_FROM_STORE:
            return {
                ...state,
                loading: true,
                error: null  // Clear error on request
            };
        case counterpartsConstants.GETCOUNTERPARTS_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
                error: null  // Clear error on success
            };
        case counterpartsConstants.GETCOUNTERPARTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function getBillListReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETBILL_LIST_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GETBILL_LIST_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GETBILL_LIST_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getBillListStatusReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETBILL_STATUS_LIST_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GETBILL_STATUS_LIST_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GETBILL_STATUS_LIST_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCounterpartsList_DATAReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETCOUNTERPARTS_LIST_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GETCOUNTERPARTS_LIST_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.GETCOUNTERPARTS_LIST_FAILURE:
            return {
                error: action.error,
                loading: false
            };
        default:
            return state
    }
}
export function getEditCounterpartsListeducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETUSERCOUNTERPART_EDIT_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GETUSERCOUNTERPART_EDIT_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GETUSERCOUNTERPART_EDIT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getTagsListReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETTAGS_FROM_STORE:
            return {
                loading: true
            };
        case counterpartsConstants.GETTAGS_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GETTAGS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getVatReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETALLVAT_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GETALLVAT_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GETALLVAT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getTagsListbudgetReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GETTAGS_BUDGET_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GETTAGS_BUDGET_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GETTAGS_BUDGET_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function getPendingPayblesReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GET_PENDING_PAYABLES_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GET_PENDING_PAYABLES_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.GET_PENDING_PAYABLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}


export function updatePayblesReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.UPDATE_PAYABLES_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.UPDATE_PAYABLES_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.UPDATE_PAYABLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function updateCounterpartsReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.EDIT_COUNTERPARTS_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.EDIT_COUNTERPARTS_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.EDIT_COUNTERPARTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function updateInvoiceReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.EDIT_INVOICE_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.EDIT_INVOICE_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.EDIT_INVOICE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function updatebulk_paymentReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.UPDATE_BULK_PAYMENT_PAYABLES_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.UPDATE_BULK_PAYMENT_PAYABLES_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.UPDATE_BULK_PAYMENT_PAYABLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function rejectbulk_paymentReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.REJECT_BULK_PAYMENT_PAYABLES_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.REJECT_BULK_PAYMENT_PAYABLES_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.REJECT_BULK_PAYMENT_PAYABLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function submitPayblesReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.SUBMIT_PAYABLES_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.SUBMIT_PAYABLES_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.SUBMIT_PAYABLES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function ceretePayblesReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.CERETE_COUNTERPARTS_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.CERETE_COUNTERPARTS_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.CERETE_COUNTERPARTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function CustomersCreateReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.CUSTOMERS_CREATE_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.CUSTOMERS_CREATE_SUCCESS:
            return {
                data: action.data,
                loading: false
            };
        case counterpartsConstants.CUSTOMERS_CREATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function getAllCategoriesReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GET_COUNTERPARTS_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GET_COUNTERPARTS_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GET_COUNTERPARTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

  // get existing-products
export function getExistingProductReducer(state={},action: any) {
    switch (action.type) {
        case counterpartsConstants.GET_EXISTING_PRODUCT_REQUEST:
            return {
                loading: true
            };
        case counterpartsConstants.GET_EXISTING_PRODUCT_SUCCESS:
            return {
                data: action.data
            };
        case counterpartsConstants.GET_EXISTING_PRODUCT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}


export const selectAllCounterpartsListData = (state: any) => {
    console.log("selected",state)

    return  {
        'GET_EXISTING_PRODUCT_SUCCESS': state.getExistingProductReducer.data,
        'GETUSERCOUNTERPARTS_SUCCESS': state.getUserCounterpartsReducer.data,
        'GET_CUSTOMER_BY_ID_SUCCESS': state.getCustomerByIdReducer.data,
        'GETCUSTOMER_DETAILS_ID_SUCCESS': state.getCustomerDetailsIdReducer.data,

        'GET_CUSTOMER_BY_ID_FAILURE': state.getCustomerByIdReducer.error,
        'GET_CUSTOMER_SUCCESS': state.getCustomerReducer.data,
        'GETCUSTOMER_DROPDOUN_SUCCESS': state.getCustomerDropDounReducer.data,
        'GETBULK_PAYMENT_PAYABLES_SUCCESS': state.getbulk_payment_payables_Reducer.data,
        'GETCOUNTERPARTS_SUCCESS': state.getCounterpartsListReducer.data,
        'GETCOUNTERPARTS_LIST_REQUEST': state.getCounterpartsList_DATAReducer.loading,
        'GETCOUNTERPARTS_LIST_SUCCESS': state.getCounterpartsList_DATAReducer.data,
        'GETUSERCOUNTERPART_EDIT_SUCCESS': state.getEditCounterpartsListeducer.data,
        'GETTAGS_SUCCESS': state.getTagsListReducer.data,
        'GETALLVAT_SUCCESS': state.getVatReducer.data,
        "GETTAGS_BUDGET_SUCCESS":state.getTagsListbudgetReducer.data,
        'GET_PENDING_PAYABLES_REQUEST' : state.getPendingPayblesReducer.loading,
        'GET_PENDING_PAYABLES_SUCCESS' : state.getPendingPayblesReducer.data,
        'GET_PENDING_PAYABLES_FAILURE' : state.getPendingPayblesReducer.error,
        'GETBILL_LIST_SUCCESS' : state.getBillListReducer.data,
        'GETBILL_STATUS_LIST_SUCCESS' : state.getBillListStatusReducer.data,
        'GETBILL_LIST_FAILURE' : state.getBillListReducer.error,
        'UPDATE_PAYABLES_REQUEST' : state.updatePayblesReducer.loading,
        'UPDATE_PAYABLES_SUCCESS' : state.updatePayblesReducer.data,
        'UPDATE_PAYABLES_FAILURE' : state.updatePayblesReducer.error,
        'UPDATE_BULK_PAYMENT_PAYABLES_REQUEST' : state.updatebulk_paymentReducer.loading,
        'UPDATE_BULK_PAYMENT_PAYABLES_SUCCESS' : state.updatebulk_paymentReducer.data,
        'UPDATE_BULK_PAYMENT_PAYABLES_FAILURE' : state.updatebulk_paymentReducer.error,
        'EDIT_COUNTERPARTS_REQUEST' : state.updateCounterpartsReducer.loading,
        'EDIT_COUNTERPARTS_SUCCESS' : state.updateCounterpartsReducer.data,
        'EDIT_COUNTERPARTS_FAILURE' : state.updateCounterpartsReducer.error,
        'EDIT_INVOICE_REQUEST' : state.updateInvoiceReducer.loading,
        'EDIT_INVOICE_SUCCESS' : state.updateInvoiceReducer.data,
        'EDIT_INVOICE_FAILURE' : state.updateInvoiceReducer.error,

        'REJECT_BULK_PAYMENT_PAYABLES_REQUEST' : state.rejectbulk_paymentReducer.loading,
        'REJECT_BULK_PAYMENT_PAYABLES_SUCCESS' : state.rejectbulk_paymentReducer.data,
        'REJECT_BULK_PAYMENT_PAYABLES_FAILURE' : state.rejectbulk_paymentReducer.error,
        'SUBMIT_PAYABLES_REQUEST' : state.submitPayblesReducer.loading,

        'SUBMIT_PAYABLES_SUCCESS' : state.submitPayblesReducer.data,
        'SUBMIT_PAYABLES_FAILURE' : state.submitPayblesReducer.error,
        'CERETE_COUNTERPARTS_REQUEST' : state.ceretePayblesReducer.loading,
        'CERETE_COUNTERPARTS_SUCCESS' : state.ceretePayblesReducer.data,
        'CERETE_COUNTERPARTS_FAILURE' : state.ceretePayblesReducer.error,
        'CUSTOMERS_CREATE_REQUEST' : state.CustomersCreateReducer.loading,
        'CUSTOMERS_CREATE_SUCCESS' : state.CustomersCreateReducer.data,
        'CUSTOMERS_CREATE_FAILURE' : state.CustomersCreateReducer.error,
        'GET_COUNTERPARTS_SUCCESS' : state.getAllCategoriesReducer.data,
    };
}
import { Box, Typography } from "@mui/material";

interface MakePaymentHeaderProps {
  value: number;
  step: number;
}

const MakePaymentHeader: React.FC<MakePaymentHeaderProps> = ({ value, step }) => {
  const headers: Record<string, string> = {
    "1_2": "Approval",
    "1_3": "Bulk Payment",
    "2_2": "Bulk Payment",
    "1_7": "Bulk payment summary",
  };

  const headerText = headers[`${value}_${step}`] || "Make Payments";

  return (
    <Box className="makepayment_header">
      <Box className="header_auto_container">
        <Typography className="header_name" style={{ fontFamily: "Poppins" }}>
          {headerText}
        </Typography>
      </Box>
    </Box>
  );
};

export default MakePaymentHeader;

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'react-bootstrap';
import './Settings.scss';
import { getBase64 } from '../../../../../../../../_helpers/common';
import { authHeader, getUser } from '../../../../../../../../_helpers';
import Form from 'react-bootstrap/Form';
import { settingActions } from '../../../../../../../../_actions/setting.actions';
// import toast from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { selectAllData } from '../../../../../../../../_reducers/entity.reducer';
import {
  choiceActions,
  entityActions,
  userActions,
} from '../../../../../../../../_actions';
import * as Yup from 'yup';
import { settingSelector } from '../../../../../../../../_reducers/setting.reducer';

import { useDispatch, useSelector } from 'react-redux';
import RegistrationForm from './SoleProprietoryShip/RegistrationForm';
import LimitedLiabilityForm, {
  FormStep,
} from './LimitedLiability/LimitedLiabilityForm';
import PartnershipForm from './Paternership/PartnershipForm';
import PartnershipStepOne from './Paternership/PartnershipStepOne';
import PartnershipStepTwo from './Paternership/PartnershipStepTwo';
import { useLocation } from 'react-router-dom';
import LimitedLiability from './LimitedLiability/LimitedLiability';
import LimitedLiabilityStepTwo from './LimitedLiability/LimitedLiabilityStepTwo';
import { useNavigate } from 'react-router-dom';
import SoleProprietoryStepOne from './SoleProprietoryShip/SoleProprietoryStepOne';
import SoleProprietory from './SoleProprietoryShip/SoleProprietory';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DocumentsSubmitted } from '../DocumentsSubmitted';
import { DocumentVerified } from '../DocumentVerfied';
import SetupFailed from './Setupfailed';
import Loader from './Loader';

interface Props {
  onSave: any;
}
interface RequestProps {
  representative: any;
  document_type: string;
  business_documents: any;
  directors: any;
  shareholders: any;
  status: string;
}

const DocumentUpload = ({
  setSetpNumberAuth,
  path,
  backpath,
  updateStep,
  api,
}: any) => {
  const [userDetail, setUserDetail] = useState(false);
  const [typevalue, setType] = useState('');
  const [users, setUsers] = useState<any>();
  const [render, setRender] = useState(true);
  const [entityType, setEntityType] = useState<any>(null);
  // const [businessdoc, setBusinessDoc] = useState<any>({});
  const user = getUser()?.data ?? '';

  const businessDetails = useSelector(
    (state: any) => state.getBusinessDetailsReducer.data
  );
  const location = useLocation();
  let organizationType = businessDetails?.businessType;
  let selectedCurrency = location.state?.selectedCurrency;
  // let fromAccountSetup = location.state?.fromAccountSetup;
  const isComingFromOTPWithCancellation = location.state?.fromCancel;

  const [renderEntity, setRenderEntity] = useState(true);
  const [renderEntity_two, setRenderEntity_two] = useState(true);
  const [status, setstatus] = useState('waiting_for_submission');
  const [formValues, setFormValues] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [LoaderApi, setLoaderAPi] = useState(false);
  const dispatch = useDispatch();
  const dataEntity: any = useSelector((state) => settingSelector(state));
  const onboardingInfo: any = useSelector(
    (state: any) => state.getOnboardingInfoReducer.data
  );
  const industries = useSelector(
    (state: any) => state.getIndustriesReducer.data
  );
  const [businessDocs, setBusinessDocs] = useState<any>([]);
  const bussiness = useSelector(
    (state: any) => state.getBusinessInfoReducer.data
  );



  const defaultShareholder = {
    business_id: `${user?.entity?.id}`,
    userId: `${user?.user_id}`,
    idType: '101',
    // firstName: '',
    // lastName: '',
    firstName: '',
    idNumber: '',
    gender: '',
    countryCode: '',
    mobile: '',
    kraPin: '',
    idFrontSideFile: '',
    idFrontSideFileType: 'image',
    idBackSideFile: '',
    idBackSideFileType: 'image',
    selfieFile: '',
    selfieFileType: 'image',
    passportFile: '',
    passportFileType: 'image',
    kraPinFile: '',
    kraPinFileType: 'image',
    selectedIdType: '',
    idBackSideFileName: '',
    idFrontSideFileName: '',
    kraPinFileName: '',
    selfieFileName: '',
    passportFileName: '',
  };

  const commonStaticFASTTT = {
    certi_of_incorpo: '{type}',
    certi_of_compliance: '{type}',
    CR: '{type}',
    KRA: '{type}',
    Passport: '{type}',
    id: null,
    fast_data: '{type}',
    secend_data: '{type}',
    thred_data: '{type}',
    frore_data: '{type}',
    frontID_new: null,
    backID_new: null,
    KRN_certi_new: null,
    Passport_new: null,
  };

  // use effect to handle entity type
  useEffect(() => {
   
    // // Check if navigation came from the cancel action
    // if (entityType !== null) {
    //   // Fetch data from the Redux store's person state
    //   if (onboardingInfo?.code === '12004') {
    //    
    //     setFormValues({
    //       ...bussiness,
    //       ...businessDocs?.data,
    //     });
    //     setEntityType(bussiness?.businessType);
    //   } else if (onboardingInfo?.data) {
    //     
    //     const { firstName, middleName, lastName } = onboardingInfo || {};
    //     const fullName = [firstName, middleName, lastName]
    //       .filter((name) => name) // Filter out empty or undefined values
    //       .join(' '); // Join the remaining values with a space
    //     setFormValues({
    //       ...onboardingInfo?.data,
    //       firstName: fullName,
    //       ...businessDocs?.data,
    //     });
    //     setEntityType(onboardingInfo?.data?.businessType);
    //   } else {
    //     const { firstName, middleName, lastName } = onboardingInfo || {};
    //     const fullName = [firstName, middleName, lastName]
    //       .filter((name) => name) // Filter out empty or undefined values
    //       .join(' '); // Join the remaining values with a space
    //     setFormValues({
    //       ...onboardingInfo,
    //       firstName: fullName,
    //       ...businessDocs?.data,
    //     });
    //     setEntityType(onboardingInfo?.businessType);
    //   }
    // } else if (fromAccountSetup && !isComingFromOTPWithCancellation) {

    //   setEntityType(organizationType);
    // } else {
    
    //   setEntityType(user?.entity?.entity_type_id);
    //   setFormValues({
    //     ...bussiness,
    //     ...businessDocs?.data,
    //   });
    //   setEntityType(bussiness?.businessType);
    // }
    
    if (businessDetails) {
      setEntityType(businessDetails?.businessType);
      setFormValues({
        ...businessDetails,
        otpType: 'email',
        email: user?.email,
        shareholders:
          businessDetails?.shareholders.length > 0
            ? businessDetails?.shareholders?.map((shareholder: any) => ({
              ...shareholder,
              userId: user?.user_id,
              mobile: `${shareholder?.mobile}`,
            }))
            : businessDetails?.businessType == '2'
              ? initialValuesLimitedLiability?.shareholders
              : initialValuesPartnership?.shareholders,
        ...businessDocs?.data,
      });
    }


  }, [businessDocs]);

  useEffect(() => {
    if (!!businessDetails) {
     
      setEntityType(businessDetails?.businessType);
      setFormValues({
        ...businessDetails,
        otpType: 'email',
        email: user?.email,
        shareholders:
          businessDetails?.shareholders.length > 0
            ? businessDetails?.shareholders?.map((shareholder: any) => ({
              ...shareholder,
              userId: user?.user_id,
              mobile: `${shareholder?.countryCode}${shareholder?.mobile}`,
            }))
            : businessDetails?.businessType == '2'
              ? initialValuesLimitedLiability?.shareholders
              : initialValuesPartnership?.shareholders,
        ...businessDocs?.data,
      });
    }
  }, [businessDetails]);

  const [inputlfast, setInputListfast] = useState<any>(commonStaticFASTTT);

  React.useEffect(() => {
    setUserDetail(false);
  }, []);

  React.useEffect(() => {
   
    if (getUser && render) {
      setIsLoading(true);
      setRender(false);
      setUsers(getUser);
      dispatch(settingActions.getEntityDocuments() as any);
      // dispatch(choiceActions.getStoreOnboardingInfo(user?.entity?.id) as any);
      dispatch(choiceActions.getBusinessDetails(user?.user_id) as any);
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (render) {
      setIsLoading(true);
      setRender(false);
      dispatch(entityActions.getEntityTypes() as any);
      //dispatch(choiceActions.getStoreOnboardingInfo(user?.entity.id) as any);
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
      // dispatch(entityActions.getEntityActivities() as any)
    }
  }, [render, dispatch]);

  React.useEffect(() => {
   
    setIsLoading(true);
    if (
      renderEntity_two &&
      dataEntity?.GETENTITYDOCUMENT_SUCCESS !== undefined
    ) {
     
      if (
        !!dataEntity.GETENTITYDOCUMENT_SUCCESS.data &&
        dataEntity.GETENTITYDOCUMENT_SUCCESS.data.length !== 0
      ) {
        if (typeof dataEntity.GETENTITYDOCUMENT_SUCCESS.data == 'object') {
          var GetData = dataEntity.GETENTITYDOCUMENT_SUCCESS.data;



          setType(GetData.document_type);
          setUserDetail(true);
          setstatus(GetData.status);
        
          // if (onboardingInfo) {
          //   setFormValues(onboardingInfo);
          // }
          setBusinessDocs({
            data: !!GetData?.business_documents
              ? GetData?.business_documents
              : '',
          });
        }
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [renderEntity_two, renderEntity, dataEntity.GETENTITYDOCUMENT_SUCCESS]);

  const validationSchema = Yup.object().shape({});

  const shareholderSchema = Yup.object().shape({
    // idType: Yup.string().required(),
    fullName: Yup.string().required('Full name is required'),
    // lastName: Yup.string().required(),
    idNumber: Yup.string().required('ID or passport number is required'),
    gender: Yup.string().required('Gender is required'),
    // countryCode: Yup.string().required(),
    mobile: Yup.string()
      .min(11, 'Too Short!')
      .required('Mobile number is required'),
    kraPin: Yup.string().required('Tax identification number is required'),
    // idFrontSideFile: Yup.string().required('ID - frontside is required'),
    // idFrontSideFileType: Yup.string().required(),
    // idBackSideFile: Yup.string().required('ID - backside is required'),
    // idBackSideFileType: Yup.string().required(),
    selfieFile: Yup.string().required('Passport photo is required'),
    // selfieFileType: Yup.string().required(),
    kraPinFile: Yup.string().required(
      'Tax identification document is required'
    ),
    selectedIdType: Yup.string().required('ID type is required'),
    // kraPinFileType: Yup.string().required(),

    idFrontSideFile: Yup.mixed().when('selectedIdType', {
      is: 'National',
      then: Yup.mixed().required('ID - frontside is required'),
      otherwise: Yup.mixed().notRequired(),
    }),
    idBackSideFile: Yup.mixed().when('selectedIdType', {
      is: 'National',
      then: Yup.mixed().required('ID - backside is required'),
      otherwise: Yup.mixed().notRequired(),
    }),
    passportFile: Yup.mixed().when('selectedIdType', {
      is: 'passport',
      then: Yup.mixed().required('Passport is required'),
      otherwise: Yup.mixed().notRequired(),
    }),
  });

  const limitedLiabilityValidationSchemaStep1 = Yup.object().shape({
    businessName: Yup.string().required('Business name is required').nullable(),
    businessCerNum: Yup.string()
      .required('Business registration number is required')
      .nullable(),
    businessAddress: Yup.string()
      .required(' Business address is required')
      .nullable(),
    businessIndustry: Yup.string()
      .required(' Business industry is required')
      .nullable(),
    country: Yup.string()
      .required('country is required'),
    business_industry_name: Yup.string()
      .required(' Business industry is required')
      .nullable(),
    // specifyIndustry: Yup.string().required('required'),
    // operatingMode: Yup.string().required('Business Operation Mode required'),
    kraPin: Yup.string()
      .required('Tax identification number is required')
      .nullable(),
    businessCertificate: Yup.string().required(
      'Registration certificate document is required'
    ),
    cr: Yup.string().required(
      ' Register of shareholders & directors document is required'
    ),
    resolution: Yup.string().required(
      'Original board resolution document is required'
    ),
    kraPinCertificate: Yup.string().required(
      'Business tax identification document is required'
    ),
    memorandum: Yup.string().required(
      'Memorandum and articles of association document is required'
    ),
  });

  const limitedLiabilityValidationSchemaStep2 = Yup.object().shape({
    shareholders: Yup.array()
      .of(shareholderSchema)
      .required('At least 1 directors are required'),
    // email: Yup.string().required('Email is required'),
    // selfie: Yup.string().required('Owner Selfie photo is required'),
    // idFront: Yup.string().required('Owner Id front photo is required'),
    // idBack: Yup.string().required('Owner Id back photo is required'),
    // kra: Yup.string().required('Owner Kra Pin photo is required'),
  });

  const soleProprietorshipValidationSchemaStep1 = Yup.object().shape({
    businessName: Yup.string().required('Business name is required').nullable(),
    businessCerNum: Yup.string()
      .required('Registration number is required')
      .nullable(),
    businessAddress: Yup.string()
      .required(' Business address is required')
      .nullable(),
    country: Yup.string()
      .required('country is required'),
    businessIndustry: Yup.string()
      .required(' Business industry is required')
      .nullable(),
    business_industry_name: Yup.string()
      .required(' Business industry is required')
      .nullable(),
    businessCertificate: Yup.string()
      .required('Business registration certificate is required')
      .nullable(),
    //    idFront: Yup.string().required(' ID - frontside photo is required'),
    // idBack: Yup.string().required(' ID - backside photo is required'),
  });

  const soleProprietorshipValidationSchemaStep2 = Yup.object().shape({
    fullName: Yup.string().required('Full name is required').nullable(),
    gender: Yup.number().required('Gender is required').nullable(),
    mobile: Yup.string()
      .min(11, 'Too Short!')
      .required('Mobile number is required')
      .nullable(),
    idNumber: Yup.string()
      .required('ID or passport number is required')
      .nullable(),
    kinFullName: Yup.string().required('Kin full name is required').nullable(),
    kinRelationship: Yup.string()
      .required('Kin relationship is required')
      .nullable(),

    kinCountryCode: Yup.string()
      .required('Kin country Code is required')
      .nullable(),
    kinMobile: Yup.string()
      .min(11, 'Too Short!')
      .required('Kin mobile is required')
      .nullable(),
    kraPin: Yup.string()
      .required('Tax identification number is required')
      .nullable(),
    birthday: Yup.string().required('Birthday is required').nullable(),
    selfie: Yup.string().required('Passport photo is required').nullable(),
    kra: Yup.string()
      .required('Owner tax identification number is required')
      .nullable(),
    selectedIdType: Yup.string().required('ID type is required').nullable(),
    idFront: Yup.mixed().when('selectedIdType', {
      is: 'National',
      then: Yup.mixed().required('ID - frontside is required'),
      otherwise: Yup.mixed().notRequired(),
    }),
    idBack: Yup.mixed().when('selectedIdType', {
      is: 'National',
      then: Yup.mixed().required('ID - backside is required'),
      otherwise: Yup.mixed().notRequired(),
    }),
    passport: Yup.mixed().when('selectedIdType', {
      is: 'passport',
      then: Yup.mixed().required('Passport is required'),
      otherwise: Yup.mixed().notRequired(),
    }),
    // TODO add field validation for id
    // idFront: Yup.string().required(' ID - frontside photo is required'),
    // idBack: Yup.string().required(' ID - backside photo is required'),
    // passport: Yup.string().required('passport photo is required'),
  });

  const partnershipValidationSchemaStep1 = Yup.object().shape({
    businessName: Yup.string().required('Business name is required').nullable(),
    businessCerNum: Yup.string()
      .required('Business registration number is required')
      .nullable(),
    businessAddress: Yup.string()
      .required(' Business address is required')
      .nullable(),
    country: Yup.string()
      .required('country is required'),
    businessIndustry: Yup.string()
      .required(' Business industry is required')
      .nullable(),
    business_industry_name: Yup.string()
      .required(' Business industry is required')
      .nullable(),
    // operatingMode: Yup.string()
    //   .required('Business operation mode required')
    //   .nullable(),
    businessCertificate: Yup.string().required(
      'Business registration document is required'
    ),
    partnershipDeed: Yup.string().required(
      'Partnership deed document is required'
    ),
    businessKraPinCertificate: Yup.string().required(
      'Business tax identification document is required'
    ),
    kraPin: Yup.string()
      .required('Tax identification number is required')
      .nullable(),
  });

  const partnershipValidationSchemaStep2 = Yup.object().shape({
    shareholders: Yup.array()
      .of(shareholderSchema)
      .min(2, 'At least 2 partners are required'),
  });

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });

  // var Submitsetp = () => {
  //   setSetpNumberAuth(5);
  //   localStorage.setItem("registerStep", "5");
  // };
  const handleLimitedLiabilitySubmit = (values: any) => {
   
    // if (values.shareholders && values.shareholders.length > 0) {
    //   values.shareholders.forEach((shareholder: any) => {
    //     // Split the first name by spaces
    //     const names = shareholder.firstName.split(' ');

    //     if (names.length === 1) {
    //       shareholder.firstName = names[0];
    //       shareholder.lastName = ''; // Clear lastName
    //       shareholder.middleName = ''; // Clear middleName
    //     } else if (names.length === 2) {
    //       shareholder.firstName = names[0];
    //       shareholder.middleName = ''; // Clear middleName
    //       shareholder.lastName = names[1];
    //     } else {
    //       shareholder.firstName = names[0];
    //       shareholder.middleName = names[1];
    //       shareholder.lastName = names.slice(2).join(' '); // Concatenate remaining names as the last name
    //     }
    //     const shareHolderMobile = shareholder?.mobile.slice(
    //       shareholder?.countryCode.length
    //     );
    //     shareholder.mobile = shareHolderMobile;
    //   });
    // }

    // const updatedShareholders = values.shareholders?.map((shareholder: any) => {
    //   // Split the first name by spaces
    //   const names = shareholder.firstName.split(' ');

    //   const updatedShareholder = { ...shareholder };

    //   if (names.length === 1) {
    //     updatedShareholder.firstName = names[0];
    //     updatedShareholder.lastName = ''; // Clear lastName
    //     updatedShareholder.middleName = ''; // Clear middleName
    //   } else if (names.length === 2) {
    //     updatedShareholder.firstName = names[0];
    //     updatedShareholder.middleName = ''; // Clear middleName
    //     updatedShareholder.lastName = names[1];
    //   } else {
    //     updatedShareholder.firstName = names[0];
    //     updatedShareholder.middleName = names[1];
    //     updatedShareholder.lastName = names.slice(2).join(' '); // Concatenate remaining names as the last name
    //   }

    //   // const shareHolderMobile = updatedShareholder?.mobile.slice(
    //   //   updatedShareholder?.countryCode.length
    //   // );
    //   // updatedShareholder.mobile = shareHolderMobile;

    //   return updatedShareholder;
    // });
    
    // const names = values.firstName.split(' ');
    // const firstName = names[0];
    // const lastName = names[1];
    const countryCode =
      values?.countryCode !== ''
        ? values?.countryCode
        : values?.shareholders[0]?.countryCode;
    const kinCountryCode = values?.kinCountryCode;
    const kinMobile = values?.kinMobile?.slice(kinCountryCode.length);
    const mobile =
      values?.mobile !== '' ? values?.mobile : values.shareholders[0]?.mobile;
    const kraPin =
      values?.kraPin !== '' ? values?.kraPin : values.shareholders[0]?.kraPin;
    // const business_industry_name=industries.find(()=>{})
    let {
      idBack,
      idFront,
      selfie,
      kra,
      cr,
      memorandum,
      resolution,
      kraPinCertificate,
      ultimateBeneficiary,
      articlesOfAssociation,
      ...payload
    } = values;

    var body = {
      business_name: values.businessName,
      company_type: entityType,
      business_activity: values.businessIndustry,
      street_address: values.businessAddress,
    };
    dispatch(
      choiceActions.setBusiness({
        ...values,
        // firstName: `${firstName} ${lastName}`,
        // lastName,
        mobile,
        kraPin,
      }) as any
    );
  
    // dispatch(
    //   choiceActions.createBusinessAccount(
    //     {
    //       ...payload,
    //       firstName: values.fullName[0],
    //       countryCode,
    //       lastName: values.fullName[1],
    //       mobile,
    //     },

    //     () => {
    //       toast.success('An OTP was sent to your email.');
    //     },
    //     setSetpNumberAuth,
    //     updateStep
    //   ) as any
    // );
   

    if (!!api) {
      const callAfter = () => {
        setSetpNumberAuth(3);
        updateStep(
          3,
          true,
          navigate(path, {
            state: {
              entityTYpe: organizationType,
              selectedCurrency,
            },
          })
        );
        setLoaderAPi(false);
      };
      var bodypayload = {
        ...values,
        // firstName: `${firstName} ${lastName}`,
        // lastName,
        countryCode,
        mobile,
        kraPin,
      };
      localStorage.setItem('register_payload', JSON.stringify(bodypayload));
      setLoaderAPi(true);
      var bodypayloadupdet = {
        country: values.country,
        street_address: values.businessAddress,
        business_name: values.businessName,
        company_type: entityType,
        business_activity: values.businessIndustry,
        entity_id: user?.entity?.id,
      };
    
      const Entitysave = () => {
        if (!!user?.entity?.id) {
          dispatch(
            entityActions.updetDataAction(bodypayloadupdet, callAfter) as any
          );
        } else {
          dispatch(entityActions.SaveEntity(body, api, callAfter) as any);
        }
      };
      delete values.businessCertificate;
      delete values.cr;
      delete values.kraPinCertificate;
      delete values.memorandum;
      delete values.resolution;
      delete values.businessKraPinCertificate;
      delete values.partnershipDeed;
      if (entityType == 1) {
        values.shareholders = [];
      }
      values.selfie = values.selfie;
      dispatch(
        entityActions.saveBusinessDetailsAction(
          {
            ...values,
            // firstName: `${firstName} ${lastName}`,
            // lastName,
            countryCode,
            mobile,
            kraPin,
          },
          Entitysave,
          true
        ) as any
      );
    } else {
      setLoaderAPi(true);
      dispatch(
        choiceActions.saveBusinessDetails(
          { ...values },
          () => {
            toast.success('Business details saved ');
       
            setLoaderAPi(false);
            dispatch(
              choiceActions.createBusinessAccount(
                {
                  ...payload,
                  firstName: values.fullName[0],
                  countryCode,
                  lastName: values.fullName[1],
                  mobile,
                },

                () => {
                  toast.success('An OTP was sent to your email.');
                  navigate(path, {
                    state: {
                      entityTYpe: organizationType,
                      selectedCurrency,
                    },
                  });
                },
                setSetpNumberAuth,
                updateStep
              ) as any
            );
            navigate(path, {
              state: {
                entityTYpe: organizationType,
                selectedCurrency,
              },
            });
          },
          0
        ) as any
      );
      const CallFun = () => {
        toast.success('An OTP was sent to your email.');
        navigate(path, {
          state: {
            entityTYpe: organizationType,
            selectedCurrency,
          },
        });
      };
      dispatch(
        choiceActions.createBusinessAccount(
          {
            ...payload,
            // firstName,
            countryCode,
            // lastName,
            mobile,
          },
          CallFun,
          setLoaderAPi,
          updateStep
        ) as any
      );
    }

    // api

    // dispatch(
    //   choiceActions.createBusinessAccount(
    //     {
    //       ...payload,
    //       firstName: values.firstName[0],
    //       countryCode,
    //       lastName: values.firstName[1],
    //       mobile,
    //     },

    //     () => {
    //       toast.success('An OTP was sent to your email.');
    //     },
    //     setSetpNumberAuth,
    //     updateStep
    //   ) as any
    // );
    // localStorage.setItem('registerStep', '3');
  };

  const initialValuesLimitedLiability = {
    businessName: '',
    businessCerNum: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    birthday: '',
    idNumber: '',
    kraPin: '',
    businessAddress: '',
    kinFullName: '',
    kinRelationship: '',
    kinCountryCode: '254',
    kinMobile: '',
    businessIndustry: '',
    business_industry_name: '',
    specifyIndustry: '',
    userId: user?.user_id,
    countryCode: '',
    businessType: `${organizationType}`,
    currency: 'KES + USD',
    // operatingMode: '1',
    otpType: 'email',
    mobile: '',
    email: user?.email,
    entity_id: `${user?.entity?.id}`,
    businessCertificate: '',
    cr: '',
    memorandum: '',
    resolution: '',
    kraPinCertificate: '',
    partnershipDeed: '',
    ultimateBeneficiary: '',
    articlesOfAssociation: '',
    shareholders: [defaultShareholder],
    selectedIdType: '',
    country: '',
    // website: '',
    license: '',
  };

  const initialValuesSoleProprietorship = {
    businessName: '',
    businessCerNum: '',
    fullName: '',
    middleName: '',
    lastName: '',
    gender: '',
    birthday: '',
    idNumber: '',
    kraPin: '',
    businessAddress: '',
    kinFullName: '',
    kinRelationship: '',
    kinCountryCode: '254',
    kinMobile: '',
    businessIndustry: '',
    business_industry_name: '',
    specifyIndustry: '',
    userId: user?.user_id,
    countryCode: '',
    businessType: `${organizationType}`,
    currency: `${selectedCurrency}`,
    // operatingMode: '',
    otpType: 'email',
    mobile: '',
    email: user?.email,
    entity_id: `${user?.entity?.id}`,
    selfie: '',
    idFront: '',
    idBack: '',
    kra: '',
    businessCertificate: '',
    selectedIdType: '',
    country: '',
    // website: '',
    license: '',
  };

  const defaultPartners = [
    { ...defaultShareholder },
    { ...defaultShareholder },
  ];
  const initialValuesPartnership = {
    businessName: '',
    businessCerNum: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    birthday: '',
    idNumber: '',
    kraPin: '',
    businessAddress: '',
    kinFullName: '',
    kinRelationship: '',
    kinCountryCode: '',
    kinMobile: '',
    businessIndustry: '',
    business_industry_name: '',
    specifyIndustry: '',
    userId: user?.user_id,
    countryCode: '',
    businessType: `${organizationType}`,
    currency: 'KES + USD',
    // operatingMode: '2',
    otpType: 'email',
    mobile: '',
    email: user?.email,
    entity_id: `${user?.entity?.id}`,
    businessCertificate: '',
    cr: '',
    memorandum: '',
    resolution: '',
    kraPinCertificate: '',
    partnershipDeed: '',
    ultimateBeneficiary: '',
    articlesOfAssociation: '',
    shareholders: [{ ...defaultShareholder }, { ...defaultShareholder }],
    selectedIdType: '',
    country: '',
    // website: '',
    license: '',
  };

 

  useEffect(() => {
  
  }, [formValues]);

  if (isLoading || !dataEntity) {
    return <Loader />;
  }
 

  return (
    <>
      {status !== 'waiting_for_submission' && (
        <div className="profile">
          {status === 'waiting_for_approval' && (
            <DocumentsSubmitted
              onSave={() => {
                navigate('/');
              }}
            />
          )}
          {status === 'approved' &&
            users?.data?.entity?.status !== 'VERIFIED' && (
              <DocumentsSubmitted
                onSave={() => {
                  navigate('/');
                }}
              />
            )}
          {status === 'approved' &&
            users?.data?.entity?.status === 'VERIFIED' && (
              <DocumentVerified
                onSave={() => {
                  navigate('/');
                }}
              />
            )}
          {status === 'rejected' && <SetupFailed />}
        </div>
      )}
      {status === 'waiting_for_submission' && (
        <ThemeProvider theme={theme}>
          <div className="accoun-management">
            {/* <div
              className="accoun-management__header"
              style={{ marginBottom: '35px' }}
            >
              Account Setup
            </div> */}
            {/* <AuthLayout element={
              <> */}
            {/* <Button
              id="button_back"
              style={{
                color: '#025141',
                marginLeft: '48px',
                marginTop: '20px',
              }}
              onClick={() => {
                navigate(backpath);
                if (backpath == '/v2/account-setup') {
                  setSetpNumberAuth(1);
                  updateStep(1,false,navigate(backpath));
                  localStorage.setItem('registerStep', '1');
                }
              }}
              className="payment-journey-container__detailbox__white-btn"
            >
              <ThemeProvider theme={theme}>
                <ArrowBackIcon
                  style={{ fontSize: '19px', marginRight: '10px' }}
                />
              </ThemeProvider>
              Go Back
            </Button> */}
            <div className="complete-profile">
              {entityType == '1' ? (
                <>
                  <RegistrationForm
                    setSetpNumberAuth={setSetpNumberAuth}
                    updateStep={updateStep}
                    LoaderApi={LoaderApi}
                    api={api}
                    initialValues={
                      formValues || initialValuesSoleProprietorship
                    }
                    entityType={entityType}
                    businessDocs={businessDocs}
                    onSubmit={(values) => handleLimitedLiabilitySubmit(values)}
                  >
                    <FormStep
                      stepName=""
                      onSumbit={() => { }}
                      validationSchema={soleProprietorshipValidationSchemaStep1}
                    >
                      <SoleProprietoryStepOne />
                    </FormStep>
                    <FormStep
                      stepName=""
                      onSumbit={() => { }}
                      validationSchema={soleProprietorshipValidationSchemaStep2}
                    >
                      <SoleProprietory />
                    </FormStep>
                  </RegistrationForm>
                </>
              ) : entityType == '2' ? (
                <>
                  <LimitedLiabilityForm
                    LoaderApi={LoaderApi}
                    initialValues={formValues || initialValuesLimitedLiability}
                    onSubmit={(values) => handleLimitedLiabilitySubmit(values)}
                    setSetpNumberAuth={setSetpNumberAuth}
                    updateStep={updateStep}
                    api={api}
                    businessDocs={businessDocs}
                    stepOneInfo={limitedLiabilityValidationSchemaStep1}
                    stepTwoInfo={limitedLiabilityValidationSchemaStep2}
                  >
                    <FormStep
                      stepName=""
                      onSumbit={() => { }}
                      validationSchema={limitedLiabilityValidationSchemaStep1}
                    >
                      <LimitedLiability />
                    </FormStep>
                    <FormStep
                      stepName=""
                      onSumbit={() => { }}
                      validationSchema={limitedLiabilityValidationSchemaStep2}
                    >
                      <LimitedLiabilityStepTwo />
                    </FormStep>
                  </LimitedLiabilityForm>
                </>
              ) : (
                <>
                  <PartnershipForm
                    LoaderApi={LoaderApi}
                    initialValues={formValues || initialValuesPartnership}
                    stepOneInfo={partnershipValidationSchemaStep1}
                    stepTwoInfo={partnershipValidationSchemaStep2}
                    businessDocs={businessDocs}
                    entityType={entityType}
                    api={api}
                    setSetpNumberAuth={setSetpNumberAuth}
                    updateStep={updateStep}
                    onSubmit={(values) => {
                      handleLimitedLiabilitySubmit(values);
                  
                    }}
                  >
                    <FormStep
                      stepName=""
                      onSumbit={() => { }}
                      validationSchema={partnershipValidationSchemaStep1}
                    >
                      <PartnershipStepOne />
                    </FormStep>
                    <FormStep
                      stepName=""
                      onSumbit={() => { }}
                      validationSchema={partnershipValidationSchemaStep2}
                    >
                      <PartnershipStepTwo />
                    </FormStep>
                  </PartnershipForm>
                </>
              )}
            </div>
            {/* </> */}
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default DocumentUpload;

import { Box, Typography } from "@mui/material"
import { DefaultBtn } from "../../Buttons/Buttons";
import "./paymentscheduled.scss";

const PaymentScheduled = ({amount , payee , payment_method , formik , buttonText ,onClick , methodTitle} : any) => {
    return (
      <Box className='schedule_noti_box'>
           <Box>
             <Box style={{ textAlign: "center"}}>
               <img src="/logo 3.svg" />
             </Box>
             <Box style={{ textAlign: "center"}}>
               <Box>
                 <Typography className="transaction_noti_text" style={{ textAlign: "center"}}>Your payment of {amount} to {payee} via {methodTitle} has been scheduled for {formik.date ? new Date(formik.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }) : '-'} & {formik?.time ? new Date(formik?.time).toLocaleTimeString() : '-'}. You will receive a confirmation once it is processed.</Typography>
               </Box>
             </Box>
             <Box style={{ textAlign: "center", padding: "15px 0px 0px 0px" }}>
               <Box style={{ display: "flex", justifyContent: "center" }}>
                 <DefaultBtn style={{ marginTop: "13px" , width:"80px" }} onClick={onClick} text={buttonText}/>
               </Box>
             </Box>
           </Box>
     
         </Box>
    );
};

export default PaymentScheduled;
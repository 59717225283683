import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import styles from './Bulktebal.module.scss'
import { Divider, Typography } from '@mui/material';
import { selectAllCounterpartsListData } from "../../../../_reducers/counterparts.reducer";
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import MaskedInput from 'react-text-mask'
import Multiselect from "multiselect-react-dropdown";
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import { counterpartsActions } from "../../../../_actions/counterparts.actions";
import { Formik, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { selectAllPayablesListData } from "../../../../_reducers/payables.reducer";
import '../../../auth/Auth.css';
import { ThemeProvider, createTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import PageHeader from "../PageHeader";
import { selectPaymentJourneyData } from "../../../../_reducers/paymentJourney.reducer";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import * as XLSX from 'xlsx';
import { dashboardActions } from "../../../../_actions";
// import toast from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    selectAllData,
} from "../../../../_reducers/dashboard.reducer";
import { ListSkeleton } from "../Skeleton/Skeleton";
import { getKESFormate } from "../../../../_helpers/common";
import Model1Line from "../../../ui/model";

interface Data {
    transaction_date: string;
    reference: string;
    Type: string;
    beneficiary: string;
    transaction_amount: string;
    description: string,
}
const AddIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z" fill="#025041" />
    </svg>
)
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'transaction_date';
const DEFAULT_ROWS_PER_PAGE = 5;
interface EnhancedTableToolbarProps {
    numSelected: number;
}
const Bulk_Payments = () => {
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [dense, setDense] = React.useState(false);
    const [visibleRows, setVisibleRows] = React.useState<Data[] | null>(null);
    const [datatebal, setDatatebal] = React.useState([])
    const [filedata, setFile] = React.useState('')
    const [isError, setIserror] = React.useState<any>()
    const counterpartsListData = useSelector((state) => selectAllCounterpartsListData(state))
    const [counterpartsList, setCounterpartsList] = React.useState<any>([]);
    const [selectMothed, setSelectedMothed] = React.useState<any>(
        [
            { name: "M-Pesa Send Money" },
            { name: "M-Pesa PayBill" },
            { name: "M-Pesa Till Number" },
            { name: "Bank Transfers" },
        ])
    const [counterpartsListRender, setCounterpartsListRender] = useState(false);
    const [dataselect, setDataselect] = useState('')
    const [tagsList, setTagsList] = useState<any>([]);
    const [totel_amount, setTotelAmount] = useState<any>(0)
    const [tagsListRender, setTagsListRender] = useState(true);
    const [bankListRender, setBankListRender] = useState<any>(true);
    const [render, setRender] = useState(true);
    const [users, setUsers] = useState<any>();
    const [isUsers, isSetUsers] = useState<any>(true);
    const [payablesListRender, setPayablesListRender] = useState(false);
    const [payablesListStastsRender, setPayablesListStastRender] = useState(false);
    const [loaderData, setLoaderData] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [validesan_data, setVlidesan_data] = useState(false)
    const [validesan_paybill_data, setVlidesan_paybill_data] = useState(false)
    const [validesan_data_two, setVlidesan_data_two] = useState(true)
    const [bankList, setBankList] = useState<any>([]);
    const [loderinapi, setLoderapi] = React.useState(true);
    const [ModelOpen, setModelOpen] = useState(false)
    const payablesListData = useSelector((state) =>
        selectAllPayablesListData(state)
    );
    const [selectedtotal, setSelectedTotel] = useState(0)
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    const data = useSelector((state) => selectAllData(state));

console.log(ModelOpen,"ModelOpen");

    const [patternDataArray, setPatternDataArray] = React.useState<any>([
        {
            counterpart_name: "",
            supplier_id: '',
            payment_method: "",
            account_number: "",
            amount: '',
            categories: [],
            is_current_price: false,
            description: '',
        }
    ])
    const [patternDataArray_validesan, setPatternDataArray_validesan] = React.useState<any>([
        {
            id: '',
            counterpart_name: "",
            supplier_id: '',
            payment_method: "",
            account_number: '',
            paybill_number: '',
            bank_name: "",
            amount: '',
            account_name: '',
            categories: [],
            is_current_price: false,
            description: '',
            status: true
        }
    ])
    const [messgebank, setMessgebank] = useState("")
    const [balanceRender, setBalanceRender] = useState(false);
    const [balance, setBalance] = useState<any>([]);
    const [submittedData, setSubmittedData] = useState<any>({
        phone_number: null,
        paybill_number: null,
        account_number: null,
        till_number: null
    });
    const [Balanceloader, setBalanceloader] = React.useState(false)

    const [Valid, setValid] = useState('')
    useEffect(() => {
        if (isUsers && getUser) {
            isSetUsers(false);
            setUsers(getUser);
        }
    });



    const listteball = () => {

        const listtebal: any = localStorage.getItem("Bulk_Payment")
        if (!!listtebal && !!counterpartsList) {
            setDatatebal(JSON.parse(listtebal))

            var newData = [];
            var data_listt = [];
            var data_listt_two = [];
            let totel_amount = 0
            for (let index = 0; index < JSON.parse(listtebal).length; index++) {
                var element = JSON.parse(listtebal)[index];
                const find_name = bankList.find(function (entry: any) { return entry.bank_name === element['Bank Name']; });
                var payeename = '';
                const findresults_fastname: any = counterpartsList?.find(function (arrayElement: any) {
                    payeename = arrayElement.counterpart_type == 'individual' ? arrayElement.contact_first_name + ' ' + arrayElement.contact_last_name : arrayElement.name

                    const payeeSearc = !!element['Payee Name*'] ? element['Payee Name*'].split(" ") : [];
                    var SearchResult = false;
                    for (let index = 0; index < payeeSearc.length; index++) {
                        const element1 = payeeSearc[index];
                        SearchResult = payeename.toLowerCase().search(element1.toLowerCase()) != -1 ? true : false;
                        if (SearchResult == true) {
                            break;
                        }

                    }
                    return SearchResult;
                });
                const findresults: any = counterpartsList.find((item: any) => {
                    return item.counterpart_name == element['Payee Name*'];
                });
                const findresultsCat: any = tagsList.find(function (item: any) {
                    const catName = item.name
                    const catSearc = element['Category*']
                    return catName.search(catSearc) != -1 ? true : false;
                });
                totel_amount += Number((element['Amount*'])?.toFixed(0))

                var object = {
                    id: index,
                    description: element["Description"],
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false
                }
                // M-Pesa Send Money
                var object_validesan = {
                    id: index,
                    description: element["Description"],
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - Send Money'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    status: true
                }
                // M-Pesa Till Number
                var object_validesan_two = {
                    id: index,
                    description: element["Description"],
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - Till Number'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    status: true
                }
                // M-Pesa PayBill
                var object_validesan_three = {
                    id: index,
                    description: element["Description"],
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - PayBill'],
                    paybill_number: element['Pay Bill Number'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    status: true
                }
                // Bank Transfers
                var object_validesan_fore = {
                    id: index,
                    description: element["Description"],
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - Bank'],
                    bank_name: !!find_name ? find_name.id : "",
                    account_name: element['Bank Account Name'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    status: true
                }
                if (element["Payment Method*"] == "M-Pesa Send Money") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan)))
                } else if (element["Payment Method*"] == "M-Pesa Till Number") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan_two)))
                } else if (element["Payment Method*"] == "M-Pesa PayBill") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan_three)))

                } else if (element["Payment Method*"] == "Bank Transfers") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan_fore)))
                }
                newData.push(JSON.parse(JSON.stringify(object)));
            }
            setPatternDataArray_validesan(data_listt)
            setPatternDataArray(data_listt)
        }
        setTimeout(() => {
            setLoderapi(false)
        }, 10000)

    }
    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(paymentJourneyActions.getPesaBankList() as any)
            dispatch(dashboardActions.getBalance("/wallet/get-balance", setBalanceRender, setBalanceloader) as any);
            // setTimeout(() => {
            //     setBalanceRender(true)
            // }, 3000)
        }
    }, [render, isUserAuthenticated]);


    useEffect(() => {
        if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
            setBalanceRender(false);
            setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
        }
    }, [data, balanceRender]);
    useEffect(() => {
        if (bankListRender && dataList?.GETPESABANKLIST_SUCCESS !== undefined) {
            setBankListRender(false);
            setBankList(dataList.GETPESABANKLIST_SUCCESS);
        }
    }, [dataList, bankListRender]);
    useEffect(() => {
        if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
            let options: any = [];
            if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
                counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
                    options.push({
                        id: item.id,
                        name: item.name
                    })
                })
            }
            setTagsListRender(false);
            setTagsList(options);
        }
    }, [counterpartsListData, tagsListRender]);
    useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(counterpartsActions.getAllCounterparts(setCounterpartsListRender) as any)
            dispatch(counterpartsActions.getAllTags() as any)
        }
    }, [render, isUserAuthenticated])


    useEffect(() => {
        if (counterpartsListRender && counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined) {


            setCounterpartsListRender(false);
            setCounterpartsList(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
        }
    }, [counterpartsListRender]);




    const handleCSVUpload = async (event: any) => {
        if (event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e: any) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData: any = XLSX.utils.sheet_to_json(sheet);
                const listtebal: any = localStorage.getItem("Bulk_Payment")
                const bulkdata = JSON.parse(listtebal)
                setExcelData(jsonData);
                xlsx_data(jsonData)
            };

            reader.readAsArrayBuffer(file);
        } else {
            toast.error("Please upload file in .xlsx format only, CSV files are not supported. Thank you!")
        }
    }
    const xlsx_data = (data: any) => {
        if (!!data) {
            setDatatebal(data)
            var newData = [];
            var data_listt = [];
            let totel_amount_data = 0;
            for (let index = 0; index < data.length; index++) {
                var element = data[index];
                var payeename = '';
                const find_name = bankList.find(function (entry: any) { return entry.bank_name === element['Bank Name']; });
                const findresults: any = counterpartsList.find((item: any) => {
                    return item.counterpart_name == element['Payee Name*'];
                });
                const findresultsCat: any = tagsList.find(function (item: any) {
                    const catName = item.name
                    const catSearc = element['Category*']
                    return catName.search(catSearc) != -1 ? true : false;
                });
                const findresults_fastname: any = counterpartsList.find(function (arrayElement: any) {
                    payeename = arrayElement.counterpart_type == 'individual' ? arrayElement.contact_first_name + ' ' + arrayElement.contact_last_name : arrayElement.name

                    const payeeSearc = !!element['Payee Name*'] ? element['Payee Name*'].split(" ") : [];
                    var SearchResult = false;
                    for (let index = 0; index < payeeSearc.length; index++) {
                        const element1 = payeeSearc[index];
                        SearchResult = payeename.toLowerCase().search(element1.toLowerCase()) != -1 ? true : false;
                        if (SearchResult == true) {
                            break;
                        }

                    }
                    return SearchResult;
                });
                totel_amount_data += Number((element['Amount*'])?.toFixed(0))

                var object = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    account_number: "122000",
                }
                // M-Pesa Send Money
                var object_validesan = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - Send Money'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    status: true
                }
                //M-Pesa Till Number
                var object_validesan_two = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - Till Number'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    status: true
                }
                //M-Pesa PayBill
                var object_validesan_three = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - PayBill'],
                    paybill_number: element['Pay Bill Number'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    status: true
                }
                // Bank Transfers
                var object_validesan_fore = {
                    id: index,
                    supplier_id: !!findresults && !!findresults.monite_counterpart_id ?
                        findresults.monite_counterpart_id :
                        !!findresults_fastname && !!findresults_fastname.monite_counterpart_id ? findresults_fastname.monite_counterpart_id : '',
                    counterpart_name: !!findresults && !!findresults.counterpart_name ? findresults.counterpart_name :
                        !!findresults_fastname && !!findresults_fastname.counterpart_name ? findresults_fastname.counterpart_name : '',
                    payment_method: element["Payment Method*"],
                    account_number: element['Account Number - Bank'],
                    bank_name: !!find_name ? find_name.id : "",
                    account_name: element['Bank Account Name'],
                    amount: Number((element['Amount*'])?.toFixed(0)),
                    categories: !!findresultsCat && !!findresultsCat.id ? [{
                        id: findresultsCat.id,
                        name: findresultsCat.name
                    }] : [],
                    is_current_price: false,
                    description: element["Description"],
                    status: true
                }
                if (element["Payment Method*"] == "M-Pesa Send Money") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan)))
                } else if (element["Payment Method*"] == "M-Pesa Till Number") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan_two)))
                } else if (element["Payment Method*"] == "M-Pesa PayBill") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan_three)))

                } else if (element["Payment Method*"] == "Bank Transfers") {
                    data_listt.push(JSON.parse(JSON.stringify(object_validesan_fore)))
                }
                newData.push(JSON.parse(JSON.stringify(object)));
            }
            var concetdata = patternDataArray_validesan.concat(data_listt)
            let totalamount = 0


            var newData_two = []
            var concetdata_add = []
            for (let index = 0; index < concetdata.length; index++) {
                var element = concetdata[index];
                const find_name = bankList.find(function (entry: any) { return entry.id === element.bank_name; });
                var payeename = '';

                const findresults: any = counterpartsList.find((item: any) => {
                    return item.counterpart_name == element.counterpart_name;
                });
                const findresultsCat: any = tagsList.find(function (item: any) {
                    const catName = item.name
                    const catSearc = element.categories
                    return catName.search(catSearc) != -1 ? true : false;
                });
                totalamount += element.amount;
                var amount_number = Number(element.amount)
                var object: MyType = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    amount: amount_number,
                    categories: !!element?.categories ? element?.categories : [],
                    is_current_price: false,
                    description: element.description,
                    account_number: element.account_number
                }
                // M-Pesa Send Money
                var object_validesan: MyTypesecendobject = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    // Send many
                    account_number: element.account_number,
                    amount: amount_number,
                    description: element.description,
                    categories: element.categories,
                    is_current_price: false,
                    status: true
                }
                // M-Pesa Till Number
                var object_validesan_two: MyTypeThreeobj = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    account_number: element.account_number,
                    amount: amount_number,
                    categories: element.categories,
                    is_current_price: false,
                    description: element.description,
                    status: true
                }
                //M-Pesa PayBill
                var object_validesan_three: MyTypeForeobj = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    account_number: element.account_number,
                    paybill_number: element.paybill_number,
                    amount: amount_number,
                    categories: element.categories,
                    is_current_price: false,
                    description: element.description,
                    status: true,
                }
                // Bank Transfers
                var object_validesan_fore: MyTypeFiweobj = {
                    id: index,
                    supplier_id: element.supplier_id,
                    counterpart_name: element.counterpart_name,
                    payment_method: element.payment_method,
                    account_number: element.account_number,
                    bank_name: !!find_name ? find_name.id : "",
                    account_name: element.account_name,
                    amount: amount_number,
                    categories: element.categories,
                    is_current_price: false,
                    description: element.description,
                    status: true
                }
                if (element.payment_method == "M-Pesa Send Money") {
                    concetdata_add.push(JSON.parse(JSON.stringify(object_validesan)))
                } else if (element.payment_method == "M-Pesa Till Number") {
                    concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_two)))
                } else if (element.payment_method == "M-Pesa PayBill") {
                    concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_three)))

                } else if (element.payment_method == "Bank Transfers") {
                    concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_fore)))
                }
                newData_two.push(JSON.parse(JSON.stringify(object)));
            }
            setPatternDataArray_validesan(concetdata_add)
            setPatternDataArray(concetdata_add)

        }
    }
    React.useEffect(() => {
        listteball()
        // setLoderapi(true)
        let rowsOnMount: any = stableSort(
            datatebal,
            getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
        );
        rowsOnMount = rowsOnMount.slice(
            0 * DEFAULT_ROWS_PER_PAGE,
            0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
        );

        setVisibleRows(rowsOnMount);
    }, [filedata, counterpartsList, tagsList]);

    useEffect(() => {
        let totel_amount_data = 0;
        for (let index = 0; index < patternDataArray_validesan.length; index++) {
            const element = patternDataArray_validesan[index];
            totel_amount_data += Number(element.amount);
        }
        setTotelAmount(totel_amount_data)
    }, [patternDataArray_validesan])
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            var new_array_two = [];

            for (let j = 0; j < patternDataArray_validesan.length; j++) {
                const element = patternDataArray_validesan[j];
                if (element.is_current_price === false && event.target.checked == true) {
                    element.is_current_price = true;
                    new_array_two.push(element)
                } else {
                    new_array_two.push(JSON.parse(JSON.stringify(element)))
                }
            }
            setPatternDataArray_validesan(new_array_two)
            const newSelected = patternDataArray.map((n: any, index: any) => index);
            setSelected(newSelected);
            return;
        }
        else {
            var new_array_two = [];
            for (let j = 0; j < patternDataArray_validesan.length; j++) {
                const element = patternDataArray_validesan[j];
                if (element.is_current_price === true && event.target.checked == false) {
                    element.is_current_price = false;
                    new_array_two.push(element)
                } else {
                    new_array_two.push(JSON.parse(JSON.stringify(element)))
                }
            }
            setPatternDataArray_validesan(new_array_two)
            setSelected([]);
        }

    };
    const handleSelectAllClickVlidet = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            var new_array_two = [];

            for (let j = 0; j < patternDataArray.length; j++) {
                const element = patternDataArray[j];
                if (element.is_current_price === false && event.target.checked == true) {
                    element.is_current_price = true;
                    new_array_two.push(element)
                } else {
                    new_array_two.push(JSON.parse(JSON.stringify(element)))
                }
            }


            setPatternDataArray(new_array_two)
        }
        else {
            var new_array_two = [];
            for (let j = 0; j < patternDataArray.length; j++) {
                const element = patternDataArray[j];
                if (element.is_current_price === true && event.target.checked == false) {
                    element.is_current_price = false;
                    new_array_two.push(element)
                } else {
                    new_array_two.push(JSON.parse(JSON.stringify(element)))
                }
            }
            setPatternDataArray(new_array_two)
        }
    }
    const handleClick = (event: React.MouseEvent<unknown>, index: any, Nametwo: string) => {
        const selectedIndex = selected.indexOf(index);
        setIserror(index)
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };


    const isSelected = (name: string, data: boolean) => selected.indexOf(name) !== -1;
    const numberMask = createNumberMask({
        prefix: '',
        suffix: '' // This will put the dollar sign at the end, with a space.
    })
    useEffect(() => {
        if (payablesListStastsRender && dataList?.GET_BULKPAYMENT_SUCCESS !== undefined) {
            setPayablesListStastRender(false)
            if (!!dataList?.GET_BULKPAYMENT_SUCCESS?.data) {
                var concetdata_add = []
                var newData_two = []
                for (let index = 0; index < dataList?.GET_BULKPAYMENT_SUCCESS?.data.length; index++) {
                    const element = dataList?.GET_BULKPAYMENT_SUCCESS?.data[index];
                    var object: MyType = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        amount: element.amount,
                        categories: !!element?.categories ? element?.categories : [],
                        is_current_price: false,
                        description: element.description,
                        account_number: element.account_number,
                    }
                    // M-Pesa Send Money
                    var object_validesan: MyTypesecendobject = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        // Send many
                        account_number: element.account_number?.toString(),
                        amount: element.amount,
                        description: element.description,
                        categories: element.categories,
                        is_current_price: false,
                        status: element.status
                    }
                    //M-Pesa Till Number
                    var object_validesan_two: MyTypeThreeobj = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        // Send many
                        account_number: element.account_number?.toString(),
                        amount: element.amount,
                        categories: element.categories,
                        is_current_price: false,
                        description: element.description,
                        status: element.status
                    }
                    // M-Pesa PayBill
                    var object_validesan_three: MyTypeForeobj = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        // Send many
                        account_number: element.account_number?.toString(),
                        paybill_number: element.paybill_number,
                        amount: element.amount,
                        categories: element.categories,
                        is_current_price: false,
                        description: element.description,
                        status: element.status
                    }
                    // Bank Transfers
                    var object_validesan_fore: MyTypeFiweobj = {
                        id: index,
                        supplier_id: element.supplier_id,
                        counterpart_name: element.counterpart_name,
                        payment_method: element.payment_method,
                        // Send many
                        account_number: element.account_number?.toString(),
                        bank_name: element.bank_name,
                        account_name: element.account_name,
                        amount: element.amount,
                        categories: element.categories,
                        is_current_price: false,
                        description: element.description,
                        status: element.status
                    }
                    if (element.payment_method == "M-Pesa Send Money") {
                        concetdata_add.push(JSON.parse(JSON.stringify(object_validesan)))
                    } else if (element.payment_method == "M-Pesa Till Number") {
                        concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_two)))
                    } else if (element.payment_method == "M-Pesa PayBill") {
                        concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_three)))

                    } else if (element.payment_method == "Bank Transfers") {
                        concetdata_add.push(JSON.parse(JSON.stringify(object_validesan_fore)))
                    }
                    newData_two.push(JSON.parse(JSON.stringify(object)));
                }
                setPatternDataArray_validesan(concetdata_add)
                setPatternDataArray(newData_two)
            }
            setLoaderData(false)

            setSelected([])
            setMessgebank(dataList?.GET_BULKPAYMENT_SUCCESS?.message)
        }
    }, [dataList, payablesListStastsRender]);
    useEffect(() => {
        if (payablesListRender && payablesListData?.BULKPAYABLES_SUCCESS !== undefined) {
            setPayablesListRender(false);
            const paybleD = payablesListData?.BULKPAYABLES_SUCCESS.map((el: any) => {
                return el.id
            })
            const bodyData = {
                payables: paybleD
            }
            dispatch(counterpartsActions.submitPaybles(bodyData) as any)
        }
    }, [payablesListData, payablesListRender]);
    useEffect(() => {
        var totalAmount = [];
        for (let index = 0; index < patternDataArray_validesan.length; index++) {
            const element = patternDataArray_validesan[index];
            if (element.is_current_price == true) {
                totalAmount.push(element)
            }
            if (element.categories.length == 0) {
                setVlidesan_data_two(false)
            } else {
                setVlidesan_data_two(true)
            }
            if (element.payment_method == "Bank Transfers" && !!element.account_name && !!element.bank_name) {
                setVlidesan_data(true)
            } else {
                setVlidesan_data(false)
            }
            if (element.payment_method == "M-Pesa PayBill" && !!element.paybill_number) {
                setVlidesan_paybill_data(true)
            } else {
                setVlidesan_paybill_data(false)
            }
        }
        let amountsubtotal = 0
        for (let index = 0; index < totalAmount.length; index++) {
            const element = totalAmount[index];
            amountsubtotal += +element.amount
        }
        setSelectedTotel(amountsubtotal)

    }, [patternDataArray_validesan]);
    const formInitailData = {
        users: patternDataArray,
    };
    const validationSchema = Yup.object().shape({
        users: Yup.array().of(
            Yup.object().shape({
                supplier_id: Yup.string()
                    .required("Please enter the payee's name."),
                payment_method: Yup.string().required("Enter a Payment method in the required field"),
                account_number: Yup.string().
                    required("Account number method in the required field")
                    .typeError('One of the payment details is invalid or unsupported. Kindly check and try again.')
                ,
                amount: Yup.string().required("Please enter the amount."),
            })
        )
    });
    function getStyles(errors: any, fieldName: any) {
        if (getIn(errors, fieldName)) {
            return {
                border: '1px solid red'
            }
        }
    }
    function getStylesExtra(errors: any, fieldName: any) {
        if (getIn(errors, fieldName)) {
            return {
                height: '40px',
                borderRadius: '0',
                fontSize: '12px',
                border: '1px solid red'
            }
        } else {
            return {
                height: '40px',
                borderRadius: '0',
                fontSize: '12px'
            }
        }
    }

    function deleteRow() {
        const list = patternDataArray.filter(function (entry: any, index: any) { return entry.is_current_price === false; })
        const list_two = patternDataArray_validesan.filter(function (entry: any, index: any) { return entry.is_current_price === false; })
        setSelected([])


        setPatternDataArray(list);
        setPatternDataArray_validesan(list_two)
    }
    console.log("validations", patternDataArray_validesan)
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        palette: { primary: { main: '#000' } },
    });
    const SubmitPayLoad = () => {
        var newData: any = [];
        for (let index = 0; index < patternDataArray_validesan.length; index++) {
            const element = patternDataArray_validesan[index];
            var findresultsCat: any = []
            for (let index = 0; index < element.categories.length; index++) {
                const categorieselement = element.categories[index];
                findresultsCat.push(categorieselement)
            }

            var account_number = element?.account_number?.toString()
            //    M-Pesa Send Money
            var object_validesan = {
                supplier_id: element.supplier_id,
                counterpart_name: element.counterpart_name,
                payment_method: element.payment_method,
                account_number: element.account_number,
                amount: +element.amount,
                description: element.description,
                categories: !!findresultsCat ? findresultsCat : [],
            }
            // M-Pesa Till Number
            var object_validesan_two = {
                supplier_id: element.supplier_id,
                counterpart_name: element.counterpart_name,
                payment_method: element.payment_method,
                account_number: element.account_number,
                amount: +element.amount,
                categories: !!findresultsCat ? findresultsCat : [],
                description: element.description,
            }
            // M-Pesa PayBill
            var object_validesan_three = {
                supplier_id: element.supplier_id,
                counterpart_name: element.counterpart_name,
                payment_method: element.payment_method,
                account_number: element.account_number,
                paybill_number: element.paybill_number,
                amount: element.amount,
                categories: !!findresultsCat ? findresultsCat : [],
                description: element.description,
            }
            //Bank Transfers
            var object_validesan_fore = {
                supplier_id: element.supplier_id,
                counterpart_name: element.counterpart_name,
                payment_method: element.payment_method,
                account_number: element.account_number,
                bank_name: +element.bank_name,
                account_name: element.account_name,
                amount: +element.amount,
                categories: !!findresultsCat ? findresultsCat : [],
                description: element.description,
            }

            if (element.payment_method == "M-Pesa Send Money" && element.is_current_price == true) {
                newData.push(object_validesan)

            }
            if (element.payment_method == "M-Pesa Till Number" && element.is_current_price == true) {
                newData.push(object_validesan_two)

            } if (element.payment_method == "M-Pesa PayBill" && element.is_current_price == true) {
                newData.push(object_validesan_three)


                // const sanitizedValue = element.account_number.replace(/[^\w\s]/gi, ''); // Regex to remove special characters

                // if (element?.account_number !== sanitizedValue) {
                //     paybill_valid = 'PayBill_type'
                // }
            }
            if (element.payment_method == "Bank Transfers" && element.is_current_price == true) {

                newData.push(object_validesan_fore);
            }
          
        }
        setLoaderData(true)
        dispatch(paymentJourneyActions.getBulk_payment({ data: newData }) as any)
        // newData.push(object);
        setTimeout(() => {
            setPayablesListStastRender(true)
        }, 10000)
    }
    const ModelClose = () => {
        setModelOpen(false)
    }
    return (
        <>
            <ThemeProvider theme={theme}>
                {ModelOpen ?
                    <Model1Line TransactionExist={ModelOpen} onCloseTransactionExist={ModelClose} Message={'Please take a moment to review your payees and payment details before proceeding with the bulk payment.'} onSubmit={SubmitPayLoad} /> : ""}
                <div className="bill-container__payables">
                    <PageHeader title="Bulk Payments" extra={<Box sx={{ height: "46px" }}></Box>} />

                    <div className={styles.contenar_data_maiana_tebal}>
                        <ListSkeleton loader={loderinapi} height={'500px'} width={'100%'}
                            element={

                                <Box sx={{ width: '100%' }}>
                                    <Paper sx={{ width: '100%', boxShadow: '0px 4px 12px rgba(89, 87, 87, 0.16)' }}>
                                        <Toolbar
                                            className={styles.tebaldata_hedinh}
                                            style={{ padding: "20px 14px" }}
                                            sx={{
                                                pl: { sm: 2 },
                                                pr: { xs: 1, sm: 1 },

                                            }}
                                        >
                                            {loaderData && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                                                <CircularProgress color="success" />
                                            </Box>}
                                            <Typography
                                                sx={{ flex: '1 1 100%' }}
                                                variant="h6"
                                                id="tableTitle"
                                                component="div"
                                                className={styles.addnewtext}
                                            >
                                                Please confirm the payment details
                                            </Typography>
                                            <div className={styles.div_uplod_data_use}>
                                                <label className={styles.save_btn22} >
                                                    Upload More <AddIcon />
                                                    <input id="inputTag" className={styles.addaffafagga} type="file" onChange={(file: any) => {
                                                        handleCSVUpload(file)
                                                        setFile(file.target.files[0]);
                                                    }}
                                                        accept=".xlsx" />
                                                </label>
                                            </div>
                                        </Toolbar>
                                        <Divider className={styles.addborderin} />
                                        <div className={styles.addlowlis}></div>
                                        <Formik
                                            enableReinitialize
                                            initialValues={formInitailData}
                                            validationSchema={validationSchema}
                                            onSubmit={async (values, { setSubmitting, resetForm }) => {
                                                var data_vilidesan: string = 'valid'
                                                for (let index = 0; index < patternDataArray_validesan.length; index++) {
                                                    const element = patternDataArray_validesan[index];
                                                    if (element.categories.length == 0) {
                                                        data_vilidesan = 'categories_error';
                                                    }
                                                    if (element.payment_method == "Bank Transfers" && (element.account_name == '' || element.bank_name == '')) {
                                                        data_vilidesan = 'Bank_Transfers_error'
                                                    }
                                                    if (element.payment_method == "Bank Transfers") {

                                                    }
                                                    if (element.payment_method == "M-Pesa PayBill" && !element.paybill_number) {
                                                        data_vilidesan = 'MPesa_PayBill_error'
                                                    }
                                                }
                                                if (data_vilidesan == 'valid') {
                                                    var newData: any = [];
                                                    var datavalid: string = 'valid';
                                                    var paybill_valid: string = "valid"
                                                    for (let index = 0; index < patternDataArray_validesan.length; index++) {
                                                        const element = patternDataArray_validesan[index];
                                                        var findresultsCat: any = []
                                                        for (let index = 0; index < element.categories.length; index++) {
                                                            const categorieselement = element.categories[index];
                                                            findresultsCat.push(categorieselement)
                                                        }
                                                        var account_number = element?.account_number?.toString()
                                                        if (element.payment_method == "M-Pesa Send Money" && element.is_current_price == true) {
                                                            if (account_number?.length != 12) {
                                                                datavalid = 'Send_Money'
                                                            }
                                                            if (element?.amount < 10) {
                                                                datavalid = 'Send_Money_amount'
                                                            }
                                                            if (element?.amount > 250000) {
                                                                datavalid = 'Mpesa'
                                                            }
                                                        }
                                                        if (element.payment_method == "M-Pesa Till Number" && element.is_current_price == true) {
                                                            if (element?.amount > 250000) {
                                                                datavalid = 'Mpesa'
                                                            }
                                                        } if (element.payment_method == "M-Pesa PayBill" && element.is_current_price == true) {
                                                            if (element?.amount > 250000) {
                                                                datavalid = 'Mpesa'
                                                            }
                                                            if (element?.account_number?.length > 14) {
                                                                datavalid = 'PayBill'
                                                            }
                                                            // const sanitizedValue = element.account_number.replace(/[^\w\s]/gi, ''); // Regex to remove special characters

                                                            // if (element?.account_number !== sanitizedValue) {
                                                            //     paybill_valid = 'PayBill_type'
                                                            // }
                                                        }
                                                        if (element.payment_method == "Bank Transfers" && element.is_current_price == true) {
                                                            if (element?.amount >= 1000000) {
                                                                datavalid = 'BankTransfers'
                                                            }
                                                            var stringno = element?.account_number.toString()
                                                            const result = stringno?.replace(/\D/g, '');


                                                            if (stringno !== result) {
                                                                datavalid = 'BankTransfers_type_number';
                                                            }
                                                        }

                                                        // newData.push(object);
                                                    }

                                                    if (datavalid == 'valid') {
                                                        // if (paybill_valid == "PayBill_type") {
                                                        //     toast.success("One of the payment details is invalid or unsupported. Kindly check and try again.")
                                                        // }
                                                        // setLoaderData(true)
                                                        setModelOpen(true)
                                                        // dispatch(paymentJourneyActions.getBulk_payment({ data: newData }) as any)
                                                        setValid(datavalid)

                                                    } else {
                                                        setValid(datavalid)
                                                    }

                                                    

                                                }

                                            }}
                                        >
                                            {({ values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                isSubmitting }) => (
                                                <>
                                                    <Form onSubmit={handleSubmit} >
                                                        <TableContainer className={styles.tebal_contenar_data}>
                                                            <Table
                                                                className={styles.addteballist}
                                                                sx={{ minWidth: 750 }}
                                                                aria-labelledby="tableTitle"
                                                                size={dense ? 'small' : 'medium'}
                                                            >
                                                                <TableHead className={styles.hedarliath}>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <Checkbox
                                                                                icon={<img src="/Checkbox.svg" />}
                                                                                checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                                color="primary"
                                                                                indeterminate={selected.length > 0 && selected.length < patternDataArray.length}
                                                                                checked={patternDataArray.length > 0 && selected.length === patternDataArray.length}
                                                                                onChange={(e: any) => {
                                                                                    handleSelectAllClick(e)
                                                                                    handleSelectAllClickVlidet(e)
                                                                                }}
                                                                                inputProps={{
                                                                                    'aria-label': 'select all desserts',
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Payee Name
                                                                        </TableCell>
                                                                        <TableCell>

                                                                            Payment Method
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            Account Number
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Paybill Number
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Bank Name
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Bank Account Name
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            Category
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            Description
                                                                        </TableCell>
                                                                        <TableCell className={styles.listactivetabal}>
                                                                            Amount
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>

                                                                <TableBody>


                                                                    <FieldArray
                                                                        name="users"
                                                                        render={arrayHelpers => {
                                                                            const users = values.users.length < 0 ? patternDataArray_validesan : patternDataArray_validesan;
                                                                            return (
                                                                                <>
                                                                                    {users && users.length > 0
                                                                                        ? users.map((user: any, index: any) => {
                                                                                            var value = user;
                                                                                            const isItemSelected = isSelected(index, user.is_current_price);
                                                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                                                            return (
                                                                                                <TableRow hover key={index}>

                                                                                                    <TableCell>
                                                                                                        <Checkbox
                                                                                                            color="primary"
                                                                                                            icon={<img src="/Checkbox.svg" />}
                                                                                                            checkedIcon={<img src="/Checkbox_two.svg" />} name={`users.${index}.is_current_price`}
                                                                                                            checked={isItemSelected} onClick={(event: any) => {
                                                                                                                handleClick(event, index, user.id)
                                                                                                                var new_array_two = [];
                                                                                                                var array = []
                                                                                                                for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                    const element = patternDataArray_validesan[j];
                                                                                                                    if (index == j && element.is_current_price == false) {
                                                                                                                        element.is_current_price = true;
                                                                                                                        new_array_two.push(element)
                                                                                                                    } else if (index == j && element.is_current_price == true) {
                                                                                                                        element.is_current_price = false;
                                                                                                                        new_array_two.push(element)
                                                                                                                    } else {
                                                                                                                        new_array_two.push(JSON.parse(JSON.stringify(element)))
                                                                                                                    }
                                                                                                                }
                                                                                                                for (let j = 0; j < patternDataArray.length; j++) {
                                                                                                                    const element = patternDataArray[j];
                                                                                                                    if (index == j && element.is_current_price == false) {
                                                                                                                        element.is_current_price = true;
                                                                                                                        array.push(element)
                                                                                                                    } else if (index == j && element.is_current_price == true) {
                                                                                                                        element.is_current_price = false;
                                                                                                                        array.push(element)
                                                                                                                    } else {
                                                                                                                        array.push(JSON.parse(JSON.stringify(element)))
                                                                                                                    }
                                                                                                                }
                                                                                                                setPatternDataArray(array)
                                                                                                                setPatternDataArray_validesan(new_array_two)
                                                                                                            }}

                                                                                                            inputProps={{
                                                                                                                'aria-labelledby': labelId,
                                                                                                            }}
                                                                                                        />

                                                                                                    </TableCell>

                                                                                                    <TableCell>
                                                                                                        <Box>
                                                                                                            <Form.Select name={`users.${index}.supplier_id`}
                                                                                                                style={getStyles(errors, `users.${index}.supplier_id`)}
                                                                                                                className={styles.tabalinputdata}
                                                                                                                onChange={(e: any) => {
                                                                                                                    setDataselect(e.target.value)
                                                                                                                    let text = e.target.value;
                                                                                                                    var newArray = [];
                                                                                                                    var newArray_two = [];
                                                                                                                    for (let j = 0; j < patternDataArray.length; j++) {
                                                                                                                        const element = patternDataArray[j];
                                                                                                                        if (index == j) {

                                                                                                                            value.supplier_id = text;
                                                                                                                            const selected = counterpartsList.find((item: any) => {
                                                                                                                                return item.monite_counterpart_id == text
                                                                                                                            })
                                                                                                                            const payeeName = selected.counterpart_type == 'individual' ? selected.contact_first_name + ' ' + selected.contact_last_name : selected.name
                                                                                                                            value.counterpart_name = payeeName;
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                        const element = patternDataArray_validesan[j];
                                                                                                                        if (index == j) {

                                                                                                                            value.supplier_id = text;
                                                                                                                            const selected = counterpartsList.find((item: any) => {
                                                                                                                                return item.monite_counterpart_id == text
                                                                                                                            })
                                                                                                                            const payeeName = selected.counterpart_type == 'individual' ? selected.contact_first_name + ' ' + selected.contact_last_name : selected.name
                                                                                                                            value.counterpart_name = payeeName;
                                                                                                                            newArray_two.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray_two.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    setPatternDataArray_validesan(newArray_two)
                                                                                                                    setPatternDataArray(newArray)
                                                                                                                }

                                                                                                                }                                                                                                >
                                                                                                                <option selected value="">Select payee</option>
                                                                                                                {/* <option>ADDA</option> */}
                                                                                                                {counterpartsList?.map((arrayElement: any, index: number) => {
                                                                                                                    const selected = value.supplier_id == arrayElement.monite_counterpart_id ? true : false
                                                                                                                    return (
                                                                                                                        <option selected={selected} value={arrayElement.monite_counterpart_id} key={index + 1}>{arrayElement.counterpart_type == 'individual' ? arrayElement.contact_first_name + ' ' + arrayElement.contact_last_name : arrayElement.name}</option>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </Form.Select>
                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        <Box >
                                                                                                            <Form.Select name={`users.${index}.payment_method`}
                                                                                                                style={getStyles(errors, `users.${index}.payment_method`)}
                                                                                                                className={styles.tabalinputdata}
                                                                                                                value={value.payment_method}
                                                                                                                onChange={(e: any) => {

                                                                                                                    let text = e.target.value;
                                                                                                                    var newArray = [];
                                                                                                                    var data_two = []
                                                                                                                    for (let j = 0; j < patternDataArray.length; j++) {
                                                                                                                        const element = patternDataArray[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.payment_method = text;
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                        const element = patternDataArray_validesan[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.payment_method = text;
                                                                                                                            data_two.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            data_two.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    setPatternDataArray_validesan(data_two)
                                                                                                                    setPatternDataArray(newArray)
                                                                                                                }}                                                                                               >
                                                                                                                <option selected value="">Select Payment Method</option>                                                                                                        {selectMothed?.map((arrayElement: any, index: number) => {
                                                                                                                    const selected = value.payment_method == arrayElement.name ? true : false
                                                                                                                    return (
                                                                                                                        <option selected={selected} value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </Form.Select>
                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        <Box >

                                                                                                            <TextField
                                                                                                                style={getStyles(errors, `users.${index}.account_number`)} className={user.status == false ? styles.error_messege : styles.tabalinputdata + ""} type="text" name={`users.${index}.account_number`}
                                                                                                                onChange={(value1: any) => {
                                                                                                                    let text = value1.target.value;
                                                                                                                    var newArray = [];
                                                                                                                    var newarray_two = []
                                                                                                                    for (let j = 0; j < patternDataArray.length; j++) {
                                                                                                                        const element = patternDataArray[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.account_number = text;
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                        const element = patternDataArray_validesan[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.account_number = text;
                                                                                                                            newarray_two.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newarray_two.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    setPatternDataArray_validesan(newarray_two)
                                                                                                                    setPatternDataArray(newArray)

                                                                                                                }}
                                                                                                                value={value.account_number} />

                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        <Box >
                                                                                                            <TextField
                                                                                                                style={getStyles(errors, `users.${index}.paybill_number`)}
                                                                                                                className={value.paybill_number == '' && value.payment_method == "M-Pesa PayBill" ? styles.err_border_lineee : styles.tabalinputdata + ""}
                                                                                                                name={`users.${index}.paybill_number`}
                                                                                                                disabled={value.payment_method == 'M-Pesa PayBill' ? false : true}
                                                                                                                onChange={(value1: any) => {
                                                                                                                    let text = value1.target.value;
                                                                                                                    var newArray = [];
                                                                                                                    for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                        const element = patternDataArray_validesan[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.paybill_number = text;
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }

                                                                                                                    setPatternDataArray_validesan(newArray)
                                                                                                                }}
                                                                                                                value={value.paybill_number}

                                                                                                            />                                                                                                    </Box>
                                                                                                    </TableCell>
                                                                                                    <TableCell className={styles.maina_withe_ajajha} style={{ width: '14%' }}>
                                                                                                        <Box>
                                                                                                            <Form.Select name={`users.${index}.Bank`}
                                                                                                                style={getStyles(errors, `users.${index}.Bank`)}
                                                                                                                value={value.bank_name}
                                                                                                                disabled={value.payment_method == "Bank Transfers" ? false : true}
                                                                                                                className={value.bank_name == "" && value.payment_method == "Bank Transfers" ? styles.err_border_lineee : styles.tabalinputdata}
                                                                                                                onChange={(e: any) => {
                                                                                                                    setDataselect(e.target.value)
                                                                                                                    let text = Number(e.target.value);
                                                                                                                    var newArray = [];
                                                                                                                    for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                        const element = patternDataArray_validesan[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.bank_name = text;
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    setPatternDataArray_validesan(newArray)
                                                                                                                }

                                                                                                                }                                                                                                >
                                                                                                                <option selected value="">Select Bank</option>                                                                                                        {bankList?.map((arrayElement: any, index: number) => {
                                                                                                                    const selected = value.bank_name == arrayElement.id ? true : false
                                                                                                                    return (
                                                                                                                        <option selected={selected} value={arrayElement.id} key={index + 1}>{arrayElement.bank_name}</option>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </Form.Select>
                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        <Box >
                                                                                                            <TextField
                                                                                                                style={getStyles(errors, `users.${index}.account_name`)}
                                                                                                                className={value.account_name == "" && value.payment_method == "Bank Transfers" ? styles.err_border_lineee : styles.tabalinputdata}
                                                                                                                name={`users.${index}.account_name`}
                                                                                                                disabled={value.payment_method == "Bank Transfers" ? false : true}
                                                                                                                onChange={(value1: any) => {
                                                                                                                    let text = value1.target.value;
                                                                                                                    var newArray = [];
                                                                                                                    for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                        const element = patternDataArray_validesan[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.account_name = text;
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    setPatternDataArray_validesan(newArray)
                                                                                                                }} value={value.account_name} />                                                                                                            </Box>
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        <Box>
                                                                                                            <Multiselect
                                                                                                                className={value.categories.length == 0 ? styles.err_border_lineee : styles.addnewselect}
                                                                                                                options={tagsList} // Options to display in the dropdown
                                                                                                                selectedValues={value.categories} // Preselected value to persist in dropdown
                                                                                                                onSelect={(selectedList: any) => {
                                                                                                                    var newArray = [];
                                                                                                                    var newArray_two = [];
                                                                                                                    for (let j = 0; j < patternDataArray.length; j++) {
                                                                                                                        const element = patternDataArray[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.categories = selectedList;
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                        const element = patternDataArray_validesan[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.categories = selectedList;
                                                                                                                            newArray_two.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray_two.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }
                                                                                                                    setPatternDataArray(newArray)
                                                                                                                    setPatternDataArray_validesan(newArray_two)
                                                                                                                }} // Function will trigger on select event
                                                                                                                onRemove={(selectedList: any) => {
                                                                                                                    if (selectedList.length == 0) {
                                                                                                                        setVlidesan_data_two(false)

                                                                                                                        var newArray_two = [];
                                                                                                                        var newArray = [];
                                                                                                                        for (let j = 0; j < patternDataArray.length; j++) {
                                                                                                                            const element = patternDataArray[j];
                                                                                                                            if (index == j) {
                                                                                                                                value.categories = selectedList;
                                                                                                                                newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                            } else {
                                                                                                                                newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                            }
                                                                                                                        }
                                                                                                                        for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                            const element = patternDataArray_validesan[j];
                                                                                                                            if (index == j) {
                                                                                                                                value.categories = selectedList;
                                                                                                                                newArray_two.push(JSON.parse(JSON.stringify(value)))
                                                                                                                            } else {
                                                                                                                                newArray_two.push(JSON.parse(JSON.stringify(element)))
                                                                                                                            }
                                                                                                                        }
                                                                                                                        setPatternDataArray(newArray)
                                                                                                                        setPatternDataArray_validesan(newArray_two)
                                                                                                                    }
                                                                                                                }} displayValue="name" // Property selectedList name to display in the dropdown options
                                                                                                                closeOnSelect={true}
                                                                                                            />
                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        <Box >
                                                                                                            <TextField
                                                                                                                className={styles.tabalinputdata + ""}
                                                                                                                onChange={(value1: any) => {
                                                                                                                    let text = value1.target.value;
                                                                                                                    var newArray = [];
                                                                                                                    for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                        const element = patternDataArray_validesan[j];
                                                                                                                        if (index == j) {
                                                                                                                            value.description = text;
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                        } else {
                                                                                                                            newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                        }
                                                                                                                    }

                                                                                                                    setPatternDataArray_validesan(newArray)
                                                                                                                }}
                                                                                                                value={value.description}

                                                                                                            />                                                                                                    </Box>
                                                                                                    </TableCell>
                                                                                                    <TableCell className={styles.main_amount_datataaa}>
                                                                                                        <Box>
                                                                                                            <InputGroup className="doc-input-wrapper input" style={{ padding: 0, }}>

                                                                                                                <MaskedInput
                                                                                                                    style={value?.payment_method == "M-Pesa Send Money" && (value?.amount || !value?.amount) < 10 ? {
                                                                                                                        height: '40px',
                                                                                                                        borderRadius: '0',
                                                                                                                        fontSize: '12px',
                                                                                                                        border: '1px solid red'
                                                                                                                    } : {
                                                                                                                        height: '40px',
                                                                                                                        borderRadius: '0',
                                                                                                                        fontSize: '12px'
                                                                                                                    }}
                                                                                                                    mask={numberMask}
                                                                                                                    className="doc-form-input form-control"
                                                                                                                    placeholder="Total"
                                                                                                                    id={styles.add_id_select_data_list}
                                                                                                                    value={(value?.amount)} name={`users.${index}.amount`}
                                                                                                                    onChange={(e) => {
                                                                                                                        let text = e.target.value;
                                                                                                                        var newArray = [];
                                                                                                                        var newArray_two = []; for (let j = 0; j < patternDataArray.length; j++) {
                                                                                                                            const element = patternDataArray[j];
                                                                                                                            if (index == j) {
                                                                                                                                var amount_number = text.replaceAll(',', '')
                                                                                                                                value.amount = Number(amount_number);

                                                                                                                                newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                                            } else {

                                                                                                                                newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                                            }
                                                                                                                        }
                                                                                                                        for (let j = 0; j < patternDataArray_validesan.length; j++) {
                                                                                                                            const element = patternDataArray_validesan[j];
                                                                                                                            if (index == j) {
                                                                                                                                value.amount = text.replaceAll(',', '');

                                                                                                                                newArray_two.push(JSON.parse(JSON.stringify(value)))
                                                                                                                            } else {
                                                                                                                                newArray_two.push(JSON.parse(JSON.stringify(element)))
                                                                                                                            }
                                                                                                                        }

                                                                                                                        setPatternDataArray(newArray)
                                                                                                                        setPatternDataArray_validesan(newArray_two)


                                                                                                                    }}
                                                                                                                    aria-describedby="basic-addon3"

                                                                                                                />
                                                                                                                <InputGroup.Text className="input-mask" id={styles.basic_addon3} style={{ borderRadius: '00' }}>
                                                                                                                    KES
                                                                                                                </InputGroup.Text>
                                                                                                            </InputGroup>
                                                                                                        </Box>
                                                                                                    </TableCell>
                                                                                                </TableRow>

                                                                                            );
                                                                                        }) : null
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }}
                                                                    />


                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: "10px 0px" }}>

                                                            {Valid == 'PayBill' ?
                                                                <div className="red invalid-feedback d-block"
                                                                    style={{ width: "50%", padding: "0px 15px" }}>
                                                                    The Paybill account number must not exceed 13 digits.</div>
                                                                : Valid == 'Send_Money' ? <div style={{ width: "50%", padding: "0px 15px" }}
                                                                    className="red invalid-feedback d-block">
                                                                    Please enter 9 digit phone number</div> :
                                                                    Valid == "Send_Money_amount" ?
                                                                        <div style={{ width: "50%", padding: "0px 15px", color: "red" }}
                                                                            className="red invalid-feedback d-block">
                                                                            For Send Money, please enter an amount greater than or equal to 10.
                                                                        </div>
                                                                        : Valid == 'Mpesa' ?
                                                                            <div style={{ width: "50%", padding: "0px 15px" }}
                                                                                className="red invalid-feedback d-block">
                                                                                The amount exceeds the maximum limit of KES 250,000 for M-Pesa payments. Please enter a lower amount to proceed with M-Pesa or select a different payment method.
                                                                            </div>
                                                                            : Valid == 'BankTransfers' ?
                                                                                <div style={{ width: "50%", padding: "0px 15px" }}
                                                                                    className="red invalid-feedback d-block">
                                                                                    The amount exceeds the maximum limit of KES 999,999 for Pesalink payments. Please enter a lower amount to proceed with the Pesalink payment.
                                                                                </div>
                                                                                : selectedtotal > balance[0]?.available_balance / 100 ?
                                                                                    <div style={{ width: "50%", padding: "0px 15px" }}
                                                                                        className="red invalid-feedback d-block">
                                                                                        Please top up your account to cover the payment.
                                                                                    </div> :
                                                                                    !!messgebank ? <div style={{ width: "50%", padding: "0px 15px" }}
                                                                                        className="red invalid-feedback d-block">{messgebank}</div>
                                                                                        : Valid == "BankTransfers_type_number" ?
                                                                                            <div style={{ width: "50%", padding: "0px 15px" }}
                                                                                                className="red invalid-feedback d-block">
                                                                                                One of the payment details is invalid or unsupported. Kindly check and try again.
                                                                                            </div> :
                                                                                            <div style={{ width: "50%", padding: "0px 15px" }}></div>
                                                            }

                                                            <div style={{ padding: '15px 15px 0px 0px', width: "50%" }}>
                                                                <div style={{ display: "flex", justifyContent: "end" }}>
                                                                    <p className={styles.text_fast_datataa}>
                                                                        Balance
                                                                    </p>
                                                                    <p className={styles.call_heding_total_data}>
                                                                        KES {getKESFormate(balance[0]?.available_balance / 100)}
                                                                    </p>
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "end" }}>
                                                                    <p className={styles.text_fast_datataa}>
                                                                        Subtotal
                                                                    </p>
                                                                    <p className={styles.call_heding_total_data}>
                                                                        KES {totel_amount}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className={styles.add_ajajjahahag} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0px' }}>
                                                            <div className={styles.add_delete_data_listtss}>
                                                                {
                                                                    selected.length > 0 && (
                                                                        <Button className={styles.save_btn_delete_btn} onClick={() => { deleteRow() }}>
                                                                            Delete
                                                                        </Button>
                                                                    )
                                                                }

                                                                <Button color="#025041" className={styles.white_btn} style={{ marginLeft: "10px", padding: "12px 30px" }} onClick={() => { navigate("/balance") }}>
                                                                    Cancel
                                                                </Button>
                                                            </div>
                                                            <div className={styles.div_laiiaajjajjaa}>
                                                                {selected.length > 0 && selectedtotal <= balance[0]?.available_balance / 100 ?
                                                                    <Button
                                                                        disabled={loaderData}
                                                                        type="submit"
                                                                        color="#025041"
                                                                        className={styles.save_btn}
                                                                    >
                                                                        {loaderData == true && <span className="spinner-border spinner-border-sm mr-1"></span>} Submit for Approval
                                                                    </Button> : <Button
                                                                        color="#025041"
                                                                        className={styles.save_btn}
                                                                        disabled
                                                                    >
                                                                        Submit for Approval
                                                                    </Button>}

                                                            </div>
                                                        </div>
                                                    </Form>
                                                </>
                                            )}
                                        </Formik>
                                    </Paper>
                                </Box>
                            }>
                        </ListSkeleton>
                    </div>
                </div >
            </ThemeProvider>
        </>
    );
}

export default Bulk_Payments;
type MyType = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    amount: number;
    description: any;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    account_number: string;
};
type MyTypesecendobject = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    account_number: any;
    amount: number;
    description: any;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    status: boolean;
}
type MyTypeThreeobj = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    account_number: any;
    amount: number;
    description: any;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    status: boolean;
}
type MyTypeForeobj = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    account_number: any;
    paybill_number: any;
    amount: number;
    description: any;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    status: boolean;

}
type MyTypeFiweobj = {
    id: number;
    supplier_id: any;
    counterpart_name: any;
    payment_method: any;
    account_number: any;
    description: any;
    bank_name: any;
    account_name: any;
    amount: number;
    categories: { id: any; name: any; }[];
    is_current_price: boolean;
    status: boolean;
};
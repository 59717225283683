import "./step4.scss"
import { useState } from "react"
import * as Yup from 'yup';
import { Formik } from "formik";
import { Button, Form } from 'react-bootstrap';
import { getUser } from "../../../../../../../../_helpers"
import { AuthorizeTransaction } from "../../../../../../../ui/ReuseableComponents/AuthorizeTransaction/AuthorizeTransaction"


const Step4 = ({ Submitsetp3or4, setEnableButton, setTime, enableButton, time, stepupdatefun, ExpressPayment, setUseLayout }: any) => {

  const [filedValue, setFiledValue] = useState({
    Otp: ""
  })
  const validationSchema = Yup.object().shape({
    Otp: Yup.string()
      .length(6, 'OTP must be exactly 6 digits')
      .required('OTP is required'),
  });

  const user = getUser()?.data ?? null;
  return (
    <Formik
      initialValues={filedValue}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        ExpressPayment(values.Otp)
        setUseLayout("excludenavlayout")
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit} >
          <AuthorizeTransaction
            time={time}
            enableButton={enableButton}
            formik={formik}
            onCancel={() => {
              stepupdatefun(2)
              setUseLayout("defaultlayout")
              setEnableButton(true)
              setTime(0)
            }}
            onResendOtp={() => { Submitsetp3or4() }}
            onClick={() => { formik.handleSubmit() }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default Step4

import { Box, Typography, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CustomInput } from '../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import { DefaultBtn } from '../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { TheSelectBox } from '../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import "./bankaccount.scss"
import { AddCircleIcon } from '../../../../../../../ui/ReuseableComponents/Icons/Icons'
import { LightIconAndTextButton } from '../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { InputNumber } from '../../../../../../../ui/input'
import { BankTabe, DigitalCurrency, Swiftteb } from './methoadCom'
import { ErrorMessage } from '../../../../../../../ui/eroor'


const BankAccount = ({ MethoadTitel, methodError, bankData, currency, PayOutMethodsByCountryId, bankTransferMethods, setPayOutMethodsByCountryId }: any) => {
    const [renderPage, setRenderPage] = useState(true)

    const [selectedBank, setSelectedBank] = useState(null);
    const [activeButton, setActiveButton] = useState(bankTransferMethods[0]?.title);
    const [index, setIndex] = useState(PayOutMethodsByCountryId)
    const [addNewPaymentdisabled, setAddNewPaymentDisabled] = useState(true);
    const handleBankChange = (event: any) => {
        setSelectedBank(event.target.value);
    };
    console.log(activeButton, renderPage, "renderPage");
    useEffect(() => {
        if (renderPage) {
            var Indexloop = []
            for (let index = 0; index < PayOutMethodsByCountryId.length; index++) {
                const element = PayOutMethodsByCountryId[index];
                if (element.payment_method === "Bank Transfer") {
                    Indexloop.push(index);
                }
            }
            console.log(Indexloop[0], "Indexloop");
            setIndex(Indexloop[0])
            setRenderPage(false)
        }
    }, [PayOutMethodsByCountryId, renderPage])
    const handleButtonClick = (buttonName: any, indexloop: any) => {
        setActiveButton(buttonName);
        setIndex(indexloop)
    };

    // const renderComponent = () => {
    //     switch (activeButton) {
    //         case 'pesalink':
    //             return <BankTabe />;
    //         case 'rtgs':
    //             return <BankTabe />;
    //         case 'eft':
    //             return <BankTabe />;
    //         case 'Swift':
    //             return <Swiftteb />
    //             case 'swift':
    //                 return <Swiftteb />
    //         default:
    //             return null;
    //     }
    // };



    return (

        <Box className="mt-3">
            <Box className="d-flex justify-content-start align-items-center flex-wrap gap-2 mb-5">
                {PayOutMethodsByCountryId.map((item: any, index: number) => {

                    return (
                        <>
                            {/* {item.key == "pesalink" ? "" : */}
                            {item.payment_method === "Bank Transfer" ?
                                <Button
                                    // className={`mpesa_btn ${activeButton === item.key ? 'active-btn' : 'inactive-btn'}`}
                                    className={`mpesa_btn ${activeButton === item.title ? 'active-btn' : index === 0 && !bankTransferMethods?.find((c: any) => c.title === activeButton) ? "active-btn" : ""}`}
                                    onClick={() => handleButtonClick(item.title, index)}
                                >
                                    {item.title}
                                </Button> : ""}
                        </>
                    )
                })}
            </Box>
            {activeButton == "RTGS" ?
                <BankTabe bankData={bankData} addNewPaymentdisabled={addNewPaymentdisabled} PayOutMethodsByCountryId={PayOutMethodsByCountryId} setPayOutMethodsByCountryId={setPayOutMethodsByCountryId} updatedIndex={index} /> :
                activeButton == "PESALINK" ?
                    <BankTabe bankData={bankData} addNewPaymentdisabled={addNewPaymentdisabled} PayOutMethodsByCountryId={PayOutMethodsByCountryId} setPayOutMethodsByCountryId={setPayOutMethodsByCountryId} updatedIndex={index} /> :
                    activeButton == "EFT" ?
                        <BankTabe bankData={bankData} addNewPaymentdisabled={addNewPaymentdisabled} PayOutMethodsByCountryId={PayOutMethodsByCountryId} setPayOutMethodsByCountryId={setPayOutMethodsByCountryId} updatedIndex={index} /> :
                        activeButton == "SWIFT" ?
                            <Swiftteb PayOutMethodsByCountryId={PayOutMethodsByCountryId} setPayOutMethodsByCountryId={setPayOutMethodsByCountryId} updatedIndex={index} />
                            // element.title == "Digital Currency"?

                            : activeButton == "Digital Currency" ? <DigitalCurrency PayOutMethodsByCountryId={PayOutMethodsByCountryId} setPayOutMethodsByCountryId={setPayOutMethodsByCountryId} updatedIndex={index} /> : ""
            }
            {!!methodError && activeButton == MethoadTitel ? <ErrorMessage style={{ marginTop: "10px", marginBottom: "10px" }} >{methodError}</ErrorMessage> : ""}

        </Box>

    )
}

export default BankAccount
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import styles from './reviewInformation.module.scss';
import { choiceActions, entityActions } from '../../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllData } from '../../../_reducers/entity.reducer';
import { getUser } from '../../../_helpers';
import { use } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  // color: theme.palette.text.secondary,
}));

const ReviewInformation = ({ setSetpNumberAuth, updateStep, path }: any) => {
  const [rendar, setRender] = React.useState(true);
  const businessDetails: any = useSelector(
    (state: any) => state.getBusinessDetailsReducer.data
  );
  const industries = useSelector(
    (state: any) => state.getIndustriesReducer.data
  );
  const [datarenser, setDataRenser] = React.useState(true);
  const [LoaderAPi, setLoaderAPi] = React.useState(false);
  const [fastSetp, setFastStep] = React.useState(true)
  const [BusinessDetailsarrow, setBusinessDetailsarrow] = React.useState(true)
  const [OwnerDetailsarrow, setOwnerDetailsarrow] = React.useState(true)
  const [ExpectedActivityarrow, setExpectedActivityarrow] = React.useState(true)
  const [Currencyarrow, setCurrencyArrow] = React.useState(true)
  const [loader, setLoader] = React.useState(false)
  const dispatch = useDispatch();
  const APIData = useSelector((state) => selectAllData(state));
  const user = getUser()?.data ?? null;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (rendar) {
      var body = {
        user_id: user?.user_id,
      };
      // dispatch(entityActions.getBusinessDetails(body) as any);
      dispatch(choiceActions.getBusinessDetails(user?.user_id) as any);
      dispatch(choiceActions.getIndustries() as any);

      setRender(false);
    }
  }, [rendar]);

  var Payload: any = localStorage.getItem('register_payload');
 

  const handleProceed = () => {
    var Payload: any = localStorage.getItem('register_payload');
    const callBack = () => {
      setSetpNumberAuth(6);
      updateStep(6, true, navigate(path));
      navigate(path, {
        state: {
          entityTYpe: businessDetails?.businessType,
        },
      });
      toast.success('An OTP was sent to your email.');
    };
   

    dispatch(
      choiceActions.createBusinessAccount(
        businessDetails,
        callBack,
        setLoaderAPi,
        updateStep
      ) as any
    );
    // dispatch(choiceActions.submitToBank(callBack) as any);
  };

  //   React.useEffect(()=>{
  // if(datarenser&&){
  //   setDataRenser(false)
  // }
  //   },[datarenser])
  let industry;
  if (industries) {
    industry = industries.find(
      (ind: { id: any }) => ind.id === businessDetails.businessIndustry
    );
  }
  return (
    <>
      <div className={styles.box_wraper}>
        <div className={styles.box_header}>
          <p className={styles.box_heading}>Review your info</p>
          <p className={styles.box_text}>
            Give everything one last look before submitting.
          </p>
        </div>
        <div className={styles.businessType}>
          <div style={{ cursor: "pointer" }} onClick={() => {
            if (fastSetp == false) {
              setFastStep(true)
            } else {
              setFastStep(false)
            }
          }} className={styles.IconDowntype}>
            <h6 className={styles.boxHeadings}  >Business Type</h6>
            {fastSetp == false ?
              <KeyboardArrowUpIcon style={{ cursor: "pointer" }} /> :
              <KeyboardArrowDownIcon style={{ cursor: "pointer" }}
              />}
          </div>
          {/* <div className="" style={{ cursor: 'pointer' }} onClick={() => {
            updateStep(1, false, navigate('/v2/account-setup'));
            setSetpNumberAuth(1);
          }}>
            <img src="/Group 2587.png" alt="" />
          </div> */}
        </div>
        {fastSetp == true ?
          <div className={styles.sectionHeading}>
            {businessDetails?.businessType === '1' && 'Sole Proprietorship'}
            {businessDetails?.businessType === '2' && 'Limited Liability Company'}
            {businessDetails?.businessType === '3' && 'Partnership'}
          </div> : ""}
        <div className={styles.businessType}>
          <div style={{ cursor: "pointer" }} onClick={() => {
            if (BusinessDetailsarrow == false) {
              setBusinessDetailsarrow(true)
            } else {
              setBusinessDetailsarrow(false)
            }
          }} className={styles.IconDowntype}>
            <h6 className={styles.boxHeadings} style={{ cursor: "pointer" }}
            >Business Details</h6>
            {BusinessDetailsarrow == false ?
              <KeyboardArrowUpIcon style={{ cursor: "pointer" }} /> :
              <KeyboardArrowDownIcon style={{ cursor: "pointer" }}
              />}
          </div>
          <div
            className=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              updateStep(2, false, navigate('/v2/document-upload'));
              setSetpNumberAuth(2);
            }}
          >
            <img src="/Group 2587.png" alt="" />
          </div>
        </div>
        {BusinessDetailsarrow == true ?
          <div className={styles.sectionWrappers}>
            <div className={styles.businessDetails_section}>
              <Box sx={{ width: '100%' }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <label className={styles.lebalClass}>Business Legal Name</label>
                    <Item className={styles.itemsInput}>
                      {businessDetails?.businessName}
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} >
                    <label className={styles.lebalClass}>Business Adress</label>
                    <Item className={styles.itemsInput}>
                      {businessDetails?.businessAddress}
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </div>

            <div className={`${styles.businessDetails_section} mt-4`}>
              <Box sx={{ width: '100%' }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <label className={styles.lebalClass}>Business Industry</label>
                    <Item className={styles.itemsInput}>
                      {businessDetails?.business_industry_name
                        ? businessDetails?.business_industry_name
                        : '-'}
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <label className={styles.lebalClass}>Business Registration Number</label>
                    <Item className={styles.itemsInput}>
                      {businessDetails?.businessCerNum}
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div> : ""}

        <div className={styles.businessType}>
          <div style={{ cursor: 'pointer' }} className={styles.IconDowntype}
            onClick={() => {
              if (OwnerDetailsarrow == false) {
                setOwnerDetailsarrow(true)
              } else {
                setOwnerDetailsarrow(false)
              }
            }}>
            <h6 style={{ cursor: 'pointer' }} className={styles.boxHeadings}>
              Owner Details</h6>
            {OwnerDetailsarrow == false ?
              <KeyboardArrowUpIcon style={{ cursor: "pointer" }} /> :
              <KeyboardArrowDownIcon style={{ cursor: "pointer" }}
              />}
          </div>
          <div
            className=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              updateStep(3, false, navigate('/v2/document-upload?step-3'));
              setSetpNumberAuth(3);
            }}
          >
            <img src="/Group 2587.png" alt="" />
          </div>
        </div>
        {OwnerDetailsarrow == true ?
          <>
            {businessDetails?.businessType === '1' ?
              <div className={styles.sectionWrappers}>
                <div className={styles.businessDetails_section}>
                  <Box sx={{ width: '100%' }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={6}>
                        <label className={styles.lebalClass}>Name</label>
                        <Item className={styles.itemsInput}>
                          {businessDetails?.fullName}
                        </Item>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <label className={styles.lebalClass}>Date of Birth</label>
                        <Item className={styles.itemsInput}>
                          {businessDetails?.birthday}
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </div>

                <div className={`${styles.businessDetails_section} mt-4`}>
                  <Box sx={{ width: '100%' }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={6}>
                        <label className={styles.lebalClass}>National ID Number</label>
                        <Item className={styles.itemsInput}>
                          {businessDetails?.idNumber}
                        </Item>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <label className={styles.lebalClass}>Tax Identification Number</label>
                        <Item className={styles.itemsInput}>
                          {businessDetails?.kraPin}
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div> :
              <>
                {businessDetails?.shareholders.map((item: any, index: number) => {
                  return (
                    <>
                      <label className={styles.lebalClass}>{businessDetails?.businessType === '3' ? `Partner ${index + 1}` : `Director / Shareholder ${index + 1}`}</label>

                      <div className={styles.sectionWrappers}>
                        <div className={styles.businessDetails_section}>
                          <Box sx={{ width: '100%' }}>
                            <Grid
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                              <Grid item xs={12} sm={12} md={6}>
                                <label className={styles.lebalClass}>Name</label>
                                <Item className={styles.itemsInput}>
                                  {item?.fullName}
                                </Item>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6}>
                                <label className={styles.lebalClass}>National ID Number</label>
                                <Item className={styles.itemsInput}>
                                  {item?.idNumber}
                                </Item>
                              </Grid>

                            </Grid>
                          </Box>
                        </div>

                        <div className={`${styles.businessDetails_section} mt-4`}>
                          <Box sx={{ width: '100%' }}>
                            <Grid
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >

                              <Grid item xs={12} sm={12} md={6}>
                                <label className={styles.lebalClass}>Tax Identification Number</label>
                                <Item className={styles.itemsInput}>
                                  {item?.kraPin}
                                </Item>
                              </Grid>
                              <Grid item xs={12} sm={12} md={6}>
                                {/* <label className={styles.lebalClass}>Business owner date of birth</label>
                            <Item className={styles.itemsInput}>
                              {item?.birthday}
                            </Item> */}
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      </div>
                    </>
                  )
                })}
              </>
            }
          </> : ""}

        <div className={styles.businessType}>
          <div style={{ cursor: "pointer" }} onClick={() => {
            if (ExpectedActivityarrow == false) {
              setExpectedActivityarrow(true)
            } else {
              setExpectedActivityarrow(false)
            }
          }} className={styles.IconDowntype}>
            <h6 className={styles.boxHeadings}>Expected Activity</h6>
            {ExpectedActivityarrow == false ?
              <KeyboardArrowUpIcon style={{ cursor: "pointer" }} /> :
              <KeyboardArrowDownIcon style={{ cursor: "pointer" }}
              />}
          </div>
          <div
            className=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              updateStep(4, false, navigate('/v2/entity'));
              setSetpNumberAuth(4);
            }}
          >
            <img src="/Group 2587.png" alt="" />
          </div>
        </div>
        {ExpectedActivityarrow == true ?
          <div className={styles.sectionWrappers}>
            <div className={styles.businessDetails_section}>
              <Box sx={{ width: '100%' }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <label className={styles.lebalClass}>Select your Business Activity</label>
                    <Item className={styles.itemsInput}>
                      {businessDetails?.business_activity_name}
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <label className={styles.lebalClass}>How do you plan on using your Niobi account?</label>
                    <Item className={styles.itemsInput}>
                      {!!businessDetails?.account_usage &&
                        businessDetails?.account_usage.map(
                          (data: any, index: number) => {
                            return (
                              <>
                                {`${data} ${index ==
                                  businessDetails?.account_usage?.length - 1
                                  ? ''
                                  : ','
                                  } `}
                              </>
                            );
                          }
                        )}
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </div>

            <div className={`${styles.businessDetails_section} mt-4`}>
              <Box sx={{ width: '100%' }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{alignItems:"end"}}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <label className={styles.lebalClass}>Please describe your product offerings</label>
                    <Item className={styles.itemsInput}>
                      {businessDetails?.product_offering}
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <label className={styles.lebalClass}>
                      What is your expected aggregated monthly transaction
                      activity for payins?
                    </label>
                    <Item className={styles.itemsInput}>
                      {businessDetails?.pay_ins}
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className={`${styles.businessDetails_section} mt-4`}>
              <Box sx={{ width: '100%' }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <label className={styles.lebalClass}>
                      {' '}
                      What is your expected aggregated monthly transaction
                      activity for payouts?
                    </label>
                    <Item className={styles.itemsInput}>
                      {businessDetails?.pay_outs}
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}></Grid>
                </Grid>
              </Box>
            </div>
          </div> : ""}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          className={styles.businessDetails_section}
        >
          <div style={{ cursor: "pointer" }} onClick={() => {
            if (Currencyarrow == false) {
              setCurrencyArrow(true)
            } else {
              setCurrencyArrow(false)
            }
          }} className={styles.IconDowntype}>
            <h6 className={`${styles.boxHeadings} mt-4`}>Currency</h6>
            {Currencyarrow == false ?
              <KeyboardArrowUpIcon style={{ cursor: "pointer" }} /> :
              <KeyboardArrowDownIcon style={{ cursor: "pointer" }}
              />}
          </div>
          <div
            className=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              updateStep(5, false, navigate('/v2/currency'));
              setSetpNumberAuth(5);
            }}
          >
            <img src="/Group 2587.png" alt="" />
          </div>
        </div>
        {Currencyarrow == true ?
          <div className={styles.currnxychecks}>
            <Box sx={{ width: '100%' }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {businessDetails?.countries?.map((data: any) => (
                  <Grid id={data.id} item xs={12} sm={12} md={6}>
                    <div className={styles.lastRow}>
                      <div>
                        <span>
                          <img
                            src={data?.country?.flag}
                            alt={data?.country?.name}
                          />
                        </span>{' '}
                        {data?.country?.name}
                      </div>
                      <div className={styles.currencyName}>
                        {data?.country?.currency}
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div> : ""}

        <div className={styles.buttonSection}>
          <button onClick={handleProceed} className={styles.submitButton}>
            {LoaderAPi == true && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default ReviewInformation;

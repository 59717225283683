import { Box, Typography } from "@mui/material";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import styles from './index.module.scss';

export const ModelCategories=({show,setName,Name,errorMsg,validesan,Close,CereteCategories}:any)=>{

    return(
        <Modal
                    show={show}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby='ModalHeader'
                    centered
                ><Modal.Body>
                        <Box className={styles.box_dialog_main}>
                            <Typography className={styles.Create_Team_heding}>Create New Category</Typography>
                            <div id="fast_name_inpurt_div" className="input-form-wrapper dot">
                                <label>Name</label>
                                <InputGroup className="doc-input-wrapper">
                                    <Form.Control
                                        className={

                                            'doc-form-input test valid'}
                                        placeholder="Name"
                                        value={Name}
                                        name='name'
                                        type="text"
                                        onChange={(e: any) => {
                                            setName(e.target.value)
                                        }}
                                    />
                                </InputGroup>
                                {!!errorMsg ?
                                    <p className="error-message">
                                        {/* The name has already been taken. */}
                                        {errorMsg}
                                    </p> :
                                    !Name && validesan == true ?
                                        <p className="error-message">Please enter the team name</p>
                                        : ""}
                            </div>

                            <Box className={styles.box_btn_cencal} sx={{ justifyContent: "space-between", display: "flex", gap: "5px" }}>
                                <Button className={styles.cerete_user} onClick={() => { Close() }}>
                                    Cancel
                                </Button>
                                <Button className={styles.savebtn} style={{ marginLeft: "0px" }}
                                    onClick={() => {
                                        CereteCategories()
                                    }}>Create</Button>


                            </Box>
                        </Box></Modal.Body></Modal >
    )
}
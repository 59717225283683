import { Box, Checkbox, CircularProgress, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import styles from './importCategoris.module.scss';
import * as XLSX from 'xlsx';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FieldArray, Formik, getIn } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { ButtonLayout } from "../../../../../ui/buttonlayout/buttonLayout";
import { ButtonSave, CreateButton, DeleteButton, SaveButton } from "../../../../../ui/Button";
import { useNavigate } from "react-router-dom";
import { payablesFlowactions } from "../../../../../../_actions";
import { useDispatch } from "react-redux";

const ImportCategoris = ({ data }: any) => {
    const [patternDataArray, setPatternDataArray] = React.useState<any>([
        {
            name: "",
            is_current_price: false
        }])
    const [loaderData, setLoaderData] = React.useState(false);
    const [filedata, setFile] = React.useState('')
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [dense, setDense] = React.useState(false);
    const [isError, setIserror] = React.useState<any>()
    let navigate = useNavigate();
    const dispatch = useDispatch()


    useEffect(() => {
        if (data.length != 0) {
            var newArray = []
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                var itemdata = {
                    name: element['Name*'],
                    is_current_price: false
                }
                newArray.push(itemdata)
            }
            setPatternDataArray(newArray)
        }
    }, [data])
    const handleCSVUpload = async (event: any) => {
        if (event.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e: any) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData: any = XLSX.utils.sheet_to_json(sheet);
                const listtebal: any = localStorage.getItem("Bulk_Payment")
                const bulkdata = JSON.parse(listtebal)
                xlsx_data(jsonData)
            };

            reader.readAsArrayBuffer(file);
        } else {
            toast.error("Please upload file in .xlsx format only, CSV files are not supported. Thank you!")
        }
    }
    const xlsx_data = (data: any) => {
        if (!!data) {
            var newArray = []
           

            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                var itemdata = {
                    name: element['Name*'],
                    is_current_price: false
                }

                newArray.push(itemdata)
            }
            var concetdata = patternDataArray.concat(newArray)
            setPatternDataArray(concetdata)
        }
    }
    const formInitailData = {
        users: patternDataArray,
    };
    const validationSchema = Yup.object().shape({
        users: Yup.array().of(
            Yup.object().shape({
                name: Yup.string()
                    .required("Please enter the name."),
            })
        )
    });
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            var new_array_two = [];

            for (let j = 0; j < patternDataArray.length; j++) {
                const element = patternDataArray[j];
                if (element.is_current_price === false && event.target.checked == true) {
                    element.is_current_price = true;
                    new_array_two.push(element)
                } else {
                    new_array_two.push(JSON.parse(JSON.stringify(element)))
                }
            }
            setPatternDataArray(new_array_two)
            const newSelected = patternDataArray.map((n: any, index: any) => index);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    }
    const isSelected = (name: string, data: boolean) => selected.indexOf(name) !== -1;
    const handleClick = (event: React.MouseEvent<unknown>, index: any, Nametwo: string) => {
        const selectedIndex = selected.indexOf(index);
        setIserror(index)
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    function getStyles(errors: any, fieldName: any) {
        if (getIn(errors, fieldName)) {
            return {
                border: '1px solid red'
            }
        }
    }
    function deleteRow() {
        const list = patternDataArray.filter(function (entry: any, index: any) { return entry.is_current_price === false; })
        setSelected([])
        setPatternDataArray(list);
    }
    return (
        <>
            <Paper sx={{ width: '100%', boxShadow: '0px 4px 12px rgba(89, 87, 87, 0.16)' }}>
                <Toolbar
                    className={styles.tebaldata_hedinh}
                    style={{ padding: "20px 14px" }}
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },

                    }}
                >
                    {loaderData && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                        <CircularProgress
                            color="success" />
                    </Box>}
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        className={styles.addnewtext}
                    >
                        Confirm Imported Categories
                    </Typography>
                    <div className={styles.div_uplod_data_use}>
                        <label className={styles.save_btn22} >
                            Upload More <AddIcon />
                            <input id="inputTag" className={styles.addaffafagga} type="file" onChange={(file: any) => {
                                handleCSVUpload(file)
                                setFile(file.target.files[0]);
                            }}
                                accept=".xlsx" />
                        </label>
                    </div>
                </Toolbar>
                <Divider className={styles.addborderin} />
                <div className={styles.addlowlis}></div>
                <Formik
                    enableReinitialize
                    initialValues={formInitailData}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        var newArray: any = []
                        var body = {
                            tags: newArray
                        }
                        for (let index = 0; index < patternDataArray.length; index++) {
                            const element = patternDataArray[index];
                         

                            if (element.is_current_price == true) {
                                newArray.push(element.name)
                            }
                        }
                        const Path = () => {
                            // navigate("/Categories")
                            window.location.reload()
                        }
                        dispatch(payablesFlowactions.PostPayment("/tags/create-bulk", body, Path, setLoaderData) as any);
                      

                    }}

                >
                    {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting }) => (
                        <>
                            <Form onSubmit={handleSubmit} >
                                <TableContainer className={styles.tebal_contenar_data}>
                                    <Table
                                        className={styles.addteballist}
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                    >
                                        <TableHead className={styles.hedarliath}>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox
                                                        icon={<img src="/Checkbox.svg" />}
                                                        checkedIcon={<img src="/Checkbox_two.svg" />}
                                                        color="primary"
                                                        indeterminate={selected.length > 0 && selected.length < patternDataArray.length}
                                                        checked={patternDataArray.length > 0 && selected.length === patternDataArray.length}
                                                        onChange={(e: any) => {
                                                            handleSelectAllClick(e)
                                                            // handleSelectAllClickVlidet(e)
                                                        }}
                                                        inputProps={{
                                                            'aria-label': 'select all desserts',
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{width:"10%"}}>
                                                    Name
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <FieldArray
                                                name="users"
                                                render={arrayHelpers => {
                                                    const users = patternDataArray;
                                                    return (
                                                        <>
                                                            {users && users.length > 0
                                                                ? users.map((user: any, index: any) => {
                                                                    var value = user;
                                                                    const isItemSelected = isSelected(index, user.is_current_price);
                                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                                    return (
                                                                        <TableRow hover key={index}>

                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    color="primary"
                                                                                    icon={<img src="/Checkbox.svg" />}
                                                                                    checkedIcon={<img src="/Checkbox_two.svg" />} name={`users.${index}.is_current_price`}
                                                                                    checked={isItemSelected} onClick={(event: any) => {
                                                                                        handleClick(event, index, user.id)
                                                                                        var array = []
                                                                                        for (let j = 0; j < patternDataArray.length; j++) {
                                                                                            const element = patternDataArray[j];
                                                                                            if (index == j && element.is_current_price == false) {
                                                                                                element.is_current_price = true;
                                                                                                array.push(element)
                                                                                            } else if (index == j && element.is_current_price == true) {
                                                                                                element.is_current_price = false;
                                                                                                array.push(element)
                                                                                            } else {
                                                                                                array.push(JSON.parse(JSON.stringify(element)))
                                                                                            }
                                                                                        }

                                                                                        setPatternDataArray(array)
                                                                                    }}

                                                                                    inputProps={{
                                                                                        'aria-labelledby': labelId,
                                                                                    }}
                                                                                />

                                                                            </TableCell>
                                                                            <TableCell style={{width:"10%"}}>
                                                                                <Box >

                                                                                    <TextField
                                                                                        style={getStyles(errors, `users.${index}.name`)} className={user.status == false ? styles.error_messege : styles.tabalinputdata + ""} type="text" name={`users.${index}.account_number`}
                                                                                        onChange={(value1: any) => {
                                                                                            let text = value1.target.value;
                                                                                            var newArray = [];
                                                                                            var newarray_two = []
                                                                                            for (let j = 0; j < patternDataArray.length; j++) {
                                                                                                const element = patternDataArray[j];
                                                                                                if (index == j) {
                                                                                                    value.name = text;
                                                                                                    newArray.push(JSON.parse(JSON.stringify(value)))
                                                                                                } else {
                                                                                                    newArray.push(JSON.parse(JSON.stringify(element)))
                                                                                                }
                                                                                            }

                                                                                            setPatternDataArray(newArray)

                                                                                        }}
                                                                                        value={value.name} />

                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow>

                                                                    );
                                                                }) : null
                                                            }
                                                        </>
                                                    )
                                                }}
                                            />
                                        </TableBody>
                                    </Table>
                                </TableContainer >
                                <ButtonLayout fastGrid={
                                    <>
                                        {selected.length > 0 && (
                                            <DeleteButton setonClick={() => { deleteRow() }}>
                                                Delete
                                            </DeleteButton>
                                        )
                                        }

                                        <SaveButton style={{ marginLeft: "10px", padding: "12px 30px" }} setonClick={() => { navigate("/balance") }}>
                                            Cancel
                                        </SaveButton>
                                    </>
                                }
                                    secendGrid={
                                        <CreateButton type="submit" disabled={selected.length > 0 ? false : true}>
                                            {loaderData == true && <span className="spinner-border spinner-border-sm mr-1"></span>} Submit
                                        </CreateButton>
                                    }
                                />


                            </Form>
                        </>
                    )}
                </Formik >

            </Paper >

        </>
    )
}
export default ImportCategoris;
const AddIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z" fill="#025041" />
    </svg>
)
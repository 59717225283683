import React, { FC, useEffect, useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import SoleProprietoryStepOne from './SoleProprietoryStepOne';
import SoleProprietory from './SoleProprietory';
import '../Settings.scss';
import { Formik, FormikConfig, FormikHelpers, FormikValues } from 'formik';
import { getBase64 } from '../../../../../../../../../_helpers/common';
import { choiceActions } from '../../../../../../../../../_actions';
import { useDispatch } from 'react-redux';
// import { toast } from "@team-monite/ui-widgets-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { authHeader } from '../../../../../../../../../_helpers';

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
  businessDocs: any;
  setSetpNumberAuth: any;
  updateStep: any;
  api: any;
  LoaderApi: any;
  entityType: any;
}
const RegistrationForm: FC<Props> = ({
  children,
  initialValues,
  onSubmit,
  businessDocs,
  setSetpNumberAuth,
  updateStep,
  api,
  LoaderApi,
  entityType,
}) => {
  const [stepNumber, setStepNumber] = useState(0);

  const [snapshot, setSnapshot] = useState(initialValues);

  useEffect(() => {
    if (window?.location?.search.includes('step-3')) {
      setStepNumber(1);
    } else {
      setStepNumber(0);
    }
  }, [window?.location?.search]);


  const dispatch = useDispatch();

  const steps = React.Children.toArray(children) as React.ReactElement[];

  const step = steps[stepNumber];
  const totalSteps = steps.length;

  const certificateLabel = 'Registration certificate';
  const [certificateFile, setCertificateFile] = useState(certificateLabel);
  const selfieLabel = 'Passport photo';
  const idFrontLabel = 'National ID - frontside';
  const idBackLabel = 'National ID - backside';
  const passportLabel = 'Passport';
  const kraLabel = 'Tax certificate';
  const licenseLabel = 'Licence';
  const [selfieFile, setSelfieFile] = useState(selfieLabel);
  const [idFrontFile, setIdFrontFile] = useState(idFrontLabel);
  const [passportFile, setPassportFile] = useState(passportLabel);
  const [idBackFile, setIdBackFile] = useState(idBackLabel);
  const [licenseFile, setLicenseFile] = useState(licenseLabel);
  const [kraFile, setKraFile] = useState(kraLabel);
  const [invoiceImage, setinvoiceImage] = useState<any>('');
  const [fileSize, setFileSize] = useState<any>('');
  const [loader, setLoader] = useState(false);
  const [setpsubmit, setSetpsubmit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!businessDocs) {
      setCertificateFile(
        !!businessDocs.data?.data?.businessCertificate?.file_name
          ? businessDocs.data?.data?.businessCertificate?.file_name
          : certificateLabel
      );
      setLicenseFile(
        !!businessDocs.data?.data?.license?.file_name
          ? businessDocs.data?.data?.license?.file_name
          : licenseLabel
      );
      setSelfieFile(
        !!businessDocs.data?.data?.selfie?.file_name
          ? businessDocs.data?.data?.selfie?.file_name
          : selfieLabel
      );
      setIdFrontFile(
        !!businessDocs.data?.data?.idFront?.file_name
          ? businessDocs.data?.data?.idFront?.file_name
          : idFrontLabel
      );
      setIdBackFile(
        !!businessDocs.data?.data?.idBack?.file_name
          ? businessDocs.data?.data?.idBack?.file_name
          : idBackLabel
      );
      setKraFile(
        !!businessDocs.data?.data?.kra?.file_name
          ? businessDocs.data?.data?.kra?.file_name
          : kraLabel
      );
      setPassportFile(
        !!businessDocs.data?.data?.passport?.file_name
          ? businessDocs.data?.data?.passport?.file_name
          : passportLabel
      );
      setSnapshot({
        ...initialValues,
        businessCertificate: businessDocs.data?.data?.businessCertificate?.url,
        idfront: businessDocs.data?.data?.idFront?.url,
        idBack: businessDocs.data?.data?.idBack?.url,
        selfie: businessDocs.data?.data?.selfie?.url,
        kra: businessDocs.data?.data?.kra?.url,
      });
    }
  }, [businessDocs]);
  const handleLoader = (value: boolean) => {
    setLoader(value);
  };

  const handleImage = (e: any, formik: any) => {
    let field = e.target.name;
    let extension = e.target.files[0].name.split('.').pop();
    extension = extension.toLowerCase();
   

    if (
      e.target.files[0] !== null &&
      e.target.files[0] !== undefined &&
      e.target.files[0].size <= 1024 * 1024
    ) {
      setFileSize(e.target.files[0].size);
      let title: any = null;
      let mediaType: any = null;
      switch (e.target.name) {
        case 'selfie':
          setSelfieFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00006';
          break;
        case 'idFront':
          setIdFrontFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00001';
          break;
        case 'idBack':
          setIdBackFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00002';
          break;
        case 'passport':
          setPassportFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00001';
          break;
        case 'kra':
          setKraFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00009';
          break;
        case 'license':
          setLicenseFile(e.target.files[0].name);
          title = field;
          mediaType = 'NIOBI00009';
          break;
        case 'businessCertificate':
          setCertificateFile(e.target.files[0].name);
          title = field;
          mediaType = 'KYCF00010';
          break;
        default:
          break;
      }
      getBase64(e.target.files[0], (result: any) => {
        setinvoiceImage(result);
        formik.setFieldValue(field, result);
        const payload = {
          title: title,
          document_type: '1',
          mediaBase64: result,
          type: 'business_documents',
          mediaType,
          contentType: extension === 'pdf' ? 'pdf' : 'image',
        };
      
        dispatch(choiceActions.uploadMedia(payload, handleLoader) as any);
        if (e.target.name === 'passport') {
          const payload = {
            title: title,
            document_type: '1',
            mediaBase64: result,
            type: 'business_documents',
            mediaType: 'KYCF00002',
            contentType: extension === 'pdf' ? 'pdf' : 'image',
          };
         
          dispatch(
            choiceActions.uploadMedia(payload, handleLoader, true) as any
          );
        }
      });
    } else {
      toast.error(
        'File size is too large. Please upload a file with max size of 1 mb.'
      );
    }
  };

  const handleRemoveImage = (fileName: string, formik?: any) => {

    if (fileName !== null && fileName !== undefined) {
      switch (fileName) {
        case 'selfie':
          setSelfieFile(selfieLabel);
          setinvoiceImage('');
          break;
        case 'license':
          setLicenseFile(licenseLabel);
          setinvoiceImage('');
          break;
        case 'idFront':
          setIdFrontFile(idFrontLabel);
          setinvoiceImage('');
          break;
        case 'idBack':
          setIdBackFile(idBackLabel);
          setinvoiceImage('');
          break;
        case 'passport':
          setPassportFile(passportLabel);
          setinvoiceImage('');
          if (formik) formik.setFieldValue('passport', '');
          break;
        case 'kra':
          setKraFile(kraLabel);
          setinvoiceImage('');
          break;
        case 'businessCertificate':
          setCertificateFile(certificateLabel);
          setinvoiceImage('');
          break;
        default:
          break;
      }
    }
    if (formik) {
      formik.setFieldValue(fileName, '');
    }
  };

  const isLastStep = stepNumber === totalSteps - 1;

  const handleNext = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber + 1);
  };

  const handleBack = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber((prevStepNumber) => prevStepNumber - 1);
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
   
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }
    if (isLastStep) {
      return onSubmit(values, actions);
    } else {
      values.shareholders = [];
      dispatch(
        choiceActions.saveBusinessDetails(
          { ...values },
          () => {
            toast.success('Business details saved ');
            handleNext(values);
            if (stepNumber === 0 && api == '/v2/create-entity') {
              localStorage.setItem('registerStep', '3');
              setSetpNumberAuth(2);
              updateStep(2, true, navigate('/v2/document-upload?step-3'));
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          },
          1
        ) as any
      );
      // actions.setTouched({});
    }
  };
  const uploadchaoic = (e: any, formik?: any, indexfun?: any) => {
    getBase64(e.target.files[0], (result: any) => {
      setinvoiceImage(result);

      let field = e.target.name;
      let extension = e.target.files[0].name.split('.').pop();
      extension = extension.toLowerCase();
      var data = { file: result };
    
      const requestOptions: any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data),
      };
      // return response.json();
      fetch(`${process.env.REACT_APP_API_URL}/upload-file`, requestOptions)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
       
          // setPhotos(data);
          let field = e.target.name;
          let extension = e.target.files[0].name.split('.').pop();
          extension = extension.toLowerCase();
      

          if (e.target.files[0] !== null && e.target.files[0] !== undefined) {
            setFileSize(e.target.files[0].size);
            let title: any = null;
            let mediaType: any = null;
            switch (e.target.name) {
              case 'selfie':
                setSelfieFile(e.target.files[0].name);
                formik.setFieldValue(field, data?.file_id);
                // title = field;
                // mediaType = 'KYCF00006';
                break;
              case 'idFront':
                setIdFrontFile(e.target.files[0].name);
                formik.setFieldValue(field, data?.file_id);
                title = field;
                mediaType = 'KYCF00001';
                break;
              case 'idBack':
                setIdBackFile(e.target.files[0].name);
                formik.setFieldValue(field, data?.file_id);
                title = field;
                mediaType = 'KYCF00002';
                break;
              case 'passport':
                setPassportFile(e.target.files[0].name);
                formik.setFieldValue(field, data?.file_id);
                title = field;
                mediaType = 'KYCF00001';
                break;
              case 'kra':
                setKraFile(e.target.files[0].name);
                formik.setFieldValue(field, data?.file_id);
                title = field;
                mediaType = 'KYCF00009';
                break;
              case 'license':
                setLicenseFile(e.target.files[0].name);
                formik.setFieldValue(field, data?.file_id);
                title = field;
                mediaType = 'NIOBI00009';
                break;
              case 'businessCertificate':
                setCertificateFile(e.target.files[0].name);
                formik.setFieldValue(field, data?.file_id);
                title = field;
                mediaType = 'KYCF00010';
                break;
              default:
                break;
            }
          }
        });

    

      // }
      // );
    });
  };
  return (
    <>
      <div className="complete-profile__heading">
        Please provide the following documents
      </div>
      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            {/* <Stepper
              activeStep={stepNumber}
              style={{ width: '40%', margin: '10px auto' }}
              sx={{
                '.MuiStepConnector-root span': {
                  borderColor: '#025041',
                },

                '.MuiSvgIcon-root': {
                  borderRadius: '50%',
                  border: '1px solid #025041',
                },
                '.MuiSvgIcon-root:not(.Mui-completed)': {
                  color: 'white',
                },
                '.MuiStepIcon-text': {
                  fill: '#025041',
                  fontWeight: 500,
                },
                '.MuiSvgIcon-root.Mui-active': {
                  color: '#025041',
                  padding: '3px',
                  borderRadius: '50%',
                  border: '1px solid #025041',
                  marginY: '-3px',
                },
                '.Mui-active .MuiStepIcon-text': {
                  fill: 'white',
                },
                '.MuiStepIcon-root.Mui-completed': {
                  color: '#025041', // Set the fill color for completed steps
                },
              }}
            >
              {steps.map((currentStep, index) => {
                const label = currentStep.props.stepName;
                return (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper> */}

            {/* {formik.errors && (
              <div className="error-message">
                {Object.keys(formik.errors).length > 0 && (
                  <div>
                    <h3>Form errors:</h3>
                    <ul>
                      {Object.keys(formik.errors).map((key) => (
                        <li key={key}>{`${formik.errors[key]}`}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )} */}
            {stepNumber === 0 && (
              <>
                <SoleProprietoryStepOne
                  formik={formik}
                  certificateFile={certificateFile}
                  handleImage={handleImage}
                  loader={loader}
                  certificateLabel={certificateLabel}
                  licenseFile={licenseFile}
                  licenseLabel={licenseLabel}
                  // uploadchaoic={uploadchaoic}
                  handleRemoveImage={handleRemoveImage}
                />
              </>
            )}
            {stepNumber === 1 && (
              <>
                <SoleProprietory
                  formik={formik}
                  loader={loader}
                  handleImage={handleImage}
                  idFrontFile={idFrontFile}
                  idFrontLabel={idFrontLabel}
                  idBackFile={idBackFile}
                  idBackLabel={idBackLabel}
                  selfieFile={selfieFile}
                  selfieLabel={selfieLabel}
                  kraFile={kraFile}
                  kraLabel={kraLabel}
                  passportFile={passportFile}
                  passportLabel={passportLabel}
                  handleRemoveImage={handleRemoveImage}
                  setpsubmit={setpsubmit}
                />
              </>
            )}
            {/* <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={stepNumber === steps.length - 1}
        >
          {stepNumber === steps.length - 1 ? 'Submit' : 'Next'}
        </Button>
        {stepNumber > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBack}
            sx={{ marginLeft: 8 }}
          >
            Back
          </Button>
        )}
      </Grid> */}
            <Box
              style={isLastStep == true ? { justifyContent: 'center' } : {}}
              className="complete-profile__boxbtndisplay"
            >
              {/* {stepNumber > 0 && (
                <button
                  className="complete-profile__goback"
                  onClick={() => {
                    handleBack(formik.values);
                    if (stepNumber === 1 && api == '/v2/create-entity') {
                      localStorage.setItem('registerStep', '2');
                      setSetpNumberAuth(2);
                      updateStep(2, false, navigate('/v2/document-upload'));
                    }
                  }}
                >
                  Back
                </button>
              )} */}
              {stepNumber === 0 && (
                <button type="submit" className="complete-profile__goback">
                  Save
                </button>
              )}

              <button
                type="submit"
                className="complete-profile__submitbtnlist"
                onClick={() => {
                  // if (stepNumber === 0) {
                  //   localStorage.setItem("registerStep", "3");
                  //   setSetpNumberAuth(2)
                  //   updateStep(2)
                  // }
                  if (isLastStep) {
                    setSetpsubmit(true);
                  }
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {LoaderApi == true && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                {isLastStep ? 'Proceed' : 'Proceed'}
              </button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default RegistrationForm;

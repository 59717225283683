import { Autocomplete, Box, Checkbox, formControlClasses, TextField, Typography } from "@mui/material"
import "./step2.scss"
import { CustomInput, InputDropBox } from "../../../../../../../ui/ReuseableComponents/Inputs/Inputs"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useMemo, useState } from "react";
import { DefaultBtn, DefaultGoBackBtn } from "../../../../../../../ui/ReuseableComponents/Buttons/Buttons";
import Model from "../../../../../../../ui/ReuseableComponents/Model/Model";
import { InputSelectBox, TheSelectBox } from "../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs";
import { EditIcon } from "../../../../../../../ui/ReuseableComponents/Icons/Icons";
import RejectionBox from "../../../../../../../ui/ReuseableComponents/Notifications/RejectionBox/RejectionBox";
import EditFields from "../../../../../../../ui/ReuseableComponents/Forms/EditFileds/EditFields";
import React from "react";
import { CloseIcon } from "../../../../../../../ui/ReuseableComponents/Icons/Icons";
import AddPaymentMethod from "../../../../../../../ui/ReuseableComponents/Payment/AddPaymentMethod";
import { payablesFlowactions } from '../../../../../../../../_actions';
import { useDispatch } from 'react-redux';
import BillPaymentMethods from "../../../../../../../ui/ReuseableComponents/BillPaymentMethods/BillPaymentMethods";
import { toast } from "react-toastify";
import { ErrorMessage } from '../../../../../../../ui/eroor';
import FullWidthTabs from "./Components/Tabs";
import countryList from "react-select-country-list";


const Step2 = ({ errorsMsg, setErrorMsg, selectpayees, setErrorMsgapi, errorsmsgapi, UpdetPayee, setIsAddPayeeComponent, selectedCurrency, SelectedFlag, setFormik, formik, stepupdatefun, step, setUseLayout, bankList, categoriesData, teamData }: any) => {
  const [isContactDetailsOpen, setIsContactDetailsOpen] = useState(false)
  const [currency, setCurrency] = useState([])

  const [CountriyId, setCountriyId] = useState(1)
  const [render, setRender] = useState(true)
  const [mobilePaymentmethoad, setMobilePaymentMothoad] = useState([])
  const [bankPaymentMethoad, setBankPaymentMethoad] = useState([])
  const [countriesByCurrency, setCountriesByCurrency] = useState([])
  const [activeButton, setActiveButton] = useState('')
  const [PayOutMethodsByCountryId, setPayOutMethodsByCountryId] = useState([])
  const [EditGetapimethoad, setEditGetapimethoad] = useState([])
  const [countryNameget, setCountryNameget] = useState('Kenya');
  const [loaderReander, setLoaderReander] = useState(false)
  const [countryId, setCountryId] = useState("1")
  const [loaderaddmethoad, setLoaderaddmethoad] = useState(false)
  const [editGetdata, setEditgetdata] = useState<any>({})
  const [countrySortcode, setCountriesSortcode] = useState('ke')
  const [MethoadTitel, setMethoadTitel] = useState("")
  const [methodCliack, setMethoadCliack] = useState('')


  const [isRejectOpen, setIsRejectOpen] = useState(false)
  const [openEditId, setOpenEditId] = useState(null);
  const [openBillId, setOpenBillId] = useState<number | null>(null);
  const [loader, setLoader] = useState(false)
  const [errorMessages, setErrorMessages] = useState<{ [key: number]: string }>({});
  const [SelectCurrency, setSelectCurrency] = useState("KES")
  const dispatch = useDispatch();

const options = useMemo(() => countryList().getData(), [])
  useEffect(() => {
    if (!!options && !!countryNameget) {
      const findcurrencies: any = options.find(
        (data: any) => data.label == countryNameget
      );
      var country_sortname_list = findcurrencies?.value?.toLowerCase();
      setCountriesSortcode(country_sortname_list);
      setLoaderReander(true)
      setTimeout(() => {
        setLoaderReander(false)
      }, 4000)
    }
  }, [countryNameget, options]);

  // Dummy data 
  const [ExampleBills, setExampleBills] = useState([
    {
      id: 1,
      ISCHECK: false,
      counterpart_name: "John Doe",
      method_no: "1234567890",
      method_title: "Bank Transfer",
      amount: 5000,
      document_id: "INV-001",
      vat: "Exempt",
      bulk_payment: { categories: [{ id: 1, name: "Office Supplies" }] },
      team: [{ id: 1, name: "Finance Team" }],
      counterpart_info: {
        name: "John Doe",
        account_number: "123456789",
        bank_code: "ABC"
      },
      apierror: "",
      fees: 100,
    },
    {
      id: 2,
      ISCHECK: false,
      counterpart_name: "Jane Smith",
      method_no: "0987654321",
      method_title: "Mobile Money",
      amount: 3000,
      document_id: "INV-002",
      vat: "16",
      bulk_payment: { categories: [{ id: 2, name: "Utilities" }] },
      team: [{ id: 2, name: "Operations" }],
      counterpart_info: {
        name: "Jane Smith",
        phone_number: "0712345678"
      },
      apierror: "Insufficient funds",
      fees: 50
    }
  ]);


  const CheckBalance = () => {
    return formik.transactionAmount;
  };

  const handleBankAndMethod = (billId: number) => {
    const selectedBill = formik.apiUpdatedBulk?.find((bill: any) => bill.id === billId);

    if (!selectedBill?.ISCHECK) {
      setErrorMessages(prev => ({ ...prev, [billId]: "Please select the bill first." }));
      return;
    }

    setErrorMessages(prev => ({ ...prev, [billId]: "" }));
    setOpenBillId(prev => (prev === billId ? null : billId));
  };

  const handleCheckboxChange = (billId: number, isChecked: boolean) => {

    setFormik((prevFormik: any) => {
      const updatedBills = prevFormik.apiUpdatedBulk.map((b: any) =>
        b.id === billId ? { ...b, ISCHECK: isChecked } : b
      );
      const newTotal = updatedBills
        .filter((b: any) => b.ISCHECK)
        .reduce((sum: number, b: any) => sum + (b.amount || 0), 0);

      return {
        ...prevFormik,
        apiUpdatedBulk: updatedBills,
        totalAmount: newTotal,
        // totalAmount: totalCheckedAmount,
      };

    });


    if (!isChecked && openBillId === billId) {
      setOpenBillId(null);
    }
  };

  const handleInputDropBoxChange = (billId: number, newValue: string) => {
    setFormik((prevFormik: any) => {
      const updatedBills = prevFormik.selectedBills.map((b: any) =>
        b.id === billId ? { ...b, method_no: newValue } : b
      );

      return {
        ...prevFormik,
        selectedBills: updatedBills,
      };
    });
  };
  const [editPayeeId, setEditPayeeId] = useState<number | null>(null);

  const handleEditMethod = (billId: number) => {
    const selectedBill = formik.apiUpdatedBulk.find((bill: any) => bill.id === billId);

    if (!selectedBill?.ISCHECK) {
      setErrorMessages((prev) => ({ ...prev, [billId]: "Please select the bill first." }));
      return;
    }

    setEditPayeeId((prev) => (prev === billId ? null : billId));
    setErrorMessages((prev) => ({ ...prev, [billId]: "" })); // Clear error message when selecting
  };

  const getMaskedValue = (value: any) => {
    if (!value || typeof value !== 'string') return '';
    const lastFourDigits = value.slice(-4);
    const maskedPart = '*'.repeat(Math.max(0, value.length - 4));
    return maskedPart + lastFourDigits;
  };

  return (
    <>
      <DefaultGoBackBtn style={{ marginTop: "-50px", textAlign: "left" }} onClick={() => stepupdatefun(0)} />
      <Box className="step2_container d-flex flex-column gap-4">
        <Typography className="step2_heading ">How much are you sending?</Typography>

        <Box>
          <Typography className="label mb-2">You Send</Typography>
          <InputSelectBox
            readOnly={true}
            // disabled={true}
            onChange={(e: any) => {
              setFormik({ ...formik, totalAmount: e.target.value });
            }}
            type="number"
            name="amount"
            value={formik?.totalAmount} placeholder="Enter amount" currencyList={<Box className="d-flex gap-1 align-items-center">
              <Box style={{ backgroundImage: `url(${SelectedFlag})`, backgroundSize: "cover", backgroundPosition: "center", width: "20px", height: "20px", borderRadius: "50%" }}></Box>
              <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }}>{selectedCurrency}</Typography>
            </Box>} />
        </Box>

        <Box>
          {

            formik.apiUpdatedBulk?.map((bill: any, index: number) => {
              return (
                <Box className=" pt-4 pb-4" style={{ borderTop: "1px solid #e0e0e0" }} key={index}>

                  <Box>
                    <Box>
                      <Box className="d-flex align-items-center gap-2 mb-2">
                        <Checkbox
                          style={{ padding: "0px 0px 0px 0px" }}
                          icon={<img src="/Checkbox.svg" />}
                          checkedIcon={<img src="/Checkbox_two.svg" />}
                          color="primary"
                          checked={bill?.ISCHECK || false}
                          onChange={(e) => handleCheckboxChange(bill?.id, e.target.checked)}
                        />
                        <Typography className="label">{bill?.counterpart_name}'s payment details</Typography>
                      </Box>

                      <InputDropBox
                        readOnly={true}
                        inputValue={getMaskedValue(bill?.account_number || "")}
                        onInputChange={(e: any) => handleInputDropBoxChange(bill?.id, e.target.value)}
                        style={{ color: "black" }}
                        placeholder={bill?.account_number || ""}
                        disabled={!bill.ISCHECK}
                        arrowForwardIcon={(
                          <Box style={{ width: "max-content" }} className="d-flex align-items-center gap-3">
                            <Typography
                              onClick={() => handleBankAndMethod(bill?.id,)}
                              style={{ cursor: "pointer", fontFamily: "Poppins", fontSize: "12px" }}
                            >
                              {bill?.method_title || "Stanbic bank"}
                            </Typography>
                            <ArrowForwardIosIcon
                              style={{
                                transform: `rotate(${openBillId === bill?.id ? 90 : 0}deg)`,
                                transition: "transform 0.3s ease",
                              }}
                              className="arrowforwardicon"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleBankAndMethod(bill?.id,);
                              }}
                            />
                          </Box>
                        )}
                      />
                      {!bill?.ISCHECK && errorMessages[bill?.id] && (
                        <Typography style={{ marginTop: "7px", color: "#FF6565", fontFamily: "Poppins", fontSize: "12px" }}>
                          {errorMessages[bill?.id]}
                        </Typography>
                      )}

                      <Box style={{
                        display: 'grid',
                        gridTemplateRows: openBillId === bill?.id ? '1fr' : '0fr',
                        transition: 'grid-template-rows 0.3s ease',
                      }}>
                        <Box style={{
                          minHeight: 0,
                          overflowY: 'auto',
                          opacity: openBillId === bill?.id ? 1 : 0,
                          visibility: openBillId === bill?.id ? 'visible' : 'hidden',
                          transition: 'opacity 0.2s ease, visibility 0.3s ease'
                        }}>
                          <Box>
                            <Typography className="mt-4 mb-4" style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins" }}>Edit payment details</Typography>
                            <FullWidthTabs
                              bankList={bankList}
                              openBillId={openBillId}
                              setFormik={setFormik}
                              formik={formik.apiUpdatedBulk.find((b: any) => b.id === openBillId)}
                              MethoadTitel={MethoadTitel}
                              // loaderReander={loaderReander}
                              countrySortcode={countrySortcode}
                            // UpdetPayeeId={""}
                            // editGetdata={editGetdata}
                            // methodError={""}
                            // setPayOutMethodsByCountryId={setPayOutMethodsByCountryId}
                            // PayOutMethodsByCountryId={PayOutMethodsByCountryId}
                            // countryId={formik.country_id}
                            // currency={formik.currency}
                            // bankPaymentMethoad={bankPaymentMethoad}
                            // mobilePaymentmethoad={mobilePaymentmethoad}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                  </Box>
                  <Box className="py-2">
                    <Typography style={{ fontSize: "12px", color: "#282828" }}>{bill?.document_id}</Typography>
                  </Box>
                  <Box className="mt-4 step2_payee_data_wrapper">
                    <Box style={{ width: "99%" }}>
                      <Box sx={{ cursor: "pointer" }} className="w-100 d-flex align-items-center justify-content-end"
                        onClick={() => {

                          handleEditMethod(bill.id);

                        }}
                      >
                        {/* <CreateIcon style={{ borderBottom: "2px Solid #025041", color: "#025041", cursor: "pointer" }} /> */}
                        <EditIcon style={{ width: "18px", height: "18px", cursor: "pointer" }} />
                      </Box>
                      <Box style={{ height: editPayeeId ? "100%" : 0, overflow: "hidden", transition: "height 0.3s ease" }}>
                        {editPayeeId === bill.id && (
                          <Box>
                            <Typography className="mt-4 mb-2" style={{ fontSize: "18px", fontWeight: "semibold", fontFamily: "Poppins" }}>
                              Edit details
                            </Typography>
                            <EditFields
                              editPayeeId={editPayeeId}
                              setFormik={setFormik}
                              formik={formik.apiUpdatedBulk.find((b: any) => b.id === editPayeeId)} // Pass only selected bill data
                              categoriesData={categoriesData}
                              teamData={teamData}
                              onClose={() => setEditPayeeId(null)} // Close function
                            />
                          </Box>
                        )}
                      </Box>
                      <Box >
                        <Box className={`step2_payee_data_container mt-1`}>
                          <Box className="d-flex flex-column col-md-6 col-sm-12 pe-md-3 justify-content-between" style={{ borderRight: "2px solid #e0e0e0" }}>
                            <Box className="step2_payee_data_row">
                              <Box className="d-flex gap-2">
                                <Box style={{ backgroundImage: `url(${SelectedFlag})`, backgroundSize: "cover", backgroundPosition: "center", width: "20px", height: "20px", borderRadius: "50%" }}></Box>
                                <Typography sx={{ fontSize: "13px" }}>{`${selectedCurrency} `}</Typography>
                              </Box>
                              <Typography className="font-base">{bill?.amount || 0} {selectedCurrency}</Typography>
                            </Box>
                            <Box className="step2_payee_data_row ">
                              <Typography className="color_secondary font-base">Fess</Typography>
                              <Typography className="font-base">
                                {(bill?.fees ?? 0) / 100} {selectedCurrency}
                              </Typography>
                            </Box>
                            <Box className="step2_payee_data_row">
                              <Typography className="color_secondary font-base">Invoice no.</Typography>
                              <Typography className="font-base">{bill?.document_id}</Typography>
                            </Box>
                          </Box>
                          <Box className="d-flex flex-column justify-content-between col-md-6 col-sm-12 ps-md-3">
                            <Box className="step2_payee_data_row">
                              <Typography className="color_secondary font-base">Category</Typography>
                              <Typography className="font-base">
                                {bill?.categories?.length === 0 ? "-" : bill?.categories?.map((tag: any, index: number) => (
                                  <Typography className="font-base" key={index} style={{ fontFamily: "Poppins", textAlign: "right" }}>
                                    {tag?.name}  {index !== bill?.categories.length - 1 ? "," : ""}
                                  </Typography>
                                ))}
                              </Typography>

                            </Box>
                            <Box className="step2_payee_data_row">
                              <Typography className="color_secondary font-base">Teams</Typography>
                              <Typography className="font-base">
                                {bill?.teams?.length === 0 ? "-" : bill?.teams?.map((team: any, index: number) => (
                                  <Typography className="font-base" key={index} style={{ fontFamily: "Poppins", textAlign: "right" }}>
                                    {team}  {index !== bill?.teams?.length - 1 ? "," : ""}
                                  </Typography>
                                ))}
                              </Typography>
                            </Box>
                            <Box className="step2_payee_data_row">
                              <Typography className="color_secondary font-base">VAT</Typography>


                              {bill?.vat !== undefined && bill?.vat !== null && (
                                <Typography
                                  className="font-base"
                                  style={{ fontFamily: "Poppins", textAlign: "right" }}
                                >
                                  {bill.vat === "Exempt" ? bill.vat : `${bill.vat}%`}
                                </Typography>
                              )}

                            </Box>

                          </Box>
                        </Box>

                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {formik.apiUpdatedBulk.find((b: any) => b?.id === bill?.id)?.apierror && (
                      <Typography className="mt-2" style={{ color: "#FF6565", fontFamily: "Poppins", fontSize: "12px" }}>
                        {formik.apiUpdatedBulk.find((b: any) => b?.id === bill?.id)?.apierror}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )
            })
          }
        </Box>
      </Box >
      {/* <Box>
        {errorsMsg ? <Typography className="mb-2" style={{ color: "#FF6565", fontFamily: "Poppins", fontSize: "12px" }}>{errorsMsg}</Typography> : null}
      </Box> */}
      <Box>
        <DefaultBtn onClick={() => {
          const totalCheckedAmount = ExampleBills
            .filter(b => b?.ISCHECK)
            .reduce((sum, b) => sum + (b?.amount || 0), 0);

          if (totalCheckedAmount > 0 && totalCheckedAmount <= formik?.transactionAmount) {
            stepupdatefun(2);
            setUseLayout("wideboxlayout");
          } else {
            toast.error(totalCheckedAmount === 0 ?
              "Please select at least one bill" :
              "Selected amount exceeds available balance"
            );
          }
        }} text="Pay Now" style={{ width: "100%" }} />
      </Box>
      <Model isModelOpen={isRejectOpen} setIsModelOpen={setIsRejectOpen}>
        <RejectionBox onClose={() => setIsRejectOpen(false)} placeholder="Enter reason..." />
      </Model>
      <Box>
      </Box>
    </>
  )
}

export default Step2
import { Box, Checkbox, Typography } from "@mui/material"
import "./step3.scss"
import { CustomInput, InputDropBox } from "../../../../../../../ui/ReuseableComponents/Inputs/Inputs"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from "react";
import FullWidthTabs from "./Components/Tabs";
import { DefaultBtn,DefaultGoBackBtn } from "../../../../../../../ui/ReuseableComponents/Buttons/Buttons";
import Model from "../../../../../../../ui/ReuseableComponents/Model/Model";
import CloseIcon from '@mui/icons-material/Close';
import { EditIcon } from "../../../../../../../ui/ReuseableComponents/Icons/Icons";


const Step3 = ({ selectedCurrency, SelectedFlag, formik, stepupdatefun, setUseLayout }: any) => {
  const [isRejectOpen, setIsRejectOpen] = useState(false)
  const [isPayee1MethodOpen, setIsPayee1MethodOpen] = useState(false)
  const handleBankAndMethod = () => {
    setIsPayee1MethodOpen(!isPayee1MethodOpen)
  }


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Box className="step2_container d-flex flex-column gap-4">
          <DefaultGoBackBtn style={{ marginTop: "-50px",textAlign:"left" }} onClick={() => stepupdatefun(1)} />
      <Typography className="step2_heading">Approve bulk payment</Typography>
      <Box fontStyle={{ borderBottom: "1px solid #e0e0e0" }}>
        <Typography className="label">You Send</Typography>
        <CustomInput style={{ marginBottom: "30px" }} />
      </Box>

      <Box className="mt-2 mb-2">
        <Box className="d-flex justify-content-between mb-2">
          <Box className="d-flex align-items-center gap-1">
            <Checkbox
              style={{ padding: "0px 10px 0px 0px" }}
              icon={<img src="/Checkbox.svg" />}
              checkedIcon={<img src="/Checkbox_two.svg" />}
              color="primary"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <Typography className="label">Payee 1 payment details</Typography>
          </Box>
          <Typography className="reject" onClick={() => setIsRejectOpen(true)}>Reject</Typography>
        </Box>
        <Box>
          <InputDropBox onClick={() => handleBankAndMethod()} arrowForwardIcon={(<Box style={{ width: "max-content", cursor: "pointer" }} className="d-flex align-items-center gap-3">
            <Typography onClick={() => handleBankAndMethod()} style={{ cursor: "pointer", fontFamily: "Poppins" }}>Stanbic bank</Typography>
            <ArrowForwardIosIcon
              style={{
                transform: `rotate(${isPayee1MethodOpen ? 90 : 0}deg)`,
                transition: "transform 0.3s ease",
                cursor: "pointer",
              }}
              className="arrowforwardicon"
              onClick={() => handleBankAndMethod()}
            />
          </Box>)} />
          <Box style={{ height: isPayee1MethodOpen ? "650px" : 0, overflow: "hidden", transition: "height 0.3s ease" }}>
            {isPayee1MethodOpen && (
              <Box>
                <Typography className="mt-4 mb-4" style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Poppins" }}>Edit payment details</Typography>
                <FullWidthTabs />
              </Box>
            )}
          </Box>
          <Box className="mt-4 step2_payee_data_wrapper">
            <Box style={{ width: "99%" }}>
              <Box className="w-100 d-flex align-items-center justify-content-end">
                {/* <CreateIcon style={{ borderBottom: "2px Solid #025041", color: "#025041", cursor: "pointer" }} /> */}
                <EditIcon style={{ width: "18px", height: "18px", cursor: "pointer" }} />
              </Box>
              <Box className="step2_payee_data_container mt-3">
                <Box className="d-flex flex-column col-md-6 col-sm-12 pe-md-3" style={{ borderRight: "2px solid #e0e0e0" }}>
                  <Box className="step2_payee_data_row">
                    <Box className="d-flex gap-2">
                      <Box style={{ backgroundImage: `url(${SelectedFlag})`, backgroundSize: "cover", backgroundPosition: "center", width: "20px", height: "20px", borderRadius: "50%" }}></Box>
                      <Typography>KES</Typography>
                    </Box>
                    <Typography className="font-base">20,000 KES</Typography>
                  </Box>
                  <Box className="step2_payee_data_row ">
                    <Typography className="color_secondary font-base">Fess</Typography>
                    <Typography className="font-base">0.00 KES</Typography>
                  </Box >
                  <Box className="step2_payee_data_row">
                    <Typography className="color_secondary font-base">Invoice no.</Typography>
                    <Typography className="font-base">1234567889</Typography>
                  </Box>
                </Box>
                <Box className="d-flex flex-column col-md-6 col-sm-12 ">
                  <Box className="step2_payee_data_row">
                    <Typography className="color_secondary font-base">Category</Typography>
                    <Typography className="font-base">lorem Ipsum</Typography>
                  </Box>
                  <Box className="step2_payee_data_row">
                    <Typography className="color_secondary font-base">Teams</Typography>
                    <Typography className="font-base">lorum Ipsum</Typography>
                  </Box>
                  <Box className="step2_payee_data_row">
                    <Typography className="color_secondary font-base">Vat</Typography>
                    <Typography className="font-base">KES 500</Typography>
                  </Box>

                </Box>
              </Box>
            </Box>

          </Box>
        </Box>

      </Box>
      <Box>
        <Typography sx={{ fontFamily: "Poppins" }}></Typography>
      </Box>
      <Box>
        <DefaultBtn text=" Approve"
          onClick={() => {
            stepupdatefun(3);
            setUseLayout("wideboxlayout");
          }}
          disabled={!isChecked}
          style={{ width: "100%" }} />
      </Box>

      <Model isModelOpen={isRejectOpen} setIsModelOpen={setIsRejectOpen}>
        <Box className="rejection_box">
          <Box className="w-100 d-flex justify-content-end align-items-center">
            <CloseIcon onClick={() => setIsRejectOpen(false)} style={{ cursor: "pointer", color: "#6D6B6B" }} />
          </Box>
          <Box>
            <Typography sx={{ fontFamily: "Poppins" }} className="rejection_heading">Reason for rejection</Typography>
            <Typography sx={{ fontFamily: "Poppins" }} className="rejection_sub_heading">Why would you like to reject this payee?</Typography>
          </Box>
          <Box>
            <CustomInput />
          </Box>
          <DefaultBtn style={{ width: "100%", marginTop: "30px" }} text="Continue" />
        </Box>
      </Model>
    </Box>
  )
}

export default Step3
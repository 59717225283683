import { AppBar, Box, styled, Tabs } from "@mui/material"


export const Tab = ({ value, handleChange, list }: any) => {

    return (
        <AppBar
            style={{ boxShadow: "none" }}
            position="static">
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                    "& .MuiTabs-indicator": {
                        backgroundColor: "#025041",
                    },
                    "& .MuiTab-root.Mui-selected": {
                        color: "#025041",
                    },
                    "& .MuiTab-root": {
                        textTransform: "none",
                        fontFamily: "Poppins"
                    },
                }}
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                style={{
                    backgroundColor: '#ffffff',
                    color: '#000',
                    borderBottom: "1px solid #DDDDDD",
                    boxShadow: "none",
                    fontFamily: "Poppins"
                }}
            >
                {/* {list.map((item: any, index: number) => {
                    return ( */}
                        <Tab label='Express payment' style={{ fontSize: "13px" }} />
                        <Tab label='Bulk payment' style={{ fontSize: "13px" }} />
                        <Tab label='Bill payment' style={{ fontSize: "13px" }} />
                    {/* )
                })} */}
            </Tabs>
        </AppBar>
    )
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  
export const TabPanel=(props: TabPanelProps) =>{
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 6 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  export const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;
  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { CustomInput } from '../../../../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import { DefaultBtn } from '../../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { TheSelectBox } from '../../../../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import "./bankaccount.scss"

const bankList = ["Stabic bank" , "Habib bank" , "Bank Al-Falah"]
const BankAccount = () => {
    const [selectedBank, setSelectedBank] = useState(null);
    const handleBankChange = (event: any) => {
        setSelectedBank(event.target.value);
      };
  return (
    <Box className="d-flex flex-column gap-3">
        <Box>
            <Typography className='mb-2 label'>Bank Name</Typography>
             <TheSelectBox
             value={selectedBank}
             options={bankList}
             defaultOption="--Select recipient bank--"
             onChange={handleBankChange}
             />
        </Box>
        <Box>
            <Typography className='label'>Bank branch</Typography>
            <CustomInput/>
        </Box>
        <Box>
            <Typography className='label'>Account name</Typography>
            <CustomInput/>
        </Box>
        <Box>
            <Typography className='label'>Account Number</Typography>
            <CustomInput/>
        </Box>
        <DefaultBtn text="Save" style={{width:"100%" , marginTop:"30px"}} />
    </Box>
  )
}

export default BankAccount
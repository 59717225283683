import "./paymentfailed.scss"
import { Box, Typography } from "@mui/material"
import { DefaultBtn } from "../../Buttons/Buttons"

const PaymentFailed = ({ heading, text, buttonText, onClick }: any) => {
  return (

    <Box className='transaction_failed_box'>
      <Box>
        <Box style={{ textAlign: "center", padding: "5px 0px 0px 0px" }}>
          <img width={50} height={50} src="/Niobi Loading Animation-Check.gif" />
        </Box>
        <Box style={{ textAlign: "center", padding: "10px 0px 0px 0px" }}>
          <Box>
            <Typography className="transaction_failed_heading">{heading}</Typography>
            <span className="transaction_failed_text" style={{ textAlign: "center", marginTop: "15px" }}>{text}</span>
          </Box>
        </Box>
        <Box style={{ textAlign: "center", padding: "15px 0px 0px 0px" }}>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <DefaultBtn style={{ marginTop: "13px" , width:"80px" }} onClick={onClick} text={buttonText}/>
          </Box>
        </Box>

      </Box>

    </Box>

  )
}

export default PaymentFailed
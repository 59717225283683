import { Box, Typography, Avatar } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { AddUserIcon } from '../Icons/Icons';

const AddANewPayee = ({onClick , style }: any) => {

    return (
        <Box
            className="pt-1 pb-3"
            style={style}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                    width: "100%",
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor:"pointer"
                }}
                onClick={onClick}
            >
                <Box style={{ display: "flex", gap: "20px", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ position: "relative", width: "max-content" }}>
                        <Avatar sx={{ width: 50, height: 50, marginBottom: 1 }} className='avatar'>
                            <AddUserIcon style={{width:"23px"}}/>
                        </Avatar>
                    </Box>

                    <Typography sx={{ marginBottom: 1, fontSize: "16px" , fontWeight:"400" , fontFamily:"Poppins"}}>
                        Add new payee
                    </Typography>
                </Box>
                <ArrowForwardIosIcon sx={{ fontSize: "18px" }} />
            </Box>

        </Box>
    );
}

export default AddANewPayee
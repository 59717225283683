import React from 'react'
import { Box } from '@mui/material'
import './loaderoverlay.scss'

function LoaderOverlay() { 
    return (
        <Box className="overlay-loader">
            <Box className="spinner"></Box>
        </Box>
    )
}

export default LoaderOverlay  
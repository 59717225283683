import { Box } from '@mui/material'
import Bulk_payment_summary from '../../../../../../../ui/ReuseableComponents/BulkPaymentSummary/BulkPaymentSummary'


function Step8({ loaderStatus, setLoaderStatus, stepupdatefun, transactionDetailsReload, transaction_detailReload }: any) {
    return (

        <>
            <Bulk_payment_summary />
        </>
    )
}

export default Step8
import { authHeader, handleResponse } from '../_helpers'

export const entityService = {
    getEntityTypes,
    getEntityActivities,
    createEntity,
    updateEntity,
    getEntities,
    getBilldata,
    getBusinessDetails,
    setCurrentStep,
    getCurrentStep,
    getDetailsForNewApi,
    saveBusinessDetailsApi,
    SaveEntity,
    updetDataAction
}

// get entity types api
function getEntityTypes() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    }
    return fetch(`${process.env.REACT_APP_API_URL}/get-entity-types`, requestOptions).then(handleResponse)
}

// get entity activities api
function getEntityActivities() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    }
    return fetch(`${process.env.REACT_APP_API_URL}/get-entity-activities`, requestOptions).then(handleResponse)
}

// create entity api
function createEntity(EntityRequest, api) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(EntityRequest)
    }
  
    return fetch(`${process.env.REACT_APP_API_URL}${api}`, requestOptions).then(handleResponse)
}
function SaveEntity(EntityRequest, api) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(EntityRequest)
    }
  
    return fetch(`${process.env.REACT_APP_API_URL}${api}`, requestOptions).then(handleResponse)
}
function getBilldata() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        // body: JSON.stringify(EntityRequest)
    }
  
    return fetch(`${process.env.REACT_APP_API_URL}/get-monite-access-token`, requestOptions).then(handleResponse)
}
function updateEntity(EntityRequest) {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(EntityRequest)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/update-entity `, requestOptions).then(handleResponse)
}

function getEntities(req) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    }
    return fetch(`${process.env.REACT_APP_API_URL}/get-entity/${req.id}`, requestOptions).then(handleResponse)
}

function getBusinessDetails(req) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    }
    return fetch(`${process.env.REACT_APP_API_URL}/get-business-details?${req}`, requestOptions).then(handleResponse)
}
function updetDataAction(req) {
   
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(req)
    }

    return fetch(`${process.env.REACT_APP_API_URL}/v2/update-entity-after-review`, requestOptions).then(handleResponse)
}
function setCurrentStep(req) {
   
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(req)
    }

    return fetch(`${process.env.REACT_APP_API_URL}/v2/update-step`, requestOptions).then(handleResponse)
}

function getCurrentStep() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },

    }

    return fetch(`${process.env.REACT_APP_API_URL}/v2/check-step`, requestOptions).then(handleResponse)
}

function getDetailsForNewApi(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },

    }
    //   https://dev.users.niobi.co/api/choice/get-business-details/4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6
    return fetch(`${process.env.REACT_APP_API_URL}/choice/get-business-details/${id}`, requestOptions).then(handleResponse)
}

function saveBusinessDetailsApi(req,payload) {
    if(payload==true){
    req.selfie = ""
    req.kra = ''
    req.idFront = ""
    req.idBack = ""
    req.businessCertificate = ""
    req.passport = ""
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(req)
    }
    return fetch(`${process.env.REACT_APP_API_URL}/choice/save-business-details`, requestOptions).then(handleResponse)
}
import { Box } from '@mui/material';
import styles from '../ui.module.scss';
import { useState } from 'react';
import { ButtonCom } from './Button';
import { SelectBox } from '../selectBox';
import { ErrorMessage } from '../eroor';
import { InputLayout } from '../layoutInput/inputLayout';
import { DateInput } from '../input';
export const Recurringcom = ({ setRecurringdata, values,submit }: any) => {
    const [tebrecurring, setTebrecurring] = useState([
        {
            name: "Weekly",
            id: "weekly"
        },
        {
            name: "Monthly",
            id: 'monthly'
        },
        {
            name: "Yearly",
            id: "yearly"
        }
    ])
    const [dayList, setDayList] = useState([
        { name: "Sunday", id: "sunday" },
        { name: "Monday", id: 'monday' },
        { name: "Tuesday", id: "tuesday" },
        { name: "Wednesday", id: "wednesday" },
        { name: "Thursday", id: "thursday" },
        { name: "Friday", id: "friday" },
        { name: "Saturday", id: "sturday" }
    ])
    const [dateList, setDateList] = useState([
        { name: "1", id: "1" },
        { name: "2", id: "2" },
        { name: "3", id: "3" },
        { name: "4", id: "4" },
        { name: "5", id: "5" },
        { name: "6", id: "6" },
        { name: "7", id: "7" },
        { name: "8", id: "8" },
        { name: "9", id: "9" },
        { name: "10", id: "10" },
        { name: "11", id: "11" },
        { name: "12", id: "12" },
        { name: "13", id: "13" },
        { name: "14", id: "14" },
        { name: "15", id: "15" },
        { name: "16", id: "16" },
        { name: "17", id: "17" },
        { name: "18", id: "18" },
        { name: "19", id: "19" },
        { name: "20", id: "20" },
        { name: "21", id: "21" },
        { name: "22", id: "22" },
        { name: "23", id: "23" },
        { name: "24", id: "24" },
        { name: "25", id: "25" },
        { name: "26", id: "26" },
        { name: "27", id: "27" },
        { name: "28", id: "28" },
        { name: "29", id: "29" },
        { name: "30", id: "30" },
        { name: "31", id: "31" },
    ])
    const [month_name, setMonth_name] = useState([
        { name: "January", id: "january" },
        { name: "February", id: "february" },
        { name: "March", id: "march" },
        { name: "April", id: "april" },
        { name: "May", id: "may" },
        { name: "June", id: "june" },
        { name: "July", id: "july" },
        { name: "August", id: "august" },
        { name: "September", id: "september" },
        { name: "October", id: "october" },
        { name: "November", id: "november" },
        { name: "December", id: "december" }
    ])
    const [errors, seterrors] = useState(
        {
            day: "Please select the Day.",
            date: "Please select the Date.",
            months: "Please select the Months.",
            StopDate:"Please select the Stop Date."
        }
    )

    return (
        <>
            <Box className={styles.RecurringBox}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    {tebrecurring.map((item: any, index: any) => {
                        return (
                            <ButtonCom active={item.id == values.recurring_type ? true : false} onClickset={() => {
                                setRecurringdata({ ...values, recurring_type: item.id })
                            }}>{item.name}</ButtonCom>
                        )
                    })}
                </Box>
                {values.recurring_type == "weekly" ?
                   <InputLayout >
                        <SelectBox mainDevstyle={{ margin: '0px' }} label={'Day'} list={dayList} value={values.day} name="CustomerName" onChangeset={(data: any) => {
                            

                            setRecurringdata({ ...values, day: data.target.value })
                        }} />
                        {!values.day&&submit ? (
                            <ErrorMessage >{errors.day}</ErrorMessage>
                        ) : null}
                    </InputLayout> : ""}
                {values.recurring_type == "monthly" ?
                    <InputLayout>
                        <SelectBox mainDevstyle={{ margin: '0px' }} label={'Date'} list={dateList} value={values.day} name="CustomerName" onChangeset={(data: any) => {
                            

                            setRecurringdata({ ...values, date: data.target.value })
                        }} />
                        {!values.date&&submit ? (
                            <ErrorMessage >{errors.date}</ErrorMessage>
                        ) : null}
                    </InputLayout> : ""}
                {values.recurring_type == "yearly" ?
                    <Box sx={{display:"flex",justifyContent:'space-between'}}>
                        <InputLayout style={{ width: "48%" }}>
                            <SelectBox mainDevstyle={{ margin: '0px' }} label={'Months'} list={month_name} value={values.months} name="CustomerName" onChangeset={(data: any) => {
                                

                                setRecurringdata({ ...values, months: data.target.value })
                            }} />
                            {!values.months&&submit ? (
                                <ErrorMessage >{errors.months}</ErrorMessage>
                            ) : null}
                        </InputLayout>
                        <InputLayout style={{ width: "48%" }}>
                            <SelectBox mainDevstyle={{ margin: '0px' }} label={'Date'} list={dateList} value={values.monthsdate} name="CustomerName" onChangeset={(data: any) => {
                                

                                setRecurringdata({ ...values, monthsdate: data.target.value })
                            }} />
                            {!values.day&&submit ? (
                                <ErrorMessage >{errors.date}</ErrorMessage>
                            ) : null}
                        </InputLayout>
                    </Box>
                    : ""}
                    <InputLayout>
                    <DateInput label={"Stop date"} onChangeset={(data: any) => {
                            

                            setRecurringdata({ ...values, StopDate: data.target.value })
                        }} velue={values.StopDate}  />
                         {!values.StopDate&&submit ? (
                            <ErrorMessage >{errors.date}</ErrorMessage>
                        ) : null}
                    </InputLayout>
            </Box>
        </>
    )
}
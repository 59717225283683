import React, { useState, useRef, useEffect } from 'react'
import { SearchInput } from '../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import { Box, Typography, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import AddANewPayee from '../../../../../../../ui/ReuseableComponents/Payee/PayeeComponents'
import { PayeeList, DetPayeeList } from '../../../../../../../ui/ReuseableComponents/PayeeLists/Payee_List'
import "./step1.scss"
import Step1AddNewPayeeComponent from './Components/AddNewPayee'
import { SkeletonSelectBox } from '../../../../../Skeleton/Skeleton'
import { useDispatch } from 'react-redux'
import { payablesFlowactions } from '../../../../../../../../_actions'
import { BorderBottom, Close as CloseIcon } from "@mui/icons-material";

const Step1 = ({ formik,
  stepupdatefun,
  setIsAddPayeeComponent,
  isAddPayeeComponent,
  getPayees, SelectFleg,
  setSearchPayee,
  searchPayee,
  loader,
  setSelectedUser,
  bankData,
  selectedCurrency,
  UpdetPayeeId,
  setCurrencyedit, Currencyedit, setCountriyIdEDIT, CountriyIdEDIT,
  setUpdetPayeeId
}: any) => {
  const [activeButton, setActiveButton] = useState("All");
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [inlineSuggestion, setInlineSuggestion] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const suggestionRef = useRef<HTMLDivElement>(null);

  const availableSuggestions = getPayees?.all_payees?.map((payee: any) => payee.name) || [];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setSearchPayee(value);

    if (value) {
      const filteredSuggestions = availableSuggestions.filter((suggestion: string) =>
        suggestion.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
      if (filteredSuggestions.length > 0) {
        setInlineSuggestion(filteredSuggestions[0]);
      } else {
        setInlineSuggestion("");
      }
    } else {
      setSuggestions([]);
      setInlineSuggestion("");
    }
    setSelectedIndex(null);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setInputValue(suggestion);
    setSearchPayee(suggestion);
    setSuggestions([]);
    setInlineSuggestion("");
    setSelectedIndex(null);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (suggestionRef.current && !suggestionRef.current.contains(event.target as Node)) {
      setSuggestions([]);
      setInlineSuggestion("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (selectedIndex !== null && suggestions[selectedIndex]) {
        // Select the suggestion based on the keyboard navigation
        handleSuggestionClick(suggestions[selectedIndex]);
      } else if (inlineSuggestion) {
        // Accept the inline suggestion
        setInputValue(inlineSuggestion);
        setSearchPayee(inlineSuggestion);
        setSuggestions([]);
        setInlineSuggestion("");
        setSelectedIndex(null);
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedIndex((prev) =>
        prev === null || prev === suggestions.length - 1 ? 0 : prev + 1
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedIndex((prev) =>
        prev === null || prev === 0 ? suggestions.length - 1 : prev - 1
      );
    }
  };

  const clearInput = () => {
    setInputValue("");
    setSearchPayee("");
    setSuggestions([]);
    setInlineSuggestion("");
    setSelectedIndex(null);
  };

  //////

  const handleClickButton = (buttonName: string) => {
    setActiveButton(buttonName);
  }
  console.log(formik, "formikformik");
  const handlePayeeClick = (payee: any) => {
    console.log(payee, "payeechecker")
    dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payee/${payee?.monite_counterpart_id}/${payee?.payee_account.currency}/${formik?.country_id}`, (data: any) => {
      if (data) {
        setSelectedUser(data);
        // data.counterpart=data
        // formik.setFieldValue('counterpart', data)
        console.log(data, "according to monite id");
        setTimeout(() => {
          stepupdatefun(1)
        }, 2000)
      }
    }) as any);
  }
  const UpdateSeup = (payee: any) => {
    // formik.setFieldValue('counterpart', payee)
    console.log(payee, "payeechecker")

    dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payee/${payee?.monite_counterpart_id}/${formik?.currency}/${formik.country_id}`, (data: any) => {
      if (data) {
        setSelectedUser(data);
        // data.counterpart=data
        console.log(data, "according to monite id");
        setTimeout(() => {
          stepupdatefun(1)
        }, 2000)
      }
    }) as any);
  }


  return (
    <Box ref={suggestionRef}>
      {
        isAddPayeeComponent === false ? <>
          <Box style={{ position: "relative" }}>
            <SearchInput
              onChange={(e: any) => handleInputChange(e)}
              value={inputValue}
              onKeyDown={handleKeyDown}
              placeholder="Search by name"
            />
            {inputValue.length > 0 && (
              <CloseIcon
                onClick={clearInput}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "8px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: "#aaa",
                }}
              />
            )}
            {inlineSuggestion && (
              <div
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "51px",
                  transform: "translateY(-50%)",
                  color: "#6D6B6B",
                  pointerEvents: "none",
                  fontFamily: "poppins",
                  fontSize: "12px",
                  fontWeight: "100px"

                }}
              >
                {inputValue + inlineSuggestion.slice(inputValue.length)}
              </div>
            )}
            <Box style={{ position: "relative" }}>
              {suggestions.length > 0 && (
                <List style={{ position: "absolute", zIndex: "1", maxHeight: "215px", overflowY: "scroll" }}
                  sx={{
                    width: "100%",
                    bgcolor: "#FFFFFF",
                    marginTop: "6px",
                    borderRadius: "8px",
                    boxShadow: "0 3px 11px hsla(0, 2%, 61%, 0.25)"
                  }}
                >
                  {suggestions.map((suggestion: string, index: number) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{
                        bgcolor: selectedIndex === index ? "#E0E0E0" : "transparent",
                        height: "40px",
                      }}
                    >
                      <ListItemButton sx={{ "&.MuiButtonBase-root.MuiListItemButton-root:hover": { backgroundColor: "#f6f6f6" } }} onClick={() => handleSuggestionClick(suggestion)}>
                        <ListItemText
                          primary={
                            <span style={{ color: "#282828", fontFamily: "poppins", fontSize: "12px" }}>
                              <span style={{ fontWeight: "600" }}>{suggestion.slice(0, inputValue.length)}</span>
                              {suggestion.slice(inputValue.length)}
                            </span>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>

          </Box>
          <Box>
            {inputValue && getPayees?.all_payees?.length === 0 && (
              <Box
                sx={{
                  marginTop: "8px",
                  padding: "8px",
                  borderRadius: "5px",
                  bgcolor: "#F9F9F9",
                  fontSize: "14px",
                  fontFamily: "poppins",
                }}
              >
                No payees found. Try a different name.
              </Box>
            )}
          </Box>
          <Box style={{ borderBottom: "1px solid #E0E0E0" }}>
            <AddANewPayee onClick={() => setIsAddPayeeComponent(true)} style={{ marginTop: "22px" }} />
          </Box>
          <Box className="recent_payee_container">
            <Typography className="recent_payee_heading">Recent</Typography>
            <SkeletonSelectBox
              width={"100%"}
              height={'100px'}
              loader={loader}
              style={{ textAlign: "center", margin: '10px 0px' }}
              element={
                <Box style={{ borderBottom: "1px solid #E0E0E0" }}>
                  <PayeeList
                    SelectFleg={SelectFleg}
                    payees={getPayees?.recent_payees}
                    onClick={(payee: any) => handlePayeeClick(payee)}
                  />
                </Box>
              }></SkeletonSelectBox>
          </Box>
          <Box>
            <Box className="d-flex gap-3 pt-4 mb-1">
              <button
                onClick={() => handleClickButton("All")}
                className={`list_all_payee_btn ${activeButton === "All" ? "active" : ""}`}
              >
                All
              </button>
              <button
                onClick={() => handleClickButton("Frequent")}
                className={`list_frequent_payee_btn ${activeButton === "Frequent" ? "active" : ""}`}
              >
                Frequent
              </button>
            </Box>
            <SkeletonSelectBox
              width={"100%"}
              height={'350px'}
              loader={loader}
              style={{ textAlign: "center", margin: '10px 0px' }}
              element={
                <>
                  <DetPayeeList selectedCurrency={selectedCurrency} SelectFleg={SelectFleg} onClick={(payee: any) => {
                    UpdateSeup(payee)
                    console.log(payee, "wwwpayee");
                  }} payees={activeButton == "All" ? getPayees?.all_payees : getPayees?.frequent_payees} />
                </>}></SkeletonSelectBox>
          </Box>
        </> : <Box>
          <Step1AddNewPayeeComponent
            setUpdetPayeeId={setUpdetPayeeId}
            stepupdatefun={stepupdatefun}
            UpdateSeup={UpdateSeup}
            setCountriyIdEDIT={setCountriyIdEDIT}
            CountriyIdEDIT={CountriyIdEDIT}
            Currencyedit={Currencyedit}
            setCurrencyedit={setCurrencyedit}
            UpdetPayeeId={UpdetPayeeId}
            bankData={bankData}
            isAddPayeeComponent={isAddPayeeComponent}
            setIsAddPayeeComponent={setIsAddPayeeComponent} />
        </Box>
      }
    </Box>
  )
}
export default Step1
import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import React, { useState } from 'react'
import { CustomInput } from '../../../../../../../../../../ui/ReuseableComponents/Inputs/Inputs'
import { DefaultBtn } from '../../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons'
import { BankSelectBoxName, TheSelectBox } from '../../../../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs'
import "./bankaccount.scss"

const BankAccount = ({ bankList, openBillId, formik, setFormik }: any) => {



    const handleFormikChange = (updatedData: any) => {
        setEditData((prev: any) => ({ ...prev, ...updatedData }));
    };


    const [editData, setEditData] = useState(formik);

    const handleContinue = () => {
        setFormik((prevFormik: any) => {
            const updatedBills = prevFormik.UploadedBulk.map((b: any) =>
                b.id === openBillId ? { ...b, ...editData } : b
            );
            return { ...prevFormik, UploadedBulk: updatedBills };
        });
        // onClose();
    };
   
    return (
        <Box className="d-flex flex-column gap-3">
            <Box>
                <Typography className='label'>Bank Name</Typography>
                <Autocomplete
                    disableClearable
                    disablePortal
                    options={bankList}
                    getOptionLabel={(option: any) => option.bank_name}
                    value={bankList.find((bank: any) => bank.id === editData?.bank_name) || null}
                    onChange={(event, newValue) =>
                        handleFormikChange({ bank_name: newValue ? newValue.id : null })
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={editData?.bank_name ? "" : "--Select recipient bank--"}
                        />
                    )}
                    sx={{
                        '& .MuiInputBase-root': {
                            display: 'flex',
                            alignItems: 'center',
                            background: '#FFFFFF',
                            borderRadius: '5px',
                            padding: '5px 11px',
                            margin: "10px 0px",
                            minHeight: '48px',
                            maxHeight: '48px',
                            borderColor: "#ccc",
                            fontSize: "12px",
                            '&.Mui-focused': {
                                borderColor: "#015241",
                                boxShadow: "0px 0px 0px 4px rgba(1, 82, 65, 0.25)",
                            },
                            '& .MuiInputBase-input.MuiOutlinedInput-input': {
                                padding: "0px !important",
                                color: "#212529 !important"
                            }
                        },
                    }}
                />
            </Box>
            <Box>
                <Typography className='label'>Bank branch</Typography>
                <CustomInput
                    value={editData?.bank_branch}
                    onChange={(e: any) => handleFormikChange({ bank_branch: e.target.value })}
                    placeholder="Enter Bank Branch"
                    style={{ padding: "5px 11px", borderRadius: "8px", color: "#6D6B6B !important" }}
                />
            </Box>
            <Box>
                <Typography className='label'>Account name</Typography>
                <CustomInput
                    value={editData?.account_name}
                    onChange={(e: any) => handleFormikChange({ account_name: e.target.value })}
                    placeholder="Enter Account Name"
                    style={{ padding: "5px 11px", borderRadius: "8px", color: "#6D6B6B !important" }}
                />
            </Box>
            <Box>
                <Typography className='label'>Account Number</Typography>
                <CustomInput
                    value={editData?.account_number}
                    onChange={(e: any) => handleFormikChange({ account_number: e.target.value })}
                    placeholder="Enter Account Number"
                    style={{ padding: "5px 11px", borderRadius: "8px", color: "#6D6B6B !important" }}
                />
            </Box>
            <DefaultBtn onClick={handleContinue} text="Save" style={{ width: "100%", marginTop: "30px" }} />
        </Box>
    )
}

export default BankAccount
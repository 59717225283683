import { Box, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DefaultBtn, DefaultGoBackBtn, LightBtn } from '../../../../../../../ui/ReuseableComponents/Buttons/Buttons';
import "./step3.scss";
import { payablesFlowactions } from '../../../../../../../../_actions';
import { useDispatch } from 'react-redux';
import LoaderPage_text from '../../../../../PaymentJourney/components/loader/loader';

const Step3 = ({ Submitsetp3or4, stepupdatefun, formik, selectedCurrency, selectedFlag, setUseLayout, loader , users }: any) => {
  const counterpart = formik.counterpart || {};
  const currency = formik.currency || '';
  const amount = formik.amount || 0;
  const vat = formik.vat || 0;
  const fee = formik.Fees || 0;
  const tags = formik.tags || [];
  const teams = formik.teams || [];
  const balance = formik.balance || 0;
  const dispatch = useDispatch()

  console.log("counter part", selectedFlag)

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });
  console.log(fee, formik, "feefee");

  return (
    <Box>
      <DefaultGoBackBtn style={{ marginTop: "-60px" }} onClick={() => stepupdatefun(1)} />
      <Box className="step3_container">
        <Box className="d-flex flex-column gap-2 text-center w-100 justify-content-center" style={{ marginBottom: "30px" }}>
          <AccessTimeIcon className="watch_icon" />
          <Typography className='confirm_heading'>Confirm details</Typography>
          <Typography className="confirm_sub_heading">Please confirm the payment details before you proceed</Typography>
        </Box>
        {/* ***** Payee ***** */}
        <Box className="d-flex flex-column gap-4">
          <Box className="box payee_box d-flex flex-column gap-2">
            <Typography className="box_heading">Payee</Typography>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Name</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {counterpart?.name
                  ? `${counterpart.name}`
                  : '-'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Email</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {counterpart?.contact_email || '-'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Mobile</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {counterpart?.contact_phone || '-'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Payment Method</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {!!formik.method_title && !!formik?.method_no
                  ? `${formik.method_title} ${formik.method_no}`
                  : '-'}
              </Typography>
            </Box>
          </Box>

          {/* ***** Payment details ***** */}
          <Box className="box payment_details_box d-flex flex-column gap-2">
            <Typography className="box_heading">Payment details</Typography>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Amount</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {currency} {!!formik.amount ? formik.amount : '-'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Vat</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {vat ? `${vat}` : '-'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Fee</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {fee ? `${currency} ${fee / 100}` : '-'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Category</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {tags.length > 0 ? tags.map((tag: any) => tag.name).join(", ") : '-'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Teams</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {teams.length > 0 ? teams.map((team: any) => team.name).join(", ") : '-'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Transaction Date</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {formattedDate || '-'}
              </Typography>
            </Box>
          </Box>

          {/* ***** Account details ***** */}
          <Box className="box account_details_box d-flex flex-column gap-2">
            <Typography className='box_heading'>Account details</Typography>
            <Box className="d-flex justify-content-between align-items-center">
              <Box className="d-flex gap-2 align-items-center">
                <Box style={{ backgroundImage: `url(${selectedFlag})`, backgroundSize: "cover", backgroundPosition: "center", width: "18px", height: "18px", borderRadius: "50%" }}></Box>
                <Typography className="kes">{currency}</Typography>
              </Box>
              <Typography style={{ fontFamily: "Poppins" }}>
                {!!users?.account_number ? users?.account_number : '-'}
              </Typography>
            </Box>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography className="color_secondary font_md" style={{ fontFamily: "Poppins" }}>Available balance</Typography>
              <Typography className='font_md' style={{ fontFamily: "Poppins" }}>
                {currency} {balance.toLocaleString() || '-'}
              </Typography>
            </Box>
          </Box>

          <Box className="mt-4 d-flex justify-content-between align-items-center">
            <DefaultBtn loader={loader} onClick={() => Submitsetp3or4()} text="Make payment" style={{width:"100%"}} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Step3;

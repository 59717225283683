import React, { useEffect, useState } from 'react';
import styles from './selectCurrency.module.scss';
import { paymentJourneyActions } from '../../../_actions/paymentJourney.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPaymentJourneyData } from '../../../_reducers/paymentJourney.reducer';
import { Grid, Tooltip } from '@mui/material';
import { choiceActions, userActions } from '../../../_actions';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { getUser } from '../../../_helpers';

const SelectCurrency = ({ setSetpNumberAuth, updateStep, path }: any) => {
  const [render, setRender] = useState(true);
  const [dataRender, setDataRender] = useState(false);
  const [dataAPi, setDataAPI] = useState<any>([]);
  const [rendar, setRenderdata] = React.useState(true);
  const [rendarData, setRebderData] = useState(false)
  const [loader, setLoading] = useState(false)
  const user = getUser()?.data ?? null;

  const dispatch = useDispatch();
  const businessDetailsReducer = useSelector(
    (state: any) => state.getBusinessDetailsReducer.data
  );
  const dataList = useSelector((state) => selectPaymentJourneyData(state));
 

  const navigate = useNavigate();

  useEffect(() => {
    if (render) {
      dispatch(paymentJourneyActions.getAllCurrenciesDetails() as any);
      setRender(false);
      setDataRender(true);
    }
  }, [render]);
  useEffect(() => {
    if (
      dataRender &&
      dataList?.GETALL_CURRENCIES_DETAILS_SUCCESS !== undefined
    ) {
      var newArray: any = [];
      for (
        let index = 0;
        index < dataList?.GETALL_CURRENCIES_DETAILS_SUCCESS.length;
        index++
      ) {
        const element = dataList?.GETALL_CURRENCIES_DETAILS_SUCCESS[index];
        var body = {
          currency: element.currency,
          flag: element.flag,
          id: element.id,
          name: element.name,
          value: false,
        };
        var bodyTrue = {
          currency: element.currency,
          flag: element.flag,
          id: element.id,
          name: element.name,
          value: true,
        };
        if (element.currency == 'KES') {
          newArray.push(bodyTrue);
        } else {
          newArray.push(body);
        }
      }
      setDataAPI(newArray);
    }
  }, [dataRender, dataList?.GETALL_CURRENCIES_DETAILS_SUCCESS]);
  const HendelSubmit = () => {
    const findQuantity = dataAPi.find((data: any) => data.value == true);
    var newArray = [];
    for (let index = 0; index < dataAPi.length; index++) {
      const element = dataAPi[index];
      if (element.value == true) {
        newArray.push(element.id);
      }
    }
    var body = {
      country_id: newArray,
    };
    const Newigesan = () => {

      updateStep(5, true, navigate('/v2/reviews'));
      setSetpNumberAuth(5);

    };

    dispatch(userActions.saveCurrencies(body, Newigesan, setLoading) as any);
  };
  React.useEffect(() => {
    if (rendar) {

      // dispatch(entityActions.getBusinessDetails(body) as any);
      dispatch(choiceActions.getBusinessDetails(user?.user_id) as any);
      setRenderdata(false);
      setTimeout(() => {
        setRebderData(true)

      }, 3000)
    }
  }, [rendar]);


  useEffect(() => {
    if (!!businessDetailsReducer && rendarData) {
      if (!!businessDetailsReducer && businessDetailsReducer?.countries.length > 0) {
        var newArray = [];
        for (
          let index = 0;
          index < dataAPi.length;
          index++
        ) {
          const element = dataAPi[index];
          var finddata = businessDetailsReducer?.countries.find((data: any) => data?.country_id == element.id)
   

          if (finddata?.country_id == element?.id) {

            element.value = true
            newArray.push(element)
          } else {
            element.value = false
            newArray.push(element)
          }

        }

        setDataAPI(newArray)
      }
    }
  }, [rendarData])
  return (
    <>
      <div className={styles.box_wraper}>
        <div className={styles.box_header}>
          <p className={styles.box_heading}>Select your preferred currencies</p>
        </div>

        <div className={styles.box_body}>
          <div className={styles.box_section}>
            <div className={styles.country_name}>Africa</div>
            {dataAPi?.map((item: any) => {
              return (
                <>
                  {/* <div className={styles.currency_list}> */}
                  {item.currency == 'USD' ? (
                    ''
                  ) : (

                    <Grid container className={styles.currency_list}>
                      <Tooltip
                        // className="doc-input-wrapper"
                        title={item.currency == 'KES'?"KES is set as the default currency and cannot be deselected.":""}
                      >
                        <Grid item md={5} sm={4} xs={6}>
                          <div className={styles.country_name_img}>
                            <img src={item.flag} alt="" />
                            <span>{item?.name == "Tanzania, United Republic of" ? "Tanzania" : item?.name}</span>
                          </div>
                        </Grid>
                      </Tooltip>
                      <Tooltip
                        // className="doc-input-wrapper"
                        title={item.currency == 'KES'?"KES is set as the default currency and cannot be deselected.":""}
                      >
                      <Grid item md={7} sm={8} xs={6} className={styles.grid_contenar}>
                        <div className={styles.currency_name}>
                          {item?.currency}
                        </div>
                        <div className={styles.currency_check}>
                          <input
                            type="checkbox"
                            disabled={item.currency == "KES" ? true : false}
                            checked={item.value}
                            className={styles.inputCurrency}
                            id="vehicle1"
                            name="vehicle1"
                            onChange={(e: any) => {
                              var newArray = [];
                              for (
                                let index = 0;
                                index < dataAPi.length;
                                index++
                              ) {
                                const element = dataAPi[index];
                                if (
                                  element.id == item.id &&
                                  element.value == true
                                ) {
                                  element.value = false;
                                  newArray.push(element);
                                } else if (
                                  element.id == item.id &&
                                  element.value == false
                                ) {
                                  element.value = true;
                                  newArray.push(element);
                                } else {
                                  newArray.push(element);
                                }
                              }
                              setDataAPI(newArray);
                            }}
                          />
                        </div>
                      </Grid>
                      </Tooltip>
                    </Grid>
                  )}
                  {/* </div> */}
                </>
              );
            })}

            <div className={`${styles.country_name} mt-3`}>Global</div>
            {dataAPi?.map((item: any) => {
              return (
                <>
                  {item.currency == 'USD' ? (
                    <Grid container className={styles.currency_list}>
                      <Grid item md={5} sm={4} xs={6}> 
                        <div className={styles.country_name_img}>
                          <img src={item.flag} alt="" />
                          <span>{item?.name == "Tanzania, United Republic of" ? "Tanzania" : item?.name}</span>
                        </div>
                      </Grid>
                      <Grid item md={7} sm={8} xs={6} className={styles.grid_contenar}>
                        <div className={styles.currency_name}>
                          {item?.currency}
                        </div>
                        <div className={styles.currency_check}>
                          <input
                            type="checkbox"
                            checked={item.value}
                            className={styles.inputCurrency}
                            id="vehicle1"
                            name="vehicle1"
                            onChange={(e: any) => {
                              var newArray = [];
                              for (
                                let index = 0;
                                index < dataAPi.length;
                                index++
                              ) {
                                const element = dataAPi[index];
                                if (
                                  element.id == item.id &&
                                  element.value == true
                                ) {
                                  element.value = false;
                                  newArray.push(element);
                                } else if (
                                  element.id == item.id &&
                                  element.value == false
                                ) {
                                  element.value = true;
                                  newArray.push(element);
                                } else {
                                  newArray.push(element);
                                }
                              }
                              setDataAPI(newArray);
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                  {/* </div> */}
                </>
              );
            })}
          </div>
          <div className={styles.buttonSection}>
            <Button
              className={styles.submitButton}
              onClick={HendelSubmit}
            >
              {loader == true && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectCurrency;

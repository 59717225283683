import * as React from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Table } from "react-bootstrap";
import { CssBaseline, CircularProgress } from '@mui/material';

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
} declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

interface Data {
    transaction_date: Date;
    reference: string | number;
    Type: string | number;
    beneficiary: number;
    description: string;
    transaction_amount: string | number;
    balance: string | number;
    more_info: string | number;
}

function createData(
    transaction_date: Date,
    reference: string | number,
    Type: string | number,
    beneficiary: number,
    description: string,
    transaction_amount: string | number,
    balance: string | number,
    more_info: string | number
): Data {
    return {
        transaction_date,
        reference,
        Type,
        beneficiary,
        description,
        transaction_amount,
        balance,
        more_info
    };
}



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'transaction_date',
        numeric: false,
        disablePadding: true,
        label: 'Name',
    },
    {
        id: 'reference',
        numeric: false,
        disablePadding: true,
        label: 'Beneficiary',
    },
    {
        id: 'Type',
        numeric: false,
        disablePadding: false,
        label: 'Email',
    },
    {
        id: 'beneficiary',
        numeric: true,
        disablePadding: false,
        label: 'Phone number',
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Business Address ',
    },
    {
        id: 'transaction_amount',
        numeric: false,
        disablePadding: false,
        label: 'Country',
    },

];

interface EnhancedTableProps {
    numSelected: number;
    //   onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            //onRequestSort(event, property);
        };

    return (
        <thead>
            <tr>
                {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className="th"
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </tr>
        </thead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <Toolbar

        //   sx={{
        //     pl: { sm: 2 },
        //     pr: { xs: 1, sm: 1 },
        //     ...(numSelected > 0 && {
        //       bgcolor: (theme) =>
        //         alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        //     }),
        //   }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Nutrition
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        {/* <DeleteIcon /> */}
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        {/* <FilterListIcon /> */}
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

export default function Bulktebal(props: any) {
    // const rows: any[] = props.data ? props.data : [];
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('transaction_date');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [isProgress, setIsProgress] = React.useState(true)
    const [dense, setDense] = React.useState(false);
    const [datatebal, setDatatebal] = React.useState([])
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
   
    const listteball = () => {
        const listtebal: any = localStorage.getItem("Bulkdata")
        setDatatebal(JSON.parse(listtebal))

    }


    React.useEffect(() => {
        listteball()
    }, [])
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = datatebal.map((n: any) => n.Name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datatebal.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });

    const onSelect = (el: any) => {
     
        props.onSelect(el);
    }

    return (

        // <ThemeProvider theme={theme}>
        <>
            {props.loderdata && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <CircularProgress color="success" />
            </Box>}
            {/* <div className='balance__table'> */}

            <TableContainer>
                <Table
                >
                    <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={datatebal.length}
                    />
                    <tbody>

                        {
                            datatebal
                                .map((element: any, index: number) => {
                                    // var TAmount = element.transaction_amount;

                                    // var tagclass = '';
                                    // var textclass = '';
                                    // var tagname = element.type;
                                    // if (element.Type == 'pay_in') {
                                    //     tagclass = 'tag_payin-tag'
                                    //     textclass = 'text_payin-tag'
                                    //     tagname = 'Pay In'
                                    // } else if (element.Type == 'pay_out') {
                                    //     tagclass = 'tag_payout-tag'
                                    //     textclass = 'text_payout-tag'
                                    //     tagname = 'Pay Out'
                                    //     TAmount = -element.transaction_amount;
                                    // } else if (element.Type == 'fees') {
                                    //     tagclass = 'tag_fees-tag'
                                    //     textclass = 'text_fees-tag'
                                    //     tagname = 'Fees'
                                    //     TAmount = -element.transaction_amount;
                                    // }

                                    return (
                                        <TableRow >

                                            <TableCell>
                                                {element.Name}
                                            </TableCell>
                                            {/* <TableCell>{element.reference}</TableCell> */}
                                            <TableCell className="type tag">
                                                {element.Counterpart}
                                            </TableCell>
                                            <TableCell>
                                                {element.Email}
                                                {/* <p className={'tebaldateformette'}>{element.beneficiary}</p> */}
                                            </TableCell>
                                            <TableCell>{element.Phone_number}</TableCell>
                                            <TableCell className={`text-color`}>
                                                {element.Business_Address}
                                            </TableCell>
                                            {/* <TableCell>KES {!!element.balance ? Number(parseFloat(element.balance).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : element.balance}</TableCell> */}
                                            <TableCell>{element.Country}</TableCell>

                                        </TableRow>
                                    );
                                })}
                        {emptyRows > 0 && (
                            <TableRow
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}

                    </tbody>
                </Table>
            </TableContainer>
            </>
        
    );
}
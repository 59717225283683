import { balanceConstants } from "../_constants/balance.constants";

export function getBalanceListReducer(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.GETBALANCE_REQUEST:
            return {
                loading: true,
                data: []
            };
        case balanceConstants.GETBALANCE_SUCCESS:
      
            return {
                loading:false,
                data: action.data
            };
        case balanceConstants.GETBALANCE_FAILURE:
            return {
                loading:false,
                error: action.error
            };
        default:
            return state
    }
}
export function getBalanceListReducertwo(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.GETBALANCE_REQUESTTWO:
            return {
                loading: true
            };
        case balanceConstants.GETBALANCE_SUCCESSTWO:
            return {
                loading:false,
                data: action.data
            };
        case balanceConstants.GETBALANCE_FAILURETWO:
            return {
                loading:false,
                error: action.error
            };
        default:
            return state
    }
}
export function getTopUpDetailsReducer(state={},action: any) {
    switch (action.type) {
        case balanceConstants.GET_TOPUP_DETAILS_FROM_STORE:
            return {
                loading: true
            };
        case balanceConstants.GET_TOPUP_DETAILS_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.GET_TOPUP_DETAILS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getTopUpDetails_DollerReducer(state={},action: any) {
    switch (action.type) {
        case balanceConstants.GETCURRENCIES_REQUEST:
            return {
                loading: true
            };
        case balanceConstants.GETCURRENCIES_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.GETCURRENCIES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getCsvExportReducer(state={},action: any) {
    switch (action.type) {
        case balanceConstants.GET_CSV_EXPORT_REQUEST:
            return {
                loading: true
            };
        case balanceConstants.GET_CSV_EXPORT_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.GET_CSV_EXPORT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function getBankDetailsByIdReducer(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.GET_BANK_DETAILS_ID_REQUEST:
            return {
                loading: true,
            };
        case balanceConstants.GET_BANK_DETAILS_ID_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.GET_BANK_DETAILS_ID_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getpdf_resucer(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.GET_PDF_REQUEST:
            return {
                loading: true,
            };
        case balanceConstants.GET_PDF_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.GET_PDF_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function getpdf_Topup_resucer(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.GET_TOPUP_PDF_REQUEST:
            return {
                loading: true,
            };
        case balanceConstants.GET_TOPUP_PDF_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.GET_TOPUP_PDF_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getpdf_Topup_Stk_resucer(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.GET_TOPUP_PDF_STK_REQUEST:
            return {
                loading: true,
            };
        case balanceConstants.GET_TOPUP_PDF_STK_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.GET_TOPUP_PDF_STK_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function savePaymentDetailsReducer(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.SAVE_PAYMENT_DETAILS_REQUEST:
            return {
                loading: true,
            };
        case balanceConstants.SAVE_PAYMENT_DETAILS_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.SAVE_PAYMENT_DETAILS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function savePaymentcurrencyDetailsReducer(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.SAVE_PAYMENT_CURRENCY_DETAILS_REQUEST:
            return {
                loading: true,
            };
        case balanceConstants.SAVE_PAYMENT_CURRENCY_DETAILS_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.SAVE_PAYMENT_CURRENCY_DETAILS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function PostKasPaymentReducer(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.POST_KES_PAYMENT_REQUEST:
            return {
                loading: true,
            };
        case balanceConstants.POST_KES_PAYMENT_SUCCESS:
            return {
                data: action.data
            };
        case balanceConstants.POST_KES_PAYMENT_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function Stk_deposit_Reducer(state={},action: any) {
    
    switch (action.type) {
        case balanceConstants.STK_DEPOSIT_REQUEST:
            return {
                loading: true,
            };
        case balanceConstants.STK_DEPOSIT_SUCCESS:
            return {
                data: action.data,
                loading: false,
            };
        case balanceConstants.STK_DEPOSIT_FAILURE:
            return {
                error: action.error,
                loading: false,
            };
        default:
            return state
    }
}
export function transtopup_reducer(state = {}, action: any) {
    switch (action.type) {
        case balanceConstants.TRANTOPUP_REQUEST:
            return {
                loading: true,
                data: undefined
            };
        case balanceConstants.TRANTOPUP_SUCCESS:
       
            return {
                data: action.otp.data,
                loading: false
            };
        case balanceConstants.TRANTOPUP_FAILURE:
            return {
                error: action.error,
                loading: false,
                data: undefined
            };
        default:
            return state
    }
}
export const selectAllBalanceLIstData = (state: any) => {
   
    return  {
        'GETBALANCE_SUCCESS': state.getBalanceListReducer.data,
        'GETBALANCE_SUCCESSTWO':(!!state.getBalanceListReducertwo.data && state.getBalanceListReducertwo.data.length > 0) ?state.getBalanceListReducertwo.data:[],
        'GETBALANCE_REQUESTTWO':state.getBalanceListReducertwo.loading,
        'GET_TOPUP_DETAILS_SUCCESSTWO': state.getBalanceListReducertwo.data,
        'GETBALANCE_REQUEST':state.getBalanceListReducer.loading,
        'GET_TOPUP_DETAILS_SUCCESS': state.getTopUpDetailsReducer.data,
        'GETCURRENCIES_SUCCESS': state.getTopUpDetails_DollerReducer.data,
        'GET_CSV_EXPORT_SUCCESS': state.getCsvExportReducer.data,
        'GET_CSV_EXPORT_FAILURE': state.getCsvExportReducer.error,
        'GET_CSV_EXPORT_REQUEST': state.getCsvExportReducer.loading,
        'GET_BANK_DETAILS_ID_SUCCESS': state.getBankDetailsByIdReducer.data,
        'GET_BANK_DETAILS_ID_FAILURE': state.getBankDetailsByIdReducer.error,
        'GET_BANK_DETAILS_ID_REQUEST': state.getBankDetailsByIdReducer.loading,

        'GET_PDF_SUCCESS': state.getpdf_resucer.data,
        'GET_PDF_FAILURE': state.getpdf_resucer.error,
        'GET_PDF_REQUEST': state.getpdf_resucer.loading,

        'GET_TOPUP_PDF_SUCCESS': state.getpdf_Topup_resucer.data,
        'GET_TOPUP_PDF_FAILURE': state.getpdf_Topup_resucer.error,
        'GET_TOPUP_PDF_REQUEST': state.getpdf_Topup_resucer.loading,

        'GET_TOPUP_PDF_STK_SUCCESS': state.getpdf_Topup_Stk_resucer.data,
        'GET_TOPUP_PDF_STK_FAILURE': state.getpdf_Topup_Stk_resucer.error,
        'GET_TOPUP_PDF_STK_REQUEST': state.getpdf_Topup_Stk_resucer.loading,

        'SAVE_PAYMENT_CURRENCY_DETAILS_REQUEST' : state.savePaymentcurrencyDetailsReducer.data,
        'SAVE_PAYMENT_CURRENCY_DETAILS_SUCCESS': state.savePaymentcurrencyDetailsReducer.error,
        'SAVE_PAYMENT_CURRENCY_DETAILS_FAILURE': state.savePaymentcurrencyDetailsReducer.loading,

        'SAVE_PAYMENT_DETAILS_SUCCESS' : state.savePaymentDetailsReducer.data,
        'SAVE_PAYMENT_DETAILS_FAILURE': state.savePaymentDetailsReducer.error,
        'SAVE_PAYMENT_DETAILS_REQUEST': state.savePaymentDetailsReducer.loading,

        'POST_KES_PAYMENT_REQUEST' : state.PostKasPaymentReducer.data,
        'POST_KES_PAYMENT_SUCCESS': state.PostKasPaymentReducer.error,
        'POST_KES_PAYMENT_FAILURE': state.PostKasPaymentReducer.loading,

        'STK_DEPOSIT_SUCCESS' : state.Stk_deposit_Reducer.data,
        'STK_DEPOSIT_FAILURE': state.Stk_deposit_Reducer.error,
        'STK_DEPOSIT_REQUEST': state.Stk_deposit_Reducer.loading,

        'TRANTOPUP_REQUEST': state.transtopup_reducer.loading,
        'TRANTOPUP_SUCCESS': state.transtopup_reducer.data,
        'TRANTOPUP_FAILURE': state.transtopup_reducer.error,
    };
}
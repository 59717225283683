import { Box } from '@mui/material'

import ProgressComponent from '../../../../../../../ui/ReuseableComponents/Notifications/Progress/Progress'
import Bulk_payment_summary from '../../../../../../../ui/ReuseableComponents/BulkPaymentSummary/BulkPaymentSummary'

function Step7({ loaderStatus, setLoaderStatus, stepupdatefun, transactionDetailsReload, transaction_detailReload }: any) {
    return (
        // <Step5 loaderStatus={loaderStatus} transactionDetailsReload={transaction_detailReload} setLoaderStatus={setLoaderStatus}  stepupdatefun={stepupdatefun} />
        <>
            {/* {
                loaderStatus === "pending" ? ( */}
            <ProgressComponent
                text="Your transaction is being processed. Please do not navigate away from this screen" />
            {/* )

                    : loaderStatus === "success" ? (
                    <SuccessComponent
                        heading="Transaction successful" />
                    )
                        : ""
            } */}
        </>
    )
}

export default Step7
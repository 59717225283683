import React, { useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
// import "./connectInvoice.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import moment from "moment";
import { useDispatch } from "react-redux";
import { authHeader, getUser } from "../../../_helpers";
import { Typography } from "@mui/material";
// import styles

const TransactionDetailsLoop = ({ data, showModal, onCloseset }: any) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [transactionDetails, setTransactionDetails] = React.useState<any>([]);
    const [moniteInvocieId, setMoniteInvocieId] = React.useState('')
    const [tagname, settagname] = React.useState<any>('');
    const [Tagclass, setTagclass] = React.useState('');
    const [option, setOption] = React.useState<any>([])
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [datadesplay, setDatadesplay] = React.useState(true)

    const user = getUser()?.data ?? null;
    const dispatch = useDispatch();

   

    useEffect(() => {
        const id: any = searchParams.get('id');
    

        setMoniteInvocieId(id)
    }, [searchParams]);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        // dispatch(balanceActions.getPdf(props.data.reference) as any);
        setDatadesplay(true)
        setTimeout(() => {
            // setBalanceRender(true)
        }, 3000)
    };

    const onClose = () => {
        navigate("/invoice");
    }

    useEffect(() => {
        setOption(data);
    }, [data])
    //   useEffect(() => {
    //     const fetchTransactionDetails = async () => {
    //       const requestOptions = {
    //         headers: {
    //           "Content-Type": "application/json",
    //           ...authHeader(),
    //         },
    //       };
    //       const body = {

    //         "transaction_ids": selected

    //       }
    //       try {
    //         const response = await axios.post(
    //           `${process.env.REACT_APP_API_URL}/invoice/get-linked-transactions`, body,
    //           requestOptions
    //         );

    //         if (response.data.success == true) {
    //          

    //           setTransactionDetails(response?.data?.data);
    //           setOption(response?.data?.data);
    //           // let tagclass = '';
    //           // if (response.data.data.data.Type == 'pay_in') {
    //           //   tagclass = 'tag_payin-tag'
    //           //   settagname('Pay In')
    //           // } else if (response.data.data.data.Type == 'pay_out') {
    //           //   tagclass = 'tag_payout-tag'
    //           //   settagname('Pay Out')
    //           // } else if (response.data.data.data.Type == 'fees') {
    //           //   tagclass = 'tag_fees-tag'
    //           //   settagname('Fees')
    //           // }
    //           // setTagclass(tagclass);
    //         }
    //       } catch (error) {
    //         console.error("Error fetching data:", error);
    //       } finally {
    //         
    //       }
    //     };
    //     if (!!moniteInvocieId) {

    //       fetchTransactionDetails();
    //     }
    //   }, [moniteInvocieId]);

   


    return (

        <Modal
            show={showModal}
            onHide={onCloseset}
            backdrop="static"
            keyboard={false}
            aria-labelledby='ModalHeader'
            className='topup-modal trans-modal'
            id='afaFafffffFFff'
            centered
        >

            {/* <Modal.Body> */}
            <div style={{ margin: "0px 0px", width: "auto" }} className='trans-detail-container'>
                <div className='trans-detail-container__heading' style={{ textAlign: "left" }}>
                    Transaction Details
                </div>
                <div className='trans-detail-containerX' >
                    {option?.map((item: any, index: any) => {
                        return (
                            <div className='trans-detail-container__table' >
                                {!!item?.Type ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Type</  div>
                                        <div className='right text-capitalize tag'>
                                            <span className={`tag_color tag_payin-tag`}>
                                                {item?.Type == "pay_in" ? "Pay In" : item?.Type}
                                            </span>
                                        </div>
                                    </div> : ""
                                }
                                {
                                    (!!item.reference) ?
                                        <div className='dflex b-bot'>
                                            <div className='left'>Reference</div>
                                            <div className='right'>{item.reference}</div>
                                        </div> : ""
                                }
                                {
                                    (!!item.payment_channel) ?
                                        <div className='dflex b-bot'>
                                            <div className='left'>Payment Channel</div>
                                            <div className='right'>{item.payment_channel}</div>
                                        </div> : ""
                                }
                                {
                                    (!!item.created_at) ?
                                        <div className='dflex b-bot'>
                                            <div className='left'>Transaction Date</  div>
                                            <div className='right'>{moment.tz(item.created_at, "UTC").tz(moment.tz.guess()).format('D MMMM YYYY, h:mm A')}</div>
                                        </div> : ""
                                }
                                {
                                    (!!item.transaction_amount) ?
                                        <div className='dflex b-bot'>
                                            <div className='left'>Transaction Amount</div>
                                            <div className='right'>{item.currency} {!!item.transaction_amount ?
                                                Number((parseFloat((item.transaction_amount))).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 }) : 0}</div>
                                        </div> : ""
                                }
                                {!!item.mobile && item?.Type == "pay_in" && !!item.payee ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Sender Name</div>
                                        <div className='right'>{item.payee}</div>
                                    </div> : ""}
                                {!!item.mobile && item?.Type == "pay_in" ?

                                    <div className='dflex b-bot'>
                                        <div className='left'>Sender Number</div>
                                        <div className='right'>{!!item.mobile ? item.mobile : ""}</div>
                                    </div>
                                    : ""
                                }
                                {item?.Type == 'pay_out' ?
                                    (!!item.payee) ?
                                        <div className='dflex b-bot'>
                                            <div className='left'>Payee Name</div>
                                            <div className='right'>{item.payee}</div>
                                        </div> : "" : (!!item.beneficiary) ? <div className='dflex b-bot'>
                                            <div className='left'>To</div>
                                            <div className='right'>{item?.Type == "pay_in" ? item.beneficiary : item.payee}</div>
                                        </div> : ""
                                }
                                {item.payment_channel == "M-Pesa Paybill" && item?.Type == 'pay_out' && !!item.beneficiary_public_name ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Beneficiary Name</div>
                                        <div className='right'>{item.beneficiary_public_name}</div>
                                    </div> : ""}
                                {item?.Type == 'fees' && !!item.beneficiary ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Payee Name</div>
                                        <div className='right'>{item.beneficiary}</div>
                                    </div> : ""}
                                {item.payment_channel == "M-Pesa Send Money" && item?.Type == 'pay_out' && !!item.beneficiary_public_name ?
                                    <div className='dflex b-bot'>
                                        <div className='left'>Beneficiary Name</div>
                                        <div className='right'>{item.beneficiary_public_name}</div>
                                    </div> : ""}
                                {item.payment_channel == "M-Pesa Till" && item?.Type == 'pay_out' && !!item.beneficiary ? <>

                                    <div className='dflex b-bot'>
                                        <div className='left'>Beneficiary Name</div>
                                        <div className='right'>Merchant Till</div>
                                    </div>
                                </> : ""}
                                {item.payment_channel == "M-Pesa Paybill" ||
                                    item.payment_channel == "M-Pesa Till" ? (
                                    <div className="dflex b-bot">
                                        <div className="left">Account Name</div>
                                        <div className="right">{item?.verified_account_name ?? "N/A"}</div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {user?.role_name == 'Developer' ?
                                    <>
                                        {item?.Type == 'pay_out' || item?.Type == "pay_in" ?
                                            <div className="dflex b-bot">
                                                <div className="left">Third Party Reference 1</div>
                                                <div className="right">{!!item.third_party_reference_1 ? item.third_party_reference_1 : "-"}</div>
                                            </div> : ""}
                                        {item?.Type == 'pay_out' || item?.Type == "pay_in" ?
                                            <div className="dflex b-bot">
                                                <div className="left">Third Party Reference 2</div>
                                                <div className="right">{!!item.third_party_reference_2 ? item.third_party_reference_2 : "-"}</div>
                                            </div> : ""}
                                    </> : ""}
                                {/* <div className="dflex b-bot">
                    <div className="left">Invoice No</div>
                    <div className="right">
                      {transactionDetails?.invoice_no}
                    </div>
                  </div> */}

                                {/* {item?.invoices?.length == 0 ? "" : */}
                                {item?.Type == 'pay_in' ?
                <div className="dflex b-bot" >
                  <div className="left">Invoice No</div>
                  <div
                    className="right"
                    style={{
                      // marginLeft: "20px",
                      fontWeight: "bold",
                    }}
                    id="adaDdADddd"
                  >
                    {item?.invoices?.length == 0 && item?.Type == 'pay_in' ?
                      <>
                        {item?.invoices?.length > 0 ? (
                          item.invoices.map((data: any, index: any) => (
                            <Typography
                              key={index}
                              style={{
                                display: "inline-flex",
                              }}
                              className="right"
                            >
                              {`${data.name}${data.invoice_no}${index === item.invoices.length - 1 ? " " : ", "}`}
                            </Typography>
                          ))
                        ) : (
                          "-"
                        )}
                        <Button
                          style={{ fontSize: "12px", marginLeft: "20px", height: '25px' }}
                          className="complete-profile__ADDBTNHHA"
                          disabled={user.role_name == "Auditor" ? true : false}
                          onClick={() => {
                            onClose()
                            navigate(
                              "/transaction/connect-to-invoice?id=" + item.id + "&currency=" + item.currency + "&transactionAmount=" + item.transaction_amount,
                              { replace: true, state: { id: item.id, currency: item.currency, transactionAmount: item.transaction_amount } }
                            );
                          }}
                        >
                          Connect Invoice
                        </Button>

                      </> : <>

                        {item?.invoices?.map((item: any, index: any) => {
                          {
                          
                          }
                          return (
                            <div onClick={() => {
                              navigate("/invoice?id=" + item.monite_invoice_id, { replace: true, state: { id: item.monite_invoice_id } })
                            }}>
                              {item?.invoice_no}{item?.invoices?.length == index + 1 ? "" : ','}{' '}
                            </div>
                          )
                        })}</>}

                  </div>
                </div> : ""}
                                {!!item?.invoices?.invoice_no?
                                <div className="dflex b-bot">
                                    <div className="left">Invoice No</div>
                                    <div className="right">
                                        {/* {item?.invoices?.map((item: any, index: any) => {
                          {
                          
                          }
                          return (
                            <> */}
                                        {item?.invoices?.invoice_no}
                                        {/* {item?.invoices?.length == index + 1 ? "" : ','}{' '} */}
                                        {/* </>
                          )
                        })} */}
                                    </div>
                                </div>:""}
                            </div>
                        )
                    })}

                </div>
                <div className='trans-detail-container__btn-row' style={{ marginTop: "40px" }}>
                    <Button className='trans-detail-container__btn-row__green'
                        style={{
                            backgroundColor: "white",
                            border: "1px solid black"
                        }}
                        onClick={() => {
                            onCloseset()
                        }}>
                        Close
                    </Button>
                    {/* <Button className='trans-detail-container__btn-row__green'
              onClick={handleClick}
            >Share</Button> */}
                </div>
                {/* </Modal.Body> */}
            </div>
        </Modal>
    )
};

export default TransactionDetailsLoop;

import { Autocomplete, Box, Checkbox, formControlClasses, TextField, Typography } from "@mui/material"
import "./step2.scss"
import { CustomInput, InputDropBox } from "../../../../../../../ui/ReuseableComponents/Inputs/Inputs"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from "react";
import { DefaultBtn, DefaultGoBackBtn } from "../../../../../../../ui/ReuseableComponents/Buttons/Buttons";
import Model from "../../../../../../../ui/ReuseableComponents/Model/Model";
import { InputSelectBox, TheSelectBox } from "../../../../../../../ui/ReuseableComponents/SelectBoxes/SelectBoxs";
import { EditIcon } from "../../../../../../../ui/ReuseableComponents/Icons/Icons";
import RejectionBox from "../../../../../../../ui/ReuseableComponents/Notifications/RejectionBox/RejectionBox";
import EditFields from "../../../../../../../ui/ReuseableComponents/Forms/EditFileds/EditFields";
import React from "react";
import { CloseIcon } from "../../../../../../../ui/ReuseableComponents/Icons/Icons";
import AddPaymentMethod from "../../../../../../../ui/ReuseableComponents/Payment/AddPaymentMethod";
import { payablesFlowactions } from '../../../../../../../../_actions';
import { useDispatch } from 'react-redux';
import BillPaymentMethods from "../../../../../../../ui/ReuseableComponents/BillPaymentMethods/BillPaymentMethods";
import { toast } from "react-toastify";
import { ErrorMessage } from '../../../../../../../ui/eroor';
import TransactionExistCom from '../../../../../../../ui/ReuseableComponents/Notifications/TransactionExit/TransactionExistCom';

const Step2 = ({ errorsMsg, setErrorMsg, selectpayees, setErrorMsgapi, errorsmsgapi, UpdetPayee, setIsAddPayeeComponent, selectedCurrency, selectedFlag, setFormik, formik, stepupdatefun, step, setUseLayout, bankList, categoriesData, teamData }: any) => {
  const [isRejectOpen, setIsRejectOpen] = useState(false)
  const [openEditId, setOpenEditId] = useState(null);
  const [openBillId, setOpenBillId] = useState<number | null>(null);
  const [loader, setLoader] = useState(false)
  const [errorMessages, setErrorMessages] = useState<{ [key: number]: string }>({});
  const [SelectCurrency, setSelectCurrency] = useState("KES")
  const dispatch = useDispatch();
  const [DuplicatetarModel, setDuplicatetarModel] = useState(false);
  const [DuplicatetarMsg, setDuplicatetarMsg] = useState<{
    message: string;
    billDetails?: any;
  }>({ message: '' });
  const duplicateTransactions: any[] = [];

  

  useEffect(() => {
    console.log('Formik Bills State Updated:', formik.bill);
  }, [formik.bill]);


  const ChackBlence = (bill: any[]) => {
    const filteredBills = bill.filter((b) => b.ISCHECK);

    const body = {
      data: filteredBills.map((bill) => ({
        currency: bill.currency,
        payee: bill?.counterpart_info?.name,
        country_id: bill?.country_id,
        type: bill?.type,
        payment_method: bill?.correspondent,
        amount: bill?.amount,
        payable_id: bill?.payable_id,
        paymentchannel: bill?.payment_channel,
        ...(bill.payment_channel === "pay bill" && {
          account_number: bill.paybill_account_number,
          paybill_number: bill.paybill_number,
        }),
        ...(bill.payment_channel === "pesalink" && {
          bank_name: bill.bank_name,
          bank_code: bill.bank_code,
          account_number: bill.bank_account_number,
        }),
      })),
    };

    dispatch(payablesFlowactions.PostPaymentfull("/v4/bulk-check-balance", body, CheckBalanceRES, setLoader,false) as any);
  };
  const CheckBalanceRES = (data: any) => {
    if (data.success) {
      const apiBills = formik.selectedBills.map((bill: any) => {
        const response = data?.data?.find((res: any) => res?.data?.payable_id === bill.payable_id);
        
        if(response?.message === "Duplicate transaction detected. Please review your pending transactions. Do you still want to proceed with this transaction?") {
          duplicateTransactions.push({
            message: response.message,
            billDetails: bill.document_id
          }); 
        }

        return response
          ? { ...bill, 
            fees: response.balance?.fees,
            balance: response.balance?.balance / 100, 
            apierror: response.message ,
            total:bill.amount,
            subtotal:bill.amount,
          } 
          : null;
          
      }).filter((bill: any) => bill !== null );

      if(duplicateTransactions.length > 0) {
        setDuplicatetarMsg({
          message: duplicateTransactions[0].message,
          billDetails: duplicateTransactions.map(t => t.billDetails)
        });

      }

      setFormik((prev: any) => ({
        ...prev,
        apiUpdatedBills: apiBills,
        balance: data?.balance,
        grossAmount:data?.total_amount,
        transactionAmount:data?.total_transaction_amount,
        totalFees:data?.total_fee, 
        payment_details:apiBills
      }));

      setErrorMsgapi("");
    } else {
      setErrorMsgapi(data.message);
      setTimeout(()=>{
        toast.error(errorsmsgapi);
      }, 1000)
    }
  };


  const handleBankAndMethod = (billId: number) => {
    setErrorMessages(prev => ({ 
        ...prev,
        [billId]: ""
    }));
    const selectedBill = formik.selectedBills.find((bill: any) => bill.id === billId);

    if (!selectedBill?.ISCHECK) { 
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [billId]: "Please select the bill first.",
      }));
      return;
    }
    setErrorMessages({});
    // setErrorMessages((prevErrors) => ({
    //   ...prevErrors,
    //   [billId]: "",
    // }));

    setOpenBillId((prevBillId) => (prevBillId === billId ? null : billId));
  };


  const handleCheckboxChange = (billId: number, isChecked: boolean,) => {
    setErrorMessages(prev => {
        const newErrors = {...prev};
        delete newErrors[billId];
        return newErrors;
    });
    setFormik((prevFormik: any) => {
      const updatedBills = prevFormik.selectedBills.map((b: any) =>
        b.id === billId ? { ...b, ISCHECK: isChecked } : b
      ); 
      const updatedApiBills = isChecked
      ? prevFormik.apiUpdatedBills
      : prevFormik.apiUpdatedBills.filter((b: any) => b.id !== billId);
      
      const totalCheckedAmount = updatedBills
        .filter((b: any) => b.ISCHECK)
        .reduce((sum: number, b: any) => sum + (b.amount || 0), 0);

      return {
        ...prevFormik,
        selectedBills: updatedBills,
        apiUpdatedBills: updatedApiBills,
        totalAmount: totalCheckedAmount,
        // payment_details:updatedBills.map((bill : any)=> {
        //   return {
        //     ...bill,
        //   total:bill.amount,
        //   subtotal:bill.amount,
        //   }
        // }), 
      };

    });
    if (!isChecked && openBillId === billId) {
      setOpenBillId(null);
    }
  };

  useEffect(() => {
    const checkedBills = formik?.selectedBills?.filter((bill: any) => bill.ISCHECK && bill.method_no !== "");
    console.log(checkedBills,formik,"checkedBillscheckedBills");
    
    if (checkedBills?.length > 0) {
      ChackBlence(checkedBills);
    }
  }, [formik.selectedBills]);

  const handleInputDropBoxChange = (billId: number, newValue: string) => {
    setFormik((prevFormik: any) => {
      const updatedBills = prevFormik.selectedBills.map((b: any) =>
        b.id === billId ? { ...b, method_no: newValue } : b
      );

      return {
        ...prevFormik,
        selectedBills: updatedBills,
      };
    });
  };

  console.log("bill id selectedBills", formik.selectedBills,)

  const onSubmit = () => {
    setDuplicatetarModel(false);
    stepupdatefun(2);
    setUseLayout("wideboxlayout");
  };

  const onCloseTransactionExist = () => {
    setDuplicatetarModel(false);
  };

  return (
    <>
      <DefaultGoBackBtn onClick={() => {
        setFormik((prevFormik: any) => ({
          ...prevFormik,
          selectedBills: prevFormik.selectedBills.map((bill: any) => ({
            ...bill,
            ISCHECK: false,
          })),
          totalAmount: 0,
          apiUpdatedBills: [],
        }));
        setOpenBillId(null);
        setErrorMessages({});
        stepupdatefun(0);
        setErrorMsg("");
        setErrorMsgapi(""); 
      }} style={{ marginTop: "-60px" }} />
      <Box className="step2_container d-flex flex-column gap-4">
        <Typography className="step2_heading ">How much are you sending?</Typography>

        <Box>
          <Typography className="label mb-2">You Send</Typography>
          <InputSelectBox
            readOnly={true}
            // disabled={true}
            onChange={(e: any) => {
              console.log(e.target.value, "e.target.value");
              setFormik({ ...formik, totalAmount: e.target.value });
            }}
            type="number"
            name="amount"
            value={formik?.transactionAmount} placeholder="Enter amount" currencyList={<Box className="d-flex gap-1 align-items-center">
              <Box style={{ backgroundImage: `url(${selectedFlag})`, backgroundSize: "cover", backgroundPosition: "center", width: "20px", height: "20px", borderRadius: "50%" }}></Box>
              <Typography style={{ fontFamily: "Poppins", fontSize: "14px" }}>{selectedCurrency}</Typography>
            </Box>} />
        </Box>

        <Box>
          {
            formik?.
              selectedBills?.map((bill: any, index: number) => {
                return (
                  <Box className=" pt-4 pb-4" style={{ borderTop: "1px solid #e0e0e0" }} key={index}>

                    <Box>
                      <Box>
                        <Box className="d-flex align-items-center gap-2 mb-2">
                          <Checkbox
                            style={{ padding: "0px 0px 0px 0px" }}
                            icon={<img src="/Checkbox.svg" />}
                            checkedIcon={<img src="/Checkbox_two.svg" />}
                            color="primary"
                            checked={bill.ISCHECK || false}
                            onChange={(e) => handleCheckboxChange(bill.id, e.target.checked)}
                          />
                          <Typography className="label">{bill.counterpart_name}'s payment details</Typography>
                        </Box>

                        <InputDropBox
                          readOnly={true}
                          inputValue={bill?.method_no || ""}
                          onInputChange={(e: any) => {
                            handleInputDropBoxChange(bill.id, e.target.value);
                            setErrorMessages(prev => ({
                              ...prev,
                              [bill.id]: ""
                            }));
                          }}
                          style={{ color: "black" }}
                          placeholder={bill?.method_no || ""}
                          disabled={!bill.ISCHECK}
                          arrowForwardIcon={(
                            <Box style={{ width: "max-content" }} className="d-flex align-items-center gap-3">
                              <Typography
                                onClick={() => handleBankAndMethod(bill.id)}
                                style={{ cursor: "pointer", fontFamily: "Poppins", fontSize: "12px" }}
                              >
                                {bill?.method_title || "Select Method"}
                              </Typography>
                              <ArrowForwardIosIcon
                                style={{
                                  transform: `rotate(${openBillId === bill.id ? 90 : 0}deg)`,
                                  transition: "transform 0.3s ease",
                                }}
                                className="arrowforwardicon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleBankAndMethod(bill.id);
                                }}
                              />
                            </Box>
                          )}
                        />
                        {errorMessages[bill.id] && (
                          <Typography style={{marginTop:"7px", color:"#FF6565", fontFamily:"Poppins", fontSize:"12px"}}>
                            {errorMessages[bill.id]}
                          </Typography>
                        )}

                        <Box style={{
                          display: 'grid',
                          gridTemplateRows: openBillId === bill.id ? '1fr' : '0fr',
                          transition: 'grid-template-rows 0.3s ease',
                        }}>
                          <Box style={{
                            minHeight: 0,
                            overflowY: 'auto',
                            opacity: openBillId === bill.id ? 1 : 0,
                            visibility: openBillId === bill.id ? 'visible' : 'hidden',
                            transition: 'opacity 0.2s ease, visibility 0.3s ease'
                          }}>
                            <Box>
                              <BillPaymentMethods
                                bill={bill}
                                setOpenBillId={setOpenBillId}
                                UpdetPayee={UpdetPayee}
                                setIsMethodOpen={""}
                                setFormik={setFormik}
                                ChackBlence={ChackBlence}
                                formik={formik.selectedBills}
                                counterpart={bill.counterpart_info}
                                SelectCurrency={SelectCurrency}
                                enterAmount={formik.amount}
                                setIsAddPayeeComponent={setIsAddPayeeComponent}
                                stepupdatefun={stepupdatefun}
                                selectedPayee={selectpayees}
                                payee={selectpayees}
                                onClose={() => { handleBankAndMethod(bill.id) }}
                                step={step}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                    </Box>
                    <Box className="py-2">
                      <Typography style={{ fontSize: "12px", color: "#282828"}}>{bill.document_id}</Typography>
                    </Box>
                    <Box>
                      <Box className={`step2_payee_data_wrapper`}>
                        <Box className={`step2_payee_data_container mt-1`}>
                          <Box className="d-flex flex-column col-md-6 col-sm-12 pe-md-3 justify-content-between" style={{ borderRight: "2px solid #e0e0e0" }}>
                            <Box className="step2_payee_data_row">
                              <Box className="d-flex gap-2">
                                <Box style={{ backgroundImage: `url(${selectedFlag})`, backgroundSize: "cover", backgroundPosition: "center", width: "20px", height: "20px", borderRadius: "50%" }}></Box>
                                <Typography sx={{ fontSize: "13px" }}>{`${selectedCurrency} `}</Typography>
                              </Box>
                              <Typography className="font-base">{bill.amount || 0} {selectedCurrency}</Typography>
                            </Box>
                            <Box className="step2_payee_data_row ">
                              <Typography className="color_secondary font-base">Fess</Typography>
                              <Typography className="font-base">
                                {formik?.apiUpdatedBills?.find((b: any) => b.id === bill.id)?.fees / 100 || 0} {selectedCurrency}
                              </Typography>
                            </Box>
                            <Box className="step2_payee_data_row">
                              <Typography className="color_secondary font-base">Invoice no.</Typography>
                              <Typography className="font-base">{bill.document_id}</Typography>
                            </Box>
                          </Box>
                          <Box className="d-flex flex-column justify-content-between col-md-6 col-sm-12 ps-md-3">
                            <Box className="step2_payee_data_row">
                              <Typography className="color_secondary font-base">Category</Typography>
                              <Typography className="font-base">
                                {bill?.bulk_payment?.categories?.length === 0 ? "-" : bill?.bulk_payment?.categories?.map((tag: any, index: number) => (
                                  <Typography className="font-base" key={index} style={{ fontFamily: "Poppins", textAlign: "right" }}>
                                    {tag.name}  {index !== bill.bulk_payment.categories.length - 1 ? "," : ""}
                                  </Typography>
                                ))}
                              </Typography>

                            </Box>
                            <Box className="step2_payee_data_row">
                              <Typography className="color_secondary font-base">Teams</Typography>
                              <Typography className="font-base">
                                {bill?.team?.length === 0 ? "-" : bill?.team?.map((team: any, index: number) => (
                                  <Typography className="font-base" key={index} style={{ fontFamily: "Poppins", textAlign: "right" }}>
                                    {team.name}  {index !== bill?.team?.length - 1 ? "," : ""}
                                  </Typography>
                                ))}
                              </Typography>
                            </Box>
                            <Box className="step2_payee_data_row">
                              <Typography className="color_secondary font-base">VAT</Typography>
                              <Typography className="font-base">{`${bill?.vat}${bill.vat.includes("Exempt") ? "" : "%"}`}</Typography>
                            </Box>
                          </Box>
                        </Box>

                      </Box>

                    </Box>
                    <Box>
                      {formik.apiUpdatedBills.find((b: any) => b.id === bill.id)?.apierror ? 
                        <Typography className="mt-2" style={{ color: "#FF6565", fontFamily: "Poppins", fontSize: "12px" }}>
                          {formik.apiUpdatedBills.find((b: any) => b.id === bill.id)?.apierror}
                        </Typography> 
                      : null}
                    </Box>

                  </Box>
                )
              })
          }
        </Box>
      </Box >
      {errorsMsg ? <Typography className="mb-2" style={{ color: "#FF6565", fontFamily: "Poppins", fontSize: "12px" }}>{errorsMsg}</Typography> : null}
      {DuplicatetarModel && (
        <TransactionExistCom 
          onSubmit={onSubmit} 
          onCloseTransactionExist={onCloseTransactionExist} 
          TransactionExistMessage={DuplicatetarMsg.message} 
          TransactionExist={DuplicatetarModel}
          billDetails={DuplicatetarMsg.billDetails}
        />
      )}
      <Box>
        <DefaultBtn onClick={() => { 
          const iserror = formik.apiUpdatedBills.some((b: any) => b.apierror);
          
          const relevantErrors = Object.values(errorMessages).filter(
            msg => msg !== "Please select the bill first."
          );

          const checkedBillsWithoutMethod = formik.selectedBills
            .filter((b: any) => b.ISCHECK && !b.method_no);

          if(checkedBillsWithoutMethod.length > 0) {
            const newErrors = checkedBillsWithoutMethod.reduce((acc: any, b: any) => {
              acc[b.id] = "Please select the method.";
              return acc;
            }, {});
            
            setErrorMessages(newErrors);
          }

          if(DuplicatetarMsg.message){
            setDuplicatetarModel(true);
          }
          
          if (formik.apiUpdatedBills.length > 0 &&
            formik.totalAmount > 0 &&
            formik.totalAmount <= formik.transactionAmount &&
            errorsMsg == "" &&
            !iserror &&
            relevantErrors.length === 0 &&
            checkedBillsWithoutMethod.length === 0
          ) {
            stepupdatefun(2);
            setUseLayout("wideboxlayout");
          } else {
            if (!formik.totalAmount) {
              toast.error("Please select at least one bill.");
            }
          } 
        }} text="Pay Now" style={{ width: "100%" }} />
      </Box>
      <Model isModelOpen={isRejectOpen} setIsModelOpen={setIsRejectOpen}>
        <RejectionBox onClose={() => setIsRejectOpen(false)} placeholder="Enter reason..." />
      </Model>
      <Box> 
      </Box>
    </>
  )
}

export default Step2
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import styles from '../csvfile/Bulktebal.module.scss';
import { selectAllCounterpartsListData } from '../../../../../../_reducers/counterparts.reducer';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {
  getUser,
  isUserAuthenticated,
  authHeader,
  getProperties,
} from '../../../../../../_helpers';
import { counterpartsActions } from '../../../../../../_actions/counterparts.actions';
import { getIn } from 'formik';
import '../../../../../auth/Auth.css';
import { Link, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import amplitude from 'amplitude-js';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import posthog from 'posthog-js';
import { SkeletonDashboardV3 } from '../../../Skeleton/Skeleton';
import { TebalNoData } from '../../../Skeleton/TebalNoData';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'transaction_date';
const DEFAULT_ROWS_PER_PAGE = 5;

export default function ApproveTable() {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [dense, setDense] = React.useState(false);
  const [datatebal, setDatatebal] = React.useState([]);
  const [filedata, setFile] = React.useState('');
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const [counterpartsList, setCounterpartsList] = React.useState<any>([]);
  const [counterpartsListRender, setCounterpartsListRender] = useState(false);
  const [tagsList, setTagsList] = useState<any>([]);
  const [selectedList, setSelectedList] = useState<any>([]);
  const [pageneshn, setPagelist] = useState(1);
  const [remove, setRemovedata] = useState(10);
  const [payeeData, setPayeeData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(10);
  const [loderinapi, setLoderapi] = React.useState(false);
  const [render, setRender] = useState(true);
  const [users, setUsers] = useState<any>();
  const [isUsers, isSetUsers] = useState<any>(true);
  const [rejectError, setRejectError] = useState(false);
  const properties = getProperties();

  let navigate = useNavigate();

  const dispatch = useDispatch();

  const [patternDataArray, setPatternDataArray] = React.useState<any>([
    {
      id: '',
      supplier_id: '',
      document_id: '',
      invoice_date: '',
      due_date: '',
      amount: '',
      description: '',
      categories: [],
      is_current_price: false,
    },
  ]);
  const eventProperties = {
    userId: !!properties && !!properties.user_id ? properties.user_id : null,
    userName: !!properties && !!properties.name ? properties.name : null,
    email: !!properties && !!properties.email ? properties.email : null,
    pageUrl: window.location.href,
  };
  useEffect(() => {
    if (isUsers && getUser) {
      isSetUsers(false);
      setUsers(getUser);
    }
  });
  const getBalanceList = async (datalist: any) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
    };

    setLoderapi(true);
    const response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/payables/get-approved-payables?&page=${datalist}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success == true) {
          setPayeeData(response.data.data.data);
          setTotalCount(response.data.data.total);
        } else {

          setLoderapi(false);
        }

        setLoderapi(false);
      });
  };
  useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(counterpartsActions.getAllCounterparts(setCounterpartsListRender) as any);
      getBalanceList(1);
    }
  }, [render, isUserAuthenticated]);
  React.useEffect(() => {
    let rowsOnMount: any = stableSort(
      datatebal,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );
    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );
  }, [filedata, counterpartsList, tagsList]);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = payeeData.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (
    event: React.MouseEvent<unknown>,
    index: number,
    Nametwo: string
  ) => {
    const selectedIndex = selected.indexOf(Nametwo);

    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Nametwo);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    if (
      counterpartsListRender &&
      counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined
    ) {
      setCounterpartsListRender(false);
      setCounterpartsList(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
    }
  }, [counterpartsListData, counterpartsListRender]);
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const numberMask = createNumberMask({
    prefix: '',
    suffix: '', // This will put the dollar sign at the end, with a space.
  });

  const formInitailData = {
    users: patternDataArray,
  };

  function getStyles(errors: any, fieldName: any) {
    if (getIn(errors, fieldName)) {
      return {
        border: '1px solid red',
      };
    }
  }
  const rejectSubmit = () => {
    setRejectError(false);
    var reject = true;
    const bodyData = selected.map((el: any) => {
      const findData = payeeData.find((o: any) => o.id == el);
      if (findData.comment == undefined || findData.comment == '') {
        const findIndex = payeeData.findIndex((o: any) => o.id == el);
        setRejectError(true);
        payeeData[findIndex].commentError = true;
        reject = false;
      }
      return { id: el, comment: !!findData ? findData.comment : '' };
    });


    if (reject == true) {
      const body = {
        payables: bodyData,
        type: 'rejected',
      };
      const event = 'Bulk Bills Rejected';
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
      dispatch(counterpartsActions.updatePaybles(body) as any);
    }
  };

  const approveSubmit = () => {
    const bodyData = selected.map((el: any) => {
      const findData = payeeData.find((o: any) => o.id == el);
      return { id: el, comment: !!findData ? findData.comment : '' };
    });
    const body = {
      payables: bodyData,
      type: 'approved',
    };
    const event = 'Bills Approved';
    const eventProperties = {
      userId: !!properties && !!properties.user_id ? properties.user_id : null,
      userName: !!properties && !!properties.name ? properties.name : null,
      email: !!properties && !!properties.email ? properties.email : null,
      pageUrl: window.location.href,
    };
    amplitude.getInstance().logEvent(event, eventProperties);
    posthog.capture(event, eventProperties);
    dispatch(counterpartsActions.updatePaybles(body) as any);
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: { primary: { main: '#000' } },
  });
  const StylesForTableCell = {
    minWidth: '110px !important',
  };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <ThemeProvider theme={theme}>
          {/* {loderinapi && (
            <Box
              sx={{
                display: 'flex',
                position: 'absolute',
                top: 150,
                bottom: 0,
                right: 0,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
              }}
            >
              <CircularProgress color="success" />
            </Box>
          )} */}
          <SkeletonDashboardV3 width={'100%'} height={'650px'} loader={loderinapi}
            element={
              <Paper
                sx={{
                  width: '100%',
                  padding: '10px',
                  boxShadow: '0px 4px 12px rgba(89, 87, 87, 0.16)',
                }}
              >

                <TableContainer>
                  <Table
                    className={styles.addteballist}
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                  >
                    <TableHead className={styles.hedarliath}>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            icon={<img src="/Checkbox.svg" />}
                            checkedIcon={<img src="/Checkbox_two.svg" />}
                            color="primary"
                            indeterminate={
                              selected.length > 0 &&
                              selected.length < payeeData.length
                            }
                            checked={
                              payeeData.length > 0 &&
                              selected.length === payeeData.length
                            }
                            onChange={handleSelectAllClick}
                            inputProps={{
                              'aria-label': 'select all desserts',
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <p className={styles.pa_tadaeagagga}>Payee Name</p>
                        </TableCell>
                        <TableCell>
                          <p className={styles.pa_tadaeagagga}>Invoice Number</p>
                        </TableCell>
                        <TableCell>
                          <p className={styles.pa_tadaeagagga}>Invoice Date</p>
                        </TableCell>
                        <TableCell>
                          <p className={styles.pa_tadaeagagga}>Due Date</p>
                        </TableCell>
                        <TableCell>
                          <p className={styles.pa_tadaeagagga}>Amount</p>
                        </TableCell>
                        <TableCell>
                          <p className={styles.pa_tadaeagagga}>Categories</p>
                        </TableCell>
                        <TableCell className={styles.listactivetabal}>
                          <p className={styles.pa_tadaeagagga}>Description</p>
                        </TableCell>
                        <TableCell className={styles.listactivetabal}>
                          <p className={styles.pa_tadaeagagga}>
                            Comment<span id={styles.reddot}></span>
                          </p>
                        </TableCell>
                        <TableCell className={styles.listactivetabal}>
                          <p className={styles.pa_tadaeagagga}>
                            More Info
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {payeeData && payeeData.length > 0
                        ? payeeData.map((user: any, index: any) => {

                          const isItemSelected = isSelected(user.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow hover key={index}>
                              <TableCell className={styles.checkbox_td_datata}>
                                <Checkbox
                                  icon={<img src="/Checkbox.svg" />}
                                  checkedIcon={<img src="/Checkbox_two.svg" />}
                                  color="primary"
                                  name={`users.${index}.is_current_price`}
                                  checked={isItemSelected}
                                  onClick={(event) => {
                                    handleClick(event, index, user.id);
                                    var newArray = [];
                                    for (let j = 0; j < payeeData.length; j++) {
                                      const element = payeeData[j];
                                      // if (index == j) {
                                      if (
                                        user.is_current_price === false &&
                                        index == j
                                      ) {
                                        user.is_current_price = true;
                                        newArray.push(user);
                                      } else if (
                                        user.is_current_price === true &&
                                        index == j
                                      ) {
                                        user.is_current_price = false;
                                        newArray.push(user);
                                      } else {
                                        newArray.push(
                                          JSON.parse(JSON.stringify(element))
                                        );
                                      }
                                    }
                                    setPayeeData(newArray);
                                  }}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </TableCell>

                              <TableCell sx={StylesForTableCell}>
                                <p className={styles.pa_tadaeagagga}>
                                  {user.counterpart_name}
                                </p>
                              </TableCell>
                              <TableCell sx={StylesForTableCell}>
                                <p className={styles.pa_tadaeagagga}>
                                  {user.document_id}
                                </p>
                              </TableCell>
                              <TableCell sx={StylesForTableCell}>
                                <p className={styles.pa_tadaeagagga}>
                                  {moment(user.created_at).format('DD-MMM-YYYY')}
                                </p>
                              </TableCell>
                              <TableCell sx={StylesForTableCell}>
                                <p className={styles.pa_tadaeagagga}>
                                  {' '}
                                  {moment(user.due_date).format('DD-MMM-YYYY')}
                                </p>
                              </TableCell>
                              <TableCell sx={StylesForTableCell}>
                                <p className={styles.pa_tadaeagagga}>
                                  KES {user.amount / 100}
                                </p>
                              </TableCell>
                              <TableCell sx={StylesForTableCell}>
                                <p
                                  className={styles.pa_tadaeagagga}
                                  style={{
                                    overflow: 'scroll',
                                    padding: '8px 0px 0px 8px',
                                  }}
                                >
                                  {!!user.bulk_payment &&
                                    !!user.bulk_payment.categories &&
                                    user.bulk_payment.categories
                                      .map((tg: any) => {
                                        return tg.name;
                                      })
                                      .toString()}
                                </p>
                              </TableCell>
                              <TableCell sx={StylesForTableCell}>
                                <p
                                  style={{
                                    overflow: 'scroll',
                                    display: 'block',
                                    padding: '8px 0px 0px 8px',
                                  }}
                                  className={styles.pa_tadaeagagga}
                                >
                                  {user.description}
                                </p>
                              </TableCell>
                              <TableCell>
                                <ThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    fullWidth
                                    id={styles.text_fild_sjsjsjjs}
                                    className={
                                      rejectError && user.commentError == true
                                        ? styles.tabalinputErrordata
                                        : styles.tabalinputdata
                                    }
                                    margin="normal"
                                    onChange={(value1: any) => {
                                      let text = value1.target.value;
                                      var newArray = [];
                                      for (let j = 0; j < payeeData.length; j++) {
                                        const element = payeeData[j];
                                        if (index == j) {
                                          user.comment = text;
                                          if (text.length > 0) {
                                            user.commentError = false;
                                          }
                                          newArray.push(
                                            JSON.parse(JSON.stringify(user))
                                          );
                                        }
                                      }
                                      setPatternDataArray(newArray);
                                    }}
                                    value={user.comment}
                                    variant="outlined"
                                  />
                                </ThemeProvider>
                              </TableCell>
                              <TableCell>
                                <p
                                  className={styles.pa_tadaeagagga}
                                  style={{
                                    cursor: "pointer",
                                    minWidth: "max-content",
                                    paddingRight: "8px"
                                  }}
                                  onClick={() => {
                                    navigate("/bills?id=" + user.payable_id, { replace: true, state: { id: user.payable_id } })
                                  }}
                                >
                                  View details
                                </p>
                              </TableCell>
                            </TableRow>
                          );
                        })
                        :
                        <TableCell colSpan={10}>
                          <TebalNoData text={'No bills found'} />
                        </TableCell>
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ paddingTop: '10px' }} className="addnewbtnclass">
                  <div className="inpagenum">
                    <p>
                      {remove - 9}-{remove}
                    </p>
                  </div>
                  <div className="btnmaindivin">
                    {pageneshn == 1 ? (
                      <button disabled className="nextntmistish">
                        <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                      </button>
                    ) : (
                      <button
                        className="nextntmistish"
                        onClick={() => {
                          setPagelist(pageneshn - 1);
                          setRemovedata(remove - 10);
                          getBalanceList(pageneshn - 1);
                        }}
                      >
                        <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                      </button>
                    )}
                    {remove > totalCount ? (
                      <button disabled className="nextntmistish">
                        <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                      </button>
                    ) : (
                      <button
                        className="nextntmistish"
                        onClick={() => {
                          setPagelist(pageneshn + 1);
                          setRemovedata(remove + 10);
                          getBalanceList(pageneshn + 1);
                        }}
                      >
                        <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                      </button>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    padding: '20px 0px',
                  }}
                >
                  <div>
                    <Button
                      disabled={selected.length > 0 ? false : true}
                      onClick={() => rejectSubmit()}
                      color="#F87168"
                      className={styles.white_btn_two}
                    >
                      Reject
                    </Button>
                    {(!!users &&
                      !!users.data.role_name &&
                      users.data.role_name == 'Viewer') ||
                      (!!users &&
                        !!users.data.role_name &&
                        users.data.role_name == 'Associate') ||
                      (!!users &&
                        !!users.data.role_name &&
                        users.data.role_name == 'Accountant') ? (
                      <>
                        <Button
                          style={{ cursor: 'not-allowed' }}
                          color="#025041"
                          className={styles.save_btn}
                        >
                          Approve
                        </Button>{' '}
                      </>
                    ) : (
                      <>
                        <Button
                          disabled={selected.length > 0 ? false : true}
                          onClick={() => approveSubmit()}
                          color="#025041"
                          className={styles.save_btn}
                        >
                          Approve
                        </Button>{' '}
                      </>
                    )}
                  </div>
                </div>
              </Paper>}></SkeletonDashboardV3>
        </ThemeProvider>
      </Box>
    </>
  );
}

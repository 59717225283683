import { Box, Typography } from "@mui/material"
import PhoneInput from "react-phone-input-2"
import { InputNumber } from "../../../../../../../../ui/input"

export const SendMoneyComponent = ({ countrySortcode, setPayOutMethodsByCountryId, PayOutMethodsByCountryId, updatedIndex, currency, activeButton }: any) => {
    console.log(countrySortcode, "countrySortcode");


    return (
        <Box>
            <Box>

                <Typography className='mb-2 label'>Phone Number</Typography>

                <PhoneInput
                    inputProps={{
                        name: "phone_number",
                        required: true,
                        autoFocus: true,
                    }}
                    onlyCountries={[countrySortcode]}
                    localization={{ de: countrySortcode }}
                    country={countrySortcode}
                    prefix=""
                    value={PayOutMethodsByCountryId[updatedIndex]?.phone_number}
                    onChange={(value, country, e, formattedValue) => {
                        const dataUppdted = [...PayOutMethodsByCountryId]
                        dataUppdted[updatedIndex].phone_number = value
                        setPayOutMethodsByCountryId(dataUppdted)
                    }} />

            </Box>

            {/* <DefaultBtn text="Add recipient" style={{ width: "100%", marginTop: "30px" }} /> */}
        </Box>
    )

}
export const TillNumberComponent = ({ setPayOutMethodsByCountryId, PayOutMethodsByCountryId, updatedIndex }: any) => {
    // console.log(formik, "formikformikformik");
    console.log(PayOutMethodsByCountryId, updatedIndex, "findmethod");

    return (
        <Box>
            <InputNumber
                requiredFild="NotActive" mainDevstyle={{ margin: '0px' }}
                value={PayOutMethodsByCountryId[updatedIndex].till_number}
                name="till_number"
                label={'Till number'}
                onChangeset={(e: any) => {
                    console.log(PayOutMethodsByCountryId[updatedIndex], e.target.value, "e.target.value");
                    const dataUppdted = [...PayOutMethodsByCountryId]
                    dataUppdted[updatedIndex].till_number = e.target.value
                    setPayOutMethodsByCountryId(dataUppdted)
                    // formik.formik.setFieldValue('till_number', e.target.value)
                    e.preventDefault()
                }}
                placeholder="Input till number here"
            />
        </Box>
    )
}
export const PayBilComponent = ({ setPayOutMethodsByCountryId, PayOutMethodsByCountryId, updatedIndex }: any) => {
    return (
        <Box>
            <Box>
                <InputNumber
                    requiredFild="NotActive" mainDevstyle={{ margin: '10px 0px', }}
                    value={PayOutMethodsByCountryId[updatedIndex].paybill_number}
                    onChangeset={(e: any) => {
                        const dataUppdted = [...PayOutMethodsByCountryId]
                        dataUppdted[updatedIndex].paybill_number = e.target.value
                        setPayOutMethodsByCountryId(dataUppdted)
                    }}
                    label={'Paybill number'}
                    placeholder="Input paybill number here"
                />
                <InputNumber
                    requiredFild="NotActive" mainDevstyle={{ margin: '0px' }}
                    label={'Account number'}
                    value={PayOutMethodsByCountryId[updatedIndex].paybill_account_number}
                    onChangeset={(e: any) => {
                        const dataUppdted = [...PayOutMethodsByCountryId]
                        dataUppdted[updatedIndex].paybill_account_number = e.target.value
                        setPayOutMethodsByCountryId(dataUppdted)
                    }}
                    placeholder="Input account number here"
                />
            </Box>
            {/* <DefaultBtn text="Add recipient" style={{ width: "100%", marginTop: "30px" }} /> */}
        </Box>
    )
}
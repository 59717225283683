import Modal from "react-bootstrap/Modal";
import "./transactionexitcom.scss"
import { Box } from "@mui/material";
import { LightBtn, DefaultBtn } from "../../Buttons/Buttons";

const TransactionExistCom = ({ TransactionExist, onCloseTransactionExist, TransactionExistMessage, onSubmit , billDetails }: any) => {
  return (
    <Modal
      show={TransactionExist}
      onHide={onCloseTransactionExist}
      backdrop="static"
      keyboard={false}
      aria-labelledby="ModalHeader"
      className="topup_modal"
      centered
      size="lg"
    >
      <Box
        style={{
          borderRadius: "5px",
          background: "#FFF",
          width: "100%",
          height: "35vh",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box
            style={{ textAlign: "center", padding: "15px 0px 0px 0px" }}
          >
            <p className="transactionexistmessage">{`Duplicate transaction detected with document ${billDetails?.length === 1 ? 'id' : 'ids'} ${billDetails?.length > 1 ? `${billDetails.slice(0, -1).join(', ')} and ${billDetails.slice(-1)}` : billDetails}. Please review your pending ${billDetails?.length === 1 ? 'transaction' : 'transactions'}. Do you still want to proceed with ${billDetails?.length === 1 ? 'this transaction' : 'these transactions'}?`}</p>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "30%",
                margin: "auto",
                paddingTop: "24px",
                gap: "7px",
              }}
            > 
              <LightBtn
                text="Cancel"
                style={{ width: "45%" }}
                onClick={() => {
                  onCloseTransactionExist();
                }}
              />
              <DefaultBtn
                text="Proceed"
                style={{ width: "45%" }}
                onClick={() => {
                  onSubmit();
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default TransactionExistCom
import styles from './budget.module.scss';
import { Formik, Field, ErrorMessage } from 'formik';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Col, Form, Button, InputGroup } from "react-bootstrap";
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react';
import Multiselect from "multiselect-react-dropdown";
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { getBase64, getKESFormate } from "../../../../_helpers/common";
import Checkbox from '@mui/material/Checkbox';
import { selectPaymentJourneyData } from "../../../../_reducers/paymentJourney.reducer";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import { selectAllCounterpartsListData } from "../../../../_reducers/counterparts.reducer";
import { counterpartsActions } from "../../../../_actions/counterparts.actions";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import { settingSelector } from "../../../../_reducers/setting.reducer";
import { settingActions } from "../../../../_actions/setting.actions";
import { useSearchParams } from "react-router-dom";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface EntityRequest {
   name: string;
   Category: string;
   Period: string;
   amount: string;
   email: string;
}
const Edit_budget = () => {
   const [entity, setEntity] = useState<EntityRequest>({
      name: '',
      Category: '',
      Period: '',
      amount: '',
      email: ""
   })
   const [data_text, setData_text] = useState("")
   const [month, set_Month] = useState('')
   const [month_two, set_Month_two] = useState('')
   const [searchParams, setSearchParams] = useSearchParams();
   const [render, setRender] = useState(true);
   const [datadisplay, setDatadisplay] = useState('')
   const [counterpartsList, setCounterpartsList] = useState<any>([
   ]);
   const [day_name, setDay_name] = React.useState("")
   const [userData, setUserData] = React.useState<any>([]);
   const [checked, setChecked] = React.useState(true);
   const [tagsListRender, setTagsListRender] = useState(true);
   const [category_name, setCategory_name] = useState<any>()
   const [feesRender, setFeesRender] = useState(true);
   const counterpartsListData = useSelector((state) => selectAllCounterpartsListData(state))
   const dataList = useSelector((state) => selectPaymentJourneyData(state))
   const [tagsList, setTagsList] = useState<any>([]);
   const [renderUser, setRenderUser] = React.useState(true)
   const [popup_datat, setPopup_data] = React.useState(true)

   const dispatch = useDispatch();


   useEffect(() => {
      const popup = searchParams.get('id');
      if (!!popup) {
         setDatadisplay(popup)
         dispatch(paymentJourneyActions.budget_viwe_list(popup) as any)
      } else {
      }
   }, [searchParams]);
   const handleChange_TWO = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
   };
   useEffect(() => {
      if (isUserAuthenticated() && render) {
         setRender(false);
         dispatch(paymentJourneyActions.getdurationlist_active() as any)
         dispatch(counterpartsActions.getAllTags() as any)
         dispatch(counterpartsActions.getBudgetTags() as any)
      }
   }, [render, isUserAuthenticated]);
   useEffect(() => {
      if (popup_datat && dataList?.GETBUDGET_LIST_SUCCESS !== undefined) {
         setPopup_data(false)
         const amount_data = dataList?.GETBUDGET_LIST_SUCCESS.items[0].amount / 100
         setEntity({
            name: dataList?.GETBUDGET_LIST_SUCCESS.title,
            Category: dataList?.GETBUDGET_LIST_SUCCESS.items[0].category_id,
            Period: dataList?.GETBUDGET_LIST_SUCCESS.duration_id,
            amount: amount_data.toString(),
            email: dataList?.GETBUDGET_LIST_SUCCESS.assignee_id
         })
         setData_text(dataList?.GETBUDGET_LIST_SUCCESS.type)
         set_Month_two(dataList?.GETBUDGET_LIST_SUCCESS.month)
         set_Month(dataList?.GETBUDGET_LIST_SUCCESS.every)
         setDay_name(dataList?.GETBUDGET_LIST_SUCCESS.day)
         if (dataList?.GETBUDGET_LIST_SUCCESS.repeat == 1) {
            setChecked(true)
         } else {
            setChecked(false)

         }
      }

   }, [popup_datat, dataList]);

   useEffect(() => {
      if (feesRender && dataList?.GETDURATIONLIST_SUCCESS !== undefined) {
         setFeesRender(false);
         setCounterpartsList(dataList.GETDURATIONLIST_SUCCESS);
      }
   }, [dataList, feesRender]);
   React.useEffect(() => {
      if (renderUser && counterpartsListData?.GETTAGS_BUDGET_SUCCESS !== undefined) {
         setRenderUser(false);
         setUserData(counterpartsListData.GETTAGS_BUDGET_SUCCESS);
      }
   }, [counterpartsListData, renderUser]);

   const [month_name, setMonth_name] = useState([
      { name: "January" },
      { name: "February" },
      { name: "March" },
      { name: "April" },
      { name: "May" },
      { name: "June" },
      { name: "July" },
      { name: "August" },
      { name: "September" },
      { name: "October" },
      { name: "November" },
      { name: "December" }
   ])
   const [dayname, setDayname] = React.useState([
      { name: "Sunday" },
      { name: "Monday" },
      { name: "Tuesday" },
      { name: "Wednesday" },
      { name: "Thursday" },
      { name: "Friday" },
      { name: "Saturday" }
   ])
   const [date_text, setDate_text] = React.useState([
      { name: "1" },
      { name: "2" },
      { name: "3" },
      { name: "4" },
      { name: "5" },
      { name: "6" },
      { name: "7" },
      { name: "8" },
      { name: "9" },
      { name: "10" },
      { name: "11" },
      { name: "12" },
      { name: "13" },
      { name: "14" },
      { name: "15" },
      { name: "16" },
      { name: "17" },
      { name: "18" },
      { name: "19" },
      { name: "20" },
      { name: "21" },
      { name: "22" },
      { name: "23" },
      { name: "24" },
      { name: "25" },
      { name: "26" },
      { name: "27" },
      { name: "28" },
      { name: "29" },
      { name: "30" },
      { name: "31" },
   ])

   const numberMask = createNumberMask({
      prefix: '',
      suffix: '' // This will put the dollar sign at the end, with a space.
   })
   useEffect(() => {
      if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
         let options: any = [];
         if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
            counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
               options.push({
                  id: item.id,
                  name: item.name
               })
            })
         }
         setTagsListRender(false);
         setTagsList(options);
      }
   }, [counterpartsListData, tagsListRender]);
   const validationSchema = Yup.object().shape({
      name: Yup.string()
         .required('Name in the required field'),
      Category: Yup.string()
         .required('Please select a category.'),
      Period: Yup.string()
         .required('Budget Period in the required field'),
      amount: Yup.string()
         .required('Please enter the amount.'),

      email: Yup.string()
         .required('Please enter a valid email address.'),

   })
   const theme = createTheme({
      status: {
         danger: orange[500],
      },
   });
   const handleSubmit = (e: any) => {
     
      const find_name = tagsList.find((item: any) => {
         return item.id == e.Category
      })
      if (data_text == "Weekly") {
         const body = {
            title: e.name,
            desc: "this is food budget",
            type: data_text,
            day: day_name,
            // month: month,
            repeat: checked == true ? 1 : 0,
            duration_id: +e.Period,
            assignee_id: e.email,
            data: [
               {
                  category_id: find_name.id,
                  category_name: find_name.name,
                  amount: +parseInt(e.amount.replaceAll(',', '')) * 100,
                  "desc": "this is drink budget"
               }
            ]
         }
     
         if (!day_name) {

         } else {
            dispatch(paymentJourneyActions.edit_budget_active(body, datadisplay) as any)
            setTimeout(() => {
               window.location.href = '/budget'

            }, 4000)
         }
      } else {
         const body = {
            title: e.name,
            desc: "this is food budget",
            type: data_text,
            every: month,
            month: month_two,
            repeat: checked == true ? 1 : 0,
            duration_id: +e.Period,
            assignee_id: e.email,
            data: [
               {
                  category_id: find_name.id,
                  category_name: find_name.name,
                  amount: +parseInt(e.amount.replaceAll(',', '')) * 100,
                  "desc": "this is drink budget"
               }
            ]
         }

         if (!month) {
         } else {
            dispatch(paymentJourneyActions.edit_budget_active(body, datadisplay) as any)
            setTimeout(() => {
               window.location.href = '/budget'
            }, 4000)
         }

      }
   }

   return (
      <>
         <ThemeProvider theme={theme}>
            <div className="balance">
               <div className="balance__header" style={{ marginBottom: '35px' }}>
                  <div className="balance__header__name">Budget</div>
                  <div>

                  </div>
               </div>
               <div style={{ margin: "50px auto" }} className='company-details-container'>
                  <Formik
                     initialValues={entity}
                     enableReinitialize={true}
                     validationSchema={validationSchema}
                     onSubmit={handleSubmit}
                  >
                     {({ values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting }) => (
                        <>
                           <div className='company-details-container__wrapper'>
                              <p className={styles.heding_tage_key_data}>Update Budget</p>
                              <Form id={styles.from_id_main_link} className='form-input' onSubmit={handleSubmit}>
                                 <div className="input-form-wrapper dot">
                                    <label>Name</label>
                                    <InputGroup className="doc-input-wrapper">
                                       <Form.Control
                                          className={touched.name && errors.name ? " doc-form-input error" : 'doc-form-input test valid'}
                                          placeholder="Name"
                                          value={values.name}
                                          name='name'
                                          type="text"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                       />
                                    </InputGroup>
                                    {touched.name && errors.name ? (
                                       <div className="error-message">{errors.name}</div>
                                    ) : null}
                                 </div>
                                 <div className="input-form-wrapper dot">
                                    <label>Budget period</label>
                                    <Form.Select name="Period"
                                       //onChange={handleSubmit}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.Period}
                                       className={touched.Period && errors.Period ? " doc-form-input error" : 'doc-form-input'}
                                    >
                                       <option selected value="">Select Period</option>
                                       {counterpartsList?.map((arrayElement: any, index: number) => {
                                          return (
                                             <option value={arrayElement.id} key={index + 1}>{arrayElement.title}</option>
                                          )
                                       })}
                                    </Form.Select>
                                    {touched.Period && errors.Period ? (
                                       <div className="error-message">{errors.Period}</div>
                                    ) : null}

                                 </div>
                                 <div className="input-form-wrapper dot">
                                    <label>Assign to user</label>
                                    <Form.Select name="email"
                                       //onChange={handleSubmit}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.email}
                                       className={touched.email && errors.email ? " doc-form-input error" : 'doc-form-input'}
                                    >
                                       <option selected value="">Assign to User</option>
                                       {userData?.map((arrayElement: any, index: number) => {
                                          return (
                                             <option value={arrayElement.id} key={index + 1}>{arrayElement.name}</option>
                                          )
                                       })}
                                    </Form.Select>
                                    {touched.email && errors.email ? (
                                       <div className="error-message">{errors.email}</div>
                                    ) : null}

                                 </div>
                                 <div className="input-form-wrapper dot">
                                    <label>Expense category</label>
                                    <Form.Select name="Category"
                                       //onChange={handleSubmit}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       value={values.Category}
                                       className={touched.Category && errors.Category ? " doc-form-input error" : 'doc-form-input'}
                                    >
                                       <option selected value="">Select Category</option>
                                       {tagsList?.map((arrayElement: any, index: number) => {
                                          return (
                                             <option value={arrayElement.id}
                                                onClick={() => { setCategory_name(arrayElement.name) }}
                                                key={index + 1}>{arrayElement.name}</option>
                                          )
                                       })}
                                    </Form.Select>
                                    {touched.Category && errors.Category ? (
                                       <div className="error-message">{errors.Category}</div>
                                    ) : null}

                                 </div>

                                 <div className="input-form-wrapper dot" style={{ padding: 0 }}>
                                    <label>Amount</label>
                                    <InputGroup className="doc-input-wrapper input" style={{ padding: 0 }}>

                                       <MaskedInput
                                          mask={numberMask}
                                          style={{ height: '100%' }}
                                          className="doc-form-input form-control"
                                          placeholder="Total"
                                          value={(values?.amount)}
                                          //onChange={handleSubmit}
                                          name='amount'
                                          onChange={(e) => {
                                             const kesValue = getKESFormate(e.target.value);
                                             const obj = e;

                                         
                                             handleChange(e)
                                          }}
                                          onBlur={handleBlur}
                                          // value="5593.00"
                                          aria-describedby="basic-addon3"

                                       />
                                       <InputGroup.Text className="input-mask" id="basic-addon3">
                                          KES
                                       </InputGroup.Text>
                                    </InputGroup>   {touched.amount && errors.amount ? (
                                       <div className="error-message">{errors.amount}</div>
                                    ) : null}
                                 </div>

                                 <div className={styles.Box_main_contean_dataa}>
                                    <div className={styles.main_three_box_in_geap}>
                                       <div className={data_text == 'Weekly' ? styles.baox_helesh_textborder : styles.Box_fast_line_text} onClick={() => { setData_text('Weekly') }}><p>Weekly</p></div>
                                       <div className={data_text == "Monthly" ? styles.baox_helesh_textborder : styles.Box_fast_line_text} onClick={() => { setData_text('Monthly') }}><p>Monthly</p></div>
                                       <div className={data_text == "Yearly" ? styles.baox_helesh_textborder : styles.Box_fast_line_text} onClick={() => { setData_text('Yearly') }}><p>Yearly</p></div>
                                    </div>
                                    <div style={{ display: 'flex', paddingTop: "10px" }}>
                                       <Checkbox
                                          className={styles.checkbox_data_fromkey}
                                          // defaultChecked
                                          checked={checked}
                                          onChange={handleChange_TWO}
                                          // onChange={(e:any)=>{setRepeat(e.target.value)}}
                                          icon={<img src='/Rectangle 2367.svg'
                                             width={'16px'} />}
                                          checkedIcon={<img src='/Group.svg' width={"21px"} />}
                                          // Group
                                          {...label} />
                                       <p>Repeat</p>
                                    </div>
                                    <div>
                                       <label className={styles.heding_data_in_linee}>Every</label>

                                       {data_text == "Weekly" ? <>
                                          <Form.Select
                                             name="name_day"
                                             //onChange={handleSubmit}
                                             onChange={(e: any) => { setDay_name(e.target.value) }}
                                             // style={{ width: "48%" }}
                                             onBlur={handleBlur}
                                             value={day_name}
                                             className={'doc-form-input'}
                                          >
                                             <option selected value="">Select Day</option>
                                             {dayname?.map((arrayElement: any, index: number) => {
                                                return (
                                                   <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                )
                                             })}
                                          </Form.Select>
                                          {!day_name ?
                                             <div className="error-message">Day in the required field</div>
                                             : null}
                                         
                                       </> : data_text == "Monthly" || data_text == "Yearly" ?
                                          <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "" }}>

                                             <div style={{ width: "48%" }}>
                                                <Form.Select
                                                   name="date"
                                                   onChange={(e: any) => { set_Month(e.target.value) }}
                                                   onBlur={handleBlur}
                                                   value={month}
                                                   className={'doc-form-input'}
                                                >
                                                   <option selected value="">Select Date</option>
                                                   {date_text?.map((arrayElement: any, index: number) => {
                                                      return (
                                                         <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                      )
                                                   })}
                                                </Form.Select>
                                       
                                                {!month ?
                                                   <div className="error-message">Date in the required field</div>
                                                   : null}
                                             </div>
                                             <div style={{ width: "48%" }}>
                                                <Form.Select
                                                   onChange={(e: any) => { set_Month_two(e.target.value) }}
                                                   onBlur={handleBlur}
                                                   value={month_two}
                                                   className={'doc-form-input'}
                                                >
                                                   <option selected value="">Select Month</option>
                                                   {month_name?.map((arrayElement: any, index: number) => {
                                                      return (
                                                         <option value={arrayElement.name} key={index + 1}>{arrayElement.name}</option>
                                                      )
                                                   })}
                                                </Form.Select>
                                                {!month_two ?
                                                   <div className="error-message">Month in the required field </div>
                                                   : null}


                                             </div>
                                          </div> : ""}

                                    </div>

                                 </div>
                                 <div className={styles.button_conteann}>
                                    <div>
                                       <Button onClick={() => { window.location.href = "/budget" }} className={styles.cancel_button_data_textt}>
                                          Cancel
                                       </Button>
                                    </div>
                                    <div className={styles.seve_and_create_button}>
                                       
                                       <Button variant="primary"
                                          type="submit" className='payDetails-modal__btn-row__green '>
                                          Save
                                       </Button>
                                    </div>
                                 </div>
                              </Form>
                           </div>
                        </>
                     )}
                  </Formik>
               </div>
            </div>

         </ThemeProvider>
      </>
   )
}
export default Edit_budget;
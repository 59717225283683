import { Box, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Button } from 'react-bootstrap';
import styles from './invoice.module.scss';
import Invoice_tebal from './components/invoice_tebal';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Invoice_Detail from './components/invoice_Detail';
import { getUser } from '../../../../_helpers';

const Invoice = () => {
    const [id, setId] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const user = getUser()?.data ?? null;

    const theme = createTheme({
        status: {
            danger: "#025041",
        },
    });
    useEffect(() => {
        const id: any = searchParams.get('id');
        setId(id)
    }, [searchParams]);

    return (
        <>
            <ThemeProvider theme={theme}>
                {!!id ? <Invoice_Detail id={id} /> :
                <>
                <Box className={`${styles.ContenarInvoice} balance__header`}>
                    <Box>
                        <Typography className={styles.typo_heding}>Invoice</Typography>
                    </Box>
                    {user.role_name == "Auditor" ? "" :
                    <Box>
                        <Button
                          style={{padding:"12px 16px"}}
                            className={`balance__save-btn ${styles.custom_invoice_btn_width} custom_mobile_btn`}
                            onClick={() => {
                                navigate("/create-invoice")
                            }}
                        >
                            Create new
                        </Button>
                    </Box>}
                </Box>
                <Box className={styles.ContenarTebal}>
                    <Invoice_tebal />
                </Box>
                </>}
            </ThemeProvider>
        </>
    )
}
export default Invoice;
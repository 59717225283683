import { Box, CircularProgress, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { counterpartsActions } from "../../../../_actions/counterparts.actions";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import CategoriesTebal from "./components/CategoriesTebal";
import { Button, InputGroup, Modal, Form } from "react-bootstrap";
import PageHeader from "../PageHeader";
import { getUser,} from "../../../../_helpers";
import styles from './components/CategoriesTebal.module.scss';
import { settingActions } from "../../../../_actions/setting.actions";
import { selectAllCounterpartsListData } from "../../../../_reducers/counterparts.reducer";
import { SkeletonDashboardV3 } from "../Skeleton/Skeleton";
import { CreateButton, SaveButton } from "../../../ui/Button";
import { CsvuploadModel } from "../../../ui/CsvuloadModel/csvuploadModel";
import ImportCategoris from "./components/importCategoris/importCategoris";

const Categories = () => {
    const [pageneshn, setPagelist] = React.useState(1);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [delete_data, setDelete_data] = React.useState('')
    const [ideditdata, setIdeditdata] = React.useState('')
    const [loading, setLoading] = useState(false)
    const [totalCount, setTotalCount] = React.useState(10);
    const [datatabal, setdatatabal] = useState(
        [

        ]
    )
    const [showModal_cerete, setShow_cerete] = React.useState(false);
    const [fild_data, setFild_data] = React.useState("")
    const [model_edit, setModel_edit] = React.useState<any>('')
    const [validesan, setValidesan] = useState(false)
    const [renderUser, setRenderUser] = React.useState(true)
    const [errorMsg, setErrorMsg] = useState("")
    const [nameEdit, setNameEdit] = useState("")
    const [renderData, setRenderData] = useState(true)
    const [dataload, setDataload] = useState(false)
    const [rowId, setRowId] = useState('')
    const [selected, setSelected] = React.useState<any>([]);
    const [loader, setLoader] = useState(false)
    const [remove, setRemovedata] = React.useState(10);
    const [showModal, setShowModal] = React.useState(false)
    const [selectData, setSelectedata] = useState([])
    // const [idEmail, setIdEmail] = React.useState<any>('')
    const [search, setSearch] = React.useState('');
    const [render , setRender] = useState(false)

    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const onSave = (data: any) => {
        setShowModal(false)

        setSelectedata(data)
    }
    const onClose = () => {
        setShowModal(false)
    }
    const counterpartsListData = useSelector((state) =>
        selectAllCounterpartsListData(state)
    );
    const user = getUser()?.data ?? null;
    const handleClick_datat = (event: React.MouseEvent<HTMLButtonElement>, name: any, id: any) => {
        setAnchorEl(event.currentTarget);
        setNameEdit(name)
        setFild_data(name)
        setRowId(id)
    };
    const handleClose = () => {
        setAnchorEl(null);
        // setDelete_data("")
        // setIdeditdata("")
    };
    const getCategoriesList = () => {

    }
    const handleShow = () => {   
        dispatch(counterpartsActions.DeleteCategories(rowId,() => { 
            setRender(true); 
            setTimeout(() => {
                setRender(false);
            }, 17000);
        }) as any); 
    };

    const rejectSubmit = () => {
        const body = {
            "ids": selected
        }
        dispatch(counterpartsActions.DeleteBalkCategories(body, setRenderData) as any);
    }
    const handleShow_cerete = () => {
        setShow_cerete(true)

    }
    const Close = () => {
        setShow_cerete(false);
        setFild_data('') 
        setValidesan(false)
        setErrorMsg('')
    };
    const CereteTeam = () => {
        setValidesan(true)
        if (!!fild_data && model_edit == "cerete") {
            var reqBody = {
                "name": fild_data
            }
            dispatch(counterpartsActions.cereteCategories(reqBody, setRenderData, Close, setErrorMsg) as any)
        } else if (!!fild_data && model_edit == "edit") {
            var reqBody = {
                "name": fild_data
            }
            dispatch(counterpartsActions.updateCategories(reqBody, rowId, setRenderData, Close, setErrorMsg) as any)
        }
    }
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    useEffect(() => {
        if (search.trim() !== '') {
            setRenderData(true);
        } else {
            setRenderData(false);    
        }
    }, [search]);
    useEffect(() => {
        if (!!renderData) {
            // setRenderData(false)  
            dispatch(counterpartsActions.getAllCategories(setDataload, setLoader, pageneshn, search) as any);
            // setRenderData(false)
        }
    }, [search,renderData, pageneshn, render ])
    useEffect(() => {
        if (dataload && counterpartsListData?.GET_COUNTERPARTS_SUCCESS !== undefined) {
            setDataload(false)
            setdatatabal(counterpartsListData?.GET_COUNTERPARTS_SUCCESS?.data)


            // setDatatebal(response.data.data.data);
            setTotalCount(counterpartsListData?.GET_COUNTERPARTS_SUCCESS?.total);
        }
    }, [dataload])

    const [requestRender, setrequestRender] = React.useState(true);

    console.log("render data",renderData)

  
    return (
        <>
            <ThemeProvider theme={theme}>

                <Grid>
                    <Grid item md={12} style={{ width: "100%", }}>
                        <PageHeader
                            title="Categories"
                            extra={
                                <>
                                    {user?.role_name != 'Viewer' ?
                                        <SaveButton setonClick={
                                            () => {
                                                setShowModal(true)
                                            }
                                        } style={{ margin: "0px 12px 0px 0px" }}>
                                            Import Categories
                                        </SaveButton> :
                                        <SaveButton >
                                            Import Categories
                                        </SaveButton>

                                    }
                                    {user?.role_name != 'Viewer' ?


                                        <CreateButton setonClick={() => {
                                            // setModelOpen(true) 
                                            setModel_edit("cerete")
                                            handleShow_cerete()

                                        }}
                                        >
                                            Create New
                                        </CreateButton> :

                                        <CreateButton style={{ cursor: 'not-allowed' }}  >
                                            Create New
                                        </CreateButton>}
                                </>
                            }
                        />
                    </Grid>

                    <Grid item md={12} className={styles.tebalContenar}>
                        <div className='main_serech_box_filtar_data'>
                            <SkeletonDashboardV3 width={'100%'} height={'60px'} loader={loader}
                                element={
                                    <>
                                        <div>
                                            <InputGroup className="balance__filter-wrapper input">
                                                <InputGroup.Text id="basic--addon1" className="icon">
                                                    <SearchIcon />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    className="balance-search-input"
                                                    placeholder="Search by name"
                                                    aria-label="Search"
                                                    aria-describedby="basic-addon1"

                                                    disabled={loading}
                                                    onChange={
                                                        (event) => {
                                                            setTimeout(() => {
                                                                if (event.target.value == '') {
                                                                    setSearch(event.target.value);
                                                                    setrequestRender(true)
                                                                } else {
                                                                    setSearch(event.target.value);
                                                                    setrequestRender(true)
                                                                }
                                                            }, 1500)

                                                        }}
                                                />

                                            </InputGroup>
                                        </div>
                                    </>
                                }
                            />
                        </div>
                        <SkeletonDashboardV3
                            loader={loader}
                            height={'650px'}
                            width={'100%'}
                            element={
                                selectData.length == 0 ?
                                    <CategoriesTebal
                                        datatabal={datatabal}
                                        loading={loading}
                                        setPagelist={setPagelist}
                                        pageneshn={pageneshn}
                                        totalCount={totalCount}
                                        handleClick_datat={handleClick_datat}
                                        setSelected={setSelected}
                                        selected={selected}
                                        rejectSubmit={rejectSubmit}
                                        setRenderData={setRenderData}
                                        setRemovedata={setRemovedata}
                                        remove={remove}
                                    /> : <ImportCategoris data={selectData} />}>

                        </SkeletonDashboardV3>
                    </Grid>
                </Grid>
                {showModal &&
                    <CsvuploadModel showModal={showModal} onClose={onClose} onSave={onSave} setp2titel={'Step 2'} setp2des={"Please upload the saved file"} setp1des={"Fill in and save the details in the file then proceed to step 2"} setp1heding={'Step 1'} Modaldes={"Follow the steps below to import categories"} ModalTitle={'Import categories'} />}
                <Modal
                    show={showModal_cerete}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby='ModalHeader'
                    centered
                ><Modal.Body>
                        <Box className={styles.box_dialog_main}>
                            <Typography className={styles.Create_Team_heding}>{model_edit == "cerete" ? "Create New Category" : `Edit tag ”${nameEdit}”`}</Typography>
                            <div id="fast_name_inpurt_div" className="input-form-wrapper dot">
                                <label>Name</label>
                                <InputGroup className="doc-input-wrapper">
                                    <Form.Control
                                        className={

                                            'doc-form-input test valid'}
                                        placeholder="Name"
                                        value={fild_data}
                                        name='name'
                                        type="text"
                                        onChange={(e: any) => {
                                            setFild_data(e.target.value)
                                        }}
                                    />
                                </InputGroup>
                                {!!errorMsg ?
                                    <p className="error-message">
                                        {/* The name has already been taken. */}
                                        {errorMsg}
                                    </p> :
                                    !fild_data && validesan == true ?
                                        <p className="error-message">Please enter the team name</p>
                                        : ""}
                            </div>

                            <Box className={styles.box_btn_cencal} sx={{ justifyContent: "space-between", display: "flex", gap: "5px" }}>
                                <Button className={styles.cerete_user} onClick={() => { Close() }}>
                                    Cancel
                                </Button>
                                <Button className={styles.savebtn} style={{ marginLeft: "0px" }}
                                    onClick={() => {
                                        CereteTeam()
                                    }}>{model_edit == "cerete" ? "Create" : "Update"}</Button>


                            </Box>
                        </Box></Modal.Body></Modal >
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        // disabled={delete_data == "" ? true : false}
                        onClick={() => {
                            setModel_edit("edit")
                            handleShow_cerete()
                        }}
                        className='menuitem'>Edit</MenuItem>
                    <MenuItem
                        disabled={user?.role_name == 'Director' ? false : true}
                        // disabled={ideditdata == "" ? true : false}
                        onClick={() => {
                            handleClose()
                            // handleClickOpendelog()
                            handleShow()
                            // deleteData()
                        }} className='menuitem' style={{ color: "#F87161" }}>Delete
                    </MenuItem>
                </Menu>
            </ThemeProvider>
        </>
    )
}
export default Categories;

const SearchIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
            stroke="#111111"
            stroke-width="1.1875"
            stroke-miterlimit="1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);
import React, { useCallback, useState, useEffect } from "react";
import PageHeader from "../PageHeader";
import { Dropdown, DropdownMenuItem } from "@team-monite/ui-kit-react";
import { TagsTable, TagFormModal } from "@team-monite/ui-widgets-react";
import "./Tags.scss";
import { Button } from "react-bootstrap";
import { getUser, isUserAuthenticated } from "../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { entityActions } from "../../../../_actions/entity.actions";

export const Tags = () => {

  const [modelOpen, setModelOpen] = React.useState(false)
  const [render, setRender] = useState(true);
  const dispatch = useDispatch();
  var localUser: any = localStorage.getItem('user');


  const changeTime = () => {
    const tokentwo: any = localStorage.getItem('tokentwo')
    if (new Date().getTime() - new Date(JSON.parse(tokentwo)).getTime() >= 600000) {
   
      setRender(false)
      dispatch(entityActions.getBilldata() as any)
    } else {
    
    }
    setTimeout(() => changeTime(), 630000)
  }
  useEffect(() => {
    changeTime()
  }, [render, isUserAuthenticated]);

  return (
    <>
      <div className="tags">
        <PageHeader
          title="Categories"
          extra={
            JSON.parse(localUser).data.role_name != 'Viewer' ?
            

                  <Button color="#025041" onClick={() => { setModelOpen(true) }}
                    className="tags__save-btn" >
                    Create New
                  </Button>:
               
                  <Button color="#025041" style={{ cursor: 'not-allowed' }} className="tags__save-btn" >
                    Create New
                  </Button>
                
          }
        />
        <div className="calss_tage_ajajahhahha" style={{ display: 'flex', height: '94vh', padding: '10px 43px' }}><TagsTable /></div>
      </div>
      {modelOpen ?
      
        <TagFormModal
          onClose={() => { setModelOpen(false) }}
          onCreate={() => { setModelOpen(false) }}
        /> : ""
      }
    </>
  );
};

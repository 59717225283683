import { Avatar, Box, Typography } from '@mui/material'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Button } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import "./billpaymentmethods.scss";
import { BankIcon, PesaIcon } from '../Icons/Icons';
import { CloseIcon } from '../Icons/Icons';
import { useEffect } from 'react';
import { useState } from 'react';

const BillPaymentMethods = ({ setOpenBillId,bill, counterpart, UpdetPayee, setIsMethodOpen, ChackBlence, formik, setFormik, onClose, style, payee, selectedPayee, enterAmount, SelectCurrency, paymentData, setPaymentData, setIsAddPayeeComponent, stepupdatefun }: any) => {
    console.log(formik, "payd selectedPayee data");
    const [renderData, setRenderData] = useState(true)
    const [payeeAccounts, setPayeeAccounts] = useState<any[]>([]);

    useEffect(() => {
        if (counterpart) {
            if (counterpart?.recent_payee_account) {
                setPayeeAccounts((prevAccounts: any[]) => [counterpart.recent_payee_account]);
            }
        }
    }, [counterpart]);

    console.log(payeeAccounts, formik.counterpart?.recent_payee_account, "payeeAccounts");



    const recentPayeeAccount = payeeAccounts?.map((account: any) => {
        const recent = []
        if (!!account?.bank_name) {
            recent.push({
                name: account?.title,
                key: account?.key,
                country_id: account?.country_id,
                payment_channel: account?.payment_channel,
                payment_method: account?.payment_method,
                account: account?.bank_account_number,
                correspondent: account?.correspondent,
                bank_name: account?.bank_name,
                bank_code: account?.bank_code,
                account_number: account?.bank_account_number,
                payType: account?.type,
            })
        }

        if (!!account?.paybill_number) {
            recent.push({
                name: "MPESA Paybill",
                key: account?.key,
                country_id: account?.country_id,
                payment_channel: account?.payment_channel,
                payment_method: account?.payment_method,
                description: `Paybill ${account?.paybill_number}, Account no. ${account?.paybill_account_number}`,
                paybill_number: account?.paybill_number,
                paybill_account_number: account?.paybill_account_number,
                correspondent: account?.correspondent,
                payType: account?.type,
            });
        }

        if (!!account?.till_number) {
            recent.push({
                name: "MPESA Till",
                key: account?.key,
                country_id: account.country_id,
                payment_channel: account?.payment_channel,
                payment_method: account?.payment_method,
                description: `Till ${account?.till_number}`,
                till_number: account?.till_number,
                correspondent: account?.correspondent,
                payType: account?.type,
            });
        }

        if (!!account?.phone_number) {
            recent.push({
                name: "MPESA Send Money",
                key: account?.key,
                country_id: account?.country_id,
                payment_channel: account?.payment_channel,
                payment_method: account?.payment_method,
                phone_number: account?.phone_number,
                description: `Send Money ${account?.phone_number}`,
                correspondent: account?.correspondent,
                payType: account?.type,
            })
        }

        return recent;



    }).flat().filter(Boolean);

    const otherMethods = (counterpart?.other_payee_accounts || payeeAccounts)?.map((account: any) => {
        const methods = [];

        // if (account.payment_channel) {
        //     methods.push({
        //         name: account.title,
        //         key:account.payment_channel.key,
        //         country_id:account.payment_channel.country_id,
        //         description: `${account.payment_channel.field}: ${account.send_money_number || ''}`,
        //         type: 'pesalink',
        //         send_money_number: account.send_money_number
        //     });
        // }

        if (!!account?.bank_name) {
            methods.push({
                name: account?.title,
                key: account?.key,
                country_id: account?.country_id,
                payment_channel: account?.payment_channel,
                payment_method: account?.payment_method,
                account: account?.bank_account_number,
                correspondent: account?.correspondent,
                bank_name: account?.bank_name,
                bank_code: account?.bank_code,
                account_number: account?.bank_account_number,
                payType: account?.paytype,
            });
        }

        if (!!account?.paybill_number) {
            methods.push({
                name: "MPESA Paybill",
                key: account?.key,
                country_id: account?.country_id,
                payment_channel: account?.payment_channel,
                payment_method: account?.payment_method,
                description: `Paybill ${account?.paybill_number}, Account no. ${account?.paybill_account_number}`,
                paybill_number: account?.paybill_number,
                paybill_account_number: account?.paybill_account_number,
                correspondent: account?.correspondent,
                payType: account?.type,
            });
        }

        if (!!account?.till_number) {
            methods.push({
                name: "MPESA Till",
                key: account?.key,
                country_id: account.country_id,
                payment_channel: account?.payment_channel,
                payment_method: account?.payment_method,
                description: `Till ${account?.till_number}`,
                till_number: account?.till_number,
                correspondent: account?.correspondent,
                payType: account?.type,
            });
        }

        if (!!account?.phone_number) {
            methods.push({
                name: "MPESA Send Money",
                key: account?.key,
                country_id: account?.country_id,
                payment_channel: account?.payment_channel,
                payment_method: account?.payment_method,
                phone_number: account?.phone_number,
                description: `Send Money ${account?.phone_number}`,
                correspondent: account?.correspondent,
                payType: account?.type,
            })
        }


        return methods;
    }).flat().filter(Boolean);
    const mixArray = recentPayeeAccount.concat(otherMethods)
    const handleMethodSelect = (method: any) => {
        console.log("Method selected:", method);
        // setIsMethodOpen(false);

        let inputValue = "";
        if (method.payment_method === "Bank Transfer") {
            inputValue = method.account;
        } else if (method.payment_method === "Mobile Money") {
            if (method.payment_channel === "pay bill") {
                inputValue = method.paybill_number;
            } else if (method.payment_channel === "till number") {
                inputValue = method.till_number;
            } else {
                inputValue = method.phone_number;
            }
        }


        setFormik((prevFormik: any) => ({
            ...prevFormik,
            selectedBills: prevFormik.selectedBills.map((b: any) =>
                b.id === bill.id
                    ? {
                        ...b,
                        method_title: method?.name,
                        payment_method: method?.payment_method,
                        correspondent: method?.correspondent,
                        payment_channel: method?.payment_channel,
                        type: method?.payType,
                        method_no: inputValue,
                        paybill_number: method?.paybill_number,
                        paybill_account_number: method?.paybill_account_number,
                        bank_name: method?.bank_name,
                        bank_code: method?.bank_code,
                        bank_account_number: method?.bank_account_number,
                        country_id: method?.country_id,
                    }
                    : b
            ),
        }));
       
       
        setOpenBillId(null)
    };



    console.log(mixArray, "mixArraymixArray");

    useEffect(() => {
        if (mixArray.length == 0) {
            setRenderData(false)
        } else {
            // setFormik({...formik,methoadSelect:mixArray[0]})
            handleMethodSelect(mixArray[0]);
            setRenderData(false)

        }

    }, [renderData]);

    return (
        <Box>
            <Box className="add_payment_method_container">
                <Box className="heading_wrapper mt-4" sx={{ marginBottom: "20px" }}>
                    <Box className="d-flex justify-content-end align-items-center w-full px-3">
                        <Box onClick={onClose} style={{ cursor: "pointer" }}>
                            <CloseIcon style={{ width: "15px" }} className="close_icon" />
                        </Box>
                    </Box>

                    <Typography className="heading mt-1">Choose payment method</Typography>
                    <Typography className='sub_heading_note color_secondary' style={{ color: "#025041" }}>You're sending {bill.amount} {SelectCurrency} to {counterpart?.name}</Typography>
                </Box>
                <Box className="other_methods">
                    <Typography className="sub_heading">Recently Used</Typography>
                    {recentPayeeAccount && recentPayeeAccount.length > 0 ? (

                        recentPayeeAccount.map((method: any, index: number) => (
                            <Box style={{ cursor: "pointer", padding: "8px", borderRadius: "10px",backgroundColor: formik?.some((b: any) => b.id === bill.id && b.method_title === method.name) ? '#F5F5F5' : 'white', }} key={index} className="d-flex gap-3 method" onClick={() => handleMethodSelect(method)}>
                                <Avatar sx={{ width: 45, height: 45 }} className='avatar'>
                                    {
                                        method.payment_method === "Bank Transfer" ? (
                                            <BankIcon style={{ width: "22px" }} />
                                        ) : (
                                            <PesaIcon style={{ width: "22px" }} />
                                        )
                                    }
                                </Avatar>
                                <Box>
                                    <Typography style={{ fontFamily: "Poppins" }}>{method.name}</Typography>
                                    <Typography className="color_secondary" style={{ fontFamily: "Poppins", fontSize: "13px" }}>
                                        {/* {method.description} */}
                                        {method.payment_method === "Bank Transfer"
                                            ? `Account ${method.account}`
                                            : method.description}
                                    </Typography>
                                </Box>
                            </Box>
                        ))


                    ) : (
                        <Typography className="color_secondary" style={{ fontFamily: "Poppins", fontSize: "14px", padding: "10px 0" }}>
                            No recently used methods
                        </Typography>
                    )

                    }
                </Box>



                {otherMethods && otherMethods.length > 0 && (
                    <Box className="other_methods">
                        <Typography className="sub_heading">Other methods</Typography>
                        {otherMethods.map((method: any, index: number) => (
                            <Box style={{ cursor: "pointer", padding: "8px", borderRadius: "10px", backgroundColor: formik?.some((b: any) => b.id === bill.id && b.method_title === method.name) ? '#F5F5F5' : 'white',}} key={index} className="d-flex gap-3 method" onClick={() => handleMethodSelect(method)}>
                                <Avatar sx={{ width: 45, height: 45 }} className='avatar'>
                                    <PesaIcon style={{ width: "22px" }} />
                                </Avatar>
                                <Box>
                                    <Typography style={{ fontFamily: "Poppins" }}>{method.name}</Typography>
                                    <Typography className="color_secondary" style={{ fontFamily: "Poppins", fontSize: "13px" }}>
                                        {/* {method.description} */}
                                        {method.payment_method === "Bank Transfer"
                                            ? `Account ${method.account}`
                                            : method.description}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}

                <Box className="add_payment_method_btn_wrapper">
                    <Button className='add_payment_method_btn'
                        onClick={() => {
                            stepupdatefun(0);
                            setIsAddPayeeComponent(true)
                            UpdetPayee(counterpart?.monite_counterpart_id)
                        }}>
                        <AddIcon className="add_icon" /> <Typography className='button_text'>Add payment method</Typography>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default BillPaymentMethods;
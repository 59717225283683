import moment from "moment";
interface Props {
    transactions: any;
    Heading:any;
}
const Pending_Transactions_popup = (props: Props) => {

    return (
        <div style={{ width: "450px", height: "230px", overflow: "scroll", padding: "10px 20px" }}>
            <div className="header" style={{ fontSize: '14px' }} id="Total_Transactions_header">{props.Heading}</div>
            <div className="main_dev_three">
                <div className="fast_dev" style={{ width: "33%", fontWeight: "600" }}>Date</div>
                <div className="secend_dev" style={{ width: "35%", textAlign: "left", fontWeight: "600" }} >Payee</div>
                {props?.Heading=="Unsettled Transactions"?"":
                <div className="thred_dev" style={{ fontWeight: "600", textAlign: "left" }}>Fee</div>}
                <div className="thred_dev" style={{ fontWeight: "600", textAlign: "left" }}>
                    Amount
                </div>
            </div>
            {!!props && !!props.transactions && props.transactions.map((data: any, index: any) => {

                return (
                    <>

                        <div className="main_dev_three">
                            <div className="fast_dev" style={{ width: "33%" }}>
                                {moment(data.transaction_date).format("DD-MMM-YYYY")}
                            </div>
                            <div className="secend_dev" style={{ width: "35%", textAlign: "left" }}>
                                {!!data.payee?data.payee:"-"}
                            </div>
                            {props?.Heading=="Unsettled Transactions"?"":
                            <div className="thred_dev" style={{ textAlign: "left" }}>

                                <span>{data.currency}</span> {!!data && !!data.fee ? (data.fee / 100).toLocaleString('en', { minimumFractionDigits: 2 }) : "0.00"}
                            </div>}
                            {props?.Heading=="Unsettled Transactions"?
                             <div className="thred_dev" style={{ textAlign: "left" }}>
                             <span>{data.currency}</span>  {!!data && !!data.credit ? (data.credit/100).toLocaleString('en', { minimumFractionDigits: 2 }) : "0.00"}

                         </div>
                            :
                            <div className="thred_dev" style={{ textAlign: "left" }}>
                                <span>{data.currency}</span> 
                                {" "} {!!data && !!data.amount ? (data.amount).toLocaleString('en', { minimumFractionDigits: 2 }) : "0.00"}

                            </div>}
                        </div>
                    </>
                )
            })}
        </div>
    )
}
export default Pending_Transactions_popup;

import { ChoiceBankConstants } from '../_constants/choiceBank.constants';

export function getChoiceTypesReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_GETTYPES_SUCCESS:
      return {
        data: action.data,
      };
    default:
      return state;
  }
}

export function getOnboardingInfoReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_ONBOARDING_SUCCESS:
      return {
        data: action.data,
      };
    default:
      return state;
  }
}

export function getBusinessOperationModesReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_GETOPERATINGMODES_SUCCESS:
      return {
        data: action.data,
      };
    default:
      return state;
  }
}

export function getGenderReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_GETGENDER_SUCCESS:
      return {
        data: action.data,
      };
    default:
      return state;
  }
}

export function getIndustriesReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_GETINDUSTRIES_SUCCESS:
    
      return {
        data: action.data,
      };
    default:
      return state;
  }
}

export function createBusinessReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_CREATEBUSINESS_REQUEST:
      return {
        loading: true,
      };
    case ChoiceBankConstants.CHOICE_CREATEBUSINESS_SUCCESS:
      return {
        data: action.data,
        loading: false,
      };
    case ChoiceBankConstants.CHOICE_CREATEBUSINESS_FAILURE:
   
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export function saveBusinessDetailsReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_SAVE_REQUEST:
      return {
        loading: true,
      };
    case ChoiceBankConstants.CHOICE_SAVE_SUCCESS:
      return {
        data: action.data,
        loading: false,
      };
    case ChoiceBankConstants.CHOICE_SAVE_FAILURE:
 
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export function verifyOtpReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_VERIFYOTP_REQUEST:
      return {
        loading: true,
      };
    case ChoiceBankConstants.CHOICE_VERIFYOTP_SUCCESS:
      return {
        data: action.data,
        loading: false,
      };
    case ChoiceBankConstants.CHOICE_VERIFYOTP_FAILURE:
     
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export function resendOtpReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_RESEND_OTP_REQUEST:
      return {
        loading: true,
      };
    case ChoiceBankConstants.CHOICE_RESEND_OTP_SUCCESS:
      return {
        data: action.data,
        loading: false,
      };
    case ChoiceBankConstants.CHOICE_RESEND_OTP_FAILURE:
    
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export function uploadMediaReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_RESEND_OTP_REQUEST:
      return {
        loading: true,
      };
    case ChoiceBankConstants.CHOICE_RESEND_OTP_SUCCESS:
      return {
        data: action.data,
        loading: false,
      };
    case ChoiceBankConstants.CHOICE_RESEND_OTP_FAILURE:
   
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export function saveShareholderReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_SAVE_SHAREHOLDER_REQUEST:
      return {
        loading: true,
      };
    case ChoiceBankConstants.CHOICE_SAVE_SHAREHOLDER_SUCCESS:
      return {
        data: action.data,
        loading: false,
      };
    case ChoiceBankConstants.CHOICE_SAVE_SHAREHOLDER_FAILURE:
 
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

const initialState = {
  businessName: '',
  businessCerNum: '',
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  birthday: '',
  idNumber: '',
  kraPin: '',
  businessAddress: '',
  kinFullName: '',
  kinRelationship: '',
  kinCountryCode: '',
  kinMobile: '',
  businessIndustry: '',
  specifyIndustry: '',
  userId: '',
  countryCode: '',
  businessType: '',
  operatingMode: '',
  otpType: '',
  mobile: '',
  email: '',
};

export function getBusinessInfoReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_BUSINESS_INFO_REQUEST:

      return { data: action.payload };
    case ChoiceBankConstants.CLEAR_BUSINESS_INFO:
      return { ...initialState };
    default:
      return state;
  }
}

export function getBusinessDetailsReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_GET_DETAILS_SUCCESS:
   
      return { data: action.data };
    case ChoiceBankConstants.CLEAR_BUSINESS_INFO:
      return { ...initialState };
    default:
      return state;
  }
}

export function submitToBankReducer(state = {}, action: any) {
  switch (action.type) {
    case ChoiceBankConstants.CHOICE_SUBMIT_TO_BANK_SUCCESS:
      return { data: action.data };
    case ChoiceBankConstants.CHOICE_SUBMIT_TO_BANK_FAILURE:
      return {
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
export const selectAllData = (state: any) => {
  return {
    GETTYPES_SUCCESS: state.getChoiceTypesReducer.data,
    GETGENDER_SUCCESS: state.getGenderReducer.data,
    GETOPERATINGMODES_SUCCESS: state.getBusinessOperationModesReducer.data,
    GETINDUSTRIES_SUCCESS: state.getIndustriesReducer.data,
    // CREATEBUSINESS_REQUEST: state.createBusinessreducer.data,
    // CREATEBUSINESS_SUCCESS: state.createBusinessreducer.data,
    // CREATEBUSINESS_FAILURE: state.createBusinessreducer.error,
    // SAVEBUSINESS_REQUEST: state.saveBusinessDetailsReducer.loading,
    // SAVEBUSINESS_SUCCESS: state.saveBusinessDetailsReducer.data,
    // SAVEBUSINESS_FAILURE: state.saveBusinessDetailsReducer.error,
    GETBUSINESSDETAILS_SUCCESS: state.getBusinessDetailsReducer.data,
    SUBMIT_TO_BANK_SUCCESS: state.submitToBankReducer.data,
  };
};

import { Box, Typography } from "@mui/material"
import "./rejectionbox.scss"
import { CustomInput } from "../../Inputs/Inputs"
import { DefaultBtn } from "../../Buttons/Buttons"
import CloseIcon from '@mui/icons-material/Close';

const RejectionBox = ({onClose , onClick , value , onChange , placeholder} : any) => {
  return (
    <Box className="rejection_box">
    <Box className="w-100 d-flex justify-content-end align-items-center">
     <CloseIcon onClick={onClose} style={{cursor : "pointer" , color: "#6D6B6B"}}/>
    </Box>
    <Box>
    <Typography className="rejection_heading">Reason for rejection</Typography>
    <Typography className="rejection_sub_heading">Why would you like to reject this payee?</Typography>
    </Box>
    <Box>
       <CustomInput placeholder={placeholder} style={{marginTop:"20px"}}/>
    </Box>
    <DefaultBtn style={{width:"100%"}} text="Continue"/>
</Box>
  )
}

export default RejectionBox
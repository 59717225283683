import { Grid, Typography, TableCell, Menu, MenuItem, TableContainer, Table, TableHead, TableRow, Button, Box } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useState } from "react";
import { orange } from '@mui/material/colors';
import styles from './user_management.module.css';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useNavigate } from "react-router-dom";
import { isUserAuthenticated, getUser, authHeader } from "../../../../../../_helpers";
import { settingActions } from "../../../../../../_actions/setting.actions";
import { useDispatch, useSelector } from 'react-redux'
import { settingSelector } from "../../../../../../_reducers/setting.reducer";
import Dialog from '@mui/material/Dialog';
import Modal from 'react-bootstrap/Modal';
import "../../Settings.scss";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CssBaseline, CircularProgress } from '@mui/material';
import { InputGroup, Form } from "react-bootstrap";
import { SkeletonCom, SkeletonDashboardV3 } from "../../../Skeleton/Skeleton";
import { TebalNoData } from "../../../Skeleton/TebalNoData";
import axios from "axios";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
} declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

interface Data {
    email: string,
    role: string,
    status: string,
    id: string,
}

function createData(
    email: string,
    role: string,
    status: string,
    id: string,
): Data {
    return {
        email,
        role,
        status,
        id
    };
}

const rows = [
    createData('Frozen yoghurt', 'Owner', 'active', '4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6'),
    createData('Frozen yoghurt', 'Owner', 'invited', '4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c7'),
    createData('Frozen yoghurt', 'Owner', 'removed', '4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c8'),
    createData('Frozen yoghurt', 'Owner', 'active', '4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c9')
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}


interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}



const User_Management = () => {
    let navigate = useNavigate();
    const userDetails = localStorage.getItem('user')
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [render, setRender] = React.useState(true)
    const [renderUser, setRenderUser] = React.useState(true)
    const [renderTeams, setRenderTeams] = React.useState(true)
    const [userData, setUserData] = React.useState<any>([]);
    const [users, setUsers] = React.useState<any>();
    const [isUsers, isSetUsers] = React.useState(true)
    const [idDelete, setIdDelete] = React.useState<any>('')
    const [idEmail, setIdEmail] = React.useState<any>('')
    const [checkstatus, setCheckstatus] = React.useState<any>('')
    const open = Boolean(anchorEl);
    const [opendelog, setOpendelog] = React.useState(false);
    const [datatrue, setDatatrue] = React.useState(false)
    const [show, setShowlist] = React.useState(false);
    const [showModal, setShow] = React.useState(false);
    const [showModal_cerete, setShow_cerete] = React.useState(false);
    const [pageneshn, setPagelist] = React.useState(1)
    const [remove, setRemovedata] = React.useState(10)
    const [totalCount, setTotalCount] = React.useState(10);
    const [model_edit, setModel_edit] = React.useState<any>('')
    const [validesan, setValidesan] = useState(false)
    const [fild_data, setFild_data] = React.useState("")
    const [validesansetp, setValidesansetp] = useState<any>()
    const [validesaRedusar, setValidesanredusar] = useState(false)
    const [edit_dataredusar, setEdit_dataredusar] = React.useState(true)
    const [cereteTeam_validet, setCereteTeam_validet] = useState("")
    const [loderinapi, setLoderapi] = React.useState(false);
    const user = getUser()?.data ?? null;

    const handleClickOpendelog = () => {
        setOpendelog(true);
    };
    const handleShow = () => {
        setShow(true);
        setShowlist(true);
    }
    const handleShow_cerete = () => {
        setShow_cerete(true)

    }
    const onClose_cerete = () => {
        setShow_cerete(false);
        setFild_data('')
    };
    const onClose = () => {
        setShow(false);
    };
    const handleClosedelog = () => {
        setOpendelog(false);
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // React.useEffect(() => {
    //     if (!!userDetails && JSON.parse(userDetails).data.role_name != 'Director') {
    //         navigate('/settings/profile')
    //     }
    // }, [userDetails]);
    const dispatch = useDispatch()
    const dataUser = useSelector((state) => settingSelector(state));
    const getTeamList = async (page: any) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...authHeader() },
        };


        var pagelimit = 10;
        setLoderapi(true);
        const response = await axios
            .get(
                `${process.env.REACT_APP_API_URL}/team/get-team-data?page=${page
                }&limit=${pagelimit}`,
                requestOptions
            )
            .then((response) => {
                if (response.data.success == true) {
                    setUserData(response.data.data.data);
                    setTotalCount(response.data.data.total);
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 2000);
                } else {
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 6000);
                }
            });
    };
    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            // dispatch(settingActions.getTeamDocuments() as any)
            getTeamList(pageneshn)
        }
    }, [render, isUserAuthenticated]);
    React.useEffect(() => {
        getTeamList(pageneshn)
    }, [pageneshn]);
    // React.useEffect(() => {
    //     if (renderUser && dataUser?.TEAMDOCUMENT_SUCCESS !== undefined) {

    //         setUserData(dataUser.TEAMDOCUMENT_SUCCESS?.data);
    //         setRenderUser(false);
    //     }
    // }, [dataUser, renderUser]);
    React.useEffect(() => {
        if (renderUser && dataUser?.TEAMDOCUMENT_FAILURE !== undefined) {
            setRenderUser(false);
        }
    }, [dataUser, renderUser]);
    React.useEffect(() => {
        if (isUsers && getUser) {
            isSetUsers(false);
            setUsers(getUser()?.data);
        }
    });
    const deleteData = () => {
        if (!!idEmail) {
            var reqBody = {
                "id": idEmail
            }
            dispatch(settingActions.deleteInactiveTaem(reqBody) as any)
            setRenderUser(true);
            ONsave()
        }
    }
    const ONsave = () => {
        setTimeout(() => {
            setRenderUser(true)
        }, 4000)
        setCereteTeam_validet("1")
        setFild_data('');
        onClose_cerete()
    }
    const Vlidesa = () => {
        setCereteTeam_validet("2")
    }
    const edit_data = () => {
        dispatch(settingActions.getTeamById(idEmail) as any)
        setTimeout(() => {
            setRenderTeams(true)
        }, 2000)

    }

    React.useEffect(() => {
        if (renderTeams && dataUser?.GETTEAMBYID_SUCCESS !== undefined) {
           
            setFild_data(dataUser.GETTEAMBYID_SUCCESS.name)
            setRenderTeams(false);
        }

    }, [dataUser, renderTeams]);
    const CereteTeam = () => {
        setValidesan(true)
        if (!!fild_data && model_edit == "cerete") {
            var reqBody = {
                "name": fild_data
            }
            dispatch(settingActions.Team_cerete(reqBody, ONsave, Vlidesa) as any)
        } else if (!!fild_data && model_edit == "edit") {
            var reqBody = {
                "name": fild_data
            }
            dispatch(settingActions.updateTeam(reqBody, idEmail, ONsave, Vlidesa) as any)
        }
    }
    return (
        <ThemeProvider theme={theme}>
            {/* {renderUser && <Box sx={{ display: 'flex', position: 'absolute', top: 150, bottom: 0, right: 0, left: 0, justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <CircularProgress color="success" />
            </Box>} */}

            <div className='accoun-management'>
                <div className='accoun-management__header'>
                    Team
                </div>
                <>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <>
                            <MenuItem onClick={() => {
                                handleShow_cerete()
                                setModel_edit("edit")
                                edit_data()
                                handleClose()
                            }}
                                className={styles.menuitem}>Update</MenuItem>
                            <MenuItem disabled={user?.role_name == 'Director' ? false : true} onClick={() => {
                                if (user?.role_name == 'Director') {
                                    handleClose()
                                    handleShow()
                                } else {

                                }
                            }} className={styles.menuitem} style={{ color: "#F87161" }}>Delete
                            </MenuItem>
                        </>

                    </Menu>
                    <Modal
                        show={showModal}
                        onHide={onClose}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby='ModalHeader'
                        centered
                        size="sm"
                    ><Modal.Body><Box className={styles.box_dialog_main}>
                        <Typography className={styles.typo_text}>Are you sure you want to delete this user?</Typography>
                        <Box className={styles.box_btn_cencal}>
                            <Button className={styles.delete_btn} onClick={() => {
                                deleteData()
                                onClose()
                            }}>Yes, Delete</Button>
                            <Button className={styles.cancel_btn} onClick={onClose}>Cancel</Button>
                        </Box>
                    </Box></Modal.Body></Modal>
                    <Modal
                        show={showModal_cerete}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby='ModalHeader'
                        centered
                    ><Modal.Body>
                            <Box className={styles.box_dialog_main}>
                                <Typography className={styles.Create_Team_heding}>{model_edit == "cerete" ? "Create Team" : "Update Team"}</Typography>
                                <div id="fast_name_inpurt_div" className="input-form-wrapper dot">
                                    <label>Name of team</label>
                                    <InputGroup className="doc-input-wrapper">
                                        <Form.Control
                                            className={

                                                'doc-form-input test valid'}
                                            placeholder="Name of team"
                                            value={fild_data}
                                            name='name'
                                            type="text"
                                            onChange={(e: any) => {
                                                setFild_data(e.target.value)
                                            }}
                                        />
                                    </InputGroup>
                                    {!fild_data && validesan == true ? <p className="error-message">Please enter the team name</p> : cereteTeam_validet == "2" ? <p className="error-message">The name has already been taken.</p> : ""}
                                </div>

                                <Box className={styles.box_btn_cencal} sx={{ justifyContent: "space-between", display: "flex" }}>
                                    <Button className={styles.cerete_user} onClick={() => { onClose_cerete() }}>
                                        Cancel
                                    </Button>
                                    <Button className={styles.savebtn} style={{ marginLeft: "0px" }}
                                        onClick={() => {
                                            CereteTeam()
                                        }}>{model_edit == "cerete" ? "Create Team" : "Update Team"}</Button>


                                </Box>
                            </Box></Modal.Body></Modal >

                    <Grid container className={styles.erwrwr_contenar_datatat}>
                        <Grid item md={1} xs={0} sm={0}>
                        </Grid>
                        <SkeletonDashboardV3 loader={loderinapi}
                            height={'650px'}
                            width={'88%'}
                            element={
                                <Grid item md={10} sm={12} xs={0} paddingTop={"50px"}>
                                    <ThemeProvider theme={theme}>
                                        <TableContainer className={styles.addtebalcontenar}>
                                            <Table className={styles.tebalheder}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Status</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <tbody>
                                                    {!!userData ? userData.map((element: any, index: number) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell style={{ cursor: "pointer" }} onClick={() => { navigate("/settings/team_user?user_id=" + element.id, { replace: true, state: { id: element.id } }) }}><p className={'tebaldateformette'}>
                                                                    {!!element.name ? element.name : ""}</p></TableCell>

                                                                <TableCell><p className={element.status == 'active' ? styles.Active : element.status == 'invited' ? styles.Invited : styles.Inactive}>{!!element.status ? element.status : "inactive"}</p></TableCell>


                                                                <TableCell style={{ textAlign: 'end' }}>
                                                                    {element.status != 'removed' ?
                                                                        <>
                                                                            <Button onClick={(event: any) => {
                                                                                // if (user?.role_name == 'Director') {
                                                                                handleClick(event)
                                                                                setIdDelete(element.id)
                                                                                setIdEmail(element.id)
                                                                                setCheckstatus(element.status)
                                                                                // } else {

                                                                                // }
                                                                            }}>
                                                                                <img src="/Vector (5).svg" /></Button>
                                                                        </> : ""}
                                                                </TableCell>


                                                            </TableRow>
                                                        );
                                                    }) : ""}
                                                    {userData?.length === 0 && (
                                                        <TableCell colSpan={7}>
                                                            <TebalNoData text={'No Team found'} />
                                                        </TableCell>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <Box className={styles.addnewdatalog}>
                                                {/* <SkeletonCom loader={loderinapi}
                        element={ */}
                                                <div className="addnewbtnclass">
                                                    <div className="inpagenum">
                                                        <p>
                                                            {remove - 9}-{remove}
                                                        </p>
                                                    </div>
                                                    <div className="btnmaindivin">
                                                        {pageneshn == 1 ? (
                                                            <button disabled className="nextntmistish">
                                                                <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="nextntmistish"
                                                                onClick={() => {
                                                                    setPagelist(pageneshn - 1);
                                                                    setRemovedata(remove - 10);
                                                                }}
                                                            >
                                                                <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                                            </button>
                                                        )}
                                                        {remove > totalCount ? (
                                                            <button disabled className="nextntmistish">
                                                                <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="nextntmistish"
                                                                onClick={() => {
                                                                    setPagelist(pageneshn + 1);
                                                                    setRemovedata(remove + 10);
                                                                }}
                                                            >
                                                                <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* }></SkeletonCom> */}
                                            </Box>
                                            <Box className={styles.addnewdatalog} sx={{ justifyContent: "space-between", display: "flex" }}>
                                                <Button style={{ marginBottom: '10px' }} className={styles.cerete_user} onClick={() => { navigate("/settings/All-user") }}>View all users</Button>

                                                <div>
                                                    {user?.role_name == 'Viewer' ?
                                                        <Button style={{ marginBottom: '10px' }} disabled className={styles.cerete_user} onClick={() => {
                                                            // handleShow_cerete()
                                                            // setModel_edit("cerete")
                                                        }}>Create Team</Button>

                                                        : <Button style={{ marginBottom: '10px' }}  className={styles.cerete_user} onClick={() => {
                                                            handleShow_cerete()
                                                            setModel_edit("cerete")
                                                        }}>Create Team</Button>}
                                                    {user?.role_name == 'Admin' ||
                                                        user?.role_name == 'Director'?
                                                    <Button style={{ marginBottom: '10px' }}
                                                     className={styles.savebtn}
                                                      onClick={() => { navigate("/settings/UserDetail?Invite_id=" + '2', { replace: true, state: { Invite_id: '2' } }); }} >
                                                        <PersonAddAltIcon className={styles.addinviebtn} />Invite Users</Button>
                                                        :<Button style={{ marginBottom: '10px' }}
                                                        className={styles.savebtn}
                                                        disabled
                                                        //  onClick={() => { navigate("/settings/UserDetail?Invite_id=" + '2', { replace: true, state: { Invite_id: '2' } }); }} 
                                                         >
                                                           <PersonAddAltIcon className={styles.addinviebtn} />Invite Users</Button>}
                                                </div>
                                            </Box>

                                        </TableContainer>
                                    </ThemeProvider>
                                </Grid>}></SkeletonDashboardV3>
                        <Grid item md={1} xs={0} sm={0}>
                        </Grid>
                    </Grid>
                </>
            </div>
        </ThemeProvider>
    );
}

export default User_Management;
import { Button } from 'react-bootstrap';
import style from './Data_Sync.module.scss';
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { useDispatch, useSelector } from "react-redux";

interface Props {
    onclick: any;
    data: any;
}
const Data_Sync = (props: Props) => {
   
    const dispatch = useDispatch();

    const ondisable = () => {
        var body = {
            id: props.data.id
        }
        dispatch(paymentJourneyActions.disable_connection(body) as any)
    }
    return (
        <>
            <div className={style.main_dev_contean}>
                <p className={style.heding_tage_listtt}>
                    {!!props.data && !!props.data.platform ? props.data.platform : ""} Data Sync
                </p>
                <div className={style.iframe_calss_div}>
                    <iframe src={props.data.connection_url} width="100%" height="640"></iframe>
                </div>
                <div className={style.conatenar_footer}>
                    <Button className={style.Cancel_footer} onClick={() => { props.onclick() }}>Cancel</Button>
                    <div>
                    <Button className={style.Cancel_footer} onClick={() => { ondisable() }}>Deactivate</Button>
                    <Button className={style.List_footer} onClick={() => { props.onclick() }}>List of integrations</Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Data_Sync;
import { Box, Typography } from "@mui/material"
import PhoneInput from "react-phone-input-2"
import { InputNumber } from "../../../../../../../../../../../ui/input"

export const SendMoneyComponent = ({ editData, handleFormikChange, countrySortcode, setPayOutMethodsByCountryId, PayOutMethodsByCountryId, updatedIndex, currency, activeButton }: any) => {
    console.log(countrySortcode, "countrySortcode");


    return (
        <Box>
            <Box>

                <Typography className='mb-2 label'>Phone Number</Typography>

                <PhoneInput
                    inputProps={{
                        name: "phone_number",
                        required: true,
                        autoFocus: true,
                    }}
                    onlyCountries={[countrySortcode]}
                    localization={{ de: countrySortcode }}
                    country={countrySortcode}
                    prefix=""
                    value={editData?.account_number}
                    onChange={(value: string) => handleFormikChange({ account_number: value })}
                />

                {/* <PhoneInput
                    inputProps={{
                        name: "phone_number",
                        required: true,
                        autoFocus: true,
                    }}
                    onlyCountries={[countrySortcode]}
                    localization={{ de: countrySortcode }}
                    country={countrySortcode}
                    prefix=""
                    value={editData?.account_number}
                    onChange={(e: any) => handleFormikChange({ account_number: e.target.value })}
                    // value={PayOutMethodsByCountryId[updatedIndex]?.phone_number}
                    // onChange={(value, country, e, formattedValue) => {
                    //     const dataUppdted = [...PayOutMethodsByCountryId]
                    //     dataUppdted[updatedIndex].phone_number = value
                    //     setPayOutMethodsByCountryId(dataUppdted)
                    // }}
                     /> */}

            </Box>

            {/* <DefaultBtn text="Add recipient" style={{ width: "100%", marginTop: "30px" }} /> */}
        </Box>
    )

}
export const TillNumberComponent = ({ editData, handleFormikChange, setPayOutMethodsByCountryId, PayOutMethodsByCountryId, updatedIndex }: any) => {
    // console.log(formik, "formikformikformik");
    console.log(PayOutMethodsByCountryId, updatedIndex, "findmethod");

    return (
        <Box>
            <InputNumber
                mainDevstyle={{ margin: '0px' }}
                value={editData?.account_number ?? ''}
                onChange={(value: any) => handleFormikChange({ account_number: value })}
                name="till_number"
                label="Till number"
                placeholder="Input till number here"
            />
        </Box>
    )
}
export const PayBilComponent = ({ editData, handleFormikChange, setPayOutMethodsByCountryId, PayOutMethodsByCountryId, updatedIndex }: any) => {
    return (
        <Box>
            <Box>
                <InputNumber
                    requiredFild="NotActive" mainDevstyle={{ margin: '10px 0px', }}
                    value={editData?.paybill_number}
                    onChange={(e: any) => handleFormikChange({ paybill_number: e.target.value })}
                    label={'Paybill number'}
                    placeholder="Input paybill number here"
                />
                <InputNumber
                    requiredFild="NotActive" mainDevstyle={{ margin: '0px' }}
                    label={'Account number'}
                    value={editData?.account_number}
                    onChange={(e: any) => handleFormikChange({ account_number: e.target.value })}
                    placeholder="Input account number here"
                />
            </Box>
            {/* <DefaultBtn text="Add recipient" style={{ width: "100%", marginTop: "30px" }} /> */}
        </Box>
    )
}
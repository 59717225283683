import {userConstants } from '../_constants/user.constants'

export function registration(state={},action){
    switch(action.type){
        case userConstants.REGISTER_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.REGISTER_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {
                    
                    data: action.data,
                    loading: false,
                }
            }
        case userConstants.REGISTER_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function updatePassword(state={},action){
    switch(action.type){
        case userConstants.UPDATEPASSWARD_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.UPDATEPASSWARD_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {
                    
                    data: action.data,
                    loading: false,
                }
            }
        case userConstants.UPDATEPASSWARD_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function forgotPassword(state={},action){
    switch(action.type){
        case userConstants.FORGOTPASSWARD_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.FORGOTPASSWARD_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {
                    
                    data: action.data,
                    loading: false,
                }
            }
        case userConstants.FORGOTPASSWARD_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function saveCurrencies(state={},action){
    switch(action.type){
        case userConstants.SAVE_CURRENCIES_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.SAVE_CURRENCIES_SUCCESS:
            return {
                data: action.data
            }
        case userConstants.SAVE_CURRENCIES_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
export function verifyToken(state={},action){
    switch(action.type){
        case userConstants.VERIFYTOKEN_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.VERIFYTOKEN_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {
                    
                    data: action.data,
                    loading: false,
                }
            }
        case userConstants.VERIFYTOKEN_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export function sendOtpReducer(state={},action){
    switch(action.type){
        case userConstants.SEND_OTP_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.SEND_OTP_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {
                    
                    data: action.data,
                    loading: false,
                }
            }
        case userConstants.SEND_OTP_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
export function ResendOtpReducer(state={},action){
    switch(action.type){
        case userConstants.SEND_OTP_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.SEND_OTP_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {
                    
                    data: action.data,
                    loading: false,
                }
            }
        case userConstants.SEND_OTP_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
export function loginOtpVerifyReducer(state={},action){
   
    switch(action.type){
        
        case userConstants.LOGIN_OTP_VERIFY_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.LOGIN_OTP_VERIFY_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {
                    
                    data: action.data,
                    loading: false,
                }
            }
        case userConstants.LOGIN_OTP_VERIFY_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error,
            }
        default:
            return state
    }
}
export function changePassword(state={},action){
    switch(action.type){
        case userConstants.CHANGEPASSWARD_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.CHANGEPASSWARD_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {
                    
                    data: action.data,
                    loading: false,
                }
            }
        case userConstants.CHANGEPASSWARD_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
export function getOtp(state={},action){
    switch(action.type){
        case userConstants.GETOTP_SUCCESS:
            return {
                data: action.data
            };
        default:
            return state
    }
}

export function registrationByInviteReducer(state={},action){
    switch(action.type){
        case userConstants.REGISTER_INVITE_REQUEST:
            return {
                registering: true, 
                refreshing: 'true',
                loading: true,
            }
        case userConstants.REGISTER_INVITE_SUCCESS:
            return {
                refreshing: 'false',
                loading: false,
                data: {
                    
                    data: action.data,
                    loading: false,
                }
            }
        case userConstants.REGISTER_INVITE_FAILURE:
            return {
                refreshing: 'false',
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

export const registerSelector = (state) => {
    return  {
        'REGISTER_SUCCESS': state.registration.data,
        'REGISTER_REQUEST': state.registration.loading,
        'REGISTER_FAILURE': state.registration.error,
        'UPDATEPASSWARD_SUCCESS': state.updatePassword.data,
        'UPDATEPASSWARD_REQUEST': state.updatePassword.loading,
        'UPDATEPASSWARD_FAILURE': state.updatePassword.error,
        'FORGOTPASSWARD_SUCCESS': state.forgotPassword.data,
        'FORGOTPASSWARD_REQUEST': state.forgotPassword.loading,
        'FORGOTPASSWARD_FAILURE': state.forgotPassword.error,
        'VERIFYTOKEN_SUCCESS': state.verifyToken.data,
        'VERIFYTOKEN_REQUEST': state.verifyToken.loading,
        'VERIFYTOKEN_FAILURE': state.verifyToken.error,
        'CHANGEPASSWARD_SUCCESS': state.changePassword.data,
        'CHANGEPASSWARD_REQUEST': state.changePassword.loading,
        'CHANGEPASSWARD_FAILURE': state.changePassword.error,
        'GETOTP_SUCCESS': state.getOtp.data,
        'REGISTER_INVITE_SUCCESS': state.registration.data,
        'REGISTER_INVITE_REQUEST': state.registration.loading,
        'REGISTER_INVITE_FAILURE': state.registration.error,
        'SEND_OTP_SUCCESS': state.sendOtpReducer.data,
        'SEND_OTP_REQUEST': state.sendOtpReducer.loading,
        'SEND_OTP_FAILURE': state.sendOtpReducer.error,
        'SEND_OTP_SUCCESS': state.ResendOtpReducer.data,
        'SEND_OTP_REQUEST': state.ResendOtpReducer.loading,
        'SEND_OTP_FAILURE': state.ResendOtpReducer.error,
        'LOGIN_OTP_VERIFY_SUCCESS': state.loginOtpVerifyReducer.data,
        'LOGIN_OTP_VERIFY_REQUEST': state.loginOtpVerifyReducer.loading,
        'LOGIN_OTP_VERIFY_FAILURE': state.loginOtpVerifyReducer.error,

        'SAVE_CURRENCIES_SUCCESS': state.saveCurrencies.data,
        'SAVE_CURRENCIES_REQUEST': state.saveCurrencies.loading,
        'SAVE_CURRENCIES_FAILURE': state.saveCurrencies.error,
    };
}
import styles from './Payment_Link.module.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, Typography } from '@mui/material';
import { Button } from 'react-bootstrap';
import Link_tebal from './components/Link_tebal/Link_tebal';
import { useNavigate, useLocation } from "react-router-dom";

const Payment_Link = () => {
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    const navigate = useNavigate();

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box>
                    <Box className={`${styles.heder_box} balance__header`}>
                        <Typography>Payment Links</Typography>
                        <Button className='custom_mobile_btn' onClick={() => {
                            navigate("/settings/CeretePaymentLink");
                        }}>Create New</Button>
                    </Box>
                    <Link_tebal />
                </Box>
            </ThemeProvider>

        </>
    )
}
export default Payment_Link;
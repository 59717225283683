import { Box, Typography } from '@mui/material'
import { DefaultNiobiLoader } from '../../Icons/NiobiLoader/DefautNiobiLoader'
import Model from '../Model/Model'
import "./progress.scss"

const ProgressComponent = ({ heading, text }: any) => {

  return (

    <Box className="transaction_process_box d-flex justify-content-center align-items-center flex-column">
      <img src="/logo 3.svg" />
      <Typography className="transaction_process_text">{text}</Typography>
      <img
        width={45}
        height={45}
        src="/Niobi Loading Animation-Cross.gif"
      />
    </Box>


  )
}

export default ProgressComponent
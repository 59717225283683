import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Step1 from './Components/Step1/Step1'
import Step2 from './Components/Step2/Step2'
import Step4 from './Components/Step4/Step4'
import Step3 from './Components/Step3/Step3'
import Step5 from './Components/Step5/Step5'
import Step6 from './Components/Step6/Step6'
import Step7 from './Components/Step7/Step7'
import Step8 from './Components/Step8/Step8'
import { payablesFlowactions } from '../../../../../../_actions'
import { useDispatch } from 'react-redux'

type ExcelData = Record<string, unknown>[];

const BulkPayment = ({ SelectCurrency, SelectedFlag, step, stepupdatefun, setUseLayout, setIsGoBackHidden }: any) => {
  const [loaderStatus, setLoaderStatus] = useState('')
  const [transaction_detailReload, setTransaction_detailReload] = useState(false)
  const [excelData, setExcelData] = useState<ExcelData>([]);
  console.log(excelData, "excelData");

  useEffect(() => {
    if (step === 6) {
      const timer = setTimeout(() => {
        stepupdatefun(7);
        setIsGoBackHidden(true)
        setUseLayout("fullscreenlayout")
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [step, stepupdatefun]);


  const [render, setRender] = useState(true)
  const [areBillsAvailable, setAreBillsAvailable] = useState("")
  const [payablesData, setPayablesData] = useState<any>([])
  const [dataSave, setDataSave] = useState([])
  const [pageneshn, setPagelist] = useState(1);
  const [remove, setRemovedata] = useState(10);
  const [totalCount, setTotalCount] = useState(10);
  const [bankData, setBankData] = useState<any[]>([])
  const [categoriesData, setCategoriesData] = useState<any[]>([])
  const [teamData, setTeamData] = useState<any[]>([]);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [selectedLimit, setSelectedLimit] = useState(10);
  const [UpdetPayeeId, setUpdetPayeeId] = useState('')
  const [loader, setLoader] = useState(false)
  const [time, setTime] = useState(0);
  const [enableButton, setEnableButton] = useState(false);
  const [errorsMsg, setErrorMsg] = useState('')
  const [errorsmsgapi, setErrorMsgapi] = useState("")
  const [isEditPayeeApiCalled, setIsEditPayeeApiCalled] = useState(false);
  const [TransactionSuccess, setTransactionSuccess] = useState([])
  const [selectedBillIds, setSelectedBillIds] = useState<string[]>([]);
  const dispatch = useDispatch()

  const [formik, setFormik] = useState<any>({
    totalAmount: 0,
    selectedBulk: [],
    counterpart: [],
    currency: "KES",
    country_id: "1",
    apiUpdatedBulk: [],
    balance: 0,
    methoadSelect: [],
    grossAmount: 0,
    transactionAmount: 0,
    totalFees: 0,
    "payment_details": [],
  })
  const [loaderPayment, setLoaderPayment] = useState(false)
  const [getPayees, setGetPayees] = useState([])
  const [searchPayee, setSearchPayee] = useState("")

  console.log("Step1_Bulk data formik", formik)

  const getBank = (data: any) => {
    setBankData(data)
  }
  const getTagName = (data: any) => {
    setCategoriesData(data)
  }
  const getTeam = (data: any) => {
    setTeamData(data)
  }
  useEffect(() => {
    if (render) {
      // dispatch(payablesFlowactions.getApipayment(`/v4/payee/get-payees?currency=${SelectCurrency}&search=${searchPayee}`, getCounterparts) as any);
      dispatch(payablesFlowactions.getApipayment("/counterparts/get-tags", getTagName) as any);
      dispatch(payablesFlowactions.getApipayment("/bank/get-pesalink-bank-list", getBank) as any);
      dispatch(payablesFlowactions.getApipayment("/team/get-team", getTeam) as any);
      setRender(false)
    }
  }, [render]);
  useEffect(() => {
    if (time > 0 && enableButton == false) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [time, enableButton]);

  const Otpres = (data: any) => {
    console.log(data.success, "otpresdata")
    if (data.success == true) {
      setEnableButton(false)
      setLoader(false)
      stepupdatefun(3)
      setUseLayout("popuplayout")
      setTime(120);
      setTimeout(() => {
        setEnableButton(true)
      }, 120000)
    }

  }



  // useEffect(() => {
  //   if (Step === 5) {
  //     const timer = setTimeout(() => {
  //       stepupdatefun(6);
  //       setIsGoBackHidden(true)
  //       setUseLayout("fullscreenlayout")
  //     }, 3000);

  //     return () => clearTimeout(timer);
  //   }
  // }, [Step, stepupdatefun]);

  const UpdetPayee = (id: any) => {
    setUpdetPayeeId(id);
    // setFormik((prevFormik: any) => ({
    //   ...prevFormik,
    //   counterpart: [id],
    // }));
  };

  // const BillPaymentStatusRES = (data: any) => {


  //   if (data?.status == "pending") {

  //     if (!!data?.transaction_id) {
  //       setTimeout(() => {
  //         setLoaderStatus("waiting")
  //       }, 1800000)
  //       setTimeout(() => {
  //         BillPaymentRes(data.transaction_id)
  //       }, 2000)
  //     }
  //   } else if (data?.status == "failed") {
  //     setLoaderStatus("failed")

  //   } else if (data?.status == "success") {
  //     setTransactionSuccess(data)
  //     setLoaderStatus("success")
  //     setTimeout(() => {
  //       setTransaction_detailReload(true)
  //     }, 3000)
  //   }
  // }
  // const BillPaymentRes = (id: any) => {
  //   dispatch(payablesFlowactions.getApipayment(`/wallet/get-transaction-status/${id}`, BillPaymentStatusRES) as any);

  // }

  // const BillPayment = (otp: any) => {

  //   var body = {
  //     "otp": otp, // Newly added
  //     "currency": formik.currency, // Newly added
  //     "country_id": formik.country_id, // Newly added
  //     "account_number": 80000003,
  //     "payment_details": formik.apiUpdatedBills[0],
  //     "counterpart": formik.apiUpdatedBills[0].counterpart_info,
  //     "payment_method_type": formik?.selectedBills?.payment_channel
  //   }
  //   setLoaderStatus("pending")

  //   dispatch(payablesFlowactions.PostPaymentfull("/v4/bill-payment", body, BillPaymentRes, setLoaderPayment) as any)
  // }
  // const BulkPaymentRes=(data:any)=>{

  // }
  // const BulkPayment = (otp: any) => {
  //   var Array=[]
  //   for (let index = 0; index < formik.apiUpdatedBills.length; index++) {
  //     const element = formik.apiUpdatedBills[index];
  //     var bodyArray={
  //       id:element.id
  //     }
  //     Array.push(bodyArray)
  //   }
  //   const body = {
  //     "otp":otp, // Newly added
  //     "currency": formik.currency, // Newly added
  //     "country_id": formik.country_id, // Newly added
  //     "data": Array
  //   }
  //   setLoaderStatus("pending")

  //   dispatch(payablesFlowactions.PostPaymentfull("/v4/bulk-payments", body, BillPaymentRes, setLoaderPayment) as any)

  // }
  // useEffect(() => {
  //   if (formik.balance > 0) {
  //     if (formik.totalAmount > formik.balance) {
  //       setErrorMsg("You have insufficient balance.")
  //     }
  //   }
  // }, [formik])

  // useEffect(() => {
  //   console.log(formik.balance, "api_balance")
  //   console.log(formik.apiUpdatedBills, "apiupdatedbills")
  //   console.log(formik.selectedBills, "apiUpdatedBillspreselected")
  //   console.log(formik, "api_balance")
  // }, [formik])


  console.log("bankdata", bankData)

  return (
    <Box>

      {
        step == 0 ?
          <Step1 formik={formik} setFormik={setFormik} stepupdatefun={stepupdatefun} />
          : step == 1 ?
            <Step2 bankList={bankData} teamData={teamData} categoriesData={categoriesData} formik={formik} setFormik={setFormik} SelectedFlag={SelectedFlag} stepupdatefun={stepupdatefun} selectedCurrency={SelectCurrency} />
            : step == 2 ?
              <Step3 setUseLayout={setUseLayout} SelectedFlag={SelectedFlag} stepupdatefun={stepupdatefun} />
              : step == 3 ?
                <Step4 stepupdatefun={stepupdatefun} setUseLayout={setUseLayout} />
                : step == 4 ?
                  <Step5 stepupdatefun={stepupdatefun} />
                  : step == 5 ?
                    <Step6 setUseLayout={setUseLayout} formik={formik} stepupdatefun={stepupdatefun} />
                    : step == 6 ?
                      <Step7 setUseLayout={setUseLayout} loaderStatus={loaderStatus} transactionDetailsReload={transaction_detailReload} setLoaderStatus={setLoaderStatus} formik={formik} stepupdatefun={stepupdatefun} />
                      : step == 7 ?
                        <Step8 />
                        : ""
      }
    </Box>
  )
}

export default BulkPayment
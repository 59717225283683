import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch, TypedUseSelectorHook } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { userActions } from "../../../_actions";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../auth/Auth.css";
import showPwdImg from "../ShowPassword/show-password.svg";
import hidePwdImg from "../ShowPassword/hide-password.svg";
import { LoginHeader } from "../LoginHeader";
import { dispatchStore } from "../../../Store/store";
import { Button, FormField } from "@team-monite/ui-kit-react";
import { registerSelector } from "../../../_reducers/registration.reducer";
import {VerifyEmail} from "../verify-email/VerifyEmail";

export interface ILogin {}

interface ForgotPasswordValues {
  email: string;
}

export const ForgotPassword = () => {
  const [inputs, setInputs] = useState<ForgotPasswordValues>({
    email: "",
  });

  const fogotPasswordData = useSelector((state) =>
     registerSelector(state)
  );

  const [submitted, setSubmitted] = useState(false);
  const [requestRender, setrequestRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [responseRender, setresponseRender] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [apiSuccRender, setapiSuccRender] = useState(false);
const [emailfild,setEmailfild] =useState('')


 
  useEffect(() => {
    if (requestRender && fogotPasswordData?.FORGOTPASSWARD_REQUEST == true) {
      setLoading(true);
    
      
      setrequestRender(false)
      setresponseRender(true);
    }
  }, [fogotPasswordData, requestRender]);

  useEffect(() => {
    if (apiSuccRender && 
     
      fogotPasswordData?.FORGOTPASSWARD_SUCCESS !== undefined ) {
      setapiSuccRender(false)

    }
  }, [fogotPasswordData, apiSuccRender]);

  useEffect(() => {
    if (responseRender 
      && 
      fogotPasswordData?.FORGOTPASSWARD_REQUEST == false) {
      
      setLoading(false);
      setresponseRender(false);
      setapiSuccRender(true)
      setShowVerify(false) 
      if(fogotPasswordData?.FORGOTPASSWARD_SUCCESS !== undefined){
        setShowVerify(true) 
      }
    }
  }, [fogotPasswordData, responseRender]);

  const dispatch = useDispatch();
  const location = useLocation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email address.").required("Please enter a valid email address."),
  });

  useEffect(() => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  }, []);

  const handleSubmit = (e: any) => {
  
    setSubmitted(true);
    if (e.email) {
      dispatch(userActions.forgotPassword({ email: e.email }) as any);
      setresponseRender(true);
      setEmailfild(e.email)
    }
  };

  return (
    <div className="register-container">
      {showVerify == false  ? 
      <div className="formBx">
        <div className="login">
          <img src="/assets/sidebar-logo.png" alt="logo" />
          <p>Forgot password</p>
        </div>
        <Formik
          initialValues={inputs}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, handleChange, values }) => (
            <Form className="form-input">
              <div className="inputBx">
                <span>Email</span>
                <Field
                  name="email"
                  type="text"
                  placeholder="Email"
                  // onChange={(e:any)=>{setEmailfild(e.target.value)}}
                  className={
                    "form-text form-control" +
                    (errors.email && touched.email ? " is-invalid" : "")
                  }
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback red"
                />
              </div>

              {/* <div className='invalid-Login'>
                        <span>The email or password is incorrect. Please retry</span>
                    </div> */}

              <div className="inputBx">
                <button
                  type="submit"
                  disabled={loading}
                  className="form-button"
                >
                  {/* {loading == true && <span className="spinner-border spinner-border-sm mr-1"></span>} */}

                  {loading == true && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  <span>Reset Password</span>
                </button>
              </div>
              <div className="inputBx">
                <p>
                  Already have an account?{" "}
                  <Link to="../login">Log in now</Link>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      : <VerifyEmail data={emailfild} />}
    </div>
  );
};
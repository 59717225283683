import React, { useState, useEffect } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import "./RecipientBank.scss";
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import { useDispatch, useSelector } from "react-redux";
import { getUser, isUserAuthenticated } from "../../../../../../_helpers";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { userActions, dashboardActions } from "../../../../../../_actions";
import { useSearchParams } from "react-router-dom";
import { payablesActions } from "../../../../../../_actions/payables.actions";
// import toast from 'react-hot-toast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
    onSave: any;
    onCancel: any;
    bank: any;
    counterpart: any;
    item: any;
    body_data: any;
    transactionExist: any;
    ChackBalance: any;
    setGetFee: any;
}

export const RecipientBank = (props: Props) => {
    const [itemm, setitem] = useState<any>();
    const [counterpart, setcounterpart] = useState<any>();
    const [datadisplay, setDatadisplay] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [feesNewRender_two, setFeesNewRender_two] = useState(false);
    const [startAPI, setStartAPI] = useState(false);
    const [entity, setEntity] = useState<any>({
        bank_name: '',
        branch_name: '',
        account_name: '',
        account_number: '',
    })
    const [bank_name, setBank_name] = useState('')
    const [render, setRender] = useState(true);
    const [loading, setLoading] = React.useState(false);
    const [bankList, setBankList] = useState<any>([]);
    const [bankvalid, setBankvlid] = useState('1')
    const [apidata, setApidata] = useState({
        bank_name: '',
        branch_name: '',
        account_name: '',
        account_number: '',
    })
    const [bankListRender, setBankListRender] = useState<any>(true);
    const [apitime, setApiTime] = useState(false)

   const[ErrorMessageSwea,setErrorMessage]=useState("")

    useEffect(() => {
        const popup: any = searchParams.get('currency');
        setDatadisplay(popup)
    }, [searchParams]);
    useEffect(() => {
        setitem(props.item);
        setcounterpart(props.counterpart);
    }, [props]);

    const validationSchema = Yup.object().shape({
        bank_name: Yup.string()
            .required('Please select the bank name.'),
        account_name: Yup.string()
            .required('Please enter the account name.'),
        account_number: Yup.number()
            .typeError('One of the payment details is invalid or unsupported. Kindly check and try again.')
            .required('Please enter the account number.'),
    })
    const dispatch = useDispatch();
    const dataList = useSelector((state) => selectPaymentJourneyData(state))
    React.useEffect(() => {
        if (isUserAuthenticated() && render) {
            setRender(false);
            dispatch(paymentJourneyActions.getPesaBankList() as any)
        }
    }, [render, isUserAuthenticated]);
    useEffect(() => {
        if (bankListRender && dataList?.GETPESABANKLIST_SUCCESS !== undefined) {
            setBankListRender(false);
            setBankList(dataList.GETPESABANKLIST_SUCCESS);
            if (!!props.counterpart) {
                const object = {
                    bank_name: '',
                    branch_name: props.counterpart.bank_branch,
                    account_name: !!props?.counterpart?.account_name ? props?.counterpart?.account_name : "",
                    account_number: !!props?.counterpart?.bank_account_number ? props.counterpart?.bank_account_number : "",
                }
                const findBankId = dataList.GETPESABANKLIST_SUCCESS.find((o: any) => o.bank_name == props.counterpart.bank_name)
                if (!!findBankId) {
                    object.bank_name = findBankId.id
                }
                setBank_name(findBankId)
                setEntity(object)
            }
        }
    }, [dataList, bankListRender]);
    useEffect(() => {
        const findBankDetails = bankList.find((o: any) => o.id == apidata.bank_name)


        if (apitime == true && !!dataList && dataList?.STANBIC_VERIFY_ACCOUNT_SUCCESS?.success == true) {


            // if (!!dataList.GET_PAYBILL_NEW_SUCCESS.message && !!dataList.GET_PAYBILL_NEW_SUCCESS.message) {
            //     toast.success(dataList.GET_PAYBILL_NEW_SUCCESS.message)

            // }

            setApiTime(false)
            if (!!apidata && !!apidata.account_number && !!apidata.bank_name && !!apidata.account_name && !datadisplay) {
                const body = {
                    bank_name: findBankDetails.bank_name,
                    bank_code: findBankDetails.niobi_bank_code,
                    account_name: apidata.account_name,
                    bank_account_number: apidata.account_number,
                    payment_details: itemm,
                    counterpart: counterpart,

                }
                dispatch(userActions.getOtp('') as any)
                props.transactionExist('pesalink')
                if (props?.counterpart?.monite_counterpart_id) {
                    const request = {
                        account_name: apidata.account_name,
                        bank_account_number: apidata.account_number,
                        bank_name: findBankDetails.bank_name,
                        id: props.counterpart.monite_counterpart_id,
                        bank_code: findBankDetails.niobi_bank_code,
                    }
                    dispatch(payablesActions.createPayableRequest(request) as any)
                } else {

                }
                onSave(body)
            } else if (!!apidata && !!apidata.account_number && !!apidata.bank_name && !!apidata.account_name && !!datadisplay) {
                const findBankDetails = bankList.find((o: any) => o.id == apidata.bank_name)
                // findBankDetails.branch_name = !!apidata.branch_name ? apidata.branch_name : '';
                const body = {
                    bank_name: findBankDetails.bank_name,
                    bank_code: findBankDetails.niobi_bank_code,
                    account_name: apidata.account_name,
                    bank_account_number: apidata.account_number,
                    counterpart: props.body_data.counterpart,
                    amount: props.body_data.amount,
                    tags: props.body_data.tags,
                    counterpart_type: props.body_data.counterpart_type,
                    is_tax: props.body_data.is_tax,
                    vat: props?.body_data?.vat
                }
                dispatch(userActions.getOtp('') as any)
                props.transactionExist('pesalink')
                if (!!props.body_data.counterpart.monite_counterpart_id) {
                    const request = {
                        account_name: apidata.account_name,
                        bank_account_number: apidata.account_number,
                        bank_name: findBankDetails.bank_name,
                        id: props.body_data.counterpart.monite_counterpart_id,
                        bank_code: findBankDetails.niobi_bank_code,
                    }
                    dispatch(payablesActions.createPayableRequest(request) as any)
                } else {

                }

                onSave(body)
            }
        } else {
            if (bankvalid == '2' && !!dataList.GET_PAYBILL_NEW_SUCCESS && !!dataList.GET_PAYBILL_NEW_SUCCESS.success == false) {
                toast.error(dataList.GET_PAYBILL_NEW_SUCCESS.message)
            } else if (bankvalid == '2' && !!dataList.STANBIC_VERIFY_ACCOUNT_SUCCESS && dataList?.STANBIC_VERIFY_ACCOUNT_SUCCESS?.success == false) {
                toast.error(dataList?.STANBIC_VERIFY_ACCOUNT_SUCCESS?.message)
            }
            setApiTime(false)
        }
    }, [apitime])
    const onSave = (bank: any) => {
        props.onSave(bank, dataList?.STANBIC_VERIFY_ACCOUNT_SUCCESS?.data?.MESSAGE);
    }

    const onCancel = () => {
        props.onCancel();
        setBankvlid("1")
    }
    useEffect(() => {
        if (startAPI && dataList?.CHACKBALANCE_SUCCESS !== undefined) {
            if (dataList?.CHACKBALANCE_SUCCESS?.success == true) {
                setLoading(false)

                setErrorMessage("")
                const findBankDetails = bankList.find((o: any) => o.id == entity.bank_name)
                // findBankDetails.branch_name = !!apidata.branch_name ? apidata.branch_name : '';
                var bank_code = "00"
                bank_code += findBankDetails?.niobi_bank_code
                var body = {
                    "bank_code": bank_code,
                    "account_number": apidata.account_number
                }
                setStartAPI(false)
                props.setGetFee(dataList?.CHACKBALANCE_SUCCESS?.data)
                //dispatch(paymentJourneyActions.getPaybillNew(findBankDetails.bank_name) as any)
                dispatch(paymentJourneyActions.StanbicVerifyAccount(body) as any)

                setTimeout(() => {

                    setApiTime(true)
                    setBankvlid('2')

                }, 8000)
            }else{
                setLoading(false)

                setErrorMessage(dataList?.CHACKBALANCE_SUCCESS.message);
            }
        }
    }, [startAPI, dataList])
    return (


        <div className='recipient-bank-container'>
            <div className='recipient-bank-container__heading'>
                Select Recipient Bank
            </div>
            <Formik initialValues={entity} enableReinitialize={true} validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    const findBankDetails = bankList.find((o: any) => o.id == values.bank_name)
                    // findBankDetails.branch_name = !!apidata.branch_name ? apidata.branch_name : '';


                    var bank_code = "00"
                    bank_code = findBankDetails.niobi_bank_code

                    setApidata(values)
                    props.ChackBalance('pesalink','pesalink','Mobile Number',values.account_number,'pesalink','',findBankDetails.bank_name,bank_code)
                    setLoading(true)
                    setTimeout(() => {
                        setStartAPI(true);
                    }, 5000);
                }}
            >
                {({ errors, touched, isSubmitting, handleChange, values }) => (
                    <div className='company-details-container__wrapper'>
                        <Form>
                            <div className='user-detail-container__wrapper'>
                                <div className='input-form-wrapper dot'>
                                    <label>Bank name</label>
                                    <Field name="bank_name" as="select"
                                        className={'text-field select-box' + (errors.bank_name && touched.bank_name ? ' is-invalid' : '')} >
                                        <option value="">--Select recipient bank--</option>
                                        {Array.isArray(bankList)
                                            ? bankList?.map((element: any) => {
                                                return <option key={element.id} value={element.id}>{element.bank_name}</option>
                                            })
                                            : "data not found"}
                                    </Field>
                                    <ErrorMessage name="bank_name" component="div" className="invalid-feedback red" />
                                </div>

                                <div className="input-form-wrapper mb-4 dot">
                                    <label>Account name</label>
                                    <Field
                                        name="account_name"
                                        type="text"
                                        placeholder="Account Name"
                                        className={
                                            "text-field field-enable" +
                                            (errors.account_name && touched.account_name
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="account_name"
                                        component="div"
                                        className="invalid-feedback red"
                                    />
                                </div>
                                <div className="input-form-wrapper m-10 dot">
                                    <label>Account number</label>
                                    <Field
                                        name="account_number"
                                        type="text"
                                        placeholder="Account Number"
                                        className={
                                            "text-field field-enable" +
                                            (errors.account_number && touched.account_number
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="account_number"
                                        component="div"
                                        className="invalid-feedback red"
                                    />
                                </div>
                                {!!ErrorMessageSwea?<div className='mpesa-container__errorMsg'>{ErrorMessageSwea}</div>:""}
                                <div className="auth-trans-container__btn-row">
                                    <div>
                                        <Button className='complete-profile__saventmm' variant='custom'
                                            onClick={() => { onCancel(); }}
                                        >   Cancel  </Button>
                                    </div>
                                    <div>
                                        <Button type='submit'
                                            disabled={loading}
                                            style={{ display: "flex", alignItems: "center" }}
                                            className='complete-profile__submitbtnlist' >
                                            {loading == true && <div style={{ marginLeft: "6px" }} className="spinner-border spinner-border-sm mr-1"></div>}
                                            <p style={{ textTransform: "capitalize" }}>Proceed</p>

                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div >
    )
}
import { Box } from '@mui/material'
import "./step5.scss"
import SuccessComponent from '../../../../../../../ui/ReuseableComponents/Notifications/Success/Success'
import ProgressComponent from '../../../../../../../ui/ReuseableComponents/Notifications/Progress/Progress'
import PaymentFailed from '../../../../../../../ui/ReuseableComponents/Notifications/FailedPayment/PaymentFailed'
import { MakePaymentTransactionDetails } from '../../../../../../../ui/TransactionDetails/MakePaymentTransactionDetails'
import Waiting from '../../../../../../../ui/ReuseableComponents/Notifications/Waiting/Waiting'
import { NotifyPayment } from '../../../../../../../ui/ReuseableComponents/Notifications/NotifyPayment/NotifyPayment'
import Payment_success from '../../../../../../../ui/USD_Payment_success/Payment_success';

function Step5({ setTransaction_detailReload,formik,TransactionSuccess,loaderStatus, setLoaderStatus, transactionDetailsReload, notifyPayment, setNotifyPayment, type , loader , setLoader }: any) {

  const onSave2 = () => {
    setTransaction_detailReload(false)
    setNotifyPayment(true)
  }
  const onSave=()=>{
    window.location.href = `/balance?currency=${TransactionSuccess?.currency}`;
  }
  return (
    <Box className="step5_container">
      {
        transactionDetailsReload ? (
          <MakePaymentTransactionDetails type={type} data={TransactionSuccess} onSave={onSave}  onSave2={onSave2} />
        ) : (
          <>
            {
              notifyPayment ? (
                <NotifyPayment loader={loader} setLoader={setLoader} counterpart={formik.counterpart} formik={formik}  data={TransactionSuccess} type={type} />
              ) : (
                <>
                  {loaderStatus == "success_USD" ?
                    (
                      <Payment_success />
                    ) :
                    loaderStatus === "pending" ? (<ProgressComponent
                      text="Your transaction is being processed. Please do not navigate away from this screen" />)

                      : loaderStatus === "success" ? (<SuccessComponent
                        heading="Transaction successful" />)

                        : loaderStatus === "failed" ? (<PaymentFailed
                          heading="Transaction failed"
                          text="We apologise as we were not able to process your payment request. Please try again later."
                          buttonText="Close"
                          onClick={() => { window.location.href = '/balance' }} />)

                          : loaderStatus === "waiting" ? (<Waiting
                            heading="Transaction in progress"
                            text1="The payment is still being processed. We request for your patience as we work to complete the process."
                            text2="In the meantime, please do not attempt making the transaction again. You can review the transaction status on the balance screen under pending transactions."
                            onClick={() => { window.location.href = '/balance' }}
                            buttonText="Close"
                          />)

                            : ""
                  }
                </>
              )
            }
          </>
        )
      }
    </Box>
  )
}

export default Step5

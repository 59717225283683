import React, { useState } from "react";
import { PaymentInitiated } from "../../../../../../../ui/ReuseableComponents/confirmdetails/confirmdetails";
import { userActions } from "../../../../../../../../_actions";
import { useDispatch } from "react-redux";

const Step5 = ({ dataSave, stepupdatefun, formik, selectedCurrency, selectedFlag, setUseLayout }: any) => {
    const [buttonType, setButtonType] = useState('BillsApproval');
    const [transactin_summary, setTransaction_summry] = useState<any>();
    const [data_aapro_data, setData_aapro_data] = useState<any>();
    const [Displaydata, setDisplaydata] = useState(false);
  const dispatch = useDispatch()

    return (
        <PaymentInitiated
            data={dataSave}
            counterpart={dataSave}
            id={data_aapro_data}
            setDisplaydata={setDisplaydata}
            type="Send money"
            onSave={() => {
                dispatch(userActions.getOtp('') as any)
                stepupdatefun(4);
                setUseLayout("excludenavlayout");
            }}
            onCancel={() => {
                stepupdatefun(2);
                setUseLayout("wideboxlayout");
                setButtonType("AuthoriseTransaction");
            }}
        />
    );
};

export default Step5;

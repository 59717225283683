import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import DatePickerll from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, IconButton, Typography } from '@mui/material';
import { alpha, createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';

const DatePopup = (props: any) => {
  const [show, setShowlist] = useState(props.show == true ? 0 : '');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const today = new Date();

  const onClose_topup = () => {
    props.onClose();
    setShowlist('300');
  };
  const OnSave = () => {
    props.OnSave([startDate,endDate]);

  }
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Modal
          show={show == 0 ? true : false}
          onHide={() => {
            setShowlist('');
          }}
          backdrop="static"
          keyboard={false}
          aria-labelledby="ModalHeader"
          className="topup-modal"
          centered
          size="lg"
        >
          {/* <Modal.Header closeButton>
          <Modal.Title>Select the date range for the statement</Modal.Title>
        </Modal.Header> */}
          <Box className="CloseContenar">
            <IconButton onClick={() => {
              onClose_topup();
            }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="heding_contean">
            <Typography>
              Select the date range for the statement
            </Typography>
          </Box>
          <Modal.Body>
            <div className="label" style={{marginLeft: '10px'}}>Date Range</div>
            <div className="type-card-list" style={{
              alignItems:"left"
            }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: "0px"
                }}
                // className={`type-card conteanaha_data_ketete`}

              >
                <div>
                  <DatePickerll
                    placeholderText="Start date"
                    dateFormat="dd MMMM yyyy"
                    className="dashboard-container__datepicker2"
                    selected={startDate}
                    onChange={(date: Date) => {
                      //@ts-ignore
                      setStartDate(date);
                    
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
               // className={`type-card`}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    //className={`type-card`}

                  >
                    <DatePickerll
                      placeholderText="End date"
                      dateFormat="dd MMMM yyyy"
                      className="dashboard-container__datepicker2"
                      selected={endDate}
                      onChange={(date: Date) => {
                        //@ts-ignore
                        setEndDate(date);
                      
                      }}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      maxDate={today}
                    />
                  </div>
                </div>
              </div>
              </div>

            

          </Modal.Body>

          <Modal.Footer>
            {/* <Button
            className="white-btn"
            onClick={() => {
             
            }}
          >
            Cancel
          </Button> */}
            <Button
              id="next_button_dataaa"
              className="green-btn"
              disabled={!startDate || !endDate}
              onClick={() => {
                setShowlist(1);
                OnSave()
              }}
            >
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      </ThemeProvider>
    </>
  )
}
export default DatePopup;
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { string } from 'yup';
import styled from '@emotion/styled';
import { CustomInput } from '../../../../../../../../ui/ReuseableComponents/Inputs/Inputs';
import { DefaultBtn } from '../../../../../../../../ui/ReuseableComponents/Buttons/Buttons';
import "./tabs.scss"
import { Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import BankAccount from './Components/BankAccount/BankAccount';
import MpesaComponent from './Components/Mpesa/MpesaComponent';
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}



function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 6 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function FullWidthTabs() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const StyledTabPanel = styled(TabPanel)`
  padding: 0;
`;

    return (
        <Box sx={{ width: "100%" }}>
            <AppBar
                style={{ boxShadow: "none" }}
                position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                        "& .MuiTabs-indicator": {
                            backgroundColor: "#025041",
                        },
                        "& .MuiTab-root.Mui-selected": {
                            color: "#025041",
                        },
                        "& .MuiTab-root": {
                            textTransform: "none",
                            textAlign: "left !important",
                            fontFamily: "Poppins",
                            // padding:"12px 0px",
                            // alignItems:"baseline"
                        },
                    }}
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    style={{
                        backgroundColor: '#ffffff',
                        color: '#000',
                        borderBottom: "1px solid #DDDDDD",
                        boxShadow: "none",
                    }}
                >
                    <Tab label="Bank Account" {...a11yProps(0)} />
                    <Tab label="Mpesa" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <StyledTabPanel value={value} index={0} dir={theme.direction}>
                <BankAccount/>
            </StyledTabPanel>
            <StyledTabPanel value={value} index={1} dir={theme.direction}>
                <MpesaComponent/>
            </StyledTabPanel>
        </Box>
    );
}

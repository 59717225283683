import React, { useCallback, useState, useEffect } from "react";
import Transaction_Summary from "../../../../../Bulk_Payments/components/Transaction_Summary/Transaction_Summary";
const Step4 = ({ stepupdatefun, setUseLayout }: any) => {

  return (

    <Transaction_Summary
      onSave={(fototer_detels: any) => {
        stepupdatefun(4)
        setUseLayout("popuplayout")
      }}
      margin="0px"
      display="none"
      onCancel={() => {
        stepupdatefun(2)
        setUseLayout("defaultlayout") 
      }} />

  )
}

export default Step4
import Modal from "react-bootstrap/Modal";
import "../ReuseableComponents/Notifications/TransactionExit/transactionexitcom.scss"
import { Box } from "@mui/material";
import { LightBtn, DefaultBtn } from "../ReuseableComponents/Buttons/Buttons";

const Model1Line = ({ TransactionExist, onCloseTransactionExist, Message, onSubmit  }: any) => {
  return (
    <Modal
      show={TransactionExist}
      onHide={onCloseTransactionExist}
      backdrop="static"
      keyboard={false}
      aria-labelledby="ModalHeader"
      className="topup_modal"
      centered
      size="lg"
    >
      <Box
        style={{
          borderRadius: "5px",
          background: "#FFF",
          width: "100%",
          height: "35vh",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box
            style={{ textAlign: "center", padding: "30px 20px 30px 20px" }}
          >
            <p className="transactionexistmessage">{Message}</p>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "30%",
                margin: "auto",
                paddingTop: "24px",
                gap: "7px",
              }}
            > 
              <LightBtn
                text="Cancel"
                style={{ width: "45%" }}
                onClick={() => {
                  onCloseTransactionExist();
                }}
              />
              <DefaultBtn
                text="Proceed"
                style={{ width: "45%" }}
                onClick={() => {
                  onSubmit();
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default Model1Line
import { Box } from "@mui/material"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from "react";
import { LightBtn, DefaultBtn, TextBtn } from "../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons"
import { CustomInput } from "../../../../../../../../../ui/ReuseableComponents/Inputs/Inputs"
import "./step3.scss"
import { Formik } from "formik";
import { AuthorizeTransaction } from "../../../../../../../../../ui/ReuseableComponents/AuthorizeTransaction/AuthorizeTransaction"
import { Form } from "react-bootstrap";

const Step3 = ({ setScheduleStep, time, setTime, setEnableButton, enableButton, schedulePaymentPayload, setUseLayout }: any) => {
  const [filedValue] = useState({
    Otp: ""
  });

  const validationSchema = Yup.object().shape({
    Otp: Yup.string()
      .length(6, 'OTP must be exactly 6 digits')
      .required('OTP is required'),
  });



  return (

    <Formik
      initialValues={filedValue}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        schedulePaymentPayload(values.Otp)
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit} >
          <Box className="schedule_step3_container d-flex flex-column justify-content-center">

            <AuthorizeTransaction
              time={time}
              setTime={setTime}
              setEnableButton={setEnableButton}
              formik={formik}
              enableButton={enableButton}
              onCancel={() => {
                setScheduleStep(1)
                setEnableButton(true)
                setTime(0)
                setUseLayout("defaultlayout")
              }}
            />

          </Box>
        </Form>
      )}
    </Formik>

  )
}

export default Step3
import React, { useCallback, useState, useEffect } from "react";
import { AuthoriseTransaction } from "../../../../../Bulk_Payments/components/AuthoriseTransaction/AuthoriseTransaction";

const Step5 = ({ stepupdatefun }: any) => {
    const [loading, setLoading] = useState(false);
    const [data_aapro_data, setData_aapro_data] = useState<any>()
    const [time, setTime] = useState(0);
    const [enableButton, setEnableButton] = useState(false);


    return (

        <AuthoriseTransaction time={time}
            handleSubmit_resend={undefined}
            enableButton={enableButton}
            id={data_aapro_data}
            loading={loading}
            onSave={() => { stepupdatefun(5) }}
            onCancel={() => { stepupdatefun(3) }}
        />

    )
}

export default Step5
import { Box, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { DefaultBtn } from "../../../../../../../../../../ui/ReuseableComponents/Buttons/Buttons";
import { PayBilComponent, SendMoneyComponent, TillNumberComponent } from "./methoadCom";
import PhoneInput from "react-phone-input-2";
import "./mpesa.scss";

const MpesaComponent = ({openBillId, MethoadTitel, methodError, loaderReander, countrySortcode, setPayOutMethodsByCountryId, PayOutMethodsByCountryId, mobilePaymentmethoad, currency, setActiveButton, activeButton, mobileMoneyMethods, formik, setFormik }: any) => {
    const [selectedMethod, setSelectedMethod] = useState<string>("Till Number");

    const handleSelection = (method: string) => {
        setSelectedMethod(method);
        // setFormik({ ...formik, payment_method: method });
    };

      const handleFormikChange = (updatedData: any) => {
            setEditData((prev: any) => ({ ...prev, ...updatedData }));
        };
    
    
        const [editData, setEditData] = useState(formik);
    
        const handleContinue = () => {
            setFormik((prevFormik: any) => {
                const updatedBills = prevFormik.UploadedBulk.map((b: any) =>
                    b.id === openBillId ? { ...b, ...editData } : b
                );
                return { ...prevFormik, UploadedBulk: updatedBills };
            });
            // onClose();
        };
    console.log("edited data",editData)

    return (
        <Box>
            {/* Buttons */}
            <Box className="d-flex justify-content-center align-items-center gap-2 mb-5">
                <Button
                    className={`mpesa_btn ${selectedMethod === "Till Number" ? "active active-btn" : ""}`}
                    onClick={() => handleSelection("Till Number")}
                >
                    Till number
                </Button>
                <Button
                    className={`mpesa_btn ${selectedMethod === "Pay Bill" ? "active active-btn" : ""}`}
                    onClick={() => handleSelection("Pay Bill")}
                >
                    Pay bill
                </Button>

                <Button
                    className={`mpesa_btn ${selectedMethod === "Send Money" ? "active active-btn" : ""}`}
                    onClick={() => handleSelection("Send Money")}
                >
                    Send money
                </Button>
            </Box>

            {/* Conditional Rendering */}
            <Box>
                {selectedMethod === "Send Money" && (

                    <SendMoneyComponent editData={editData} handleFormikChange={handleFormikChange} countrySortcode={countrySortcode} />

                )}
                {selectedMethod === "Pay Bill" && (
                    <>

                        <PayBilComponent editData={editData} handleFormikChange={handleFormikChange}/>
                    </>
                )}
                {selectedMethod === "Till Number" && (
                    <>

                        <TillNumberComponent editData={editData} handleFormikChange={handleFormikChange}/>
                    </>
                )}
            </Box>

            <Box>
                <DefaultBtn text="Save" style={{ width: "100%", marginTop: "30px" }} />
            </Box>
        </Box>
    );
};

export default MpesaComponent;

import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import styles from "./Bulktebal.module.scss";
import { Divider, Typography } from "@mui/material";
import { selectAllCounterpartsListData } from "../../../../../../_reducers/counterparts.reducer";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Papa from "papaparse";
import moment from "moment";
import { InputGroup } from "react-bootstrap";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import MaskedInput from "react-text-mask";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getProperties,
  getUser,
  isUserAuthenticated,
} from "../../../../../../_helpers";
import { counterpartsActions } from "../../../../../../_actions/counterparts.actions";
import {
  Formik,
  Field,
  ErrorMessage,
  useField,
  useFormikContext,
  FieldArray,
  getIn,
} from "formik";
import * as Yup from "yup";
import { payablesActions } from "../../../../../../_actions/payables.actions";
import { selectAllPayablesListData } from "../../../../../../_reducers/payables.reducer";
import "../../../../../auth/Auth.css";
import { ThemeProvider, createTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CssBaseline, CircularProgress } from "@mui/material";
import amplitude from "amplitude-js";
import Multiselect from "multiselect-react-dropdown";
import posthog from "posthog-js";

interface Data {
  transaction_date: string;
  reference: string;
  Type: string;
  beneficiary: string;
  transaction_amount: string;
  description: string;
}

function createData(
  transaction_date: string,
  reference: string,
  Type: string,
  beneficiary: string,
  description: string,
  transaction_amount: string
): Data {
  return {
    transaction_date,
    reference,
    Type,
    beneficiary,
    description,
    transaction_amount,
  };
}
const AddIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z"
      fill="#025041"
    />
  </svg>
);
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}
function TablePaginationActions(props: any) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "transaction_date",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "reference",
    numeric: false,
    disablePadding: true,
    label: "Beneficiary",
  },
  {
    id: "Type",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "beneficiary",
    numeric: true,
    disablePadding: false,
    label: "Phone number",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Business Address ",
  },
  {
    id: "transaction_amount",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },
];

const DEFAULT_ORDER = "asc";
const DEFAULT_ORDER_BY = "transaction_date";
const DEFAULT_ROWS_PER_PAGE = 5;

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

export default function EnhancedTable() {
  const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = React.useState<keyof Data>(DEFAULT_ORDER_BY);
  const [selected, setSelected] = React.useState<any>([]);
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [visibleRows, setVisibleRows] = React.useState<Data[] | null>(null);
  // const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = React.useState(0);
  const [datatebal, setDatatebal] = React.useState([]);
 
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filedata, setFile] = React.useState("");
  const [lisrture, setTuredata] = React.useState(false);
  const [age, setAge] = React.useState("");
  const [checkbox, setChecbox] = React.useState(false);
  const [isError, setIserror] = React.useState<any>();
  const counterpartsListData = useSelector((state) =>
    selectAllCounterpartsListData(state)
  );
  const [counterpartsList, setCounterpartsList] = React.useState<any>([]);
  const [counterpartsListRender, setCounterpartsListRender] = useState(false);
  const [dataselect, setDataselect] = useState("");
  const [tagsList, setTagsList] = useState<any>([]);

  const [tagsListRender, setTagsListRender] = useState(true);
  const [newdate, setNewdate] = useState("");
  const [selectedValue, setSelectedValue] = useState(new Date());
  const [selectedList, setSelectedList] = useState<any>([]);

  const [render, setRender] = useState(true);
  const [users, setUsers] = useState<any>();
  const [isUsers, isSetUsers] = useState<any>(true);
  const [loading, setLoading] = useState(false);
  const [buttonType, setButtonType] = useState("");
  const [payablesListRender, setPayablesListRender] = useState(false);
  const [loaderData, setLoaderData] = useState(false);

  let navigate = useNavigate();

  const dispatch = useDispatch();



  const [patternDataArray, setPatternDataArray] = React.useState<any>([
    {
      id: "",
      supplier_id: "",
      document_id: "",
      invoice_date: "",
      due_date: "",
      amount: "",
      description: "",
      categories: [],
      is_current_price: false,
    },
  ]);
  const onSelect = (selectedList: any) => {
    setSelectedList(selectedList);
  };
 

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datatebal.length) : 0;

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const payablesListData = useSelector((state) =>
    selectAllPayablesListData(state)
  );
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (isUsers && getUser) {
      isSetUsers(false);
      setUsers(getUser);
    }
  });

  const listteball = () => {
    const listtebal: any = localStorage.getItem("Bulkdata");
    if (!!listtebal && !!counterpartsList) {
      // localStorage.setItem('Bulkdata',patternDataArray)
      setDatatebal(JSON.parse(listtebal));
    
      var newData = [];
      for (let index = 0; index < JSON.parse(listtebal).length; index++) {
        var element = JSON.parse(listtebal)[index];
       
        var payeename = "";
        const payeeSearc = !!element["Payee Name*"]
          ? element["Payee Name*"]
          : [];
        const findresults: any = counterpartsList.find((item: any) => {
          return item.counterpart_name == element["Payee Name*"];
        });
        const findresults_fastname: any = counterpartsList.find(function (
          arrayElement: any
        ) {
          payeename =
            arrayElement.counterpart_type == "individual"
              ? arrayElement.contact_first_name +
              " " +
              arrayElement.contact_last_name
              : arrayElement.name;
         
          const payeeSearc = !!element["Payee Name*"]
            ? element["Payee Name*"].split(" ")
            : [];
          var SearchResult = false;
          for (let index = 0; index < payeeSearc.length; index++) {
            const element1 = payeeSearc[index];
            SearchResult =
              payeename.toLowerCase().search(element1.toLowerCase()) != -1
                ? true
                : false;
            if (SearchResult == true) {
              break;
            }
          }
          return SearchResult;
        });
        // const findresults_fastname = counterpartsList.find(function (arrayElement: any) {
        //     element['Payee Name*'] == arrayElement.counterpart_name
        //     const payeeSearc = !!element['Payee Name*'] ? element['Payee Name*'].split(" ") : [];
        //     var SearchResult = false;
        //     for (let index = 0; index < payeeSearc.length; index++) {
        //         const element1 = payeeSearc[index];
        //         SearchResult = payeename.toLowerCase().search(element1.toLowerCase()) != -1 ? true : false;
        //         if (SearchResult == true) {
        //             break;
        //         }

        //     }
        //     return SearchResult;
        // });

        const findresultsCat: any = tagsList.find(function (item: any) {
          const catName = item.name;
          const catSearc = element["Category*"];
          return catName.search(catSearc) != -1 ? true : false;
        });
       
        if (!element["Payee Name*"]) {
          break;
        }
        const dateInvoice = element["Invoice Date*(DD-MM-YYYY)"];
       
        var amountReples =
          element["Amount*"] == "Enter amount in KES"
            ? "0"
            : element["Amount*"];
        let result = amountReples.replace("KES", "");
      
        var object = {
          id: index,
          supplier_id:
            !!findresults && !!findresults.monite_counterpart_id
              ? findresults.monite_counterpart_id
              : !!findresults_fastname &&
                !!findresults_fastname.monite_counterpart_id
                ? findresults_fastname.monite_counterpart_id
                : "",
          counterpart_name:
            !!findresults && !!findresults.counterpart_name
              ? findresults.counterpart_name
              : !!findresults_fastname &&
                !!findresults_fastname.counterpart_name
                ? findresults_fastname.counterpart_name
                : "",
          Nametwo: element["Invoice Number*"],
          document_id: element["Invoice Number*"],
          invoice_date: moment(dateInvoice).format("YYYY-MM-DD"),
          due_date: moment(element["Due Date*(DD-MM-YYYY)"]).format(
            "YYYY-MM-DD"
          ),
          amount: result,
          description: element["Description"],
          categories:
            !!findresultsCat && !!findresultsCat.id
              ? [
                {
                  id: findresultsCat.id,
                  name: findresultsCat.name,
                },
              ]
              : [],
          is_current_price: false,
        };
        newData.push(JSON.parse(JSON.stringify(object)));
      }
      setPatternDataArray(newData);
    }
  };
  useEffect(() => {
    if (tagsListRender && counterpartsListData?.GETTAGS_SUCCESS !== undefined) {
      let options: any = [];
      if (counterpartsListData.GETTAGS_SUCCESS.length > 0) {
        counterpartsListData.GETTAGS_SUCCESS.map((item: any, ind: number) => {
          options.push({
            id: item.id,
            name: item.name,
          });
        });
      }
      setTagsListRender(false);
      setTagsList(options);
     
    }
  }, [counterpartsListData, tagsListRender]);
  useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(counterpartsActions.getAllCounterparts(setCounterpartsListRender) as any);
      dispatch(counterpartsActions.getAllTags() as any);
    }
  }, [render, isUserAuthenticated]);
  const handleCSVUpload = (file: any) => {
    // setCsvnamedata(file.name)
    if (!!file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
         
          // var userJSON = userJSON.find(item => item.buyPrice === '');

         
          // element.buyPrice
          // if (results.data === '') {
          //     break;
          //   }
          // listteball()
          var newData = [];
          for (let index = 0; index < results.data.length; index++) {
            const element: any = results.data[index];
           
            var payeename = "";
            // const findresults: any = counterpartsList.find(function (arrayElement: any) {
            //     payeename = arrayElement.counterpart_type == 'individual' ? arrayElement.contact_first_name + ' ' + arrayElement.contact_last_name : arrayElement.name
          
            //     const payeeSearc = !!element['Payee Name*'] ? element['Payee Name*'].split(" ") : [];
            //     var SearchResult = false;
            //     for (let index = 0; index < payeeSearc.length; index++) {
            //         const element1 = payeeSearc[index];
            //         SearchResult = payeename.toLowerCase().search(element1.toLowerCase()) != -1 ? true : false;
            //         if (SearchResult == true) {
            //             break;
            //         }

            //     }
            //     return SearchResult;
            // });
            const findresults: any = counterpartsList.find((item: any) => {
              return item.counterpart_name == element["Payee Name*"];
            });
           
            const findresultsCat: any = tagsList.find(function (item: any) {
              const catName = item.name;
              const catSearc = element["Category*"];
              return catName.search(catSearc) != -1 ? true : false;
            });
            const findresults_fastname: any = counterpartsList.find(function (
              arrayElement: any
            ) {
              payeename =
                arrayElement.counterpart_type == "individual"
                  ? arrayElement.contact_first_name +
                  " " +
                  arrayElement.contact_last_name
                  : arrayElement.name;
            
              const payeeSearc = !!element["Payee Name*"]
                ? element["Payee Name*"].split(" ")
                : [];
              var SearchResult = false;
              for (let index = 0; index < payeeSearc.length; index++) {
                const element1 = payeeSearc[index];
                SearchResult =
                  payeename.toLowerCase().search(element1.toLowerCase()) != -1
                    ? true
                    : false;
                if (SearchResult == true) {
                  break;
                }
              }
              return SearchResult;
            });
          
            if (!element["Payee Name*"]) {
              break;
            }
          
            var amountReples =
              element["Amount*"] == "Enter amount in KES"
                ? "0"
                : element["Amount*"];
            var object = {
              id: index,
              supplier_id:
                !!findresults && !!findresults.monite_counterpart_id
                  ? findresults.monite_counterpart_id
                  : !!findresults_fastname &&
                    !!findresults_fastname.monite_counterpart_id
                    ? findresults_fastname.monite_counterpart_id
                    : "",
              counterpart_name:
                !!findresults && !!findresults.counterpart_name
                  ? findresults.counterpart_name
                  : !!findresults_fastname &&
                    !!findresults_fastname.counterpart_name
                    ? findresults_fastname.counterpart_name
                    : "",
              Nametwo: element["Invoice Number*"],
              document_id: element["Invoice Number*"],
              invoice_date: moment(element["Invoice Date*(DD-MM-YYYY)"]).format(
                "YYYY-MM-DD"
              ),
              due_date: moment(element["Due Date*(DD-MM-YYYY)"]).format(
                "YYYY-MM-DD"
              ),
              amount: amountReples,
              description: element["Description"],
              categories:
                !!findresultsCat && !!findresultsCat.id
                  ? [
                    {
                      id: findresultsCat.id,
                      name: findresultsCat.name,
                    },
                  ]
                  : [],
              is_current_price: false,
            };
            newData.push(JSON.parse(JSON.stringify(object)));
          }
          // patternDataArray.concat(newData)
          setPatternDataArray(patternDataArray.concat(newData));
          if (!!results.data) {
            setTuredata(true);
          }
          // localStorage.setItem('Bulkdata', JSON.stringify(results.data))
          // setUserJSON(results.data)
          // setListcsvdsts(results.data)
        },
      });
    }
  };
  const handleChange = (event: any) => {
    setAge(event.target.value as string);
  };
  React.useEffect(() => {
    // setTimeout(() => {
    listteball();
    // }, 500);

    let rowsOnMount: any = stableSort(
      datatebal,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );
    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    setVisibleRows(rowsOnMount);
  }, [filedata, counterpartsList, tagsList]);
  // const theme = createTheme({
  //     status: {
  //         danger: orange[500],

  //     },
  // });
  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => {
      const isAsc = orderBy === newOrderBy && order === "asc";
      const toggledOrder = isAsc ? "desc" : "asc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(
        datatebal,
        getComparator(toggledOrder, newOrderBy)
      );
      const updatedRows: any = sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      setVisibleRows(updatedRows);
    },
    [order, orderBy, page, rowsPerPage]
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      var new_array_two = [];

      for (let j = 0; j < patternDataArray.length; j++) {
        const element = patternDataArray[j];
        if (
          element.is_current_price === false &&
          event.target.checked == true
        ) {
         

          element.is_current_price = true;
          new_array_two.push(element);
        } else {
          // element.is_current_price = false;
          // new_array_two.push(element)
          new_array_two.push(JSON.parse(JSON.stringify(element)));
        }
      }
      setPatternDataArray(new_array_two);
      const newSelected = patternDataArray.map((n: any, index: any) => index);
      setSelected(newSelected);
      return;
    } else {
      var new_array_two = [];
      for (let j = 0; j < patternDataArray.length; j++) {
        const element = patternDataArray[j];
        if (
          element.is_current_price === true &&
          event.target.checked == false
        ) {
          

          element.is_current_price = false;
          new_array_two.push(element);
        } else {
          // element.is_current_price = false;
          // new_array_two.push(element)
          new_array_two.push(JSON.parse(JSON.stringify(element)));
        }
      }
      setPatternDataArray(new_array_two);
      setSelected([]);
    }
    // if (event.target.checked) {
    //     const newSelected = patternDataArray.map((n: any, index: any) => index);
    //     setSelected(newSelected);
    //     return;
    // }
    // setSelected([]);
  };
  const dataarr = [];
  const properties = getProperties();
  const eventProperties = {
    userId: !!properties && !!properties.user_id ? properties.user_id : null,
    userName: !!properties && !!properties.name ? properties.name : null,
    email: !!properties && !!properties.email ? properties.email : null,
    pageUrl: window.location.href,
  };
  const handleClick = (
    event: React.MouseEvent<unknown>,
    index: number,
    Nametwo: string
  ) => {
    const selectedIndex = selected.indexOf(index);

    setIserror(index);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
  useEffect(() => {
    if (
      counterpartsListRender &&
      counterpartsListData?.GETCOUNTERPARTS_SUCCESS !== undefined
    ) {
      setCounterpartsListRender(false);
      setCounterpartsList(counterpartsListData?.GETCOUNTERPARTS_SUCCESS);
    }
  }, [counterpartsListData, counterpartsListRender]);
  const isSelected = (name: string, data: boolean) =>
    selected.indexOf(name) !== -1 && data == true;
  const numberMask = createNumberMask({
    prefix: "",
    suffix: "", // This will put the dollar sign at the end, with a space.
  });

  useEffect(() => {
    if (
      payablesListRender &&
      payablesListData?.BULKPAYABLES_SUCCESS !== undefined
    ) {
      setPayablesListRender(false);
      const paybleD = payablesListData?.BULKPAYABLES_SUCCESS.map((el: any) => {
        return el.id;
      });
      const bodyData = {
        payables: paybleD,
      };
      dispatch(counterpartsActions.submitPaybles(bodyData) as any);
    }
  }, [payablesListData, payablesListRender]);

  const formInitailData = {
    users: patternDataArray,
  };
  const validationSchema = Yup.object().shape({
    users: Yup.array().of(
      Yup.object().shape({
        supplier_id: Yup.string()
          // .min(2, "*Names must have at least 2 characters")
          // .max(100, "*Names can't be longer than 100 characters")
          .required("Please enter the payee's name."),
        document_id: Yup.string().required("Please enter the invoice number."),
        invoice_date: Yup.string().required("Please enter the invoice date."),
        due_date: Yup.string().required("Please provide the due date."),
        amount: Yup.string().required("Please enter the amount."),
        // description: Yup.string().required('Description is required')
      })
    ),

    // blog: Yup.string()
    // .url("*Must enter URL in http://www.example.com format")
    // .required("*URL required")
  });

  function getStyles(errors: any, fieldName: any) {
    if (getIn(errors, fieldName)) {
      return {
        border: "1px solid red",
      };
    }
  }
  function getStylesExtra(errors: any, fieldName: any) {
    if (getIn(errors, fieldName)) {
      return {
        height: "40px",
        borderRadius: "0",
        fontSize: "12px",
        border: "1px solid red",
      };
    } else {
      return {
        height: "40px",
        borderRadius: "0",
        fontSize: "12px",
      };
    }
  }

  function deleteRow() {
    // const list = [...patternDataArray];
    // for (let index = 0; index < list.length; index++) {
    //     const is_current_price = list[index].is_current_price;
    //     if (is_current_price == true) {
    //         list.splice(index, 1);
    //     }
    // }
    const list = patternDataArray.filter(function (entry: any, index: any) {
      return entry.is_current_price === false;
    });
   
    // var newArray = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
    

      // if (element.is_current_price == true) {
      //     element.is_current_price = false;
      //     newArray.push(element)
      // }
    }
    
    setSelected([]);
    setPatternDataArray(list);
  }

  // const enableDelete: any = patternDataArray.filter(function (entry: any) { return entry.is_current_price === true; });


  const submitPaybales = () => {
   
  };
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: { primary: { main: "#000" } },
  });

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <ThemeProvider theme={theme}>
          <Paper
            sx={{
              width: "100%",
              boxShadow: "0px 4px 12px rgba(89, 87, 87, 0.16)",
            }}
          >
            <Toolbar
              className={styles.tebaldata_hedinh}
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                // ...(numSelected > 0 && {
                //     bgcolor: (theme) =>
                //         alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                // }),
              }}
            >
              {loaderData && (
                <Box
                  sx={{
                    display: "flex",
                    position: "absolute",
                    top: 150,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 10,
                  }}
                >
                  <CircularProgress color="success" />
                </Box>
              )}
              <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
                className={styles.addnewtext}
              >
                Confirm Imported Bills
              </Typography>
              <div className={styles.div_uplod_data_use}>
                <label className={styles.save_btn22}>
                  Upload More <AddIcon />
                  <input
                    id="inputTag"
                    className={styles.addaffafagga}
                    type="file"
                    onChange={(file: any) => {
                      handleCSVUpload(file.target.files[0]);
                      setFile(file.target.files[0]);
                    }}
                    accept=".csv"
                  />
                </label>
              </div>
            </Toolbar>
            <Divider className={styles.addborderin} />
            <div className={styles.addlowlis}></div>
            <Formik
              enableReinitialize
              initialValues={formInitailData}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
               
                const ReqBody: any[] = [];
                patternDataArray?.map((values: any, index: any) => {
                  var itemid: any = [];
                  if (!!values.categories && values.categories.length > 0) {
                    itemid = values.categories?.map((item: any) => {
                      return item.id;
                    });
                  }
           
                  if (values.is_current_price == true) {
                    const body = {
                      currency: "KES",
                      counterpart_id: values.supplier_id,
                      counterpart_name: values.counterpart_name,
                      sender: users?.data.email,
                      amount: parseInt(values.amount.replaceAll(",", "")) * 100,
                      document_id: values.document_id,
                      due_date: moment(values.due_date).format("YYYY-MM-DD"),
                      issued_at: moment(values.invoice_date).format(
                        "YYYY-MM-DD"
                      ),
                      description: values.description,
                      tags: !!itemid ? itemid : [],
                    };
                    ReqBody.push(body);
                  }
                });
                const amount_valid: any = ReqBody.find(
                  (data: any) => data.amount == 0
                );

               
                var datalog = [];
                // const findItem_name = values.find((data: any) => data.amount == '')
                if (amount_valid == undefined) {
                  setLoading(true);
                  dispatch(
                    payablesActions.bulkpayables({ data: ReqBody }) as any
                  );
                  localStorage.setItem("Bulkdata", JSON.stringify([]));
                  if (buttonType == "submit") {
                    const event = "New Bills Imported";
                    const event_two = "Bill Submitted for Approval";
                    amplitude.getInstance().logEvent(event, event_two);
                    posthog.capture(event, eventProperties);
                    setPayablesListRender(true);
                    setLoaderData(true);
                    setLoading(true);
                    setTimeout(() => {
                      setLoading(false);
                    }, 6000);
                  } else {
                    const event = "New Bills Imported";
                    amplitude.getInstance().logEvent(event, eventProperties);
                    posthog.capture(event, eventProperties);
                    setLoaderData(true);
                    setTimeout(() => {
                      window.location.href = "/bills";
                      setLoading(false);
                    }, 6000);
                  }
                }

              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <TableContainer className={styles.tebal_contenar_data}>
                      <Table
                        className={styles.addteballist}
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <TableHead className={styles.hedarliath}>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                color="primary"
                                indeterminate={
                                  selected.length > 0 &&
                                  selected.length < patternDataArray.length
                                }
                                checked={
                                  patternDataArray.length > 0 &&
                                  selected.length === patternDataArray.length
                                }
                                onChange={handleSelectAllClick}
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                              />
                            </TableCell>
                            <TableCell>Payee Name</TableCell>
                            <TableCell>Invoice Number</TableCell>
                            <TableCell>Invoice Date</TableCell>
                            <TableCell>Due Date</TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>Categories</TableCell>
                            <TableCell className={styles.listactivetabal}>
                              Description
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <FieldArray
                            name="users"
                            render={(arrayHelpers) => {
                              const users =
                                values.users.length < 0
                                  ? patternDataArray
                                  : patternDataArray;
                              return (
                                <>
                                  {users && users.length > 0
                                    ? users.map((user: any, index: any) => {
                                      var value = user;
                                    

                                      const isItemSelected = isSelected(
                                        index,
                                        user.is_current_price
                                      );
                                      const labelId = `enhanced-table-checkbox-${index}`;
                                      return (
                                        <TableRow hover key={index}>
                                          <TableCell>
                                            <Checkbox
                                              color="primary"
                                              // checked={user.is_current_price}
                                              name={`users.${index}.is_current_price`}
                                              checked={isItemSelected}
                                              onClick={(event: any) => {
                                                handleClick(
                                                  event,
                                                  index,
                                                  user.id
                                                );
                                               
                                                var newArray = [];
                                                for (
                                                  let j = 0;
                                                  j < patternDataArray.length;
                                                  j++
                                                ) {
                                                  const element =
                                                    patternDataArray[j];
                                                  // if (index == j) {
                                                  if (
                                                    user.is_current_price ===
                                                    false &&
                                                    index == j
                                                  ) {
                                                   

                                                    user.is_current_price =
                                                      true;
                                                    newArray.push(user);
                                                  } else if (
                                                    user.is_current_price ===
                                                    true &&
                                                    index == j
                                                  ) {
                                                    user.is_current_price =
                                                      false;
                                                    newArray.push(user);
                                                  } else {
                                                    newArray.push(
                                                      JSON.parse(
                                                        JSON.stringify(
                                                          element
                                                        )
                                                      )
                                                    );
                                                  }
                                                }
                                                setPatternDataArray(newArray);
                                              }}
                                              // onChange={(event: any) => {
                                           
                                              // }}
                                              inputProps={{
                                                "aria-labelledby": labelId,
                                              }}
                                            />
                                            {/* <span
                                                                                            style={{ cursor: 'pointer', color: 'red' }}
                                                                                            onClick={() => {
                                                                                               
                                                                                                const list = [...patternDataArray];
                                                                                                list.splice(index, 1);
                                                                                                // setInputListfast(inputlfast);
                                                                                                setPatternDataArray(list);
                                                                                            }
                                                                                            } // remove a friend from the list
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </span> */}
                                          </TableCell>

                                          <TableCell>
                                            <Box>
                                              <Form.Select
                                                name={`users.${index}.supplier_id`}
                                                style={getStyles(
                                                  errors,
                                                  `users.${index}.supplier_id`
                                                )}
                                                className={
                                                  styles.tabalinputdata
                                                }
                                                onChange={(e: any) => {
                                                  setDataselect(
                                                    e.target.value
                                                  );

                                                  let text = e.target.value;
                                                 

                                                  var newArray = [];
                                                  for (
                                                    let j = 0;
                                                    j <
                                                    patternDataArray.length;
                                                    j++
                                                  ) {
                                                    const element =
                                                      patternDataArray[j];
                                                    if (index == j) {
                                                      value.supplier_id =
                                                        text;
                                                      const selected =
                                                        counterpartsList.find(
                                                          (item: any) => {
                                                            return (
                                                              item.monite_counterpart_id ==
                                                              text
                                                            );
                                                          }
                                                        );
                                                      const payeeName =
                                                        selected.counterpart_type ==
                                                          "individual"
                                                          ? selected.contact_first_name +
                                                          " " +
                                                          selected.contact_last_name
                                                          : selected.name;
                                                      value.counterpart_name =
                                                        payeeName;
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            value
                                                          )
                                                        )
                                                      );
                                                    } else {
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            element
                                                          )
                                                        )
                                                      );
                                                    }
                                                  }
                                                  setPatternDataArray(
                                                    newArray
                                                  );
                                                }}
                                              // value={value.Name}
                                              // className={touched.supplier_id && errors.supplier_id ? " doc-form-input error" : 'doc-form-input'}
                                              >
                                                <option selected value="">
                                                  Select payee
                                                </option>
                                                {/* <option>ADDA</option> */}
                                                {counterpartsList?.map(
                                                  (
                                                    arrayElement: any,
                                                    index: number
                                                  ) => {
                                                    const selected =
                                                      value.supplier_id ==
                                                        arrayElement.monite_counterpart_id
                                                        ? true
                                                        : false;
                                                    return (
                                                      <option
                                                        selected={selected}
                                                        value={
                                                          arrayElement.monite_counterpart_id
                                                        }
                                                        key={index + 1}
                                                      >
                                                        {arrayElement.counterpart_type ==
                                                          "individual"
                                                          ? arrayElement.contact_first_name +
                                                          " " +
                                                          arrayElement.contact_last_name
                                                          : arrayElement.name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </Form.Select>
                                              {/* <ErrorMessage name={`users.${index}.supplier_id`} component="div" className={styles.addnewdaddaa} /> */}

                                              {value.is_current_price ==
                                                true && value.Name == "" ? (
                                                <p
                                                  className={
                                                    styles.peregarafdata
                                                  }
                                                >
                                                  it is error
                                                </p>
                                              ) : (
                                                " "
                                              )}
                                              {/* {isError ? isError.name == "" && isError.index == index ? <p>is error in name</p> : "" : ""} */}

                                              <input
                                                type="hidden"
                                                name="counterpart_name"
                                                value={value.counterpart_name}
                                              />
                                            </Box>
                                          </TableCell>
                                          <TableCell>
                                            <Box>
                                              <TextField
                                                style={getStyles(
                                                  errors,
                                                  `users.${index}.document_id`
                                                )}
                                                // sx={{ flex: 1 }}

                                                className={
                                                  styles.tabalinputdata + ""
                                                }
                                                // margin="normal"
                                                name={`users.${index}.document_id`}
                                                onChange={(value1: any) => {
                                                  let text =
                                                    value1.target.value;
                                                  var newArray = [];
                                                  for (
                                                    let j = 0;
                                                    j <
                                                    patternDataArray.length;
                                                    j++
                                                  ) {
                                                    const element =
                                                      patternDataArray[j];
                                                    if (index == j) {
                                                      value.document_id =
                                                        text;
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            value
                                                          )
                                                        )
                                                      );
                                                    } else {
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            element
                                                          )
                                                        )
                                                      );
                                                    }
                                                  }
                                                  setPatternDataArray(
                                                    newArray
                                                  );
                                                }}
                                                // onBlur={handleBlur}
                                                value={value.document_id}
                                              // variant="outlined"
                                              />
                                              {/* <ErrorMessage name={`users.${index}.document_id`} component="div" className={styles.addnewdaddaa} /> */}
                                            </Box>
                                          </TableCell>
                                          <TableCell>
                                            <Box>
                                              <input
                                                type="date"
                                                style={getStyles(
                                                  errors,
                                                  `users.${index}.invoice_date`
                                                )}
                                                id={styles.addnewinput}
                                                className={
                                                  styles.tabalinputdata22
                                                }
                                                // onChange={setInvoiceDate}
                                                value={value.invoice_date}
                                                // 2014-02-25
                                                // format="yyyy-MM-dd"moment(Date.now()).format('yyyy-mm-dd')
                                                name={`users.${index}.invoice_date`}
                                                onChange={(e: any) => {
                                                  setNewdate(e.target.value);
                                                  let text = e.target.value;
                                                  var newArray = [];
                                                  for (
                                                    let j = 0;
                                                    j <
                                                    patternDataArray.length;
                                                    j++
                                                  ) {
                                                    const element =
                                                      patternDataArray[j];
                                                    if (index == j) {
                                                      value.invoice_date =
                                                        text;
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            value
                                                          )
                                                        )
                                                      );
                                                    } else {
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            element
                                                          )
                                                        )
                                                      );
                                                    }
                                                  }
                                                  setPatternDataArray(
                                                    newArray
                                                  );
                                                }}
                                              // onBlur={handleBlur}
                                              />
                                              {/* <ErrorMessage name={`users.${index}.invoice_date`} component="div" className={styles.addnewdaddaa} /> */}
                                            </Box>
                                          </TableCell>
                                          <TableCell>
                                            <Box>
                                              <input
                                                type="date"
                                                style={getStyles(
                                                  errors,
                                                  `users.${index}.due_date`
                                                )}
                                                id={styles.addnewinput}
                                                className={
                                                  styles.tabalinputdata22
                                                }
                                                // onChange={setInvoiceDate}
                                                value={value.due_date}
                                                // moment(value.due_date).format('YYYY-MM-DD')
                                                // format="yyyy-MM-dd"moment(Date.now()).format('yyyy-mm-dd')
                                                name={`users.${index}.due_date`}
                                                onChange={(e: any) => {
                                                  setNewdate(e.target.value);
                                                  let text = e.target.value;
                                                  

                                                  var newArray = [];
                                                  for (
                                                    let j = 0;
                                                    j <
                                                    patternDataArray.length;
                                                    j++
                                                  ) {
                                                    const element =
                                                      patternDataArray[j];
                                                    if (index == j) {
                                                      value.due_date = text;
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            value
                                                          )
                                                        )
                                                      );
                                                    } else {
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            element
                                                          )
                                                        )
                                                      );
                                                    }
                                                  }
                                                  setPatternDataArray(
                                                    newArray
                                                  );
                                                }}
                                              // onBlur={handleBlur}
                                              />
                                              {/* <ErrorMessage name={`users.${index}.due_date`} component="div" className={styles.addnewdaddaa} /> */}
                                            </Box>
                                          </TableCell>
                                          <TableCell style={{ width: "14%" }}>
                                            <Box>
                                              <InputGroup
                                                className="doc-input-wrapper input"
                                                style={{ padding: 0 }}
                                              >
                                                <MaskedInput
                                                  style={getStylesExtra(
                                                    errors,
                                                    `users.${index}.amount`
                                                  )}
                                                  mask={numberMask}
                                                  // style={{ height: '40px', borderRadius: '0' , fontSize: '12px'}}
                                                  className="doc-form-input form-control"
                                                  placeholder="Total"
                                                  id={
                                                    styles.add_id_select_data_list
                                                  }
                                                  value={value?.amount}
                                                  //onChange={handleSubmit}
                                                  name={`users.${index}.amount`}
                                                  onChange={(e) => {
                                                    let text = e.target.value;
                                                   
                                                    var newArray = [];
                                                    for (
                                                      let j = 0;
                                                      j <
                                                      patternDataArray.length;
                                                      j++
                                                    ) {
                                                      const element =
                                                        patternDataArray[j];
                                                      if (index == j) {
                                                        value.amount = text;
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              value
                                                            )
                                                          )
                                                        );
                                                      } else {
                                                        newArray.push(
                                                          JSON.parse(
                                                            JSON.stringify(
                                                              element
                                                            )
                                                          )
                                                        );
                                                      }
                                                    }
                                                    setPatternDataArray(
                                                      newArray
                                                    );
                                                  }}
                                                  // onBlur={handleBlur}
                                                  // value="5593.00"
                                                  aria-describedby="basic-addon3"
                                                />
                                                <InputGroup.Text
                                                  className="input-mask"
                                                  id="basic-addon3"
                                                  style={{
                                                    borderRadius: "00",
                                                  }}
                                                >
                                                  KES
                                                </InputGroup.Text>
                                              </InputGroup>
                                              {/* <ErrorMessage name={`users.${index}.amount`} component="div" className={styles.addnewdaddaa} /> */}
                                            </Box>
                                          </TableCell>
                                          <TableCell>
                                            <Box>
                                              <Multiselect
                                                // className={styles.tabalinputdata}
                                                // style={}
                                                className={
                                                  styles.addnewselect
                                                }
                                                options={tagsList} // Options to display in the dropdown
                                                selectedValues={
                                                  value.categories
                                                } // Preselected value to persist in dropdown
                                                onSelect={(
                                                  selectedList: any
                                                ) => {
                                                  var itemid: any =
                                                    selectedList?.map(
                                                      (item: any) => {
                                                        return item.id;
                                                      }
                                                    );

                                                  var newArray = [];
                                                  for (
                                                    let j = 0;
                                                    j <
                                                    patternDataArray.length;
                                                    j++
                                                  ) {
                                                    const element =
                                                      patternDataArray[j];
                                                    if (index == j) {
                                                      value.categories =
                                                        selectedList;
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            value
                                                          )
                                                        )
                                                      );
                                                    } else {
                                                      newArray.push(
                                                        JSON.parse(
                                                          JSON.stringify(
                                                            element
                                                          )
                                                        )
                                                      );
                                                    }
                                                  }
                                                  setPatternDataArray(
                                                    newArray
                                                  );
                                                }} // Function will trigger on select event
                                                // onRemove={onRemove} // Function will trigger on remove event
                                                displayValue="name" // Property selectedList name to display in the dropdown options */}
                                                closeOnSelect={true}
                                              />
                                              {/* <ErrorMessage name={`users.${index}.categories`} component="div" className={styles.addnewdaddaa} /> */}
                                            </Box>
                                          </TableCell>
                                          <TableCell>
                                            <TextField
                                              type="text"
                                              // style={getStyles(errors, `users.${index}.description`)}
                                              // sx={{ flex: 1 }}
                                              // error={value.isQuantityError}
                                              fullWidth
                                              // helperText={value.isQuantityError ? 'Enter Buy Quantity' : ''}
                                              className={
                                                styles.tabalinputdata
                                              }
                                              margin="normal"
                                              // name={`users.${index}.description`}
                                              onChange={(value1: any) => {
                                                let text =
                                                  value1.target.value;
                                                var newArray = [];
                                                for (
                                                  let j = 0;
                                                  j < patternDataArray.length;
                                                  j++
                                                ) {
                                                  const element =
                                                    patternDataArray[j];
                                                  if (index == j) {
                                                    value.description = text;
                                                    newArray.push(
                                                      JSON.parse(
                                                        JSON.stringify(value)
                                                      )
                                                    );
                                                  } else {
                                                    newArray.push(
                                                      JSON.parse(
                                                        JSON.stringify(
                                                          element
                                                        )
                                                      )
                                                    );
                                                  }
                                                }
                                                setPatternDataArray(newArray);
                                              }}
                                              value={value.description}
                                              variant="outlined"
                                            />
                                            {/* <ErrorMessage name={`users.${index}.description`} component="div" className={styles.addnewdaddaa} /> */}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })
                                    : null}
                                </>
                              );
                            }}
                          />
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div
                      className={styles.add_ajajjahahag}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "20px 0px",
                      }}
                    >
                      <div className={styles.add_delete_data_listtss}>
                        {selected.length > 0 && (
                          <Button
                            className={styles.save_btn_delete_btn}
                            onClick={() => {
                              deleteRow();
                            }}
                          >
                            Delete
                          </Button>
                        )}

                        <Button
                          color="#025041"
                          className={styles.white_btn}
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            navigate("/bills");
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className={styles.div_laiiaajjajjaa}>
                        <Button
                          disabled={selected.length > 0 ? false : true}
                          type="submit"
                          color="#025041"
                          className={styles.save_btn}
                          onClick={() => setButtonType("save")}
                        >
                          {loading == true && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          Save
                        </Button>
                        <Button
                          disabled={selected.length > 0 ? false : true}
                          type="submit"
                          color="#025041"
                          className={styles.white_btn}
                          onClick={() => setButtonType("submit")}
                        >
                          {loading == true && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          Submit for Approval
                        </Button>
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </Paper>
        </ThemeProvider>
      </Box>
    </>
  );
}

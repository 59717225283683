import { Box, Typography } from "@mui/material";
import { CustomInput } from "../Inputs/Inputs";
import { Button } from "react-bootstrap";
import "./authorizeTransaction.scss";
import { getUser } from "../../../../_helpers";

export const AuthorizeTransaction = ({
  enableButton,
  formik,
  time,
  onResendOtp,
  onCancel,
  onClick
}: any) => {

  const user = getUser()?.data ?? null;


  return (
    <Box className="otp_box d-flex flex-column justify-content-center">
      <Box className="d-flex flex-column justify-content-center align-items-center">
        <Typography className="otp_authorize_heading">Authorise Transaction</Typography>
        <Typography className="authorize_sub_heading">
          To authorise the transaction, please enter the security code that was sent to{' '}
          {user.mobile && (
            <span>{`${user.mobile.slice(0, 4)}*****${user.mobile.slice(-1)}`}</span>
          )}
          {user.mobile && user.email && ' or '}
          {user.email && (
            <span>{`${user.email[0]}******${user.email.slice(user.email.indexOf('@') - 1)}`}</span>
          )}
        </Typography>
      </Box>
      <Box>
        <label className="otp_label">Security code</label>
        <CustomInput className="otp_input form-control"
          placeholder="123456"
          value={formik.values.Otp}
          onChange={(e: any) => formik.setFieldValue('Otp', e.target.value)}
        />
        {formik.touched.Otp && formik.errors.Otp ? (
          <Typography>{formik.errors.Otp}</Typography>
        ) : ""
        }
      </Box>

      <Box className="btn_wrapper d-flex justify-content-between w-100">

        <Button className="otp_button white" onClick={onCancel}>Cancel</Button>

        <Box className="d-flex gap-2">
          <Button
            className="otp_button green"
            disabled={enableButton == true ? false : true}
            onClick={onResendOtp}
          >Resend OTP in {time > 0 ? time - 1 : time} Sec</Button>
          <Button
            type='submit'
            className="otp_button green"
            onClick={onClick} >Authorize</Button>
        </Box>
      </Box>
    </Box>
  )
}
